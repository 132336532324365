import { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import VelocityLoader from 'components/shared/LoadingIndicator';
import { useWindowSize } from 'hooks';
import { VehicleSummary } from 'models';

import './ConditionReport.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export interface IPDFViewerProps {
  vehicle: VehicleSummary;
}

const PDFViewer = ({ vehicle }: IPDFViewerProps) => {
  const [numPages, setNumPages] = useState<number | null>(0);
  const [width, setWidth] = useState(useWindowSize().width);
  const viewportRef = useRef<HTMLDivElement>(null);
  const [docKey, setDocKey] = useState(0);
  const [retryFailed, setRetryFailed] = useState(false);
  const documentUrl = vehicle.sections.filter(
    (section) => section.id === 'conditionReportMedia'
  )?.[0].links.self;
  const label = 'PDF';

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  function handleResize() {
    if (viewportRef.current) {
      const { offsetWidth } = viewportRef.current;
      setWidth(offsetWidth);
    }
  }

  function onError() {
    if (docKey < 3) {
      setDocKey(docKey + 1);
    }
    if (docKey >= 3) {
      setRetryFailed(true);
    }
    return <div />;
  }

  function errorMessage() {
    if (docKey < 1) {
      return ``;
    }
    return retryFailed
      ? `${label} is not currently available`
      : `${label} is taking longer than expected, please wait.`;
  }

  useEffect(() => {
    if (!window) return;
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    handleResize();
  }, []);

  return (
    <div ref={viewportRef} style={{ width: '100%' }}>
      <div className="pdf-retry">{errorMessage()}</div>
      <Document
        file={documentUrl}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={<VelocityLoader />}
        renderMode="canvas"
        error={onError}
        key={docKey} // incrementing key via onRetry will cause the document to re-render, forcing another request
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            loading={<></>}
            width={width}
            className="pdf-page-layout"
          />
        ))}
      </Document>
    </div>
  );
};

export default PDFViewer;
