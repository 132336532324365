import { navigate } from '@reach/router';
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import Sidebar from 'react-sidebar';

import { useChangeLocation, useLocations, useLogoutMutation } from 'api';
import { removeUUIDs } from 'api/authenticate/urlManagement';
import { CrmVendor, getEmailTemplate } from 'api/engage/crmEmailTemplate';
import permissions from 'common/permissions';
import strings from 'common/strings';
import SubmitFeedbackModal from 'components/shared/SubmitFeedbackModal';
import {
  useCurrentLocationId,
  usePermissions,
  useTourMenu,
  useTourMenuActions,
} from 'hooks';
import { Location } from 'models';
import {
  DASHBOARD_RELATIVE,
  DEALER_SETTINGS_RELATIVE,
  INDEX,
  INVENTORY_RELATIVE,
  INVOICING_RELATIVE,
  REPORTS_RELATIVE,
} from 'navigation/routes';
import { UploadItem, UploadStatus, UploadType } from 'store/uploads/types';
import useUploadStore from 'store/uploads/useUploadStore';
import copyStringToClipboard from 'utils/clipboard';

import Alert from '../Alert';
import ChangePasswordModal from '../Header/ChangePasswordModal';
import EditProfileModal from '../Header/EditProfileModal';
import VINInquiryModal from '../Header/VINInquiryModal';
import PaneHeader from '../PaneHeader';

import './HamburgerMenu.scss';

const zIndex = 1001;

const sidebarStyles = {
  sidebar: { background: 'white', width: '320px', zIndex: zIndex.toString() },
  overlay: { top: '60px', zIndex: (zIndex - 1).toString() },
};

interface HamburgerMenuProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({ isOpen, setIsOpen }) => {
  return (
    <Sidebar
      open={isOpen}
      sidebar={<SidebarContent isOpen={isOpen} setIsOpen={setIsOpen} />}
      styles={sidebarStyles}
    >
      <></>
    </Sidebar>
  );
};

const SidebarContent: React.FC<HamburgerMenuProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const activeRooftopId = useCurrentLocationId();
  const { mutateAsync: logout } = useLogoutMutation();
  const uploadStoreData = useUploadStore((uploadStore) => ({
    uploads: uploadStore.uploads,
    closeUppyInstances: uploadStore.closeUppyInstances,
  }));
  const { actionType: tourMenuActionType, name: tourName } = useTourMenu();
  const tourMenuActions = useTourMenuActions();
  const changeLocationQuery = useChangeLocation();
  const locationsQuery = useLocations();
  const rooftopList = locationsQuery?.data ?? [];

  const [isEditProfileModalOpen, setIsEditProfileModalOpen] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);
  const [isSubmitFeedbackModalOpen, setIsSubmitFeedbackModalOpen] =
    useState(false);
  const tabs: any[] = [];
  const { hasPermission } = usePermissions();
  if (
    hasPermission(permissions.PLUGIN_VELOCITYENGAGE_VIEW_DASHBOARD) ||
    hasPermission(permissions.DASHBOARD_INVENTORY_NEEDING_ATTENTION_VIEW) ||
    hasPermission(permissions.DASHBOARD_SUMMARY_VIEW)
  ) {
    tabs.push({ label: strings.DASHBOARD, route: DASHBOARD_RELATIVE });
  }
  if (
    hasPermission(permissions.RECON_VIEW) ||
    hasPermission(permissions.INVENTORY_VIEW)
  ) {
    tabs.push({ label: strings.INVENTORY, route: INVENTORY_RELATIVE });
  }
  if (
    hasPermission(permissions.REPORTS_STEP_REPORT_VIEW) ||
    hasPermission(permissions.PLUGIN_VELOCITYENGAGE_CORPORATE_REPORT_VIEW)
  ) {
    tabs.push({ label: strings.REPORTS, route: REPORTS_RELATIVE });
  }
  if (hasPermission(permissions.INVOICING_VIEW)) {
    tabs.push({ label: strings.VENDOR, route: INVOICING_RELATIVE });
  }
  const [isCopyTemplateSuccess, setIsCopyTemplateSuccess] = useState(false);
  const [isCopyTemplateError, setIsCopyTemplateError] = useState(false);
  const [isVINInquiryModalOpen, setIsVINInquiryModalOpen] = useState(false);
  const [isVINInquiryModalError, setIsVINInquiryModalError] = useState(false);

  function handleCloseModal() {
    setIsEditProfileModalOpen(false);
    setIsOpen(false);
  }

  const handleEditProfileClick = () => {
    setIsEditProfileModalOpen(true);
    setIsOpen(false);
  };

  const handleChangePasswordClick = () => {
    setIsChangePasswordModalOpen(true);
  };

  const closeChangePasswordModal = () => {
    setIsChangePasswordModalOpen(false);
  };

  const closeVINInquiryModal = () => {
    setIsVINInquiryModalOpen(false);
  };

  const handleSettingsClick = () => {
    navigate(DEALER_SETTINGS_RELATIVE, { replace: true });
  };

  const handleCopyEmailTemplateClick = async (crmVendor: CrmVendor) => {
    try {
      const emailTemplate: string = await getEmailTemplate(crmVendor);
      copyStringToClipboard(emailTemplate);
      setIsCopyTemplateSuccess(true);
    } catch (e) {
      setIsCopyTemplateError(true);
    }
  };

  const handleVINInquiryClick = async () => {
    setIsVINInquiryModalOpen(true);
  };

  const onCloseCopyTemplateAlert = () => {
    setIsCopyTemplateSuccess(false);
    setIsCopyTemplateError(false);
  };

  const handleSubmitFeedbackClick = () => {
    setIsSubmitFeedbackModalOpen(true);
    setIsOpen(false);
  };

  const handleCloseFeedbackModal = () => {
    setIsSubmitFeedbackModalOpen(false);
    setIsOpen(false);
  };

  const handleLogoutClick = async () => {
    const activeNoteUploads = uploadStoreData.uploads.filter(
      (upload: UploadItem) =>
        upload.uploadStatus === UploadStatus.UPLOADING &&
        upload.meta.target.type === UploadType.NOTE
    );
    const deleteNotePromises = activeNoteUploads.map((upload: UploadItem) => {
      if (upload.onUploadCancel) {
        return upload.onUploadCancel();
      }
      return undefined;
    });

    await Promise.all(deleteNotePromises);
    uploadStoreData.closeUppyInstances();
    await logout();
    await navigate(INDEX);
  };

  const handleRooftopSelect = async (rooftopId: string) => {
    const currentRoute = removeUUIDs(window.location.pathname, false);
    const routePath = currentRoute.includes(INVENTORY_RELATIVE)
      ? `${rooftopId}/${INVENTORY_RELATIVE}`
      : `/${rooftopId}${currentRoute}`;

    if (rooftopId !== activeRooftopId) {
      await changeLocationQuery.mutateAsync({
        locationId: rooftopId,
        routePath,
      });
    }
  };

  const handleLinkSelect = async (eventKey: string) => {
    setIsOpen(false);
    const tab = tabs.find((tab) => tab.route === eventKey);
    if (tab) {
      if (!activeRooftopId) return;
      await navigate(`/${activeRooftopId}/${tab.route}`);
      window.scrollTo(0, 0);
    }
  };

  const handleShowTour = () => {
    tourMenuActions.showTour();
    setIsOpen(false);
  };

  const renderMobileProfileMenu = () => (
    <div className="d-sm-none">
      <Dropdown.Divider />
      <Dropdown.Header>{strings.PROFILE_OPTIONS}</Dropdown.Header>
      <Dropdown.Item
        className="Dropdown-mobileFeedback"
        onClick={handleSubmitFeedbackClick}
      >
        {strings.SUBMIT_FEEDBACK}
      </Dropdown.Item>
      {hasPermission(permissions.USERS_SELF_UPDATE) && (
        <>
          <Dropdown.Item
            className="Dropdown-mobileProfileEdit"
            onClick={handleEditProfileClick}
          >
            {strings.EDIT_PROFILE}
          </Dropdown.Item>
          <Dropdown.Item
            className="Dropdown-mobileProfilePassword"
            onClick={handleChangePasswordClick}
          >
            {strings.CHANGE_PASSWORD}
          </Dropdown.Item>
          <Dropdown.Divider />
        </>
      )}
      {
        <Dropdown.Item
          className="Dropdown-mobileSettings"
          onClick={handleSettingsClick}
        >
          {strings.SETTINGS}
        </Dropdown.Item>
      }
      {hasPermission(permissions.PLUGIN_VELOCITYENGAGE_CREATE) && (
        <>
          <Dropdown.Divider />
          <Dropdown.Item
            onClick={() => handleCopyEmailTemplateClick('DEALERSOCKET')}
          >
            {strings.VELOCITY_ENGAGE_EMAIL_TEMPLATE_DEALERSOCKET}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => handleCopyEmailTemplateClick('DRIVE_CENTRIC')}
          >
            {strings.VELOCITY_ENGAGE_EMAIL_TEMPLATE_DRIVE_CENTRIC}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => handleCopyEmailTemplateClick('ELEAD')}>
            {strings.VELOCITY_ENGAGE_EMAIL_TEMPLATE_ELEAD}
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => handleCopyEmailTemplateClick('VIN_SOLUTIONS')}
          >
            {strings.VELOCITY_ENGAGE_EMAIL_TEMPLATE_VIN_SOLUTIONS}
          </Dropdown.Item>
        </>
      )}
      {(hasPermission(permissions.PLUGIN_VELOCITYWINDOWSTICKER_VIEW) ||
        hasPermission(permissions.PLUGIN_VELOCITYENGAGE_VIEW)) && (
        <Dropdown.Item onClick={handleVINInquiryClick}>
          {strings.VIN_INQUIRY}
        </Dropdown.Item>
      )}
      {tourMenuActionType && (
        <Dropdown.Item onClick={handleShowTour}>{tourName}</Dropdown.Item>
      )}
      <Dropdown.Divider />
      <Dropdown.Item
        className="Dropdown-mobileLogout"
        onClick={handleLogoutClick}
      >
        {strings.LOGOUT}
      </Dropdown.Item>
    </div>
  );

  const renderMobileRooftopMenu = () => {
    return (
      <div className="d-md-none">
        <Dropdown.Divider />
        <Dropdown.Header>{strings.ROOFTOP_OPTIONS}</Dropdown.Header>
        {rooftopList.map((rooftop: Location) => (
          <Dropdown.Item
            className={`Dropdown-mobileRooftop ${
              rooftop.id === activeRooftopId ? 'active-rooftop' : ''
            }`}
            key={rooftop.id}
            eventKey={rooftop.id}
            onSelect={(key) => handleRooftopSelect(`${key}`)}
          >
            {rooftop.name}
          </Dropdown.Item>
        ))}
      </div>
    );
  };

  const renderMobileMenu = () => (
    <div>
      <Dropdown.Header className="d-md-none">
        {strings.SECTIONS}
      </Dropdown.Header>
      {tabs.map((tab) => (
        <Dropdown.Item
          className={'Dropdown-' + tab.route}
          key={tab.route}
          eventKey={tab.route}
          onSelect={(key) => handleLinkSelect(`${key}`)}
        >
          {tab.label}
        </Dropdown.Item>
      ))}
      {renderMobileRooftopMenu()}
      {renderMobileProfileMenu()}
    </div>
  );

  return (
    <div>
      <PaneHeader
        appearance="mobile"
        onCloseButtonClick={() => setIsOpen(!isOpen)}
        title="Menu"
        flipView
      />
      {renderMobileMenu()}

      {isCopyTemplateError && (
        <Alert
          open={isCopyTemplateError}
          contentProps={{
            message: strings.API_MESSAGE,
            variant: 'error',
            onClose: onCloseCopyTemplateAlert,
          }}
        />
      )}
      {isVINInquiryModalError && (
        <Alert
          open={isVINInquiryModalError}
          contentProps={{
            message: strings.VEHICLE_NOT_FOUND_ALERT_MESSAGE,
            variant: 'error',
            onClose: () => setIsVINInquiryModalError(false),
          }}
          handleClose={() => setIsVINInquiryModalError(false)}
          duration={5000}
        />
      )}
      {isCopyTemplateSuccess && (
        <Alert
          open={isCopyTemplateSuccess}
          contentProps={{
            message: strings.COPY_EMAIL_TEMPLATE_SUCCESS,
            variant: 'success',
            onClose: onCloseCopyTemplateAlert,
          }}
          handleClose={onCloseCopyTemplateAlert}
          duration={5000}
        />
      )}
      {isEditProfileModalOpen && (
        <EditProfileModal onClose={handleCloseModal} />
      )}
      {isChangePasswordModalOpen && (
        <ChangePasswordModal onClose={closeChangePasswordModal} />
      )}
      {isSubmitFeedbackModalOpen && (
        <SubmitFeedbackModal onClose={handleCloseFeedbackModal} />
      )}
      {isVINInquiryModalOpen && (
        <VINInquiryModal
          onClose={closeVINInquiryModal}
          onError={() => setIsVINInquiryModalError(true)}
        />
      )}
    </div>
  );
};

export default HamburgerMenu;
