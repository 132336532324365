import { MoreVert } from '@material-ui/icons';
import { forwardRef } from 'react';
import { Dropdown } from 'react-bootstrap';

import { useDeleteNoteAttachment, useToken } from 'api';
import { permissions, strings } from 'common';
import { usePermissions } from 'hooks';

interface VehicleCardAttachmentEditDropDownProps {
  attachmentId: string;
  noteId: string;
  userId: string;
  vehicleId: string;
}
const VehicleCardAttachmentEditDropDown = ({
  attachmentId,
  noteId,
  userId,
  vehicleId,
}: VehicleCardAttachmentEditDropDownProps) => {
  const { data: sessionData } = useToken();
  const { hasPermission } = usePermissions();
  const { deleteNoteAttachmentAsync } = useDeleteNoteAttachment();
  const canUserDelete = hasPermission(
    permissions.INVENTORY_VDP_NOTES_ATTACHMENT_DELETE
  );
  const isCurrentUserMessage = userId === sessionData?.user?.id;

  if (!canUserDelete || !isCurrentUserMessage) {
    return null;
  }

  const CustomToggle = forwardRef(({ onClick }: any, ref: any) => (
    <div
      role="button"
      onClick={(e: any) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
      }}
      ref={ref}
      tabIndex={0}
    >
      <MoreVert />
    </div>
  ));

  const deleteAttachment = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    deleteNoteAttachmentAsync({ attachmentId, noteId, vehicleId });
  };

  return (
    <Dropdown drop="left">
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" />
      <Dropdown.Menu className="border-0 drop-shadow">
        <Dropdown.Item className="py-2" onClick={deleteAttachment}>
          {strings.DELETE_ATTACHMENT}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default VehicleCardAttachmentEditDropDown;
