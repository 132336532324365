import { AddAPhoto } from '@material-ui/icons';
import { useQueryClient } from '@tanstack/react-query';
import { FC, useEffect, useState } from 'react';
import TruncatedList from 'react-truncate-list';

import {
  useAddAttachmentToTask,
  useDeleteTasksAttachment,
  usePresignedAttachmentTaskUploadUrl,
  useTasksAttachments,
  useUploadFileToS3,
} from 'api';
import { isImage } from 'common/images';
import permissions from 'common/permissions';
import strings from 'common/strings';
import Alert from 'components/shared/Alert';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import ModalCarousel from 'components/shared/ModalCarousel';
import PermissionsGate from 'components/shared/PermissionsGate';
import { usePermissions } from 'hooks';
import {
  Attachment,
  convertTaskAttachmentsToVehicleImage,
  Task,
  VehicleSummary,
} from 'models';
import { ALLOWED_FILETYPES_STRING } from 'store/uploads/types';

import MobileMediaViewer from '../MobileFlatTasksBody/MobileMediaViewer';
import {
  AttachmentUploadProvider,
  useAttachmentUploadContext,
} from './AttachmentUploadProviderTask';
import {
  SubmittedAttachments,
  SubmittedAttachmentState,
} from './AttachmentUploadProviderTask/types';

import './TaskPhotos.scss';
import 'react-truncate-list/dist/styles.css';

type MediaProps = {
  attachments: Attachment[];
  onAdd: () => void;
  onClick: (index: number) => void;
  isLoading?: boolean;
};

interface TaskPhotosProps {
  task?: Task;
  disabled?: boolean;
  vehicle: VehicleSummary;
  mediaListRender?: (props: MediaProps) => React.ReactElement;
}

const TaskPhotosContent: FC<TaskPhotosProps> = ({
  task,
  disabled = false,
  vehicle,
  mediaListRender,
}) => {
  const { hasPermission } = usePermissions();
  const { data, isLoading } = useTasksAttachments(
    vehicle?.vehicleCard?.id ?? '',
    task?.id ?? ''
  );
  const { data: attachments = [] } = data ?? {};
  const attachmentRemoveMutation = useDeleteTasksAttachment(
    vehicle?.vehicleCard?.id ?? '',
    task?.id ?? ''
  );
  const queryClient = useQueryClient();
  const { uploadFileToS3 } = useUploadFileToS3();

  useEffect(() => {
    if (attachments.length === 0) {
      setIsGalleryOpen(false);
    }
  }, [attachments.length]);
  const { getNewPresignedAttachmentUploadUrl } =
    usePresignedAttachmentTaskUploadUrl();
  const { addAttachmentToTask } = useAddAttachmentToTask();
  const [maxFilesError, setMaxFilesError] = useState(false);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [galleryIndex, setGalleryIndex] = useState(0);
  const [tempAttachmentLoading, setTempAttachmentLoading] = useState(false);
  const [errorUploadingAttachment, setErrorUploadingAttachment] =
    useState(false);
  const { stagedAttachments, setStagedAttachments, setSubmittedAttachments } =
    useAttachmentUploadContext();

  const setAttachmentState = (
    index: number,
    state: SubmittedAttachmentState
  ) => {
    setSubmittedAttachments((curSubmittedAttachments) =>
      curSubmittedAttachments.map((mapState, mapIndex) => {
        if (mapIndex !== index) {
          return mapState;
        }

        return {
          ...mapState,
          ...state,
        };
      })
    );
  };

  const openGallery = (photoIndex: number = 0) => {
    setGalleryIndex(photoIndex);
    setIsGalleryOpen(true);
  };
  const closeGallery = (
    event: React.SyntheticEvent<HTMLButtonElement, Event>
  ) => {
    event.stopPropagation();
    event.preventDefault();
    setIsGalleryOpen(false);
  };

  const deleteImage = (id: string) => {
    if (disabled) {
      return;
    }
    setGalleryIndex(0);
    attachmentRemoveMutation.mutate(id);
  };

  const refreshAttachments = () => {
    const path = `/inventory/${vehicle?.vehicleCard?.id ?? ''}/tasks/${
      task?.id ?? ''
    }/attachments`;
    queryClient.invalidateQueries([path]);
  };

  const handleCloseMaxFilesError = () => setMaxFilesError(false);
  const handleCloseApiError = () => setErrorUploadingAttachment(false);

  const handles3Upload = async (files: File[]) => {
    const vehicleId = vehicle?.vehicleCard?.id ?? '';
    const taskId = task?.id ?? '';
    if (taskId && stagedAttachments) {
      const attachments: SubmittedAttachments = files.map((file) => ({
        file,
        taskId,
        status: 'pending',
      }));

      setSubmittedAttachments(attachments);
      setStagedAttachments([]);
      setTempAttachmentLoading(true);

      const requests = attachments.map(async ({ file, taskId }, index) => {
        try {
          const {
            data: { uploadUrl, s3AttachmentId },
          } = await getNewPresignedAttachmentUploadUrl({
            vehicleId,
            taskId,
            contentType: file.type,
          });

          setAttachmentState(index, {
            status: 'uploading',
            progress: 0,
          });
          await uploadFileToS3({
            file,
            presignedUrl: uploadUrl,
            onUploadProgress: ({ loaded, total }) => {
              if (total !== undefined) {
                setAttachmentState(index, {
                  status: 'uploading',
                  progress: (loaded / total) * 100,
                });
              }
            },
          });

          await addAttachmentToTask({
            vehicleId,
            taskId,
            s3AttachmentId,
            attachmentName: file.name,
          });

          setAttachmentState(index, { status: 'completed' });
          refreshAttachments();
          setTempAttachmentLoading(false);

          await Promise.all(requests);
          setSubmittedAttachments([]);
        } catch (error) {
          console.error('Error uploading picture to task error: ', error);
        }
      });
    }
  };

  const handleChange = async (event: Event) => {
    const target = event.target as HTMLInputElement;
    const files = target.files ? Array.from(target.files) : [];
    if (files.length > 5) {
      setMaxFilesError(true);
      return;
    }
    handles3Upload(files);
  };
  const fileSelector = document.createElement('input');
  fileSelector.setAttribute('type', 'file');
  fileSelector.setAttribute('accept', ALLOWED_FILETYPES_STRING);
  fileSelector.setAttribute('multiple', 'multiple');
  fileSelector.onchange = handleChange;

  const hasCustomMediaList = !!mediaListRender;
  let mediaListElement = null;

  const handleMediaClick = (index: number) => {
    openGallery(index);
  };

  if (hasCustomMediaList) {
    mediaListElement = (
      <div style={{ overflowX: 'auto', flex: 1 }}>
        {mediaListRender?.({
          attachments,
          onAdd: () => {
            if (
              hasPermission(permissions.INVENTORY_VDP_TASKS_ATTACHMENT_CREATE)
            ) {
              return disabled ? false : fileSelector.click();
            }
          },
          onClick: handleMediaClick,
          isLoading: tempAttachmentLoading || isLoading,
        })}
      </div>
    );
  }

  return (
    <div className="TaskPhotos">
      {mediaListElement ?? (
        <TruncatedList
          className="TaskPhotos-photos"
          renderTruncator={({ hiddenItemsCount }) => {
            const lastIndex = attachments?.length - 1;
            const lastAttachment = attachments[lastIndex];
            const url =
              lastAttachment && lastAttachment.uri ? lastAttachment.uri : '';
            const concatNameToAttachment =
              url && lastAttachment.name && lastAttachment?.createdDate
                ? url + '/' + lastAttachment.name
                : '';
            return (
              !isLoading && (
                <div className="TaskPhotos-photos">
                  <div
                    className="TaskPhotos-photo-container TaskPhotos-photo-container-truncated"
                    role="none"
                    onClick={() => openGallery(0)}
                  >
                    {isImage(concatNameToAttachment) ? (
                      <img className="TaskPhotos-photo" src={url} alt="task" />
                    ) : (
                      <video className="TaskPhotos-photo" src={url} />
                    )}
                    <div className="TaskPhotos-photo-count-overlay">{`+${hiddenItemsCount}`}</div>
                  </div>
                </div>
              )
            );
          }}
        >
          {!isLoading &&
            attachments.map((photo: Attachment, index: number) => (
              <div
                key={photo.id}
                className="TaskPhotos-photo-container"
                role="none"
                onClick={() => openGallery(index)}
              >
                {isImage(photo?.name) ? (
                  <img
                    className="TaskPhotos-photo"
                    src={photo.uri}
                    alt="task"
                  />
                ) : (
                  <video className="TaskPhotos-photo" src={photo.uri} />
                )}
              </div>
            ))}
          {(tempAttachmentLoading || isLoading) && (
            <div className="TaskPhotos-photo-container TaskPhotos-loading-photo">
              <LoadingIndicator />
            </div>
          )}
        </TruncatedList>
      )}
      {!hasCustomMediaList && (
        <PermissionsGate
          permissions={[permissions.INVENTORY_VDP_TASKS_ATTACHMENT_CREATE]}
        >
          <div
            className={`TaskPhotos-photo-container TaskPhotos-add-photo ${
              disabled ? 'TaskPhotos-add-photo-disabled' : ''
            }`}
            role="none"
            onClick={() => (disabled ? false : fileSelector.click())}
          >
            <AddAPhoto />
          </div>
        </PermissionsGate>
      )}

      {mediaListElement ? (
        <MobileMediaViewer
          open={isGalleryOpen}
          onClose={closeGallery}
          onDelete={deleteImage}
          media={convertTaskAttachmentsToVehicleImage(attachments.slice())}
          selectedItemIndex={galleryIndex}
          canDelete={
            task?.status === 'COMPLETE' ||
            task?.status === 'APPROVED_PENDING_COMPLETE'
              ? false
              : hasPermission(permissions.INVENTORY_VDP_TASKS_ATTACHMENT_DELETE)
          }
        />
      ) : (
        <ModalCarousel
          modalIsOpen={isGalleryOpen}
          currentIndex={galleryIndex}
          images={convertTaskAttachmentsToVehicleImage(attachments.slice())}
          onClose={closeGallery}
          deletePermission={
            task?.status === 'COMPLETE' ||
            task?.status === 'APPROVED_PENDING_COMPLETE'
              ? 'NONE'
              : permissions.INVENTORY_VDP_TASKS_ATTACHMENT_DELETE
          }
          onDeleteImage={deleteImage}
          assignPermission="NONE"
        />
      )}
      {maxFilesError && (
        <Alert
          open={maxFilesError}
          duration={3000}
          handleClose={handleCloseMaxFilesError}
          contentProps={{
            message: strings.TOO_MANY_FILES,
            variant: 'error',
            onClose: handleCloseMaxFilesError,
          }}
        />
      )}
      {errorUploadingAttachment && (
        <Alert
          open={errorUploadingAttachment}
          duration={3000}
          handleClose={handleCloseApiError}
          contentProps={{
            message: strings.API_MESSAGE,
            variant: 'error',
            onClose: handleCloseApiError,
          }}
        />
      )}
    </div>
  );
};
const TaskPhotos = (props: TaskPhotosProps) => (
  <AttachmentUploadProvider>
    <TaskPhotosContent {...props}></TaskPhotosContent>
  </AttachmentUploadProvider>
);

export default TaskPhotos;
