import { omit } from 'lodash';
import { ChangeEvent, useMemo, useState } from 'react';

import {
  useCreateVehicleTasksBulk,
  useCurrentLocation,
  useTaskTemplates,
} from 'api';
import Checkbox from 'components/shared/Checkbox';
import Modal from 'components/shared/Modal';
import { useWindowSize } from 'hooks';
import { TaskTemplate } from 'models';

import { newTask, taskFromTaskTemplate } from '../taskUtils';

import './AddTasksModal.scss';

const HEADER_FOOTER_HEIGHT = 300;
type CustomTasksTextType = { [key: string]: string };

const AddTasksModal = ({
  vehicleId,
  open,
  onClose,
}: {
  vehicleId: string;
  open: boolean;
  onClose: () => void;
}) => {
  const { data: taskTemplateData } = useTaskTemplates();

  const tenant = useCurrentLocation();
  const tenantId = tenant?.id;

  const { data: originalTaskTemplates = [], meta } = taskTemplateData ?? {};
  const totalTasks = meta?.totalRecords;

  const createOrUpdateVehicleTasksBulkQuery =
    useCreateVehicleTasksBulk(vehicleId);

  const { height } = useWindowSize();

  const [customTaskTemplates, setCustomTaskTemplates] = useState<
    TaskTemplate[]
  >([]);

  const [checkedTaskIds, setCheckedTaskIds] = useState<any>({});

  const handleCheckboxOnClick = (taskTemplate: TaskTemplate) => {
    const id = taskTemplate.id;
    const currentValue = checkedTaskIds[id];

    if (currentValue) {
      setCheckedTaskIds(omit(checkedTaskIds, id));
      return;
    } else {
      if (taskTemplate?.id?.startsWith('custom')) {
        const customNumber = Number(taskTemplate.id.split('custom')[1]) || 0;
        if (customNumber === customTaskTemplates?.length) {
          const newCustomTemplate = {
            ...originalTaskTemplates?.[0],
            id: `custom${customTaskTemplates?.length + 1}`,
          };
          setCustomTaskTemplates([newCustomTemplate, ...customTaskTemplates]);
        }
      }
    }

    setCheckedTaskIds({ ...checkedTaskIds, [id]: taskTemplate });
  };

  const [filterText, setFilterText] = useState('');
  const handleOnFilterTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.target.value);
  };

  const [customTasksText, setCustomTasksText] = useState<CustomTasksTextType>(
    {}
  );

  const handleOnCustomTaskTextChange = (
    event: ChangeEvent<HTMLInputElement>,
    taskId: string
  ) => {
    setCustomTasksText({
      ...customTasksText,
      ...{ [taskId]: event.target.value },
    });
  };

  const taskTemplates = useMemo(() => {
    return [...customTaskTemplates, ...originalTaskTemplates];
  }, [originalTaskTemplates, customTaskTemplates]);

  const filteredTaskTemplates = useMemo(() => {
    const lowerCaseFilterText = filterText?.toLowerCase();
    return taskTemplates.filter(
      (taskTemplate) =>
        taskTemplate?.label?.toLowerCase().match(lowerCaseFilterText) ||
        taskTemplate?.description?.toLowerCase().match(lowerCaseFilterText) ||
        taskTemplate?.tenantName?.toLowerCase().match(lowerCaseFilterText)
    );
  }, [filterText, taskTemplates]);

  const handleOnPrimaryButtonClick = async () => {
    const tasksToCreate = [];
    for (const taskId in checkedTaskIds) {
      const taskTemplate = checkedTaskIds[taskId];
      if (taskTemplate) {
        const task = taskFromTaskTemplate(newTask(), taskTemplate);
        if (taskId.startsWith('custom')) {
          task.label = customTasksText[taskId];
        }
        tasksToCreate.push(task);
      }
    }

    await createOrUpdateVehicleTasksBulkQuery.mutateAsync(tasksToCreate);
    handleOnClose();
  };

  const handleOnClose = () => {
    setCheckedTaskIds({});
    setFilterText('');
    setCustomTasksText({});
    setCustomTaskTemplates([]);
    onClose();
  };

  const checkedTasksText = useMemo(() => {
    const idsArray = Object.keys(checkedTaskIds);
    return `${totalTasks} Tasks, ${idsArray.length} Checked`;
  }, [checkedTaskIds, totalTasks]);

  return (
    <Modal
      className="AddTasksModal"
      title="Add Tasks"
      primaryButtonLabel="Add"
      open={open}
      onClose={handleOnClose}
      onPrimaryButtonClick={handleOnPrimaryButtonClick}
      primaryButtonDisabled={createOrUpdateVehicleTasksBulkQuery.isLoading}
      primaryButtonLoading={createOrUpdateVehicleTasksBulkQuery.isLoading}
    >
      <div className="AddTasksModal-body">
        <input
          type="text"
          value={filterText}
          onChange={handleOnFilterTextChange}
          className="AddTasksModal-FilterTextbox"
          placeholder="Filter Tasks"
        />
        <div
          style={{ height: `${height - HEADER_FOOTER_HEIGHT}px` }}
          className="AddTasksModal-TaskTemplates"
        >
          {filteredTaskTemplates.map((value, index, thisArg) => {
            const label = value?.id.startsWith('custom') ? (
              <input
                type="text"
                className="AddTasksModal-CustomTextbox"
                value={customTasksText[value?.id]}
                onChange={(event) =>
                  handleOnCustomTaskTextChange(event, value?.id)
                }
                placeholder="Custom Task"
              />
            ) : (
              <div className="AddTasksModal-task">
                <div className="AddTasksModal-task-top">
                  <div className="AddTasksModal-task-label">{value.label}</div>
                </div>
                <div className="AddTasksModal-task-bottom">
                  {tenantId !== value.tenantId && (
                    <div className="AddTasksModal-task-tenantName">
                      {value.tenantName} {value.description && '\u2014'}
                    </div>
                  )}
                  <div className="AddTasksModal-task-description">
                    {value.description}
                  </div>
                </div>
              </div>
            );

            return (
              <div key={value?.id} className="AddTasksModal-row">
                <Checkbox
                  className="AddTasksModal-checkbox"
                  checked={!!checkedTaskIds[value?.id]}
                  onClick={() => handleCheckboxOnClick(value)}
                />
                {label}
              </div>
            );
          })}
        </div>
        <div className="AddTasksModal-TasksCounts">{checkedTasksText}</div>
      </div>
    </Modal>
  );
};

export default AddTasksModal;
