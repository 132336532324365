import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';

import { permissions, strings } from 'common';
import { usePermissions } from 'hooks';

type Props = {
  onShowAppraisalInfo: () => void;
};

const MobileFlatTasksHeader = ({ onShowAppraisalInfo }: Props) => {
  const classes = useStyles();
  const { hasPermissions } = usePermissions();
  const hasViewAppraisalPermission = hasPermissions([
    permissions.INVENTORY_VDP_RECON_INFO_VIEW,
  ]);

  return (
    <Box className={classes.container}>
      <Box className={classes.columnHeader}>
        <Box style={{ width: '85px' }}>
          {hasViewAppraisalPermission && (
            <Box className={classes.link} onClick={onShowAppraisalInfo}>
              {strings.APPRAISAL_INFO}
            </Box>
          )}
        </Box>

        <Box className={classes.columnCellContainer}>
          <Typography variant="body2" className={classes.columnCell}>
            {strings.RATE}
          </Typography>
          <Typography variant="body2" className={classes.columnCell}>
            {strings.HOURS}
          </Typography>
          <Typography variant="body2" className={classes.columnCell}>
            {strings.LABOR}
          </Typography>
          <Typography variant="body2" className={classes.columnCell}>
            {strings.PARTS}
          </Typography>
          <Typography variant="body2" className={classes.columnCell}>
            {strings.TOTAL}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const fontStyle: CreateCSSProperties<{}> = {
  textTransform: 'uppercase',
  fontWeight: 600,
  fontSize: '12px',
};

const cellContainerStyle: CreateCSSProperties<{}> = {
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  columnGap: '10px',
  borderRadius: 3,
  justifyContent: 'space-between',
};

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    container: {
      marginTop: -theme.spacing(1),
      padding: `0 ${theme.spacing(2)}px`,
      paddingBottom: theme.spacing(1),
    },
    columnHeader: {
      display: 'flex',
      flexDirection: 'row',
      columnGap: '10px',
      marginTop: theme.spacing(2),
      alignItems: 'center',
    },
    columnCellContainer: {
      backgroundColor: theme.palette.grey[200],
      padding: theme.spacing(0.8),
      ...cellContainerStyle,
    },
    columnFooterContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      columnGap: '10px',
      padding: theme.spacing(0.8),
      borderRadius: 3,
      justifyContent: 'space-between',
    },
    columnCell: fontStyle,
    link: {
      ...fontStyle,
      textDecoration: 'underline',
      color: theme.palette.primary.dark,
      width: 85,
    },
  })
);

export default MobileFlatTasksHeader;
