import { navigate, useLocation, WindowLocation } from '@reach/router';
import { FC, useEffect, useMemo, useState } from 'react';

import { setItem } from 'api/lib/typedLocalStorage';
import strings from 'common/strings';
import Button from 'components/shared/Button';
import FlatVDPPaneHeader from 'components/shared/FlatVDPPaneHeader';
import { useCarfax } from 'hooks/useCarfax';
import { VehicleSummary } from 'models';

import CarfaxLandingPage from './CarfaxLandingPage';

import './CarfaxLogin.scss';

interface CarfaxLoginProps {
  vehicle: VehicleSummary;
}

const CarfaxLogin: FC<CarfaxLoginProps> = ({ vehicle }) => {
  const { pathname, state } = useLocation() as WindowLocation<{
    isLogout: boolean;
  }>;

  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const { validateSession, LOGIN_REDIRECT_URI } = useCarfax();

  const isLogout = useMemo<boolean>(() => Boolean(state.isLogout), [state]);

  useEffect(() => {
    // Fixes "Can't perform a react state update on unmounted component" error
    // https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component
    let isMounted = true;

    const checkIsAuthorized = async () => {
      const results = await validateSession();

      if (results) {
        if (isMounted) setIsAuthorized(true);
      } else {
        setTimeout(() => {
          navigate(LOGIN_REDIRECT_URI);
        }, 1000);
      }
    };

    setItem('carfaxVdpPath', pathname);

    // No need to try and validate the session if we just logged out
    if (!isLogout) {
      checkIsAuthorized();
    }

    return () => {
      isMounted = false;
    };
  }, [
    pathname,
    validateSession,
    setIsAuthorized,
    LOGIN_REDIRECT_URI,
    isLogout,
  ]);

  return !isAuthorized ? (
    <>
      <FlatVDPPaneHeader className="carfax-header">
        <div>{strings.CARFAX}</div>
      </FlatVDPPaneHeader>
      <div className="carfax-content">
        {isLogout ? (
          <>
            <p>Successfully logged out</p>
            <Button
              variant="primary"
              type="button"
              onClick={() => navigate(LOGIN_REDIRECT_URI)}
            >
              Login
            </Button>
          </>
        ) : (
          <p>Redirecting to Carfax Login...</p>
        )}
      </div>
    </>
  ) : (
    <CarfaxLandingPage
      vin={vehicle.vehicleCard.vin}
      zipCode={vehicle.vehicleCard.location.zipCode}
    />
  );
};

export default CarfaxLogin;
