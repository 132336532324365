/* eslint-disable */
import { FC, useState, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import strings from 'common/strings';
import LoadingIndicator from '../LoadingIndicator';
import { TextInput, TextArea } from 'components/shared/Inputs';

import './SubmitFeedbackModal.scss';
import Alert from '../Alert';
import Select from '../Inputs/Select';
import {
  useCurrentLocation,
  useLocations,
  useSubmitFeedback,
  useToken,
} from 'api';
import { getFullName } from 'utils/user';

const THREE_SECONDS = 3000;

interface SubmitFeedbackModalProps {
  onClose: () => void;
}

const SubmitFeedbackModal: FC<SubmitFeedbackModalProps> = ({ onClose }) => {
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [apiError, setApiError] = useState(false);
  const submitFeedbackAsync = useSubmitFeedback();
  const { data: user } = useToken();

  const locationsQuery = useLocations();
  const rooftops = locationsQuery?.data ?? [];

  const location = useCurrentLocation();
  const activeRooftop = location || rooftops[0];
  const [rooftop, setRooftop] = useState(activeRooftop.name);

  const onSubmit = useCallback(async () => {
    const userName = user?.user ? getFullName(user?.user) : '';
    const userEmail = user?.user.emailAddress || '';
    const userPhone = user?.user.mobilePhone;

    try {
      await submitFeedbackAsync.mutateAsync({
        subject,
        message,
        userName,
        userEmail,
        rooftop,
        userPhone,
      });
      setSuccess(true);
      setMessage('');
      setSubject('');
      setRooftop(activeRooftop.name);
      setTimeout(() => onClose(), THREE_SECONDS);
    } catch (error) {
      setApiError(true);
      setTimeout(() => setApiError(false), THREE_SECONDS);
    }
  }, [subject, message, rooftop]);

  const onRooftopChange = (options: any) => {
    setRooftop(options.value);
  };

  return (
    <>
      {apiError && (
        <Alert
          open={apiError}
          contentProps={{
            message: strings.API_MESSAGE,
            variant: 'error',
            onClose: () => setApiError(false),
          }}
        />
      )}
      {success ? (
        <Alert
          open={success}
          contentProps={{
            message: strings.FEEDBACK_SUCCESS,
            variant: 'success',
            onClose: () => onClose(),
          }}
        />
      ) : (
        <Modal
          show
          centered
          onHide={onClose}
          className="SubmitFeedbackModal"
          backdrop="static"
        >
          <Modal.Header className="modal-header-container">
            <Modal.Title>{strings.SUBMIT_FEEDBACK}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="SubmitFeedbackModal-body flex-grow">
            <div className="SubmitFeedbackModal-select-container">
              <label className="SubmitFeedbackModal-form-label">
                {strings.ROOFTOP}
              </label>
              <Select
                onChange={onRooftopChange}
                options={rooftops.map((rooftop) => ({
                  label: rooftop.name,
                  value: rooftop.name,
                }))}
                isSearchable
                defaultValue={{
                  label: activeRooftop.name,
                  value: activeRooftop.name,
                }}
              />
            </div>
            <TextInput
              containerClassName="SubmitFeedbackModal-input-container"
              onChange={(e) => setSubject(e.target.value)}
              value={subject}
              placeholder={strings.SUBJECT}
              required={false}
            />
            <TextArea
              onChange={(e) => setMessage(e.target.value)}
              value={message}
              containerClassName="SubmitFeedbackModal-textarea"
              className="SubmitFeedbackModal-textarea-input"
              placeholder={strings.MESSAGE}
              required={false}
            />
            <span className="version-info">{window.recon.versionSummary}</span>
          </Modal.Body>
          <Modal.Footer>
            <div className="SubmitFeedbackModal-footer">
              <button
                type="button"
                onClick={onClose}
                className="SubmitFeedbackModal-cancel-button"
              >
                {strings.CANCEL}
              </button>
              <button
                type="button"
                className="blue-button SubmitFeedbackModal-submit-button"
                onClick={onSubmit}
                disabled={
                  !(subject.trim().length > 0 && message.trim().length > 0)
                }
              >
                {submitFeedbackAsync.isLoading ? (
                  <LoadingIndicator size={30} />
                ) : (
                  <div>{strings.SUBMIT}</div>
                )}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default SubmitFeedbackModal;
