/* eslint-disable max-len */
import AlarmIcon from '../Images/AlarmIcon.svg';
import CarfaxLogo from '../Images/carfax_logo.svg';
import Clipboard from '../Images/Clipboard.svg';
import ReconBadgeIcon from '../Images/ReconIcon.svg';
import ReconVelocityVDPLogo from '../Images/ReconVelocityVDPLogo.svg';
import TurnAutomotiveLogo from '../Images/turn-automotive-logo.png';
import UploadIcon from '../Images/UploadIcon.svg';
import ReconIcon from '../Images/Velocity-Logo-2Color-500.png';
import ReconLogo from '../Images/Velocity-Logo-White-250.png';

const images = {
  CarfaxLogo,
  Clipboard,
  LoginGif:
    'https://d1icd6shlvmxi6.cloudfront.net/gsc/ORWA03/d4/f0/13/d4f0135df7eb44f1ad34c24e91e5c9f4/images/recon_login/u2.gif?token=2ea98b735a96c2f52aee78451d4633beed6d22dae9a47f247ecbdc6c8e40c915',
  ReconIcon,
  ReconLogo,
  ReconBadgeIcon,
  ReconVelocityVDPLogo,
  AlarmIcon,
  UploadIcon,
  TurnAutomotiveLogo,
};

export function isImage(url?: string): boolean {
  if (!url) {
    return false;
  }

  return /\.(gif|jpg|jpeg|tiff|png|webp)(\?timestamp=\d+)?$/i.test(url);
}

export const hasImageContentType = (contentType?: string): boolean => {
  return /(^image)(\/)[a-zA-Z0-9_]*/gm.test(contentType ?? '');
};

export default images;
