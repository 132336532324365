import { FC, useMemo, useState } from 'react';

import { SubmittedAttachments } from '../types';
import ActiveUploadsContext, {
  MediaUploadContextData,
} from './MediaUploadContext';

const MediaUploadProvider: FC = ({ children }) => {
  const [submittedMedia, setSubmittedMedia] = useState<SubmittedAttachments>(
    []
  );

  const context: MediaUploadContextData = useMemo(
    () => ({
      submittedMedia,
      setSubmittedMedia,
    }),
    [submittedMedia]
  );

  return (
    <ActiveUploadsContext.Provider value={context}>
      {children}
    </ActiveUploadsContext.Provider>
  );
};

export default MediaUploadProvider;
