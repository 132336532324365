import { useContext } from 'react';

import VehicleImageView from 'components/shared/VehicleImageView';

import VehicleCardNotes from '../../../../shared/VehicleCardNotes';
import VehicleDetailsTable from '../components/VehicleCardDetailsTable';
import VehicleCardYearMakeModel from '../components/VehicleCardYearMakeModel';
import { VehicleCardContext } from '../VehicleCard';
import InvoiceTasks from './InvoiceTasks';

import './VehicleCardInvoiceDesktop.scss';

const VehicleCardVendor = () => {
  const { vehicle } = useContext(VehicleCardContext);
  const vehicleId = vehicle?.vehicleCard.id;

  return (
    <div className="VehicleCardVendor">
      <div className="VehicleCardVendor-details">
        <div className="VehicleCardVendor-vehicleInfo">
          <VehicleImageView
            thumbnail
            imageUrl={vehicle?.vehicleCard.vehicleImage}
            skeleton={!vehicle}
          />
          <VehicleCardYearMakeModel />
        </div>

        <VehicleDetailsTable showVin={false} />
      </div>

      <div className="border-right" />
      <InvoiceTasks vehicle={vehicle!} />
      <div className="border-right" />
      <VehicleCardNotes notes={vehicle?.notesByDate!} vehicleId={vehicleId!} />
    </div>
  );
};

export default VehicleCardVendor;
