import { FC, useMemo } from 'react';

import { useToken } from 'api';
import {
  useChangeSharedStep,
  useTenantUtilityWorkflow,
} from 'api/inventory/workflows';
import permissions from 'common/permissions';
import strings from 'common/strings';
import BaseStepDropdown from 'components/shared/BaseStepDropdown';
import { useGetProductServiceSettings, usePermissions } from 'hooks';
import { StepItem } from 'models';
import { recurseChildSteps } from 'utils/workflow';

interface SharedStepDropdownProps {
  containerClassName?: string;
  loaderType?: 'srp' | 'default' | 'srpGradient';
  currentStep?: Pick<StepItem, 'id' | 'name'>;
  inventoryId?: string;
  tenantId?: string;
}

const SharedStepDropdown: FC<SharedStepDropdownProps> = ({
  tenantId,
  inventoryId,
  containerClassName,
  loaderType,
  currentStep,
}) => {
  const { hasPermission } = usePermissions();
  const { getProductServiceSetting } = useGetProductServiceSettings();
  const { data: sessionData } = useToken();
  const { data: queryWorkflowData } = useTenantUtilityWorkflow(tenantId);
  const changeStepMutation = useChangeSharedStep(tenantId, inventoryId);

  const workflow = queryWorkflowData?.data;
  const currentUserId = sessionData?.user?.id;

  const flattenedSteps = useMemo(
    () => (workflow ? recurseChildSteps(workflow) : []),
    [workflow]
  );
  const currentStepDefinition = currentStep
    ? flattenedSteps.find((listStep) => listStep.id === currentStep.id)
    : undefined;

  const handleStepSelect = async (newSelectedStepId: string) => {
    await changeStepMutation.mutateAsync(newSelectedStepId);
  };

  const allowMoveStepCheckingRestrictToStepUserList =
    getProductServiceSetting('RESTRICT_MOVE_STEP_TO_STEP_USER_LIST') !== '1' ||
    currentUserId === undefined ||
    currentStepDefinition?.allowedUsers === undefined ||
    currentStepDefinition?.allowedUsers.length === 0 ||
    currentStepDefinition?.allowedUsers
      .map((user) => user.id)
      .includes(currentUserId);
  const allowMoveStep =
    hasPermission(permissions.RECON_VDP_WORKFLOW_STEP_UPDATE) &&
    allowMoveStepCheckingRestrictToStepUserList;

  if (allowMoveStep) {
    return (
      <BaseStepDropdown
        containerClassName={containerClassName}
        workflow={workflow}
        isLoading={changeStepMutation.isLoading}
        loaderType={loaderType}
        currentStep={currentStep}
        onStepChange={handleStepSelect}
      />
    );
  }

  return (
    <span className="stepName">{currentStep?.name || strings.EMPTY_VALUE}</span>
  );
};

export default SharedStepDropdown;
