import { useQuery } from '@tanstack/react-query';

import { defaultMetaQueryFn } from 'api/useAPI';
import { Invoice } from 'models/invoicing';

export const useInvoicesQuery = (inventoryId: string) => {
  return useQuery<{ data: Invoice[] }>({
    queryKey: ['invoices', inventoryId],
    queryFn: () =>
      defaultMetaQueryFn(`/vendor-invoice/inventory/${inventoryId}/invoices`),
  });
};
