import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { APIResponse, defaultMutationFn } from 'api';
import { Profile, ProfileUpdateData } from 'models';

const path = '/profile';

export function useProfile() {
  const queryClient = useQueryClient();

  return {
    query: useQuery<APIResponse<Profile>>([path]),
    mutation: useMutation(
      (data: ProfileUpdateData) => defaultMutationFn(path, 'PUT', data),
      {
        onSuccess: async (data) => {
          queryClient.setQueryData([path], data);
        },
      }
    ),
  };
}
