import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { useState } from 'react';

import {
  useDeleteVehicleTask,
  useTaskTemplates,
  useUpdateVehicleTasksStatus,
  useUsers,
} from 'api';
import { strings } from 'common';
import Alert from 'components/shared/Alert';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { Task, TaskStatus, VehicleSummary } from 'models';

import MobileEditFlatTaskDialog from '../MobileEditFlatTaskDialog/MobileEditFlatTaskDialog';
import MobileFlatTaskMenu from './MobileFlastTaskMenu';
import MobileFlatTask from './MobileFlatTask';
import MobileFlatTaskEdit from './MobileFlatTaskEdit';

type Props = {
  tasks: Task[];
  loading?: boolean;
  selectedIds: string[];
  vehicle: VehicleSummary;
};

const MobileFlatTasksBody = ({
  tasks,
  loading = false,
  selectedIds,
  vehicle,
}: Props) => {
  const { data: userData } = useUsers();
  const updateVehicleTasksStatus = useUpdateVehicleTasksStatus(
    vehicle?.vehicleCard?.id
  );

  const [apiError, setApiError] = useState('');
  const [showDrawer, setShowDrawer] = useState(false);
  const [showTaskEditDialog, setShowTaskEditDialog] = useState(false);
  const classes = useStyles();
  const [task, setTask] = useState<Task>();
  const { data: taskTemplateData } = useTaskTemplates();
  const deleteVehicleTaskQuery = useDeleteVehicleTask(
    vehicle?.vehicleCard?.id,
    task?.id
  );
  if (loading) {
    return <LoadingIndicator />;
  }

  const handleTaskPress = (task: Task) => {
    setShowDrawer(true);
    setTask(task);
  };

  const handleTaskEdit = (task: Task) => {
    setShowTaskEditDialog(true);
    setTask(task);
  };

  const handleClose = () => {
    setShowDrawer(false);
  };

  const handleMenuClick = async (status: TaskStatus) => {
    try {
      if (task?.id) {
        await updateVehicleTasksStatus.mutateAsync({
          status,
          taskIds: [task.id],
        });
        handleClose();
      }
    } catch (error) {
      setApiError(strings.API_MESSAGE);
    }
  };

  const handleDelete = () => {
    deleteVehicleTaskQuery.mutate();
    handleClose();
  };

  const handleCloseEditTaskDialog = () => {
    setShowTaskEditDialog(false);
  };

  return (
    <Box className={classes.container}>
      {tasks.map((task, index) => (
        <MobileFlatTask
          key={task.id}
          position={index}
          task={task}
          vehicle={vehicle}
          onTaskPress={handleTaskPress}
          onTaskEdit={handleTaskEdit}
        />
      ))}
      {task && (
        <MobileFlatTaskMenu
          task={task}
          show={showDrawer}
          onClose={handleClose}
          onClick={handleMenuClick}
          onDelete={handleDelete}
        />
      )}
      <MobileEditFlatTaskDialog
        open={showTaskEditDialog}
        onClose={handleCloseEditTaskDialog}
        title="Edit Task"
      >
        <MobileFlatTaskEdit
          task={task}
          vehicle={vehicle}
          taskTemplates={taskTemplateData?.data ?? []}
          users={userData?.data ?? []}
          onSaveComplete={handleCloseEditTaskDialog}
        />
      </MobileEditFlatTaskDialog>
      <Alert
        open={!!apiError}
        duration={3000}
        handleClose={() => setApiError('')}
        contentProps={{
          variant: 'error',
          message: apiError,
          onClose: () => setApiError(''),
        }}
      />
    </Box>
  );
};

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      justifyContent: 'stretch',
      flexDirection: 'column',
      padding: theme.spacing(1),
      backgroundColor: theme.palette.grey[200],
      marginTop: 0,
    },
  })
);

export default MobileFlatTasksBody;
