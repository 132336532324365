import { MouseEvent } from 'react';

import {
  useUpdateVehicleTaskStatus,
  useVehicleTask,
  useVehicleTasks,
} from 'api';
import { permissions, strings } from 'common';
import Button from 'components/shared/Button';
import PermissionsGate from 'components/shared/PermissionsGate';
import { Notification, TaskStatus } from 'models';

type TaskApprovalProps = {
  notification: Notification;
  onClick: () => void;
};
const TaskApproval = ({ notification, onClick }: TaskApprovalProps) => {
  const { parentType, targetType, parentId, targetId } = notification;

  const vehicleId = parentType === 'VEHICLE' ? parentId : undefined;
  const taskId = targetType === 'TASK' ? targetId : undefined;

  const { data: vehicleTasksData, isLoading: isLoadingTasksData } =
    useVehicleTasks(vehicleId);
  const { data: vehicleTaskData, isLoading: isLoadingVehicleTaskData } =
    useVehicleTask(vehicleId, taskId);
  const taskStatusMutation = useUpdateVehicleTaskStatus(vehicleId, taskId);

  const tasks = vehicleTasksData?.data?.filter(
    (task) => task.status === 'PENDING_APPROVAL'
  );
  const task = vehicleTaskData?.data;

  if (notification.notificationType !== 'RECON_TASK_APPROVAL') {
    return null;
  }

  if (
    !taskId ||
    !task ||
    task.status !== 'PENDING_APPROVAL' ||
    (tasks && tasks.length > 1)
  ) {
    return null;
  }

  var isLoading = isLoadingTasksData || isLoadingVehicleTaskData;
  const handleButtonOnClick = (status: TaskStatus, event?: MouseEvent) => {
    event?.stopPropagation();
    taskStatusMutation.mutate(status);
    onClick();
  };

  return (
    <PermissionsGate permissions={[permissions.INVENTORY_VDP_TASKS_APPROVE]}>
      <div className="NotificationListItem-task-actions">
        <Button
          id="approve-task-button"
          type="button"
          variant="success"
          onClick={(e) => handleButtonOnClick('APPROVED_PENDING_COMPLETE', e)}
          loading={isLoading}
        >
          {strings.APPROVE}
        </Button>
        <Button
          id="deny-task-button"
          type="button"
          variant="danger"
          onClick={(e) => handleButtonOnClick('DENIED', e)}
          loading={isLoading}
        >
          {strings.DENY}
        </Button>
      </div>
    </PermissionsGate>
  );
};

export default TaskApproval;
