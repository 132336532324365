import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  SvgIcon,
  SvgIconProps,
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useCallback, useEffect, useRef, useState } from 'react';

import { getInventoryQueryParams } from 'api/invoicing';
import { strings } from 'common';
import VehicleSearch from 'components/pages/VehicleCollectionBrowser/VehicleSearch/VehicleSearch';
import { useCurrentLocationId } from 'hooks';
import { InvoiceStatus } from 'models/invoicing';
import { updateUrlSearchParam } from 'navigation/util/ParamHelpers';

import './Header.scss';

const Header = () => {
  const locationId = useCurrentLocationId();

  const [open, setOpen] = useState(false);

  const [searchInputValue, setSearchInputValue] = useState(
    getInventoryQueryParams()?.s
  );

  const [invoiceStatus, setInvoiceStatus] = useState<InvoiceStatus | ''>(
    getInventoryQueryParams()?.invoiceStatus
  );

  const anchorRef = useRef<HTMLButtonElement>(null);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef?.current?.focus?.();
    }

    prevOpen.current = open;
  }, [open]);

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleVinScanner = useCallback(() => {
    // This is an Universal Link / App Link.  If Velocity Automotive app is installed
    // the app's vin scanner will launch and if it is not installed Velocity
    // Automotive Landing page will show
    window.location.replace(
      `${process.env.REACT_APP_VELOCITY_AUTOMOTIVE_VIN_SCANNER}?returnURL=${
        window.location.protocol +
        '//' +
        window.location.hostname +
        `/${locationId}/invoicing`
      }`
    );
  }, [locationId]);

  const handleClose = useCallback((event?: { target: any }) => {
    if (anchorRef.current?.contains?.(event?.target)) {
      return;
    }

    setOpen(false);
  }, []);

  const handleListKeyDown = useCallback(
    (event: { key: string; preventDefault: () => void }) => {
      if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
      }
    },
    []
  );

  const handleListItemSelect = useCallback(
    (value: InvoiceStatus) => {
      if (value === 'All') {
        updateUrlSearchParam({ invoiceStatus: '' });
        setInvoiceStatus('');
      } else {
        updateUrlSearchParam({ invoiceStatus: value });
        setInvoiceStatus(value);
      }
      handleClose();
    },
    [handleClose, setInvoiceStatus]
  );

  return (
    <div className="vendor-header">
      <VehicleSearch
        searchInputValue={searchInputValue}
        setSearchInputValue={setSearchInputValue}
        placeholder={strings.SEARCH_INVOICING}
      />
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            columnGap: '8px',
          }}
        >
          <Button
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            size="small"
            style={{ minWidth: '40px' }}
          >
            <FilterListIcon color="secondary" />
          </Button>
          <Button
            size="small"
            onClick={handleVinScanner}
            style={{ minWidth: '40px' }}
          >
            <VinScannerIcon color="secondary" viewBox="0 0 512 512" />
          </Button>
        </div>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                    dense
                  >
                    <MenuItem
                      dense
                      selected={invoiceStatus === 'All'}
                      onClick={() => handleListItemSelect('All')}
                    >
                      {strings.ALL}
                    </MenuItem>
                    <MenuItem
                      dense
                      selected={invoiceStatus === 'PENDING'}
                      onClick={() => handleListItemSelect('PENDING')}
                    >
                      {strings.PENDING_INVOICES}
                    </MenuItem>
                    <MenuItem
                      dense
                      selected={invoiceStatus === 'PAID'}
                      onClick={() => handleListItemSelect('PAID')}
                    >
                      {strings.PAID_INVOICES}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
};

const VinScannerIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <g>
        <path d="M90.1,418.7H39.5c-2.4,0-4.7-2.4-4.7-4.7V98c0-2.4,2.4-4.7,4.7-4.7h50.6c9.5,0,18.2-7.9,18.2-17.4   c0-4.7-1.6-8.7-4.7-12.6c-3.2-3.2-10.3-4.7-15-4.7H22.1C9.5,58.5,0,65.6,0,78.2v355.5c0,12.6,8.7,19.8,21.3,19.8h68.7   c9.5,0,18.2-6.3,18.2-15.8c0-4.7-1.6-9.5-4.7-12.6C99.5,421.9,94.8,418.7,90.1,418.7L90.1,418.7z M491.4,58.5h-69.5   c-11.9,0-21.3,10.3-17.4,22.9c0,0.8,0.8,1.6,0.8,2.4c3.2,6.3,9.5,9.5,15.8,9.5h50.6c2.4,0,4.7,2.4,4.7,4.7v316   c0,2.4-2.4,4.7-4.7,4.7h-49.8c-9.5,0-18.2,7.9-18.2,17.4c0,4.7,1.6,9.5,4.7,12.6c3.2,3.2,7.9,4.7,12.6,4.7h69.5   c12.6,0,21.3-7.1,21.3-20.5V78.2C512.7,65.6,504,58.5,491.4,58.5z"></path>
        <path d="M90.9,157.2c-9.5,0-17.4,7.9-17.4,17.4v162.7c0,9.5,7.9,17.4,17.4,17.4s17.4-7.9,17.4-17.4V174.6   C108.2,165.1,100.3,157.2,90.9,157.2z M421.1,354.8c9.5,0,17.4-7.9,17.4-17.4V174.6c0-9.5-7.9-17.4-17.4-17.4   c-9.5,0-17.4,7.9-17.4,17.4v162.7C403.7,346.9,411.6,354.8,421.1,354.8z M342.9,117.7c-9.5,0-17.4,7.9-17.4,18.2v241   c0,10.3,7.9,18.2,17.4,18.2c9.5,0,17.4-7.9,17.4-18.2v-241C360.3,125.6,352.4,117.7,342.9,117.7z M169.1,117.7   c-9.5,0-17.4,7.9-17.4,18.2v241c0,10.3,7.9,18.2,17.4,18.2c9.5,0,17.4-7.9,17.4-18.2v-241C186.5,125.6,178.6,117.7,169.1,117.7z    M256,137.5c-9.5,0-17.4,7.9-17.4,17.4v201.5c0,9.5,7.9,17.4,17.4,17.4s17.4-7.9,17.4-17.4V154.9   C273.4,145.4,265.5,137.5,256,137.5z" />
      </g>
    </SvgIcon>
  );
};

export default Header;
