import { Step } from 'react-joyride';

import { Tour as BaseTour, styles } from 'components/shared/Tour';
import { useVendor, useWindowSize } from 'hooks';

const steps: Step[] = [
  {
    target: '.vendor-inventory-list-content',
    title: 'Invoicing Tour',
    content:
      "Welcome to the invoicing page.  Let's take a quick tour to familiarize you with invoicing.",
    disableBeacon: true,
    placement: 'center',
    styles: {
      ...styles,
      tooltipContainer: {
        textAlign: 'left',
      },
    },
    locale: {
      next: 'Start',
    },
  },
  {
    content: 'Task that is part of an invoice for the vehicle',
    target: '.tour-label',
  },
  {
    content: 'Payment status of the invoiced task',
    target: '.tour-status',
  },
  {
    content:
      "Navigates to the vehicle's invoices list and displays the invoice payment status",
    target: '.tour-view-invoice',
  },
  {
    content: "Navigates to the vehicle's notes",
    target: '.tour-invoice-notes',
  },
];

const dealerTaskStep = {
  content: "Navigates to the vehicle's tasks",
  target: '.tour-invoice-tasks',
  locale: {
    last: 'Close ',
  },
};

const vendorTaskStep = {
  content:
    "Navigates to the vehicle's vendor tasks where invoices can be created",
  target: '.tour-invoice-tasks',
  locale: {
    last: 'Close ',
  },
};

type Props = {
  name: string;
  showTour: boolean;
};
const Tour = ({ name, showTour }: Props) => {
  const { isVendor } = useVendor();
  const { isMobileViewport } = useWindowSize();
  const isMobile = isMobileViewport();
  const tourSteps = isVendor
    ? [...steps, vendorTaskStep]
    : [...steps, dealerTaskStep];

  return (
    <BaseTour
      name={name}
      menuTriggerType={isMobile ? 'hamburgerMenu' : 'profileMenu'}
      showTour={showTour}
      localStorageKey={'tour_hasShownInvoicingTour'}
      steps={tourSteps}
    />
  );
};

export default Tour;
