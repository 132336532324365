import { FC } from 'react';

import images from 'common/images';

import { IconProps } from './types';

const TurnAutomotiveLogo: FC<IconProps> = ({ className }) => {
  return (
    <img
      src={images.TurnAutomotiveLogo}
      className={className}
      width="40"
      height="40"
      alt="turn-automotive-logo"
    />
  );
};

export default TurnAutomotiveLogo;
