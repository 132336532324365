import { Paper } from '@material-ui/core';
import moment from 'moment';

import { Invoice as InvoiceType, Vehicle } from 'models/invoicing';
import { currencyFormatter, phoneNumberFormatter } from 'utils/formatter';

type Props = {
  invoice: InvoiceType;
  invoiceNumber: React.ReactElement;
  statusComponent: React.ReactElement | null;
  total: number;
};

const DesktopInvoice = ({
  invoice,
  invoiceNumber,
  statusComponent,
  total,
}: Props) => {
  const vehicles = invoice?.vehicles;

  const header = (
    <div className="header-top">
      <div className="header-left">
        <div className="vendor-logo-container">
          {invoice?.vendor?.logoUrl && (
            <img
              className="vendor-logo"
              alt="logo"
              src={invoice?.vendor?.logoUrl}
            />
          )}
        </div>
        {invoiceNumber}
      </div>
      <div className="header-middle">{statusComponent}</div>
      <div className="header-right">
        <div className="vendor-details">
          <span className="vendor-name">{invoice?.vendor?.name}</span>
          <span className="vendor-detail">
            {invoice?.vendor?.mailingAddress?.street1}
          </span>
          <span className="vendor-detail">
            {invoice?.vendor?.mailingAddress?.city},{' '}
            {invoice?.vendor?.mailingAddress?.state?.name}
          </span>
          <span className="vendor-detail">
            {invoice?.vendor?.mailingAddress?.postalCode}
          </span>
          <span className="vendor-detail">
            {phoneNumberFormatter(
              invoice?.vendor?.serviceDepartment?.phone ?? ''
            )}
          </span>
          <span className="vendor-detail">{invoice?.vendor?.websiteUrl}</span>
        </div>
      </div>
    </div>
  );

  const dealerDetails = (
    <div className="header-bottom">
      <div className="header-left">
        <div className="invoice-details-container">
          <span className="invoice-detail">
            Date: {moment(invoice?.createdAt).format('l')}
          </span>
          <span className="invoice-detail-bold">
            Payment Due By: {moment(invoice?.dueByDate).format('l')}
          </span>
          <span className="invoice-detail-bold">
            Total Amount Due: {currencyFormatter(invoice?.totalAmountDue ?? 0)}
          </span>
        </div>
      </div>
      <div className="header-right">
        <div className="tenant-details-container">
          <span className="tenant-detail-bold">{invoice?.tenant?.name}</span>
          <span className="tenant-detail">
            {phoneNumberFormatter(
              invoice?.tenant?.serviceDepartment?.phone ?? ''
            )}
          </span>
          <span className="tenant-detail">
            {invoice?.tenant?.serviceDepartment?.email}
          </span>
        </div>
      </div>
    </div>
  );

  const getTasks = (vehicle: Vehicle) => (
    <div className="task">
      <div className="task-row">
        <div className="task-item task-item-right task-item-border">Task</div>
        <div className="task-item task-item-border">Description</div>
        <div className="task-item task-item-left task-item-border">Total</div>
      </div>
      {vehicle?.tasks?.map((task: any) => {
        return (
          <div key={task.label} className="task-row">
            <div className="task-value task-value-right task-value-background">
              {task.label}
            </div>
            <div className="task-value task-value-background">{task.notes}</div>
            <div className="task-value task-value-left task-value-background task-total">
              {currencyFormatter(task?.price?.amount ?? 0)}
            </div>
          </div>
        );
      })}
    </div>
  );

  const getVehicleTotal = (vehicle: Vehicle) => (
    <div className="task">
      <div className="task-row">
        <div className="task-item task-item-right"></div>
        <div className="task-item"></div>
        <div className="task-item task-item-left task-item-border">
          Vehicle Total
        </div>
      </div>
      <div className="task-row">
        <div className="task-value"></div>
        <div className="task-value"></div>
        <div className="task-value task-total">
          {currencyFormatter(vehicle.totalTaskCost)}
        </div>
      </div>
    </div>
  );

  const getVehicleItem = (vehicle: Vehicle) => {
    return (
      <div className="item">
        <div key={vehicle?.id} className="vehicle">
          <div className="year-make-model">
            {vehicle?.year} {vehicle?.make} {vehicle?.model}
          </div>
          <div className="vin">VIN: {vehicle?.vin}</div>
          <div className="stock">Stock #:{vehicle?.stockNumber}</div>
        </div>
        {getTasks(vehicle)}
        {getVehicleTotal(vehicle)}
      </div>
    );
  };

  return (
    <div className="invoice">
      <Paper elevation={1} className="invoice-page">
        <header className="invoice-header">
          {header}
          <div className="horizontal-line horizontal-line-margin-top horizontal-line-margin-bottom" />
          {dealerDetails}
        </header>
        <main className="invoice-main">
          {vehicles.map((vehicle) => getVehicleItem(vehicle))}
        </main>
        <div className="horizontal-line horizontal-line-margin-top horizontal-line-margin-small-bottom" />
        <footer className="invoice-footer">
          <div className="total-price">Total: {currencyFormatter(total)}</div>
        </footer>
      </Paper>
    </div>
  );
};

export default DesktopInvoice;
