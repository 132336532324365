import { Redirect, RouteComponentProps, useLocation } from '@reach/router';
import { useState } from 'react';

import { useGetDealerInventoryByVinQuery } from 'api/invoicing/useGetDealerInventoryQuery';
import { useGetVendorInventoryIdsByVinQuery } from 'api/invoicing/useGetVendorInventoryQuery';
import { permissions } from 'common';
import Alert from 'components/shared/Alert';
import ApiError from 'components/shared/ApiError';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { useFeatures, usePermissions, useVendor } from 'hooks';
import { tasksBuilder } from 'navigation/routes';

import Header from './Header';
import VehicleList from './VehicleList/VehicleList';

import './Inventory.scss';

type Props = RouteComponentProps;

const Inventory = ({ navigate, ...props }: Props) => {
  const { isVendor, isLoading: isVendorLoading } = useVendor();
  const { hasFeature } = useFeatures();
  const { hasPermission } = usePermissions();
  const [closeAlert, setCloseAlert] = useState(false);
  const location = useLocation();
  const hasVendorFeature = hasFeature('VELOCITY_VENDOR');
  const vin = new URLSearchParams(location.search).get('vin');
  const getDealerInventoryByVinQuery = useGetDealerInventoryByVinQuery(
    !isVendorLoading && !isVendor && !!vin,
    vin
  );
  const getVendorInventoryIdsByVinQuery = useGetVendorInventoryIdsByVinQuery(
    !isVendorLoading && isVendor && !!vin,
    vin
  );
  const query = isVendor
    ? getVendorInventoryIdsByVinQuery
    : getDealerInventoryByVinQuery;
  const error = query.error;

  let showVehicleNotFoundMessage = false;
  if (query.isSuccess) {
    let inventoryId;
    if (isVendor) {
      inventoryId = getVendorInventoryIdsByVinQuery.data?.data?.[0];
    } else {
      inventoryId =
        getDealerInventoryByVinQuery?.data?.data?.[0]?.vehicleCard?.id;
    }
    // Vehicle has been scanned with the Velocity Automotive scanner and the vin has been located
    // in inventory.  Redirect to VDP Task page
    if (inventoryId) {
      return (
        <Redirect
          noThrow
          to={`${tasksBuilder(inventoryId, false, undefined, isVendor)}`}
        />
      );
    } else {
      // Vin has not been found in inventory.  Show vehicle not found message
      showVehicleNotFoundMessage = true;
    }
  }

  if (query.isInitialLoading && query.isLoading) {
    return <LoadingSpinner />;
  }

  if (!hasPermission(permissions.INVOICING_VIEW) || !hasVendorFeature) {
    // User is using a vendor rooftop without the vendor feature flag
    return (
      <div className="vendor-permission-container">
        <span className="vendor-permission full-height full-width flex-rows">
          You don't have the required permissions to view this page. Please
          contact your administrator.
        </span>
      </div>
    );
  }

  return (
    <div className="vendor-inventory-list">
      <main className="vendor-inventory-list-content">
        <div className="vendor-inventory-list-container">
          <Header />
          <VehicleList />
        </div>
      </main>
      <ApiError error={error} />
      <Alert
        open={showVehicleNotFoundMessage && !closeAlert}
        contentProps={{
          variant: 'error',
          onClose: () => setCloseAlert(true),
          message: 'Vehicle not found in inventory',
        }}
      />
    </div>
  );
};

export default Inventory;
