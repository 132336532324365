import { useCallback, useState } from 'react';

import { DealerStepReportSummaryItem } from 'models';

export interface VehicleStepReportModalState {
  open: boolean;
  step?: DealerStepReportSummaryItem;
}

export const useVehicleStepReportModalState = () => {
  const [vehicleStepReportModalState, setVehicleStepReportModalState] =
    useState<VehicleStepReportModalState>({
      open: false,
      step: undefined,
    });

  const onOpenStepModal = useCallback((step) => {
    setVehicleStepReportModalState({
      open: true,
      step,
    });
  }, []);

  const onCloseStepModal = useCallback(() => {
    setVehicleStepReportModalState({
      open: false,
      step: vehicleStepReportModalState.step,
    });
  }, [vehicleStepReportModalState.step]);

  return {
    vehicleStepReportModalState,
    onOpenStepModal,
    onCloseStepModal,
  };
};
