import { ChevronLeft, ChevronRight, DateRange } from '@material-ui/icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dropdown as StyledDropdown } from 'react-bootstrap';

import strings from 'common/strings';
import CustomDatePickerModal from 'components/shared/CustomDatePickerModal';
import Dropdown from 'components/shared/Dropdown';
import { DropdownOption } from 'components/shared/Dropdown/Dropdown';
import useOnChange from 'hooks/useOnChange';
import { GroupFacet } from 'models';
import { FilterFacet } from 'models/engage/engagmenetSearchFacets';

import ToolbarDropdown from './EngagementsTable/ToolbarDropdown';

import './EngageToolbar.scss';

interface SelectProps {
  facet: FilterFacet;
  current: string | null;
  queryCallback: (name: string, query: string | null) => void;
  disabled: boolean;
}

interface SelectGroupProps {
  facets: GroupFacet[];
  current: string | null;
  queryCallback: (query: string | null) => void;
}

export const RenderSelect: React.FC<SelectProps> = ({
  facet,
  queryCallback,
  current,
  disabled,
}) => {
  const { displayName, attribute } = facet;

  const ddOptions: DropdownOption[] = React.useMemo(
    () =>
      facet?.filterOptions.map((i) => ({
        id: i.value !== null ? i.value : 'reset',
        value: i.displayName,
      })),
    [facet]
  );

  const [selected, setSelected] = useState<DropdownOption>(ddOptions[0]);

  function handleSelect(optionsId: string | null) {
    if (optionsId === 'reset') {
      setSelected(ddOptions[0]);
      queryCallback(attribute, null);
    } else {
      // eslint-disable-next-line
      const newOption =
        ddOptions.find((i) => i.id === optionsId) || ddOptions[0];
      setSelected(newOption);
      queryCallback(attribute, optionsId);
    }
  }

  useEffect(() => {
    // This allows us to update when the parent resets our
    // parameters.
    if (selected.value !== current) {
      handleSelect(current);
    }
    // eslint-disable-next-line
  }, [current, selected.value]);

  return (
    <ToolbarDropdown
      selectedOption={selected}
      onSelect={handleSelect}
      options={ddOptions}
      disabled={disabled}
      searchOptionPlaceholder={displayName}
    />
  );
};

export const RenderGroupSelect: React.FC<SelectGroupProps> = ({
  facets,
  queryCallback,
  current,
}) => {
  const facetsOptions = facets.map((i) => ({
    id: i.attribute,
    value: `Group by ${i.displayName}`,
  }));
  const ddOptions: DropdownOption[] = useMemo(
    () => [
      {
        id: 'reset',
        value: 'Do Not Group',
      },
      ...facetsOptions,
    ],
    [facetsOptions]
  );

  const [selected, setSelected] = useState<DropdownOption>(ddOptions[0]);

  const handleSelect = useCallback(
    (optionsId: string | null) => {
      if (optionsId === 'reset') {
        setSelected(ddOptions[0]);
        queryCallback(null);
      } else {
        const newOption =
          ddOptions.find((i) => i.id === optionsId) || ddOptions[0];
        setSelected(newOption);
        queryCallback(optionsId);
      }
    },
    [ddOptions, queryCallback]
  );

  const currentChangeCallback = useCallback(() => {
    // This allows us to update when the parent resets our
    // parameters.
    if (selected.value !== current) {
      handleSelect(current);
    }
  }, [current, handleSelect, selected.value]);

  useOnChange(currentChangeCallback, current);

  return (
    <Dropdown
      enableSearchOption
      searchOptionPlaceholder="Group by"
      containerClassName="EngagementsHeader-dropdown"
      selectedOption={selected}
      onSelect={handleSelect}
      options={ddOptions}
    />
  );
};

export interface CalendarOption {
  label: string;
  id: string;
  start: string | null;
  end: string | null;
}

interface DateRangeSelectProps {
  filterCallback: (start: string | null, end: string | null) => void;
  current: string | null;
}
export const RenderDateRangeSelect: React.FC<DateRangeSelectProps> = ({
  filterCallback,
  current,
}) => {
  const [customRange, setCustomRange] = useState<CalendarOption>({
    label: 'Custom Range',
    id: 'custom_range',
    start: null,
    end: null,
  });

  const dateRanges = React.useMemo(
    () => [
      { label: 'All Time', id: 'all_time', start: null, end: null },
      { label: 'Today', id: 'today', start: getOffset(0), end: null },
      {
        label: 'Week to Date',
        id: 'week_to_date',
        start: getOffset(7),
        end: null,
      },
      {
        label: 'Two Weeks to Date',
        id: 'two_week_to_date',
        start: getOffset(14),
        end: null,
      },
      {
        label: 'Month to Date',
        id: 'month_to_date',
        start: getStartDate(false),
        end: null,
      },
      {
        label: 'Year to Date',
        id: 'year_to_date',
        start: getStartDate(true),
        end: null,
      },
      customRange,
    ],
    [customRange]
  );

  const [currentDateRangeOption, setCurrentDateRange] = useState<number>(4);
  const [isCustomRangeModelOpen, setIsCustomRangeModelOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (current === null && currentDateRangeOption !== 0) {
      setCurrentDateRange(0);
    }
  }, [current, currentDateRangeOption]);

  const range = React.useMemo(
    () => dateRanges[currentDateRangeOption],
    [dateRanges, currentDateRangeOption]
  );

  function getOffset(numDays: number): string {
    const end = new Date();
    const dis = 1000 * 60 * 60 * 24;
    const offset = dis * numDays;
    end.setHours(0, 0, 0, 0);
    end.setTime(end.getTime() - offset);
    return end.toISOString();
  }

  function getStartDate(year: boolean) {
    const now = new Date();
    now.setDate(1);
    if (!year) {
      return now.toISOString();
    }
    now.setMonth(0);
    return now.toISOString();
  }

  function onSelect(selectionId: string | null) {
    if (selectionId === null) {
      setRangeWithDispatcher(0);
      return;
    }

    if (selectionId === 'custom_range') {
      setIsCustomRangeModelOpen(true);
      return;
    }
    setRangeWithDispatcher(
      dateRanges.findIndex((i) => i.id === selectionId) || 0
    );
  }

  function onDirectionSelect(direction: 'left' | 'right') {
    // what is this controlling actually?
    if (direction === 'left') {
      setRangeWithDispatcher(Math.max(currentDateRangeOption - 1, 0));
    }
    if (direction === 'right') {
      setRangeWithDispatcher(
        Math.min(currentDateRangeOption + 1, dateRanges.length - 1)
      );
    }
  }

  const startFormat = Intl.DateTimeFormat('en-US', {
    day: 'numeric',
    month: 'numeric',
  });
  const endFormat = Intl.DateTimeFormat('en-US', {
    day: 'numeric',
    month: 'numeric',
    year: '2-digit',
  });
  function onConfirmCustomRange(range: {
    startDate: Date;
    endDate: Date;
    showDateDisplay: Boolean;
  }) {
    const { startDate, endDate } = range;
    const rangeString = `${startFormat.format(startDate)}-${endFormat.format(
      endDate
    )}`;
    setCustomRange({
      label: rangeString,
      id: 'custom_range',
      start: startDate.toISOString(),
      end: endDate.toISOString(),
    });
    setRangeWithDispatcher(dateRanges.length - 1, startDate, endDate);
    setIsCustomRangeModelOpen(false);
  }

  function setRangeWithDispatcher(
    index: number,
    startDate?: Date,
    endDate?: Date
  ) {
    setCurrentDateRange(index);
    if (startDate !== undefined && endDate !== undefined) {
      filterCallback(startDate.toISOString(), endDate.toISOString());
    } else {
      const { start, end } = dateRanges[index];
      filterCallback(start, end);
    }
  }

  return (
    <>
      <StyledDropdown className="DateDropdown-container">
        <div className="DateDropdown">
          <div
            className="DateDropdown-icon-container"
            onClick={() => onDirectionSelect('left')}
            role="none"
          >
            <ChevronLeft className="DateDropdown-icon" />
          </div>
          <div className="DateDropdown-divider" />
          <StyledDropdown.Toggle
            id="DateDropdown-filter-toggle"
            variant="light"
          >
            <div className="DateDropdown-main-container">
              <DateRange className="DateDropdown-calendar-icon" />
              <div className="DateDropdown-filter-label">
                {range.label || strings.TODAY}
              </div>
            </div>
          </StyledDropdown.Toggle>
          <div className="DateDropdown-divider" />
          <div
            className="DateDropdown-icon-container"
            onClick={() => onDirectionSelect('right')}
            role="none"
          >
            <ChevronRight className="DateDropdown-icon" />
          </div>
        </div>
        <StyledDropdown.Menu alignRight>
          {dateRanges.map((option) => (
            <StyledDropdown.Item
              key={option.id}
              eventKey={option.id}
              onSelect={onSelect}
            >
              {option.label}
            </StyledDropdown.Item>
          ))}
        </StyledDropdown.Menu>
      </StyledDropdown>
      <CustomDatePickerModal
        showModal={isCustomRangeModelOpen}
        onAccept={onConfirmCustomRange}
        onClose={() => setIsCustomRangeModelOpen(false)}
      />
    </>
  );
};
export default RenderSelect;
