/* eslint-disable jsx-a11y/anchor-is-valid */
import { useQueryClient } from '@tanstack/react-query';

import { useGenerateVelocityLocateSSOUrl } from 'api';
import strings from 'common/strings';

const FleetButton: any = () => {
  const queryClient = useQueryClient();
  const { data: { data: velocityLocateSSOUrl } = {} } =
    useGenerateVelocityLocateSSOUrl();

  const onClick = async () => {
    window.open(velocityLocateSSOUrl, '_blank');
    await queryClient.invalidateQueries([
      '/plugins/velocitylocate/generateSSOUrl',
    ]);
  };

  return (
    <a
      onClick={onClick}
      role="presentation"
      target="_blank"
      rel="noopener noreferrer"
      className="align-self-center font-weight-bold btn py-2 shadow btn-dark VehicleSearch-banner-button text-white"
    >
      {strings.MANAGE_FLEET.toUpperCase()}
    </a>
  );
};

export default FleetButton;
