import { useQuery } from '@tanstack/react-query';

import { APIResponse, defaultMetaQueryFn } from 'api';
import { DateRange } from 'common/DateRange';
import { DealerStepReportVehicleV2 } from 'models';
import { getApiDateStringFromDate } from 'utils/time';

interface UseVehicleStepReportParams {
  dateRange: DateRange;
  stepId: string;
  tenantId?: string;
  selectedVehicleState?: string;
}

export const useVehicleStepReport = ({
  dateRange: { start, end },
  stepId,
  tenantId,
  selectedVehicleState,
}: UseVehicleStepReportParams) => {
  const path = `/reports/steps/${stepId}/vehicles`;

  const params = new URLSearchParams({
    startDate: getApiDateStringFromDate(start),
    endDate: getApiDateStringFromDate(end),
  });

  const key = [
    path,
    getApiDateStringFromDate(start),
    getApiDateStringFromDate(end),
  ];

  if (tenantId) {
    params.append('tenantId', tenantId);
    key.push(tenantId);
  }

  if (selectedVehicleState) {
    params.append('vehicleState', selectedVehicleState);
    key.push(selectedVehicleState);
  }

  const url = `${path}?${params}`;

  return useQuery<APIResponse<DealerStepReportVehicleV2[]>>(key, () =>
    defaultMetaQueryFn(url)
  );
};
