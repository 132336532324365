import { useContext } from 'react';

import VehicleImageView from 'components/shared/VehicleImageView';

import VehicleCardReconInventoryTabs from '../components/VehicleCardReconInventoryTabs';
import VehicleCardYearMakeModel from '../components/VehicleCardYearMakeModel';
import { VehicleCardContext } from '../VehicleCard';

import './VehicleCardInvoiceMobile.scss';

const VehicleCardMobile = () => {
  const { vehicle } = useContext(VehicleCardContext);

  return (
    <div className="VehicleCardMobile">
      <div className="VehicleCardMobile-vehicleInfo">
        <VehicleImageView
          imageUrl={vehicle?.vehicleCard.vehicleImage}
          skeleton={!vehicle}
          className="VehicleImageView-showroom-size"
        />
        <VehicleCardYearMakeModel />
      </div>
      <VehicleCardReconInventoryTabs
        showingInvoicing={true}
        showNotesTab={true}
      />
    </div>
  );
};

export default VehicleCardMobile;
