import { STATUS } from 'utils/tasks';

import './TaskStatus.scss';

type TaskStatusProps = {
  taskStatus: keyof typeof STATUS;
};

const TaskStatus: React.FC<TaskStatusProps> = ({ taskStatus }) => {
  const status = STATUS[taskStatus];
  const className = `TaskStatus-${status.toLowerCase()}`;
  return (
    <div className="TaskStatus-container">
      <div className={className}>{status}</div>
    </div>
  );
};

export default TaskStatus;
