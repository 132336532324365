import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import {
  addNoteToQueryCache,
  fetchNote,
  useAddAttachmentToNote,
  useAddNote,
  usePresignedNoteAttachmentUploadUrl,
  useUploadFileToS3,
} from 'api';
import { strings } from 'common';
import { useAttachmentUploadContext } from 'components/shared/AttachmentManager';
import { AttachmentUploadContextData } from 'components/shared/AttachmentManager/AttachmentUploadContext';

const MAX_UPLOADS = 5;

type onUploadProgressProps = {
  loaded: number;
  total: number;
};

type handleSelectedFilesProps = {
  vehicleId: string;
  message?: string;
  attachments: any;
  addNote: any;
  getNewPresignedAttachmentUploadUrl: any;
  uploadFileToS3: any;
  addAttachmentToNote: any;
  attachmentUploadContext?: AttachmentUploadContextData;
};
const handleSelectedFiles = async ({
  vehicleId,
  message,
  attachments,
  addNote,
  getNewPresignedAttachmentUploadUrl,
  uploadFileToS3,
  addAttachmentToNote,
  attachmentUploadContext,
}: handleSelectedFilesProps) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (attachments.length > MAX_UPLOADS) {
        reject(strings.TOO_MANY_FILES);
        return;
      }

      const savedNote = await addNote({
        vehicleId: vehicleId!,
        note: message,
        skipAddToCache: true,
      });
      const noteId = savedNote?.data?.id;

      if (noteId && attachments) {
        const requests = attachments.map(
          async (attachment: any, index: number) => {
            let file = attachment?.file ?? attachment;
            const {
              data: { uploadUrl, s3AttachmentId },
            } = await getNewPresignedAttachmentUploadUrl({
              vehicleId,
              noteId,
              contentType: file.type,
            });

            attachmentUploadContext?.setAttachmentState(index, 'uploading', 0);
            await uploadFileToS3({
              file,
              presignedUrl: uploadUrl,
              onUploadProgress: ({ loaded, total }: onUploadProgressProps) => {
                const progress = (loaded / total) * 100;
                attachmentUploadContext?.setAttachmentState(
                  index,
                  'uploading',
                  progress
                );
              },
            });

            await addAttachmentToNote({
              vehicleId,
              noteId,
              s3AttachmentId,
              attachmentName: file.name,
            });
            attachmentUploadContext?.setAttachmentState(
              index,
              'completed',
              100
            );
          }
        );
        await Promise.all(requests);

        resolve({ noteId });
      } else {
        resolve({});
      }
    } catch (error) {
      console.error({ error });
      reject('Error Uploading Files');
    }
  });
};

const handleAddNotesToQuery = async (
  vehicleId: string,
  noteId: string,
  queryClient: any
) => {
  if (noteId) {
    const note = await fetchNote(vehicleId, noteId).then(({ data }) => data);
    addNoteToQueryCache(vehicleId, queryClient, note);
  }
};

// TODO: come up with easy pattern to follow
export const useAttachments = () => {
  const queryClient = useQueryClient();
  const { mutateAsync: addNote } = useAddNote();
  const { getNewPresignedAttachmentUploadUrl } =
    usePresignedNoteAttachmentUploadUrl();
  const { uploadFileToS3 } = useUploadFileToS3();
  const { mutateAsync: addAttachmentToNote } = useAddAttachmentToNote();
  const attachmentUploadContext = useAttachmentUploadContext();

  const [isLoading, setIsLoading] = useState(false);

  const createNoteAndUploadAttachments = async (
    vehicleId: string,
    attachments: any,
    message?: string
  ) => {
    setIsLoading(true);
    return await handleSelectedFiles({
      vehicleId,
      message,
      attachments,
      addNote,
      getNewPresignedAttachmentUploadUrl,
      uploadFileToS3,
      addAttachmentToNote,
      attachmentUploadContext,
    })
      .then(({ noteId }: any) =>
        handleAddNotesToQuery(vehicleId, noteId, queryClient)
      )
      .finally(() => setIsLoading(false));
  };

  return { createNoteAndUploadAttachments, isLoading };
};
