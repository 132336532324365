import { useContext } from 'react';

import { VehicleCardConfigProp, VehicleCardContext } from './VehicleCard';
import VehicleCardDesktopV2 from './VehicleCardDesktopV2';
import VehicleCardInvoiceDesktop from './VehicleCardInvoiceDesktop';
import VehicleCardShowroomDesktop from './VehicleCardShowroomDesktop';

interface VehicleCardDesktopProps {
  config: VehicleCardConfigProp;
}

const VehicleCardDesktop = ({ config }: VehicleCardDesktopProps) => {
  const { isShowroomMode, showingInvoicing } = useContext(VehicleCardContext);

  if (showingInvoicing) {
    return <VehicleCardInvoiceDesktop />;
  }

  if (isShowroomMode) {
    return <VehicleCardShowroomDesktop config={config} />;
  }
  return <VehicleCardDesktopV2 />;
};

export default VehicleCardDesktop;
