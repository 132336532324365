import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { ReactNode } from 'react';

type Props = {
  position: number;
  label: string;
  value: ReactNode;
};

const MobileTaskItem = ({ position, label, value }: Props) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.labelContainer}>
        <Typography variant="body2" className={classes.label}>
          {label}
        </Typography>
      </Box>
      <Box
        className={classes.valueContainer}
        id={`mobile-task-card-${label.toLowerCase()}-${position}`}
      >
        <Typography variant="body2" className={classes.value}>
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
    },
    labelContainer: {
      width: 110,
    },
    label: {
      textTransform: 'uppercase',
      color: theme.palette.grey[700],
    },
    valueContainer: {
      flex: 1,
    },
    value: {
      fontWeight: 600,
      color: theme.palette.secondary.main,
    },
  })
);

export default MobileTaskItem;
