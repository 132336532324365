/* eslint-disable complexity */
/* eslint-disable max-len */
import React from 'react';

import { defaultColor, IconProps } from './types';

const ArrowUpBold: React.FC<IconProps> = ({
  size = 20,
  color = defaultColor,
  className,
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={className}
      d="M9.27161 13.2716H4.47161V6.87161H0.599609L6.87161 0.599609L13.1436 6.87161H9.27161V13.2716Z"
      fill={color}
    />
  </svg>
);

export default ArrowUpBold;
