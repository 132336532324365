import { useEffect, useState } from 'react';

type Direction = 'up' | 'down';

const scrollIncrement = 10;
export const useScrollDirection = (scrollElementId: string) => {
  const [scrollDirection, setScrollDirection] = useState<Direction | null>(
    null
  );
  const [containerHeight, setContainerHeight] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);

  useEffect(() => {
    const scrollElement = document.getElementById(scrollElementId);
    if (!scrollElement) {
      return;
    }
    let lastScrollY = scrollElement.scrollTop;

    const updateScrollDirection = () => {
      const scrollY = scrollElement.scrollTop;
      const direction: Direction = scrollY > lastScrollY ? 'down' : 'up';

      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > scrollIncrement ||
          scrollY - lastScrollY < -scrollIncrement)
      ) {
        setScrollDirection(direction);
        setContainerHeight(scrollElement.offsetHeight);
        setScrollHeight(scrollElement.scrollHeight);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    scrollElement.addEventListener('scroll', updateScrollDirection); // add event listener
    return () => {
      scrollElement.removeEventListener('scroll', updateScrollDirection); // clean up
    };
  }, [scrollDirection, scrollElementId]);

  return { scrollDirection, containerHeight, scrollHeight, scrollIncrement };
};
