import { navigate, Redirect, Router, WindowLocation } from '@reach/router';
import React from 'react';

import { useGetInventoryTenantId, useVehicleSummaryByTenantId } from 'api';
import permissions from 'common/permissions';
import strings from 'common/strings';
import ConditionReport from 'components/pages/ConditionReport';
import FlatMedia from 'components/pages/FlatMedia';
import FlatTasks from 'components/pages/FlatTasks';
import FlatHistory from 'components/pages/History';
import Invoices from 'components/pages/Invoices';
import Location from 'components/pages/Location';
import MultipointInspection from 'components/pages/MultipointInspection';
import Notes from 'components/pages/Notes';
import Recalls from 'components/pages/Recalls';
import ReconVelocityOverview from 'components/pages/ReconVelocityOverview';
import RepairOrder from 'components/pages/RepairOrder';
import VehicleDetails2 from 'components/pages/VehicleDetails';
import { PinnableComponentType } from 'components/pages/VehicleDetails/PinnedComponent';
import VelocityEngage from 'components/pages/VelocityEngage';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { useCurrentLocationId, usePermissions, useVendor } from 'hooks';
import { VehicleSummary } from 'models';

import CarfaxLogin from '../components/pages/Carfax/CarfaxLogin';
import Alert from '../components/shared/Alert';
import {
  CARFAX_RELATIVE,
  CONDITION_REPORT_RELATIVE,
  ENGAGE_RELATIVE,
  HISTORY_RELATIVE,
  INDEX,
  INVENTORY_RELATIVE,
  INVOICES_RELATIVE,
  LOCATION_RELATIVE,
  MEDIA_RELATIVE,
  MPI_RELATIVE,
  NOTES_RELATIVE,
  OVERVIEW_RELATIVE,
  RECALLS_RELATIVE,
  RECON_INFO_RELATIVE,
  RECON_VELOCITY_OVERVIEW_RELATIVE,
  REPAIR_ORDERS_RELATIVE,
  TASKS_RELATIVE,
  VEHICLE_INFO_RELATIVE,
} from './routes';

const FLAT_SECTIONS: Record<string, any> = {
  [OVERVIEW_RELATIVE]: ReconVelocityOverview,
  [ENGAGE_RELATIVE]: VelocityEngage,
  [VEHICLE_INFO_RELATIVE]: ReconVelocityOverview,
  [NOTES_RELATIVE]: Notes,
  [RECON_INFO_RELATIVE]: ReconVelocityOverview,
  [TASKS_RELATIVE]: FlatTasks,
  [MPI_RELATIVE]: MultipointInspection,
  [LOCATION_RELATIVE]: Location,
  [MEDIA_RELATIVE]: FlatMedia,
  [RECALLS_RELATIVE]: Recalls,
  [HISTORY_RELATIVE]: FlatHistory,
  [CARFAX_RELATIVE]: CarfaxLogin,
  [RECON_VELOCITY_OVERVIEW_RELATIVE]: ReconVelocityOverview,
  [REPAIR_ORDERS_RELATIVE]: RepairOrder,
  [INVOICES_RELATIVE]: Invoices,
  [CONDITION_REPORT_RELATIVE]: ConditionReport,
};
interface VDPRouterProps {
  location?: WindowLocation;
  path?: string;
  vehicle: VehicleSummary;
  setCustomHeaderTitle?: (title: string) => void;
  setBackAction?: (backAction: () => void) => void;
  mobileVDPInfoHeight?: number;
  rooftopId?: string;
  vehicleId?: string;
  openPinnedComponent?: (componentName: PinnableComponentType) => void;
}

export const FlatVDP: React.FC<React.PropsWithChildren<VDPRouterProps>> = ({
  location,
  path,
  rooftopId,
  vehicleId,
  ...otherProps
}) => {
  const { hasPermission } = usePermissions();
  return (
    <Router className="white-background vertical-scroll full-height-calc">
      <VehicleDetails2 path={INDEX} showCloseButton={true} {...otherProps} />
      <VehicleDetailsSection path=":sectionName/*" {...otherProps} />
      {!hasPermission(permissions.RECON_VIEW) &&
        hasPermission(permissions.PLUGIN_VELOCITYENGAGE_VIEW) && (
          <Redirect
            from="/overview/*"
            to={`/${rooftopId}/${INVENTORY_RELATIVE}/${vehicleId}/${ENGAGE_RELATIVE}`}
            noThrow
          />
        )}
      {!hasPermission(permissions.RECON_VIEW) && (
        <Redirect
          from="/overview/*"
          to={`/${rooftopId}/${INVENTORY_RELATIVE}/${vehicleId}/${NOTES_RELATIVE}`}
          noThrow
        />
      )}
    </Router>
  );
};

interface VehicleDetailsSectionProps {
  location?: WindowLocation;
  path?: string;
  sectionName?: string;
  vehicle: VehicleSummary;
  openPinnedComponent?: (componentName: PinnableComponentType) => void;
}

export const VehicleDetailsSection = (props: VehicleDetailsSectionProps) => {
  const { sectionName, vehicle, ...otherProps } = props;
  const { hasPermission } = usePermissions();
  if (!sectionName) return null;
  if (
    !hasPermission(permissions.RECON_VIEW) &&
    sectionName === OVERVIEW_RELATIVE
  )
    return null;
  if (
    !hasPermission(permissions.RECON_VIEW) &&
    sectionName === RECON_VELOCITY_OVERVIEW_RELATIVE
  )
    return null;

  const Section = FLAT_SECTIONS[sectionName];
  return (
    <Section
      {...otherProps}
      vehicle={vehicle}
      vehicleId={vehicle.vehicleCard.id || ''}
      tenantId={vehicle.vehicleCard.tenantId || ''}
      shouldFocus={sectionName === NOTES_RELATIVE}
    />
  );
};

interface VehicleDetailsRouterProps {
  location?: WindowLocation;
  path?: string;
  vehicleId?: string;
  rooftopId?: string;
}

const VehicleDetailsRouter: React.FC<
  React.PropsWithChildren<VehicleDetailsRouterProps>
> = (props) => {
  const { location, path, vehicleId } = props;
  const locationId = useCurrentLocationId();
  const { isVendor } = useVendor();

  // TODO: find consistent solution for multi-tenant / vendors
  // vendor vehicle summary will come back without auth-tenant-id
  // multi-tenant requires auth-tenant-id
  const inventoryTenantIdQuery = useGetInventoryTenantId(vehicleId);
  const tenantId = isVendor ? locationId : inventoryTenantIdQuery.data?.data;

  const { data, isError } = useVehicleSummaryByTenantId(
    vehicleId === 'background' ? undefined : vehicleId,
    tenantId
  );

  const goBackToSRP = async () => {
    await navigate(`/${locationId}`);
  };

  if (vehicleId && data?.data) {
    return (
      <VehicleDetails2
        path={path}
        location={location}
        vehicle={data.data}
        showCloseButton={true}
      />
    );
  }

  return (
    <>
      <Alert
        open={isError}
        contentProps={{
          message: strings.VEHICLE_NOT_FOUND,
          variant: 'error',
          onClose: goBackToSRP,
        }}
      />
      {!isError && <LoadingIndicator size={80} />}
    </>
  );
};

export default VehicleDetailsRouter;
