import create from 'zustand';

export type MenuTriggerType =
  | 'hamburgerMenu'
  | 'profileMenu'
  | 'vdpMenu'
  | null;

type TourState = {
  name: string | null;
  menuTriggerType: MenuTriggerType;
  actionType: 'show' | 'hide' | 'init' | null;
  actions: {
    initTour: (name: string, menuTriggerType: MenuTriggerType) => void;
    showTour: () => void;
    hideTour: () => void;
    reset: () => void;
  };
};

// https://tkdodo.eu/blog/working-with-zustand
// Not exported, so that no one can subscribe to the entire store
const useTourMenuStore = create<TourState>((set) => ({
  actionType: null,
  name: null,
  menuTriggerType: null,
  actions: {
    initTour: (name: string, menuTriggerType: MenuTriggerType) =>
      set({ actionType: 'init', name, menuTriggerType }),
    showTour: () =>
      set((state) => {
        return { actionType: 'show' };
      }),
    hideTour: () =>
      set((state) => {
        return { actionType: 'hide' };
      }),
    reset: () => set({ actionType: null, name: null, menuTriggerType: null }),
  },
}));

// Export only pieces of the store that you want updates for
// This will give you a cleaner interface, and you don't need to write the selector repeatedly everywhere you want to subscribe to just one value in the store. Also, it avoids accidentally subscribing to the entire store:

// exported - consumers don't need to write selectors
export const useTourMenu = () =>
  useTourMenuStore((state) => {
    return {
      actionType: state.actionType,
      name: state.name,
      menuTriggerType: state.menuTriggerType,
    };
  });

// exported - consumers don't need to write selectors
export const useTourMenuActions = () =>
  useTourMenuStore((state) => state.actions);
