import IconButton from '@material-ui/core/IconButton';
import { AttachMoney, Send } from '@material-ui/icons';
import LinkIcon from '@material-ui/icons/Link';
import { navigate } from '@reach/router';
import { useCallback, useContext, useRef, useState } from 'react';

import { useToken } from 'api';
import useEngageOverview from 'api/inventory/useEngageOverview';
import permissions from 'common/permissions';
import strings from 'common/strings';
import { generateTestId, testIds } from 'common/testIds';
import LabelValue from 'components/shared/LabelValue';
import PermissionsGate from 'components/shared/PermissionsGate';
import PriceHistoryModal from 'components/shared/PriceHistoryModal';
import SendVehicleModal from 'components/shared/SendVehicleModal';
import { EngageOverviewCard, VehicleSummary } from 'models';
import { vehicleDetailsBuilder } from 'navigation/routes';
import { getExtensionModeInfo } from 'utils/extension';
import { getFormattedDurationFromSeconds } from 'utils/time';

import { VehicleCardContext } from '../../VehicleCard';
import VehicleCardTabFooter from './VehicleCardTabFooter/VehicleCardTabFooter';

const engagePlaceholderTitles = [
  'SHOPPERS',
  'PORTFOLIOS SENT',
  'PORTFOLIO OPENS',
  'OPEN RATE',
  'ENGAGEMENTS',
  'AVG. ENGAGEMENT TIME',
];

const VehicleCardEngageDetails = () => {
  const {
    vehicle,
    inExtensionCardMode,
    position,
    currentTab: activeCollectionType,
  } = useContext(VehicleCardContext);
  const ref = useRef<HTMLDivElement>(document.createElement('div'));
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isCopyPopupOpen, setIsCopyPopupOpen] = useState<boolean>(false);

  const [isSendVehicleModalOpen, setIsSendVehicleModalOpen] = useState(false);
  const handleEngageSendClick = (vehicle: VehicleSummary | undefined) => {
    if (vehicle) {
      setIsSendVehicleModalOpen(true);
    }
  };

  const { refetch: refetchEngageOverview } = useEngageOverview(
    vehicle?.vehicleCard?.id,
    false
  );

  const { data: tokenData } = useToken();

  const openPriceHistoryModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const renderEngageValues = useCallback(() => {
    const overviewCards = vehicle?.vehicleCard?.velocityEngageOverviewCards;
    if (!overviewCards) return undefined;

    const buildValue = (
      card?: EngageOverviewCard | null,
      override?: string
    ) => {
      let convertedValue: string | number | null = null;
      if (card?.name === 'AVG_SESSION_DURATION_SECONDS') {
        convertedValue = getFormattedDurationFromSeconds(Number(card.value));
      } else if (card?.name === 'FOLIO_OPEN_PERCENTAGE') {
        // rounding a server side value to two decimal percent (0.0492 to 4.92% for example)
        const percent = Math.floor(Number(card?.value) * 10000) / 100;
        convertedValue = percent ? `${percent}%` : strings.EMPTY_VALUE;
      }

      const key = override || `${card?.name}-${card?.id}`;
      const label = card?.displayName || '';

      return (
        <LabelValue key={key} label={label} labelCaps>
          {convertedValue || card?.value || strings.EMPTY_VALUE}
        </LabelValue>
      );
    };

    if (overviewCards?.length === 0) {
      return (
        <div
          className={`VehicleCard-engage-data-container VehicleCard-engage-data-container-column`}
        >
          {engagePlaceholderTitles.map(() => buildValue(null, ''))}
        </div>
      );
    }

    return (
      <div
        className={`VehicleCard-engage-data-container VehicleCard-engage-data-container-column`}
      >
        {overviewCards.map((card) => buildValue(card))}
      </div>
    );
  }, [vehicle?.vehicleCard?.velocityEngageOverviewCards]);

  const handleClickNav = (clickSource: string, engage = false) => {
    if (vehicle?.vehicleCard?.id) {
      if (engage) {
        navigate(
          `${vehicleDetailsBuilder(
            vehicle?.vehicleCard?.id
          )}/engage/?fromVDP=${activeCollectionType}`
        );
      } else {
        navigate(
          `${vehicleDetailsBuilder(
            vehicle?.vehicleCard?.id
          )}?fromVDP=${activeCollectionType}`
        );
      }
    }
  };

  const handleClickEngageLink = async () => {
    if (
      !vehicle?.vehicleCard?.vin ||
      !vehicle.vehicleCard?.id ||
      !getExtensionModeInfo().enabled
    ) {
      return;
    }
    const { data: engageOverviewResponse, isError: isErrorEngageOverview } =
      await refetchEngageOverview();
    const userName = tokenData?.user?.userName;
    if (engageOverviewResponse?.data && !isErrorEngageOverview) {
      let shareUrl = engageOverviewResponse?.data.folioURL;
      if (shareUrl) {
        shareUrl = `${shareUrl.replace(
          vehicle.vehicleCard.vin,
          vehicle.vehicleCard.id
        )}?dealerUser=${userName}`;
      }
      await navigator.clipboard.writeText(shareUrl);
      setIsCopyPopupOpen(true);
      setTimeout(() => {
        setIsCopyPopupOpen(false);
      }, 2000);
    }
  };

  return (
    <div
      ref={ref}
      className="VehicleCard-tab-container engage"
      data-vas-testing={generateTestId(
        testIds.INVENTORY_CARD_ENGAGE_CONTAINER,
        {
          position: position.toString(),
        }
      )}
    >
      {inExtensionCardMode && (
        <div className="VehicleCard-tabs-engage-link">
          <IconButton onClick={handleClickEngageLink} size="small">
            <LinkIcon />
          </IconButton>
          {isCopyPopupOpen && (
            <div className="VehicleCard-tabs-engage-link-popover">
              URL Copied to Clipboard
            </div>
          )}
        </div>
      )}
      {renderEngageValues()}
      <VehicleCardTabFooter
        vehicle={vehicle}
        navClick={() => handleClickNav('', true)}
        showEngage={true}
        showTasks={false}
        showRecalls={false}
        hideNavigations={inExtensionCardMode}
      >
        <div className="VehicleCardTabFooter-Engage-buttons">
          <div
            className="VehicleCardTabFooter-badge"
            onClick={openPriceHistoryModal}
          >
            <div>{strings.PRICE}</div>
            <AttachMoney className="engage-btn-icon" />
          </div>
          {!inExtensionCardMode && (
            <PermissionsGate
              permissions={[permissions.PLUGIN_VELOCITYENGAGE_SEND]}
            >
              <div
                className="VehicleCardTabFooter-badge"
                onClick={() => handleEngageSendClick(vehicle)}
              >
                <div>{strings.SEND}</div>
                <Send className="engage-btn-icon" />
              </div>
              <SendVehicleModal
                selectedVehicleList={vehicle ? [vehicle] : []}
                open={isSendVehicleModalOpen}
                onClose={() => setIsSendVehicleModalOpen(false)}
              />
            </PermissionsGate>
          )}
        </div>
      </VehicleCardTabFooter>
      {vehicle?.vehicleCard?.priceHistory && (
        <PriceHistoryModal
          open={isModalOpen}
          onClose={closeModal}
          onClick={openPriceHistoryModal}
          priceHistory={vehicle.vehicleCard.priceHistory}
          customButton
        />
      )}
    </div>
  );
};

export default VehicleCardEngageDetails;
