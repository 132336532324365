import { Link, List, ListItem } from '@material-ui/core';
import { navigate } from '@reach/router';
import moment from 'moment';
import { useEffect } from 'react';

import { strings } from 'common';
import { useVendor } from 'hooks/useVendor';
import { Invoice } from 'models/invoicing';
import { tasksBuilder } from 'navigation/routes';
import { currencyFormatter } from 'utils/formatter';
import { getInvoiceStatus } from 'utils/invoice';

type Props = {
  invoices: Invoice[];
  onClick: (invoice: Invoice) => void;
  onItemsRendered: () => void;
  vehicleId: string;
};

const InvoiceList = ({
  invoices,
  onClick,
  onItemsRendered,
  vehicleId,
}: Props) => {
  useEffect(() => {
    if (invoices.length > 0) {
      onItemsRendered();
    }
  }, [invoices, onItemsRendered]);
  const { isVendor } = useVendor();

  const navigateToTasks = () => {
    navigate(tasksBuilder(vehicleId, true, undefined, isVendor));
  };

  return (
    <List style={{ paddingTop: 0 }}>
      {invoices.length > 0 ? (
        invoices.map((invoice) => {
          const status = invoice?.vehicles?.[0]?.tasks?.[0]?.status;
          return (
            <ListItem
              className="tour-list-item"
              key={invoice?.id}
              divider
              button
              onClick={() => onClick(invoice)}
              style={{ padding: 0 }}
            >
              <div className="invoices-list">
                <div className="invoices-list-row">
                  <div className="invoices-list-name">
                    <span className="tour-invoice-number ">
                      {invoice?.invoiceNumber}
                    </span>{' '}
                    <span
                      className={`tour-invoice-status invoices-status-${status?.toLocaleLowerCase()}`}
                    >
                      {getInvoiceStatus(status)}
                    </span>
                  </div>
                  <div className="invoices-list-total">
                    <span className="tour-invoice-total">
                      {currencyFormatter(invoice?.totalAmountDue) ?? 0}
                    </span>
                  </div>
                </div>
                <div className="invoices-list-row">
                  <div className="invoices-list-details">
                    <span className="tour-invoice-date">
                      {strings.INVOICE_DATE}:{' '}
                      {moment(invoice?.createdAt).format('l')}
                    </span>
                  </div>
                  <div className="invoices-list-details">
                    <span className="tour-invoice-payment-due-by">
                      {strings.INVOICE_DUE_DATE}:{' '}
                      {moment(invoice?.dueByDate).format('l')}
                    </span>
                  </div>
                </div>
                <div className="invoices-list-col">
                  <div className="invoices-list-details">
                    <span className="tour-invoice-vendor">
                      {invoice?.vendor?.name}
                    </span>
                  </div>
                </div>
              </div>
            </ListItem>
          );
        })
      ) : (
        <ListItem
          className="invoices-no-data"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '14px',
            textAlign: 'center',
            height: '50vh',
          }}
        >
          <div>{strings.NO_INVOICE_DATA}</div>
          <Link
            color="textPrimary"
            href="#"
            underline="always"
            onClick={(e) => {
              e.preventDefault();
              navigateToTasks();
            }}
            style={{ fontWeight: 600 }}
            className="invoice-nav-to-task"
          >
            {strings.NO_INVOICE_DATA_CREATE_NEW}
          </Link>
        </ListItem>
      )}
    </List>
  );
};

export default InvoiceList;
