import { useContext } from 'react';

import AttachmentUploadContext from './AttachmentUploadContext';

const useAttachmentUploadContext = () => {
  const context = useContext(AttachmentUploadContext);

  if (!context) {
    throw new Error(
      'AttachmentUploadProvider not present, please add it to the tree'
    );
  }

  return { ...context };
};

export default useAttachmentUploadContext;
