import React, { useMemo } from 'react';

import AccordionContext from './AccordionContext';
import Collapse from './Collapse';
import { CollapseProps } from './Collapse/Collapse';
import Toggle from './Toggle';
import { ToggleProps } from './Toggle/Toggle';

interface AccordionComposition {
  Toggle: React.FC<ToggleProps>;
  Collapse: React.FC<CollapseProps>;
}

interface AccordionProps {
  element: any;
  activeEventKey: string | number;
  onToggle: (eventKey: string | number) => void;
  className?: string;
}

// eslint-disable-next-line
// @ts-ignore
const Accordion: React.FC<AccordionProps> & AccordionComposition = ({
  element: Component = 'div',
  activeEventKey,
  onToggle,
  children,
  className = '',
  ...otherProps
}) => {
  const context = useMemo(
    () => ({ activeEventKey, onToggle }),
    [activeEventKey, onToggle]
  );
  return (
    <AccordionContext.Provider value={context}>
      <Component className={className} {...otherProps}>
        {children}
      </Component>
    </AccordionContext.Provider>
  );
};

Accordion.Toggle = Toggle;
Accordion.Collapse = Collapse;

export default Accordion;
