import { useContext } from 'react';

import DocumentUploadContext from './DocumentUploadContext';

const useDocumentUploadContext = () => {
  const context = useContext(DocumentUploadContext);

  if (!context) {
    throw new Error(
      'FlatDocumentSection components must be rendered as children of DocumentUploadProvider'
    );
  }

  return context;
};

export default useDocumentUploadContext;
