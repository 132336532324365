import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';

import { defaultTextPlainMutationFn } from 'api/useAPI';
import { Invoice } from 'models/invoicing';

type UpdateInvoiceVariables = {
  invoiceId: string;
  invoiceNumber: string;
};

const useSendInvoiceMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: UpdateInvoiceVariables) => {
      return defaultTextPlainMutationFn(
        `/vendor-invoice/${data.invoiceId}`,
        'PUT',
        data.invoiceNumber
      ) as Promise<UseMutationResult<Invoice>>;
    },
    {
      onSettled: (data) => {
        queryClient.invalidateQueries([`/vendor-invoice/${data?.data?.id}`]);
      },
    }
  );
};

export default useSendInvoiceMutation;
