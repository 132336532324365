import React, { MouseEvent } from 'react';
import { Modal as BSModal, Spinner } from 'react-bootstrap';

import { generateTestId, testIds } from 'common/testIds';

import Button from '../Button';
import { Variant } from '../Button/Button';

import './Modal.scss';

type Size = 'sm' | 'lg' | 'xl';

type ClickEvent = (event: MouseEvent) => void;

interface ModalProps {
  title?: string;
  open: boolean;
  onClose(): void;
  closeButton?: boolean;
  secondaryButton?: boolean;
  loading?: boolean;
  size?: Size;
  className?: string;
  children: any;
  primaryButtonLabel?: string;
  primaryButtonDisabled?: boolean;
  primaryButtonLoading?: boolean;
  primaryButtonVariant?: Variant;
  onPrimaryButtonClick: ClickEvent;
  secondaryButtonLabel?: string;
  onSecondaryButtonClick?: ClickEvent;
  otherButtonLabel?: string;
  onOtherButtonClick?: ClickEvent;
  otherButtonClassName?: string;
  backdrop?: 'static' | boolean;
}

const Modal: React.FC<ModalProps> = ({
  title = '',
  open,
  onClose,
  closeButton,
  secondaryButton = true,
  loading,
  size,
  className = '',
  children,
  primaryButtonLabel,
  onPrimaryButtonClick,
  primaryButtonDisabled,
  secondaryButtonLabel,
  onSecondaryButtonClick,
  otherButtonLabel,
  onOtherButtonClick,
  otherButtonClassName,
  primaryButtonLoading,
  primaryButtonVariant = 'primary',
  backdrop = true,
}) => {
  const handlePrimaryClick: any = (event: MouseEvent) => {
    if (onPrimaryButtonClick) {
      onPrimaryButtonClick(event);
    }
  };

  const handleCancelClick: any = (event: MouseEvent) => {
    if (onSecondaryButtonClick) {
      onSecondaryButtonClick(event);
    }
    onClose();
  };

  const handleOtherClick: any = (event: MouseEvent) => {
    if (onOtherButtonClick) {
      onOtherButtonClick(event);
    }
  };

  const renderModalContent = () => {
    if (loading) {
      return (
        <div className="text-center my-3">
          <Spinner role="status" animation="border" variant="danger" />
        </div>
      );
    }

    return children;
  };

  const renderOtherButton = () => {
    if (!otherButtonLabel) {
      return null;
    }

    return (
      <Button
        id="modalOtherButton"
        type="button"
        variant="secondary"
        className={otherButtonClassName}
        disabled={false}
        loading={false}
        onClick={handleOtherClick}
      >
        {otherButtonLabel || 'Other'}
      </Button>
    );
  };

  return (
    <div className="Button">
      <BSModal
        onHide={onClose}
        centered
        show={open}
        size={size || 'lg'}
        className={`Modal ${className}`}
        backdrop={backdrop}
      >
        {title && (
          <BSModal.Header closeButton={closeButton || false}>
            <span className="modal-title">{title}</span>
          </BSModal.Header>
        )}
        <BSModal.Body className="flex-grow">
          {renderModalContent()}
        </BSModal.Body>
        <BSModal.Footer className="d-flex justify-content-between bg-white">
          <div>{renderOtherButton()}</div>
          <div className="d-flex justify-content-end">
            {secondaryButton && (
              <Button
                data-vas-testing={generateTestId(testIds.MODAL_PRIMARY_BUTTON, {
                  variant: `${secondaryButtonLabel}`,
                })}
                id="modalSecondaryButton"
                type="button"
                variant="secondary"
                className="mr-2"
                disabled={false}
                loading={false}
                onClick={handleCancelClick}
              >
                {secondaryButtonLabel || 'Close'}
              </Button>
            )}
            <Button
              data-vas-testing={generateTestId(testIds.MODAL_PRIMARY_BUTTON, {
                variant: `${primaryButtonLabel}`,
              })}
              id="modalPrimaryButton"
              type="button"
              variant={primaryButtonVariant}
              disabled={primaryButtonDisabled || false}
              loading={primaryButtonLoading || false}
              onClick={handlePrimaryClick}
            >
              {primaryButtonLabel || 'Save'}
            </Button>
          </div>
        </BSModal.Footer>
      </BSModal>
    </div>
  );
};

export default Modal;
