import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { FC, useState } from 'react';

import useCorporateReports, {
  useCorporateReportDownloadLink,
  useCorporateReportFilters,
} from 'api/reports/useCorporateReport';
import { DateRange } from 'common/DateRange';
import strings from 'common/strings';
import DownloadMenu from 'components/shared/DownloadMenu';
import { ReportType } from 'components/shared/DownloadMenu/DownloadMenu';
import StickyContainer from 'components/shared/StickyContainer';
import SummaryCard from 'components/shared/SummaryCard';
import { TabProps } from 'components/shared/Tabs/Tabs';
import { SummaryItem } from 'models';
import CorporatePayload, {
  Overview,
  Price,
  Rooftop,
} from 'models/reports/corporatePayload';
import { currencyFormatter } from 'utils/formatter';

import ConditionFilter from '../ConditionFilter';
import DealerStepReport from '../DealerStepReport';
import ReportContainer from '../ReportContainer';
import ReportRow from '../ReportRow';
import ReportTitleRow from '../ReportTitleRow';
import TagFilter from '../TagFilter';

import './CorporateReport.scss';

interface ReportsProps extends TabProps {
  dateRange: DateRange;
}

interface OverviewRowProps {
  overviewData: Overview;
}

type CurrencyQuadSquareProps = Pick<
  CorporatePayload['overview'],
  | 'averageOverage'
  | 'totalOverage'
  | 'averageEstimatedRecon'
  | 'averageActualRecon'
>;

interface RooftopSummaryRowProps {
  rooftop: Rooftop;
}

const RooftopSummaryRow: FC<RooftopSummaryRowProps> = ({
  rooftop: {
    averageActualRecon,
    averageOverage,
    averageReconTimeAsSeconds,
    averageReconTimeGoalAsSeconds,
    averageRetailReadyAsSeconds,
    averageRetailReadyGoalAsSeconds,
    completedVehicles,
    tenantId,
    tenantName,
  },
}) => (
  <ReportRow
    columns={[
      {
        contents: completedVehicles,
      },
      {
        seconds: averageRetailReadyAsSeconds,
        secondsGoal: averageRetailReadyGoalAsSeconds,
      },
      {
        seconds: averageReconTimeAsSeconds,
        secondsGoal: averageReconTimeGoalAsSeconds,
      },
      {
        money: averageActualRecon,
      },
      {
        money: averageOverage,
      },
    ]}
    title={tenantName}
    key={tenantId}
  />
);

// returns '--' or '73.05' or '+$90.65' or '-$89.48'
const displayOptionalDollarAmount = (
  price: Price | null,
  unshiftPlusSymbol = true
): string =>
  price && price?.amount
    ? `${unshiftPlusSymbol && price?.amount > 0 ? '+' : ''}${currencyFormatter(
        price?.amount
      )}`
    : strings.EMPTY_VALUE;

var CurrencyQuadSquare = ({
  averageActualRecon,
  averageOverage,
  averageEstimatedRecon,
  totalOverage,
}: CurrencyQuadSquareProps) => {
  // return '' or 'quadrant-bad' or 'quadrant-good' style string
  const determineOverageClassName = (averageOverage: Price | null) =>
    averageOverage && averageOverage.amount
      ? averageOverage.amount > 0
        ? 'quadrant-bad'
        : 'quadrant-good'
      : '';

  return (
    <div className="CorporateReport-SummaryCard-container">
      <div className="CorporateReport-QuadrantCard">
        <div className="CorporateReport-Quadrant border-right border-bottom">
          <h4 id="average-estimated-recon">
            {displayOptionalDollarAmount(averageEstimatedRecon, false)}
          </h4>
          {strings.AVERAGE_ESTIMATED}
        </div>
        <div className="CorporateReport-Quadrant border-bottom">
          <h4 id="average-actual-recon">
            {displayOptionalDollarAmount(averageActualRecon, false)}
          </h4>
          {strings.AVERAGE_ACTUAL}
        </div>
        <div className="CorporateReport-Quadrant border-right">
          <h4
            id="average-overage-recon"
            className={determineOverageClassName(averageOverage)}
          >
            {displayOptionalDollarAmount(averageOverage)}
          </h4>
          {strings.AVERAGE_OVERAGE}
        </div>
        <div className="CorporateReport-Quadrant">
          <h4
            id="total-overage-recon"
            className={determineOverageClassName(totalOverage)}
          >
            {displayOptionalDollarAmount(totalOverage)}
          </h4>
          {strings.TOTAL_OVERAGE}
        </div>
      </div>
    </div>
  );
};

const CorporateReport: FC<ReportsProps> = ({ dateRange, path, title }) => {
  const [tagFilters, setTagFilters] = useState<string[]>([]);

  const [selectedConditionFilter, setSelectedConditionFilter] =
    useState<string>('ALL');

  const {
    data: { data } = {},
    error,
    isInitialLoading: isReportLoading,
  } = useCorporateReports({
    dateRange,
    tagIds: tagFilters,
    condition: selectedConditionFilter,
  });

  const { isLoading: isFiltersLoading } = useCorporateReportFilters();

  const isLoading = isFiltersLoading || isReportLoading;
  var OverviewRowSquares = ({
    overviewData: {
      completedVehicles,
      averageActualRecon,
      averageEstimatedRecon,
      averageOverage,
      averageReconTimeAsSeconds,
      averageRetailReadyAsSeconds,
      totalOverage,
    },
  }: OverviewRowProps) => {
    const nonCurrencySummarySquares = [
      {
        itemType: 'VehiclesCompleted',
        statValue: completedVehicles || 0,
        statLabel: String(strings.VEHICLES_COMPLETED),
        unit: 'Items',
      },
      {
        itemType: 'RetailReadyTime',
        statValue: averageRetailReadyAsSeconds || 0,
        statLabel: String(strings.AVERAGE_RETAIL_READY_TIME),
        unit: 'Seconds',
      },
      {
        itemType: 'AverageReconTime',
        statValue: averageReconTimeAsSeconds || 0,
        statLabel: String(strings.AVERAGE_RECON_TIME),
        unit: 'Seconds',
      },
    ].map((summaryItem: SummaryItem) => (
      <div
        key={summaryItem.statLabel}
        className="CorporateReport-SummaryCard-container"
        id={`step-report-corporate-${summaryItem?.itemType}-container`}
      >
        <SummaryCard summaryItem={summaryItem} />
      </div>
    ));

    return (
      <div className="CorporateReport-overview">
        {nonCurrencySummarySquares}
        <CurrencyQuadSquare
          averageOverage={averageOverage}
          averageActualRecon={averageActualRecon}
          averageEstimatedRecon={averageEstimatedRecon}
          totalOverage={totalOverage}
        />
      </div>
    );
  };
  const overviewData = data?.overview;
  const rooftopsData = data?.data ?? [];

  const { data: { data: filters } = {} } = useCorporateReportFilters();

  const [selectedReportType, setSelectedReportType] =
    useState<ReportType | null>(null);
  const { isLoadingExport, downloadFileUrl = '' } =
    useCorporateReportDownloadLink(selectedReportType, dateRange);

  return (
    <ReportContainer
      className="CorporateReport"
      loading={isLoading}
      error={error}
    >
      <div className="CorporateReport-header">
        <div className="CorporateReport-title">{strings.OVERVIEW}</div>
        <TagFilter
          onChange={setTagFilters}
          selectedTags={tagFilters}
          allOptionsLabel="All Tags"
        />
        <ConditionFilter
          onChange={setSelectedConditionFilter}
          selectedConditionId={selectedConditionFilter}
          conditions={filters?.conditions}
        />
      </div>
      {overviewData && <OverviewRowSquares overviewData={overviewData} />}
      <div
        className="flex-rows full-width"
        id="corporate-report-summary-container"
      >
        <div className="CorporateReport-title">
          {strings.ORGANIZATION_SUMMARY}
          <DownloadMenu
            downloadFileUrl={downloadFileUrl}
            onSelectedMenuType={setSelectedReportType}
            isLoading={isLoadingExport}
            formats={['EXCEL']}
          />
        </div>
        <StickyContainer>
          <ReportTitleRow
            titles={[
              { name: strings.VEHICLES_COMPLETED },
              { name: strings.AVERAGE_RETAIL_READY_TIME },
              { name: strings.AVERAGE_RECON_TIME },
              { name: strings.AVERAGE_ACTUAL },
              { name: strings.AVERAGE_OVERAGE },
            ]}
          />
        </StickyContainer>

        {rooftopsData?.map((rooftop) => (
          <Accordion
            key={rooftop.tenantId}
            TransitionProps={{ mountOnEnter: true }}
          >
            <AccordionSummary expandIcon={false}>
              <RooftopSummaryRow rooftop={rooftop} />
            </AccordionSummary>
            <AccordionDetails>
              <DealerStepReport
                path={path}
                title={title}
                dateRange={dateRange}
                corporateTenantId={rooftop.tenantId}
                displayOverView={false}
              />
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </ReportContainer>
  );
};

export default CorporateReport;
