import { useMediaQuery, useTheme } from '@material-ui/core';
import { PhotoLibrary } from '@material-ui/icons';
import React, { useContext, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';

import { permissions, RECON_STEP_STATE } from 'common';
import { usePermissions } from 'hooks';
import { Task, VehicleCardTabType, VehicleSummary } from 'models';

import VehicleCardDesktop from './VehicleCardDesktop';
import VehicleCardMobile from './VehicleCardMobile';

import './VehicleCard.scss';

interface VehicleCardContextData {
  vehicle?: VehicleSummary;
  inExtensionCardMode: boolean;
  isOnboarding: boolean;
  isShowroomMode: boolean;
  position: number;
  forceShowEngageTab: boolean;
  currentTab?: VehicleCardTabType;
  setCurrentTab?: (t: VehicleCardTabType) => void;
  showingInvoicing?: boolean;
}

export const VehicleCardContext = React.createContext<VehicleCardContextData>({
  inExtensionCardMode: false,
  isOnboarding: false,
  isShowroomMode: false,
  position: 0,
  forceShowEngageTab: false,
  currentTab: 'engage',
});

interface WrapConditionalContainerProps {
  children: React.ReactNode;
  toggleIsModalGalleryOpen: () => void;
}

export const WrapImagePreviewForShowroom = ({
  children,
  toggleIsModalGalleryOpen,
}: WrapConditionalContainerProps) => {
  const { vehicle, isShowroomMode } = useContext(VehicleCardContext);
  const vehicleImagesCount = vehicle?.vehicleCard?.imageCarousel?.length || 0;

  if (!isShowroomMode || !vehicle) {
    return <>{children}</>;
  }

  const runOnClickIfImages = () => {
    if (vehicleImagesCount > 0) toggleIsModalGalleryOpen();
  };

  return (
    <div onClick={runOnClickIfImages} role="button" tabIndex={0}>
      {children}
      {vehicleImagesCount > 0 && (
        <div className="vehicle-image-count">
          {vehicleImagesCount || ''}
          <PhotoLibrary />
        </div>
      )}
    </div>
  );
};

interface GetInitialTabParameters {
  showingInvoicing: boolean;
  vehicleIsInRecon: boolean;
  hasReconViewPermission: boolean;
  hasVelocityEngageViewPermission: boolean;
  hasInvoicingViewPermission: boolean;
}
function getInitialTab({
  showingInvoicing,
  vehicleIsInRecon,
  hasReconViewPermission,
  hasVelocityEngageViewPermission,
  hasInvoicingViewPermission,
}: GetInitialTabParameters): VehicleCardTabType {
  let initialTab: VehicleCardTabType = 'recon';
  if (showingInvoicing && hasInvoicingViewPermission) {
    initialTab = 'tasks';
  } else if (hasReconViewPermission && vehicleIsInRecon) {
    initialTab = 'recon';
  } else if (hasVelocityEngageViewPermission) {
    initialTab = 'engage';
  } else {
    initialTab = 'inventory';
  }
  return initialTab;
}

interface VehicleCardInfoReturn {
  CardComponent: any;
  cardClassName: string;
  cardHeight: number;
}
// TODO: include showroom components once feature flag is no longer needed
// TODO: cleanup once feature flag is no longer needed
// TODO: create card type
export function useVehicleCardInfo(
  isShowroomMode: boolean = false,
  showingInvoicing: boolean = false
): VehicleCardInfoReturn {
  const theme = useTheme();
  const mdMatch = useMediaQuery(theme.breakpoints.up('md'));

  const cardClassName = mdMatch ? 'VehicleCardDesktop' : 'VehicleCardMobile';

  let CardComponent;
  if (mdMatch) {
    CardComponent = VehicleCardDesktop;
  } else {
    CardComponent = VehicleCardMobile;
  }

  let cardHeight;
  if (mdMatch) {
    if (showingInvoicing) {
      cardHeight = 235;
    } else if (isShowroomMode) {
      cardHeight = 105;
    } else {
      cardHeight = 245;
    }
  } else {
    if (showingInvoicing) {
      cardHeight = 315;
    } else if (isShowroomMode) {
      cardHeight = 190;
    } else {
      cardHeight = 442;
    }
  }
  return { CardComponent, cardClassName, cardHeight };
}

export interface VehicleCardConfigProp {
  onVehicleCardEngageSendClick: () => void;
  onViewCVDPClick: () => void;
}

interface VehicleCardProps {
  contextData: {
    vehicle?: VehicleSummary;
    tasks?: Task[];
    inExtensionCardMode: boolean;
    isOnboarding: boolean;
    isShowroomMode: boolean;
    position: number;
    forceShowEngageTab: boolean;
    showingInvoicing?: boolean;
  };
  config: VehicleCardConfigProp;
  height?: number | string;
  onPreviewVehicle: () => void;
  isPreviewed: boolean;
}

const VehicleCard = ({
  height,
  onPreviewVehicle,
  isPreviewed,
  contextData,
  config,
}: VehicleCardProps) => {
  const [currentTab, setCurrentTab] = useState<VehicleCardTabType>('recon');
  const style = height ? { height, maxHeight: height } : {};
  const { vehicle, isShowroomMode, showingInvoicing = false } = contextData;

  const vehicleIsInRecon: boolean | undefined =
    vehicle && vehicle?.vehicleCard.reconStatus === RECON_STEP_STATE.ACTIVE;
  const { hasPermission } = usePermissions();
  const hasReconViewPermission = hasPermission(permissions.RECON_VIEW);
  const hasVelocityEngageViewPermission = hasPermission(
    permissions.PLUGIN_VELOCITYENGAGE_VIEW
  );
  const hasInvoicingViewPermission = hasPermission(permissions.INVOICING_VIEW);
  useEffect(() => {
    if (vehicleIsInRecon !== undefined) {
      setCurrentTab(
        getInitialTab({
          showingInvoicing,
          vehicleIsInRecon,
          hasReconViewPermission,
          hasVelocityEngageViewPermission,
          hasInvoicingViewPermission,
        })
      );
    }
  }, [
    vehicleIsInRecon,
    setCurrentTab,
    hasReconViewPermission,
    hasVelocityEngageViewPermission,
    hasInvoicingViewPermission,
    isShowroomMode,
    showingInvoicing,
  ]);

  const { CardComponent, cardClassName } = useVehicleCardInfo(isShowroomMode);
  const soldClassName = vehicle?.vehicleCard.sold ? 'VehicleCard-sold' : '';
  const status = vehicle?.stepItem?.status;
  const statusClassName = status ? `status-${status}` : '';

  const extraClasses = `${soldClassName} ${statusClassName} ${cardClassName}`;

  return (
    <VehicleCardContext.Provider
      value={{
        ...contextData,
        currentTab,
        setCurrentTab,
      }}
    >
      <Card
        id={`vehicleCard-${contextData.position}`}
        className={`VehicleCard2 VehicleCard-mode-inventory ${extraClasses}`}
        onClick={onPreviewVehicle}
      >
        <CardComponent style={style} config={config} />
        {isPreviewed && <div className="VehicleCard2-selected" />}
      </Card>
    </VehicleCardContext.Provider>
  );
};

export default VehicleCard;
