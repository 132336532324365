import {
  Box,
  createStyles,
  IconButton,
  makeStyles,
  SvgIcon,
  SvgIconProps,
  Theme,
  Typography,
} from '@material-ui/core';
import {
  AssignmentOutlined,
  AssignmentTurnedIn,
  AttachFile,
  Build,
  Close,
  Comment,
  FolderOpen,
  History,
  LocationOn,
  Receipt,
  Warning,
} from '@material-ui/icons';
import { navigate } from '@reach/router';
import { useEffect, useState } from 'react';

import strings from 'common/strings';
import theme from 'common/theme';
import {
  CarfaxLogo,
  MPIIcon,
  RVLogoSmall,
  TourPopover,
  VelocityEngageIcon,
  VelocityLocateIcon,
} from 'components/shared';
import {
  useTourMenu,
  useTourMenuActions,
  useVendor,
  useWindowSize,
} from 'hooks';
import { Section, VehicleSummary } from 'models';
import {
  CARFAX_RELATIVE,
  carfaxBuilder,
  CHECKLIST_RELATIVE,
  DOCUMENTS_RELATIVE,
  engageBuilder,
  HISTORY_RELATIVE,
  historyBuilder,
  INVOICES_RELATIVE,
  invoicesBuilder,
  LOCATION_RELATIVE,
  locationBuilder,
  MEDIA_RELATIVE,
  mediaBuilder,
  MPI_RELATIVE,
  mpiBuilder,
  NOTES_RELATIVE,
  notesBuilder,
  ORDERS_RELATIVE,
  OVERVIEW_RELATIVE,
  overviewBuilder,
  RECALLS_RELATIVE,
  recallsBuilder,
  RECON_INFO_RELATIVE,
  REPAIR_ORDERS_RELATIVE,
  repairOrderBuilder,
  TASKS_RELATIVE,
  tasksBuilder,
  VEHICLE_INFO_RELATIVE,
  vehicleDetailsBuilder,
  VELOCITY_ENGAGE_RELATIVE,
} from 'navigation/routes';

import Menu, { MenuItem } from './Menu';

export type MobileHeaderData = {
  title: string;
  icon?: React.ReactElement;
  navigateTo: (vehicleId: string) => string;
};

export const getSelectedMenuOption = (pathname: string) => {
  const pathArr = pathname.split('/');
  if (pathArr.includes('engage')) {
    return VELOCITY_ENGAGE_RELATIVE;
  }
  if (pathArr.includes(OVERVIEW_RELATIVE)) {
    return OVERVIEW_RELATIVE;
  }
  if (pathArr.includes(VELOCITY_ENGAGE_RELATIVE)) {
    return VELOCITY_ENGAGE_RELATIVE;
  }
  if (pathArr.includes(NOTES_RELATIVE)) {
    return NOTES_RELATIVE;
  }
  if (pathArr.includes(VEHICLE_INFO_RELATIVE)) {
    return VEHICLE_INFO_RELATIVE;
  }
  if (pathArr.includes(RECON_INFO_RELATIVE)) {
    return RECON_INFO_RELATIVE;
  }
  if (pathArr.includes(MPI_RELATIVE)) {
    return MPI_RELATIVE;
  }
  if (pathArr.includes(CHECKLIST_RELATIVE)) {
    return CHECKLIST_RELATIVE;
  }
  if (pathArr.includes(TASKS_RELATIVE)) {
    return TASKS_RELATIVE;
  }
  if (pathArr.includes(MEDIA_RELATIVE)) {
    return MEDIA_RELATIVE;
  }
  if (pathArr.includes(LOCATION_RELATIVE)) {
    return LOCATION_RELATIVE;
  }
  if (pathArr.includes(DOCUMENTS_RELATIVE)) {
    return DOCUMENTS_RELATIVE;
  }
  if (pathArr.includes(RECALLS_RELATIVE)) {
    return RECALLS_RELATIVE;
  }
  if (pathArr.includes(ORDERS_RELATIVE)) {
    return ORDERS_RELATIVE;
  }
  if (pathArr.includes(HISTORY_RELATIVE)) {
    return HISTORY_RELATIVE;
  }
  if (pathArr.includes('repairOrders')) {
    return REPAIR_ORDERS_RELATIVE;
  }
  if (pathArr.includes('velocityLocate')) {
    return 'velocityLocate';
  }
  if (pathArr.includes(CARFAX_RELATIVE)) {
    return CARFAX_RELATIVE;
  }
  if (pathArr.includes(INVOICES_RELATIVE)) {
    return INVOICES_RELATIVE;
  }
  return OVERVIEW_RELATIVE;
};

const getMobileHeaderData = (
  isVendor: boolean
): Record<string, MobileHeaderData> => ({
  overview: {
    title: strings.RECON_VELOCITY,
    icon: <RVLogoSmall />,
    navigateTo: (vehicleId: string) => overviewBuilder(vehicleId, true),
  },
  velocityEngage: {
    title: strings.VELOCITY_ENGAGE,
    icon: <VelocityEngageIcon />,
    navigateTo: (vehicleId: string) => engageBuilder(vehicleId, true),
  },
  notes: {
    title: strings.NOTES,
    icon: <Comment />,
    navigateTo: (vehicleId: string) =>
      notesBuilder(vehicleId, true, undefined, isVendor),
  },
  mpi: {
    title: strings.MPI,
    icon: <MPIIcon />,
    navigateTo: (vehicleId: string) => mpiBuilder(vehicleId, true),
  },
  // Need to handle different id of VehicleSection
  // but this is the same as MPI
  checklist: {
    title: strings.MPI,
    icon: <AssignmentOutlined />,
    navigateTo: (vehicleId: string) => mpiBuilder(vehicleId, true),
  },
  recalls: {
    title: strings.RECALLS,
    icon: <Warning />,
    navigateTo: (vehicleId: string) => recallsBuilder(vehicleId, true),
  },
  [REPAIR_ORDERS_RELATIVE]: {
    title: strings.REPAIR_ORDERS,
    icon: <Build />,
    navigateTo: (vehicleId: string) => repairOrderBuilder(vehicleId, true),
  },
  location: {
    title: strings.LOCATION,
    icon: <LocationOn />,
    navigateTo: (vehicleId: string) => locationBuilder(vehicleId, true),
  },
  tasks: {
    title: strings.TASKS,
    icon: <AssignmentTurnedIn />,
    navigateTo: (vehicleId: string) =>
      tasksBuilder(vehicleId, true, undefined, isVendor),
  },
  media: {
    title: strings.MEDIA_AND_DOCS,
    icon: <FolderOpen />,
    navigateTo: (vehicleId: string) => mediaBuilder(vehicleId, true),
  },
  documents: {
    title: strings.DOCUMENTS,
    icon: <AttachFile />,
    navigateTo: (vehicleId: string) => mediaBuilder(vehicleId, true),
  },
  history: {
    title: strings.HISTORY,
    icon: <History />,
    navigateTo: (vehicleId: string) => historyBuilder(vehicleId, true),
  },
  velocityLocate: {
    title: strings.VELOCITY_LOCATE,
    icon: <VelocityLocateIcon />,
    navigateTo: (vehicleId: string) =>
      `${vehicleDetailsBuilder(vehicleId)}/locate`,
  },
  carfax: {
    title: strings.CARFAX,
    icon: <CarfaxLogo />,
    navigateTo: (vehicleId: string) => carfaxBuilder(vehicleId, true),
  },
  invoices: {
    title: strings.INVOICES,
    icon: <Receipt />,
    navigateTo: (vehicleId: string) =>
      invoicesBuilder(vehicleId, false, isVendor),
  },
});

type Props = {
  onClose: () => void;
  sections: Section[];
  vehicleSummary: VehicleSummary;
};

const Header = ({ onClose, sections, vehicleSummary }: Props) => {
  const windowSize = useWindowSize();
  const [showMenu, setShowMenu] = useState(false);
  const classes = useStyles({
    isMobile: windowSize.isMobileViewport(),
  });
  const { isVendor } = useVendor();
  const mobileHeaderData = getMobileHeaderData(isVendor);
  const {
    actionType: tourActionType,
    name: tourName,
    menuTriggerType,
  } = useTourMenu();
  const tourMenuActions = useTourMenuActions();
  const [anchorPopover, setAnchorPopover] = useState<HTMLDivElement | null>();
  const [showPopover, setShowPopover] = useState(false);
  const [hasShownPopover, setHasShownPopover] = useState(false);

  const getMobileHeaderItem = (): MobileHeaderData => {
    const activeSection = getSelectedMenuOption(location!.pathname);
    if (activeSection) {
      return mobileHeaderData[activeSection];
    }
    // Default return overview
    return mobileHeaderData[OVERVIEW_RELATIVE];
  };
  const { title, icon } = getMobileHeaderItem();

  const handleMenuClick = () => {
    setShowMenu(!showMenu);
  };

  const getVelocityLocateUrl = (links: string) => {
    const linkIndex = links.lastIndexOf('https');
    return links.slice(linkIndex);
  };

  const handleMenuItemClick = (item: MenuItem) => {
    setShowMenu(false);

    if (item.isTour) {
      tourMenuActions.showTour();
      return;
    }

    if (item?.id === 'velocityLocate') {
      const velocityLocateUrl = getVelocityLocateUrl(item?.links!.self);
      window.open(velocityLocateUrl, '_newtab');
      return;
    }
    if (item.navigateTo) {
      navigate(item.navigateTo(vehicleSummary.vehicleCard.id!), {
        replace: true,
      });
    }
  };

  const handleClosePopover = () => {
    setAnchorPopover(null);
    setShowPopover(false);
    setHasShownPopover(true);
  };

  useEffect(() => {
    if (
      anchorPopover &&
      !hasShownPopover &&
      tourActionType === 'hide' &&
      (menuTriggerType === 'vdpMenu' || menuTriggerType === 'profileMenu')
    ) {
      setShowPopover(true);
    }
  }, [anchorPopover, hasShownPopover, menuTriggerType, tourActionType]);

  return (
    <>
      <Box className={classes.box}>
        <IconButton onClick={onClose}>
          <Close className={classes.icon} />
        </IconButton>
        <Box className={`${classes.box} ${classes.titleContainer}`}>
          {icon && <div className="MobileVDPHeader-title-icon">{icon}</div>}
          <Typography variant="body2" className={classes.title}>
            {title}
          </Typography>
        </Box>
        <IconButton onClick={handleMenuClick}>
          <div
            ref={(ref) => setAnchorPopover(ref)}
            style={
              showPopover
                ? {
                    border: `2px solid ${theme.palette.primary.main}`,
                  }
                : undefined
            }
          >
            <AppIcon
              className={classes.icon}
              width="48"
              height="48"
              viewBox="0 96 960 960"
            />
          </div>
        </IconButton>
        <TourPopover
          open={showPopover}
          anchorEl={anchorPopover}
          onClose={handleClosePopover}
          message={`To reopen tour, select the menu icon, then ${tourName}.`}
        />
      </Box>
      <Menu
        sections={sections}
        mobileHeaderData={mobileHeaderData}
        open={showMenu}
        onMenuItemClick={handleMenuItemClick}
      />
    </>
  );
};

const useStyles = makeStyles<Theme, { isMobile: boolean }>((theme: Theme) =>
  createStyles({
    box: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      color: 'primary.main',
    },
    titleContainer: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      color: theme.palette.primary.dark,
      fontWeight: 600,
      paddingLeft: theme.spacing(1),
    },
    icon: {
      color: theme.palette.primary.dark,
    },
  })
);

const AppIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M110 526V205h321v321H110Zm0 420V625h321v321H110Zm420-420V205h321v321H530Zm0 420V625h321v321H530Z" />
    </SvgIcon>
  );
};

export default Header;
