import { Step } from 'react-joyride';

import { Tour as BaseTour, styles } from 'components/shared/Tour';
import { useWindowSize } from 'hooks';

const welcomeStep: Step = {
  target: '.tour-main',
  title: 'Vehicle Invoices Tour',
  content:
    "Welcome to the vehicle invoices page.  Let's take a quick tour to familiarize you with the vehicle invoices.",
  disableBeacon: true,
  placement: 'center',
  styles: {
    ...styles,
    tooltipContainer: {
      textAlign: 'left',
    },
  },
  locale: {
    next: 'Start',
  },
};

const tableSteps: Step[] = [
  welcomeStep,
  {
    content: 'Payment status of the invoice',
    target: '.tour-invoice-status',
  },
  {
    content:
      'Clicking the invoice number will navigate to the invoice where it can be paid',
    target: '.tour-invoice-number',
  },
  {
    content: 'Date invoice was created',
    target: '.tour-invoice-date',
  },
  {
    content: 'Payment due date for invoice',
    target: '.tour-invoice-payment-due-by',
  },
  {
    content: 'Vendor that created the invoice',
    target: '.tour-invoice-vendor',
  },
  {
    content: 'Total amount due for the invoice',
    target: '.tour-invoice-total',
    locale: {
      last: 'Close ',
    },
  },
];

const listSteps: Step[] = [
  welcomeStep,
  {
    content:
      'Pressing the invoice will navigate to the invoice where it can be paid',
    target: '.tour-list-item',
  },
  {
    content: 'Invoice number',
    target: '.tour-invoice-number',
  },
  {
    content: 'Payment status of the invoice',
    target: '.tour-invoice-status',
  },
  {
    content: 'Total amount due for the invoice',
    target: '.tour-invoice-total',
  },
  {
    content: 'Date invoice was created',
    target: '.tour-invoice-date',
  },
  {
    content: 'Payment due date for invoice',
    target: '.tour-invoice-payment-due-by',
  },
  {
    content: 'Vendor that created the invoice',
    target: '.tour-invoice-vendor',
    locale: {
      last: 'Close ',
    },
  },
];

type Props = {
  showTour: boolean;
  name: string;
};
const Tour = ({ name, showTour }: Props) => {
  const { isMobileViewport } = useWindowSize();
  const isMobile = isMobileViewport();

  return (
    <BaseTour
      name={name}
      menuTriggerType={isMobile ? 'vdpMenu' : 'profileMenu'}
      showTour={showTour}
      localStorageKey={'tour_hasShownInvoicesTour'}
      steps={isMobile ? listSteps : tableSteps}
    />
  );
};

export default Tour;
