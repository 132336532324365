import Delete from '@material-ui/icons/Delete';
import { FC } from 'react';

import permissions from 'common/permissions';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import PermissionsGate from 'components/shared/PermissionsGate';
import S3UploadPlaceholder from 'components/shared/S3UploadPlaceholder';
import {
  AttachmentIcon,
  ImageIcon,
} from 'components/shared/UploadPlaceholder/Icons';
import { VehicleAttachment } from 'models';
import { getCalendarDateTimeStringFromDate } from 'utils/time';

import { SubmittedAttachment } from '../UploadProvider';

import './DocumentRow.scss';

interface DocumentRowProps {
  onDeleteClick?: (attachment: VehicleAttachment) => void;
  loading?: boolean;
  attachment: VehicleAttachment;
  document?: SubmittedAttachment;
}

const DocumentRow: FC<DocumentRowProps> = ({
  attachment,
  onDeleteClick,
  loading = false,
  document,
}) => {
  const renderIcon = () => {
    if (loading && !document) {
      return (
        <div className="DocumentRow-loading-spinner">
          <LoadingSpinner />
        </div>
      );
    }
    if (loading && document) {
      return <S3UploadPlaceholder attachment={document} showOverlay />;
    }

    switch (attachment.contentType) {
      case 'application/pdf':
        return <AttachmentIcon />;
      case 'image/png':
      case 'image/jpg':
      case 'image/jpeg':
        return <ImageIcon />;
      default:
        return <AttachmentIcon />;
    }
  };

  const viewDocument = () => {
    window.open(attachment.uri);
  };

  return (
    <div className="DocumentRow" role="none" onClick={viewDocument}>
      <div className="DocumentRow-icon-container">{renderIcon()}</div>
      <div className="DocumentRow-file-info">
        <div className="DocumentRow-title">{attachment.name}</div>
        {attachment.createdDate && (
          <div className="DocumentRow-subtitle">
            {getCalendarDateTimeStringFromDate(
              new Date(attachment.createdDate)
            )}
          </div>
        )}
      </div>
      {onDeleteClick && (
        <PermissionsGate
          permissions={[permissions.INVENTORY_VDP_ATTACHMENTS_DELETE]}
        >
          <Delete
            className="DocumentRow-delete-button"
            onClick={(event: any) => {
              event.stopPropagation();
              onDeleteClick(attachment);
            }}
          />
        </PermissionsGate>
      )}
    </div>
  );
};

export default DocumentRow;
