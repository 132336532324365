import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { navigate, Link as RLink } from '@reach/router';
import { FC, useCallback, useEffect, useState } from 'react';
import validateJs from 'validate.js';

import {
  ResetPasswordError,
  useResetPassword,
  useValidateResetPasswordToken,
} from 'api';
import images from 'common/images';
import strings from 'common/strings';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import Wrapper from 'components/shared/Wrapper';
import { FORGOT_PASSWORD } from 'navigation/routes';

import { changePasswordValidations } from './validations';

import './ChangePassword.scss';

function getTokenFromUrl() {
  const regex = /token=([^&]*)/;
  const matches = window!.location!.href!.match(regex);
  const token = matches && matches[1];
  return token;
}

interface ChangePasswordProps {
  path: string;
}

const ChangePassword: FC<ChangePasswordProps> = () => {
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState<
    string | null
  >(null);
  const [formError, setFormError] = useState<string | null>(null);

  const token = getTokenFromUrl();
  const { mutateAsync: changePassword, isLoading } = useResetPassword({
    passwordToken: token!,
    newPassword: password,
  });

  const clearErrors = (): void => {
    setPasswordError(null);
    setConfirmPasswordError(null);
    setFormError(null);
  };

  const validateFields = (): boolean => {
    clearErrors();
    const results = validateJs(
      { password, confirmPassword },
      { ...changePasswordValidations }
    );

    if (results) {
      setPasswordError(
        results.password && results.password.length ? results.password[0] : null
      );
      setConfirmPasswordError(
        results?.confirmPassword.length ? results.confirmPassword[0] : null
      );
      return false;
    }

    return true;
  };

  const handleSubmit = async (): Promise<void> => {
    if (validateFields()) {
      try {
        if (token) {
          await changePassword();
          await navigate('/', { replace: true });
        }
      } catch (error) {
        if (error instanceof ResetPasswordError) {
          handleResetPasswordError(error);
        } else {
          setFormError(strings.API_MESSAGE);
        }
      }
    }
  };

  const handleResetPasswordError = useCallback(
    async ({ error }: ResetPasswordError) => {
      setIsTokenValid(false);
      switch (error) {
        case 'EXPIRED_TOKEN': {
          try {
            setFormError(strings.EXPIRED_PASSWORD_RESET_LINK);
          } catch (newError) {
            setFormError(strings.API_MESSAGE);
          }
          break;
        }
        case 'INVALID_TOKEN': {
          setFormError(strings.INVALID_PASSWORD_RESET_LINK);
          break;
        }
        case 'USED_TOKEN': {
          setFormError(strings.USED_PASSWORD_RESET_LINK);
          break;
        }
        default: {
          setFormError(strings.API_MESSAGE);
        }
      }
    },
    []
  );

  const {
    mutateAsync: validateResetPasswordToken,
    isLoading: isTokenValidLoading,
  } = useValidateResetPasswordToken(token);

  const [isTokenValid, setIsTokenValid] = useState(false);

  useEffect(() => {
    if (!token) return;
    validateResetPasswordToken()
      .then(() => {
        clearErrors();
        setIsTokenValid(true);
      })
      .catch(function (error) {
        handleResetPasswordError(error);
      });
  }, [
    validateResetPasswordToken,
    setFormError,
    token,
    handleResetPasswordError,
  ]);

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      component="main"
      className="root page-container"
    >
      <Grid
        item
        component={Paper}
        elevation={6}
        xs={12}
        className="form-container"
        square
      >
        <img alt="recon-img" src={images.ReconIcon} className="change-logo" />

        {(isLoading || isTokenValidLoading) && <LoadingIndicator size={60} />}

        {!isTokenValid && !isTokenValidLoading && (
          <div className="d-flex align-items-center flex-column flex-grow justify-content-center">
            <Typography
              variant="h4"
              className="blue-text font-bold justify-content-center"
            >
              {strings.CHANGE_PASSWORD_UNABLE_TO_RESET_PASSWORD}
            </Typography>
            <br />
            <Button
              id="request-new-password"
              type="button"
              fullWidth
              variant="contained"
              className="submit blue-button double-margin-bottom"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              <Link
                color="inherit"
                component={RLink}
                to={`/${FORGOT_PASSWORD}`}
              >
                {strings.CHANGE_PASSWORD_CLICK_HERE}
              </Link>
            </Button>
          </div>
        )}

        {isTokenValid && !isTokenValidLoading && (
          <>
            <Typography variant="h4" className="blue-text font-bold">
              {strings.CHANGE_PASSWORD_TITLE}
            </Typography>
            <Typography
              paragraph
              variant="subtitle1"
              className="grey-text font-light"
            >
              {strings.CHANGE_PASSWORD_DESCRIPTION}
            </Typography>
            <Typography variant="caption" className="blue-text font-bold">
              {strings.CHANGE_PASSWORD_SUBTITLE}
            </Typography>

            <ul className="list">
              <li>
                <Typography
                  variant="caption"
                  className="grey-text font-light list-item"
                >
                  {strings.CHANGE_PASSWORD_TEXT_1}
                </Typography>
              </li>
              <li>
                <Typography
                  variant="caption"
                  className="grey-text font-light list-item"
                >
                  {strings.CHANGE_PASSWORD_TEXT_2}
                </Typography>
              </li>
              <li>
                <Typography
                  variant="caption"
                  className="grey-text font-light list-item"
                >
                  {strings.CHANGE_PASSWORD_TEXT_3}
                </Typography>
              </li>
              <li>
                <Typography
                  variant="caption"
                  className="grey-text font-light list-item"
                >
                  {strings.CHANGE_PASSWORD_TEXT_4}
                </Typography>
              </li>
            </ul>
            <Wrapper class={passwordError ? 'input-error' : 'input'}>
              <TextField
                variant="filled"
                margin="normal"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                id="password"
                label={strings.PASSWORD_FIELD}
                error={!!passwordError || !!formError}
                helperText={passwordError}
                name="password"
                className="double-margin-bottom"
                autoFocus
                disabled={isLoading}
                type="password"
              />
            </Wrapper>
            <Wrapper class={confirmPasswordError ? 'input-error' : 'input'}>
              <TextField
                variant="filled"
                margin="normal"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                fullWidth
                id="confirm-password"
                label={strings.CONFIRM_PASSWORD_FIELD}
                error={!!confirmPasswordError || !!formError}
                helperText={confirmPasswordError}
                name="confirmPassword"
                className="double-margin-bottom"
                autoFocus
                disabled={isLoading}
                type="password"
              />
            </Wrapper>
            <Button
              id="change-password"
              type="submit"
              fullWidth
              variant="contained"
              className="submit blue-button double-margin-bottom"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {strings.CHANGE_PASSWORD_BUTTON_TEXT}
            </Button>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default ChangePassword;
