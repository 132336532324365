import { RouteComponentProps } from '@reach/router';
import { useState } from 'react';

import { useInvoicesQuery } from 'api/inventory/useInvoicesQuery';
import { strings } from 'common';
import ApiError from 'components/shared/ApiError';
import FlatVDPPaneHeader from 'components/shared/FlatVDPPaneHeader';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { useCurrentLocationId, useWindowSize } from 'hooks';
import { Invoice } from 'models/invoicing';
import { invoiceBuilder } from 'navigation/routes';

import InvoiceList from './InvoiceList';
import InvoiceTable from './InvoiceTable';
import Tour from './Tour';

import './Invoices.scss';

type Props = RouteComponentProps & { vehicleId: string };

const Invoices = ({ navigate, vehicleId }: Props) => {
  const locationId = useCurrentLocationId();
  const invoicesQuery = useInvoicesQuery(vehicleId);
  const { isMobileViewport } = useWindowSize();
  const isMobile = isMobileViewport();
  const [showTour, setShowTour] = useState(false);

  if (invoicesQuery.isLoading) {
    return <LoadingIndicator />;
  }

  const invoices = invoicesQuery.data?.data ?? [];

  const handleItemsRendered = () => {
    setShowTour(true);
  };

  const handleClick = (invoice: Invoice) => {
    if (locationId && invoice.id) {
      const invoicePath = invoiceBuilder(locationId, invoice.id, false);
      navigate?.(invoicePath);
    }
  };

  return (
    <div className="invoices">
      <FlatVDPPaneHeader>
        <div>{strings.INVOICES}</div>
      </FlatVDPPaneHeader>
      <main className="tour-main invoices-main">
        {isMobile ? (
          <InvoiceList
            invoices={invoices}
            onClick={handleClick}
            onItemsRendered={handleItemsRendered}
            vehicleId={vehicleId}
          />
        ) : (
          <InvoiceTable
            invoices={invoices}
            onClick={handleClick}
            onItemsRendered={handleItemsRendered}
            vehicleId={vehicleId}
          />
        )}
      </main>
      {invoicesQuery.isError && <ApiError error={invoicesQuery.isError} />}
      <Tour name={`Vehicle ${strings.INVOICES} Tour`} showTour={showTour} />
    </div>
  );
};

export default Invoices;
