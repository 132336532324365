import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { APIResponse } from 'api';
import { EngagementSearchFacets } from 'models';

export function useEngagementSearchFacets() {
  const path = '/plugins/vdp/velocityengage/engagementSearchFacets';
  const query = useQuery<APIResponse<EngagementSearchFacets>>([path]);
  const begin = useMemo(() => {
    const d = new Date();
    d.setDate(d.getDate() - 14);
    d.setHours(0, 0, 0, 0);
    return d.toISOString();
  }, []);

  // TODO: does this need to be here?
  const defaultFilterBy = {
    SHOPPER: null,
    USER: null,
    METHOD: null,
    TIMESTAMP_BEGIN: begin,
    TIMESTAMP_END: null,
  };

  return {
    query,
    defaultFilterBy,
  };
}

export default useEngagementSearchFacets;
