import { navigate, useLocation } from '@reach/router';
import { parse } from 'query-string';
import { FC, useEffect, useState } from 'react';

import { setItem } from 'api/lib/typedLocalStorage';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { useCarfax } from 'hooks/useCarfax';

export interface HandleRedirectProps {
  path?: string;
}

const HandleRedirect: FC<HandleRedirectProps> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLogout, setIsLogout] = useState<boolean>(false);
  const [pathname, setPathname] = useState<string>('');

  const { code, logout } = parse(useLocation().search);
  const { REDIRECT_ROOT, handleLogin, getVdpPath } = useCarfax();

  useEffect(() => {
    const authenticate = async () => {
      setIsLoading(true);
      await handleLogin();
      setIsLoading(false);
    };

    setPathname(getVdpPath());

    // TODO: I don't think we need to save this code to local storage
    if (code && code !== null) {
      setItem('carfaxAuthorizationCode', code as string);
    }

    if (logout) {
      setIsLogout(true);
      setIsLoading(false);
    } else {
      authenticate();
    }
  }, [code, getVdpPath, handleLogin, logout]);

  if (isLoading) {
    return <LoadingIndicator />;
  } else {
    navigate(`${REDIRECT_ROOT}${pathname}`, { state: { isLogout } });
  }
  return <></>;
};

export default HandleRedirect;
