import { useMutation } from '@tanstack/react-query';
import { merge } from 'lodash';

import { getAPIHeaders } from 'api';

export function useSendResetPassword(email: string) {
  return useMutation(async () => {
    const headers = await getAPIHeaders('POST');
    const requestOptions: RequestInit = merge<RequestInit, RequestInit>(
      headers?.options,
      {
        body: JSON.stringify({
          email,
        }),
      }
    );

    const response = await fetch(
      `${headers?.host}/authenticate/sendResetPassword`,
      requestOptions
    );

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.status}`);
    }
  });
}

export function useSendNewResetPasswordLink(expiredToken: string | null) {
  return useMutation(async () => {
    if (!expiredToken) {
      throw new Error('No token provided');
    }

    const headers = await getAPIHeaders('POST');
    const requestOptions: RequestInit = merge<RequestInit, RequestInit>(
      headers?.options,
      {
        body: JSON.stringify({
          expiredToken,
        }),
      }
    );

    const response = await fetch(
      `${headers?.host}/authenticate/sendNewResetPasswordLink`,
      requestOptions
    );

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.status}`);
    }
  });
}
