const localStorageKeys = [
  'rvweb_authBearerToken',
  'rvweb_authBearerTokenExpiration',
  'rvweb_authRefreshToken',
  'pinned-component-open',
  'carfaxVdpPath',
  'carfaxSession',
  'carfaxAuthorizationCode',
  'tour_hasShownInvoicingTour',
  'tour_hasShownInvoicesTour',
  'tour_hasShownTasksTour',
  'tour_hasShownStepReportTour',
] as const;

export type LocalStorageKey = (typeof localStorageKeys)[number];

export function getItem(key: LocalStorageKey): string {
  return localStorage.getItem(key) as string;
}
export function setItem(key: LocalStorageKey, value: string) {
  localStorage.setItem(key, value);
}
export function removeItem(key: LocalStorageKey) {
  localStorage.removeItem(key);
}
