import IconButton from '@material-ui/core/IconButton';
import {
  Close,
  FlashOn,
  PriorityHigh,
  RemoveCircle,
  Room,
  Visibility,
} from '@material-ui/icons';
import React from 'react';

import { VehicleTag } from 'models';

import './Tag.scss';

type LegacyVehicleTag = Omit<VehicleTag, 'tagIcon'> & { tagIcon?: string };

enum TagIconName {
  activeLeads = 'CUSTOMER_INTEREST',
  highPriority = 'HIGH_PRIORITY',
  matched = 'MATCHED',
  needsAttention = 'NEEDS_ATTENTION',
  notInFeed = 'NOT_IN_FEED',
  tracking = 'TRACKING',
  none = 'NONE',
}

export interface TagProps {
  tag: LegacyVehicleTag;
  removable?: boolean;
  removeTag?: (tag: LegacyVehicleTag) => void;
}

const systemIcons = {
  [TagIconName.activeLeads]: <FlashOn className="TagComponent-tag-icon" />,
  [TagIconName.highPriority]: (
    <PriorityHigh className="TagComponent-tag-icon" />
  ),
  [TagIconName.needsAttention]: (
    <Visibility className="TagComponent-tag-icon" />
  ),
  [TagIconName.notInFeed]: <RemoveCircle className="TagComponent-tag-icon" />,
  [TagIconName.tracking]: <Room className="TagComponent-tag-icon" />,
} as { [key: string]: any };

const Tag: React.FC<TagProps> = ({ removeTag, tag, removable = false }) => {
  const tagIcon = tag?.tagIcon || 'missing';
  return (
    <div
      className="TagComponent-tag"
      style={{ backgroundColor: tag.hexColor }}
      key={tag.tagId}
    >
      <div className="TagComponent-tag-padding">
        {tag.tagType === 'SYSTEM' && systemIcons[tagIcon]}
        <div className="TagComponent-tag-name">{tag.value}</div>
        {removable && tag.tagType !== 'SYSTEM' && (
          <IconButton
            size="small"
            className="TagComponent-tag-remove-button"
            onClick={async () =>
              typeof removeTag !== 'undefined' && removeTag(tag)
            }
          >
            <Close className="TagComponent-tag-remove-icon" />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default Tag;
