import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Axios from 'axios';
import moment from 'moment';

import { getAuthTenantId } from 'api';
import { getItem, removeItem, setItem } from 'api/lib/typedLocalStorage';
import {
  CarfaxAuthPayload,
  CarfaxPurchaseReportResponse,
  CarfaxReport,
  CarfaxReportResponse,
  CarfaxSession,
} from 'models/carfax';
import {
  CarfaxValues,
  HistoryBasedValue,
  HistoryBasedValueResponse,
  UpdateHistoryBasedValueInput,
  UpdateHistoryBasedValueResponse,
} from 'models/carfax/historyBasedValue';

export const useCarfax = () => {
  const AUTH_PATH = process.env.REACT_APP_CARFAX_AUTH_PATH;
  const CONNECT_PATH = process.env.REACT_APP_CARFAX_CONNECT_PATH;
  const CLIENT_PATH = process.env.REACT_APP_CARFAX_CLIENT_PATH;
  const REDIRECT_ROOT = process.env.REACT_APP_CARFAX_REDIRECT_ROOT;
  const CLIENT_ID = process.env.REACT_APP_CARFAX_CLIENT_ID;
  const REDIRECT_URI = process.env.REACT_APP_CARFAX_REDIRECT_URI;
  const STATE = btoa(process.env.REACT_APP_CARFAX_STATE as string);
  const RESPONSE_TYPE = process.env.REACT_APP_CARFAX_RESPONSE_TYPE;
  const AUDIENCE = process.env.REACT_APP_CARFAX_AUDIENCE;
  const SCOPE = process.env.REACT_APP_CARFAX_SCOPE;
  const DEFAULT_HEADERS = {
    'Content-Type': 'application/json',
  };
  const AUTH_API = Axios.create({
    baseURL: AUTH_PATH,
    headers: DEFAULT_HEADERS,
  });
  const CONNECT_API = Axios.create({
    baseURL: CONNECT_PATH,
    headers: DEFAULT_HEADERS,
  });
  const LOGIN_REDIRECT_URI = `${CLIENT_PATH}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&state=${STATE}&response_type=${RESPONSE_TYPE}&audience=${AUDIENCE}&scope=${SCOPE}`;
  const CARFAX_REPORT_GRAPHQL = `
    query($vin: Vin!){
      dealerReport(vin: $vin){
        carfaxLink {
          url
        }
        expiresAt {
          rfc
        }
      }
    }
  `;
  const CARFAX_PURCHASE_REPORT_GRAPHQL = `
    mutation ($vin: Vin!) {
      purchaseDealerReportIfRequired(vin: $vin) {
        dealerReport {
          carfaxLink {
            url
          }
          expiresAt {
            rfc
          }
        }
      }
    }
  `;
  const CARFAX_HISTORY_BASED_VALUE_GRAPHQL = `
    query($chbvInput: ChbvFetchInput) {
        historyBasedValueFetch(chbvInput: $chbvInput) {
            fairConditionValues {
                wholesale
                retail
                certified
            }
            goodConditionValues {
                wholesale
                retail
                certified
            }
            excellentConditionValues {
                wholesale
                retail
                certified
            }
            odometer
            trims {
                name
                selected
            }
            options {
                name
                selected
            }
            colors {
                name
                selected
            }
            arrows {
                up {
                    direction
                    iconUrl
                    text
                }
                down {
                    direction
                    iconUrl
                    text
                }
            }
        }
    }
`;
  const CARFAX_UPDATE_HISTORY_BASED_VALUE_GRAPHQL = `
    query($chbvInput: ChbvInput) {
      historyBasedValueUpdate(chbvInput: $chbvInput) {
        fairConditionValues {
          retail
          wholesale
          certified
        }
        goodConditionValues {
          retail
          wholesale
          certified
        }
        excellentConditionValues {
          retail
          wholesale
          certified
        }
      }
    }
  `;

  // Helpers
  const queryClient = useQueryClient();
  const getSession = (): CarfaxSession => {
    return JSON.parse(getItem('carfaxSession'));
  };
  const getAuthorizationCode = (): string => {
    return getItem('carfaxAuthorizationCode');
  };
  const getVdpPath = (): string => {
    return getItem('carfaxVdpPath');
  };
  const setVdpPath = (vdpPath: string) => setItem('carfaxVdpPath', vdpPath);
  const getReportPath = (vin: string) => {
    const tenantId = getAuthTenantId();

    return `/${tenantId}/carfaxReport/${vin}`;
  };
  const getHistoryBasedValuePath = (vin: string) => {
    const tenantId = getAuthTenantId();

    return `/${tenantId}/historyBasedValue/${vin}`;
  };

  // Auth Functions
  const handleLogout = async (): Promise<void> => {
    const session = getSession();

    await AUTH_API.post('/oauth/revoke', {
      client_id: CLIENT_ID,
      token: session.refreshToken,
    });

    removeItem('carfaxAuthorizationCode');
    removeItem('carfaxSession');
  };
  const handleLogin = async (): Promise<boolean> => {
    const authorizationCode = getAuthorizationCode();

    const { data, status } = await AUTH_API.post<CarfaxAuthPayload>(
      '/oauth/token',
      `grant_type=authorization_code&code=${authorizationCode}&redirect_uri=${REDIRECT_URI}&client_id=${CLIENT_ID}`,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );

    if (status === 200) {
      setItem(
        'carfaxSession',
        JSON.stringify({
          accessToken: data['access_token'],
          expiresAt: moment().add(data['expires_in'], 's').toString(),
          refreshToken: data['refresh_token'],
        })
      );
      return true;
    } else {
      return false;
    }
  };
  const handleRefreshToken = async (): Promise<boolean> => {
    const session = getSession();

    if (session && session.refreshToken) {
      const { data, status } = await AUTH_API.post<CarfaxAuthPayload>(
        '/oauth/token',
        `grant_type=refresh_token&refresh_token=${session.refreshToken}&client_id=${CLIENT_ID}`,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );

      if (status === 200) {
        setItem(
          'carfaxSession',
          JSON.stringify({
            accessToken: data['access_token'],
            expiresAt: moment().add(data['expires_in'], 'seconds').toString(),
            refreshToken: data['refresh_token'],
          })
        );
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  const validateSession = async (): Promise<boolean> => {
    const session = getSession();

    if (session && session.expiresAt && session.accessToken) {
      if (moment().isSameOrAfter(session.expiresAt)) {
        return await handleRefreshToken();
      } else if (session.accessToken) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  // Connect Functions
  const getReport = (vin: string): Promise<CarfaxReport> => {
    const session = getSession();

    return new Promise(async (resolve, reject) => {
      const response = await CONNECT_API.post<CarfaxReportResponse>(
        '',
        {
          query: CARFAX_REPORT_GRAPHQL,
          variables: {
            vin,
          },
        },
        {
          headers: {
            ...DEFAULT_HEADERS,
            Authorization: `Bearer ${session.accessToken}`,
          },
        }
      );

      const { data, errors } = response.data;

      if (errors && errors.length > 0) {
        reject(errors);
      } else {
        resolve({
          carfaxLink: data.dealerReport.carfaxLink.url,
          expiresAt: data.dealerReport.expiresAt.rfc,
        });
      }
    });
  };
  const carfaxPurchaseReport = (vin: string): Promise<CarfaxReport> => {
    const session = getSession();

    return new Promise(async (resolve, reject) => {
      const response = await CONNECT_API.post<CarfaxPurchaseReportResponse>(
        '',
        {
          query: CARFAX_PURCHASE_REPORT_GRAPHQL,
          variables: {
            vin,
          },
        },
        {
          headers: {
            ...DEFAULT_HEADERS,
            Authorization: `Bearer ${session.accessToken}`,
          },
        }
      );

      const { data, errors } = response.data;

      if (errors && errors.length > 0) {
        reject(errors);
      } else {
        resolve({
          carfaxLink:
            data.purchaseDealerReportIfRequired.dealerReport.carfaxLink.url,
          expiresAt:
            data.purchaseDealerReportIfRequired.dealerReport.expiresAt.rfc,
        });
      }
    });
  };
  const getHistoryBasedValue = (params: {
    vin: string;
    zipCode: string;
  }): Promise<HistoryBasedValue> => {
    const session = getSession();

    return new Promise(async (resolve, reject) => {
      const response = await CONNECT_API.post<HistoryBasedValueResponse>(
        '',
        {
          query: CARFAX_HISTORY_BASED_VALUE_GRAPHQL,
          variables: {
            chbvInput: {
              ...params,
              mobile: true,
            },
          },
        },
        {
          headers: {
            ...DEFAULT_HEADERS,
            Authorization: `Bearer ${session.accessToken}`,
          },
        }
      );

      const { data, errors } = response.data;

      if (errors && errors.length > 0) {
        reject(errors);
      } else {
        resolve(data.historyBasedValueFetch);
      }
    });
  };
  const updateHistoryBasedValue = (
    input: UpdateHistoryBasedValueInput
  ): Promise<CarfaxValues> => {
    const session = getSession();

    return new Promise(async (resolve, reject) => {
      const response = await CONNECT_API.post<UpdateHistoryBasedValueResponse>(
        '',
        {
          query: CARFAX_UPDATE_HISTORY_BASED_VALUE_GRAPHQL,
          variables: {
            chbvInput: input,
          },
        },
        {
          headers: {
            ...DEFAULT_HEADERS,
            Authorization: `Bearer ${session.accessToken}`,
          },
        }
      );

      const { data, errors } = response.data;

      if (errors && errors.length > 0) {
        reject(errors);
      } else {
        resolve(data.historyBasedValueUpdate);
      }
    });
  };

  const useCarfaxPurchaseReport = (vin: string) => {
    const tenantId = getAuthTenantId();

    return useMutation(
      [`/${tenantId}/carfaxPurchaseReport/${vin}`],
      () => carfaxPurchaseReport(vin),
      {
        onSuccess: (data) => {
          queryClient.setQueryData([getReportPath(vin)], data);
        },
        onError: (error) => console.log(error),
        retry: false,
      }
    );
  };
  const useCarfaxReport = (vin: string) => {
    return useQuery([getReportPath(vin)], () => getReport(vin), {
      onSuccess: (data) => {
        const { expiresAt } = data;

        const isReportExpired = moment().isSameOrAfter(expiresAt);

        if (isReportExpired) {
          queryClient.invalidateQueries([getReportPath(vin)]);
        }
      },
      onError: (error) => console.log(error),
    });
  };
  const useHistoryBasedValue = (
    params: { vin: string; zipCode: string },
    options?: any
  ) => {
    return useQuery(
      [getHistoryBasedValuePath(params.vin)],
      () => getHistoryBasedValue(params),
      {
        ...options,
        retry: false,
      }
    );
  };
  const useUpdateHistoryBasedValue = (options?: any) => {
    const tenantId = getAuthTenantId();
    return useMutation(
      [`/${tenantId}/updateHistoryBasedValue`],
      updateHistoryBasedValue,
      {
        ...options,
      }
    );
  };

  const LOGOUT_REDIRECT = `${AUTH_PATH}/v2/logout?client_id=${CLIENT_ID}&returnTo=${REDIRECT_URI}?logout=true`;

  return {
    LOGIN_REDIRECT_URI,
    LOGOUT_REDIRECT,
    REDIRECT_ROOT,

    validateSession,
    handleLogout,
    handleLogin,
    getHistoryBasedValuePath,
    getSession,
    getVdpPath,
    setVdpPath,
    useCarfaxPurchaseReport,
    useCarfaxReport,
    useHistoryBasedValue,
    useUpdateHistoryBasedValue,
  };
};
