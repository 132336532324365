import { DealerStepReportVehicle, Price, SummaryItem } from 'models';

export enum ratings {
  GOOD = 'good',
  NEUTRAL = 'neutral',
  BAD = 'bad',
}

export function compareAverageOverage(
  averageEstimatedRecon?: Price,
  averageActualRecon?: Price
) {
  return getRatingForValue(
    averageActualRecon?.amount,
    averageEstimatedRecon?.amount
  );
}

export function compareTotalOverage(totalOverage?: Price) {
  return getRatingForValue(totalOverage?.amount, 0);
}

export function compareGoalValue(summaryItem: SummaryItem) {
  return getRatingForValue(summaryItem.statValue, summaryItem.goalValue);
}

export function compareItemOverage(item: DealerStepReportVehicle) {
  return getRatingForValue(item?.overage?.amount, 0);
}

export function getRatingForValue(
  value: number | null = null,
  neutralValue: number | null = null
) {
  if (value === null || neutralValue === null) return ratings.NEUTRAL;
  if (value < neutralValue) return ratings.GOOD;
  if (value > neutralValue) return ratings.BAD;
  return ratings.NEUTRAL;
}
