/* eslint-disable unused-imports/no-unused-vars */
import { Step } from 'react-joyride';

import { styles } from 'components/shared/Tour';

import imageDesktopStep1 from './images/dealer/desktop/step1.png';
import imageDesktopStep2 from './images/dealer/desktop/step2.png';
import imageDesktopStep3 from './images/dealer/desktop/step3.png';
import imageDesktopStep4 from './images/dealer/desktop/step4.png';
import imageMobileStep1 from './images/dealer/mobile/step1.png';
import imageMobileStep2 from './images/dealer/mobile/step2.png';
import imageMobileStep3 from './images/dealer/mobile/step3.png';
import imageMobileStep4 from './images/dealer/mobile/step4.png';

export const name = 'Tasks Tour';

export const steps: Step[] = [
  {
    target: '.tour-main',
    title: 'Create Tasks Tour',
    content:
      "Welcome to the vehicle tasks page.  Let's take a quick tour to familiarize you with tasks",
    disableBeacon: true,
    placement: 'center',
    styles: {
      ...styles,
      tooltipContainer: {
        textAlign: 'left',
      },
    },
    locale: {
      next: 'Start',
    },
  },
  {
    content:
      'The tasks list will shows all tasks assigned to the vehicle.  Click the ADD button to add a task to the vehicle',
    target: '.tour-carousel',
    spotlightPadding: 0,
  },
  {
    content: 'Add one or many tasks to the vehicle',
    target: '.tour-carousel',
    spotlightPadding: 0,
  },
  {
    content:
      'Tasks will be moved to different statuses based on their completion',
    target: '.tour-carousel',
    spotlightPadding: 0,
  },
  {
    content:
      'All tasks are completed once they are marked as completed or denied',
    target: '.tour-carousel',
    spotlightPadding: 0,
    locale: {
      last: 'Close ',
    },
  },
];

export const images = [
  {
    alt: 'step 1',
    mobileSrc: imageMobileStep1,
    desktopSrc: imageDesktopStep1,
  },
  {
    alt: 'step 2',
    mobileSrc: imageMobileStep2,
    desktopSrc: imageDesktopStep2,
  },
  {
    alt: 'step 3',
    mobileSrc: imageMobileStep3,
    desktopSrc: imageDesktopStep3,
  },
  {
    alt: 'step 4',
    mobileSrc: imageMobileStep4,
    desktopSrc: imageDesktopStep4,
  },
];
