import InputLabel from '@material-ui/core/InputLabel';
import React, { ChangeEvent, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { components } from 'react-select';

import { useCreateAndAssignVehicleTag } from 'api/inventory/useInventory';
import { TAG_COLOR_OPTIONS } from 'common/constants';
import strings from 'common/strings';
import Select from 'components/shared/Inputs/Select';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { VehicleTag } from 'models';

import './CreateTagModal.scss';

interface CreateTagModalProps {
  onClose: () => void;
  text: string;
  onCreateTag: (tag: VehicleTag) => void;
  existingTags?: VehicleTag[];
  vehicleId?: string;
}

var CustomOption = (props: any): any => {
  const {
    data,
    isSelected,
    isFocused,
    theme: { colors },
  } = props;
  const backgroundColor = isSelected
    ? colors.primary
    : isFocused
    ? colors.primary25
    : 'transparent';
  return (
    <div className="CreateTagModal-option-container">
      <div
        className="CreateTagModal-circle-container"
        style={{ backgroundColor }}
      >
        <div
          style={{ backgroundColor: data.value }}
          className="CreateTagModal-color-circle"
        />
      </div>
      <components.Option {...props} />
    </div>
  );
};

const CreateTagModal: React.FC<
  React.PropsWithChildren<CreateTagModalProps>
> = ({ onClose, text, onCreateTag, existingTags, vehicleId }) => {
  const createAndAssignTagMutation = useCreateAndAssignVehicleTag(vehicleId);
  const [tagText, setTagText] = useState(text);
  const [hexColor, setHexColor] = useState(TAG_COLOR_OPTIONS[0].value);

  const tagWithTextExists = (newTagText: string) => {
    return existingTags
      ? existingTags?.filter((tag: VehicleTag) => tag.value === newTagText)
          .length > 0
      : false;
  };

  const isCreateButtonDisabled = () => {
    return (
      createAndAssignTagMutation.isLoading ||
      tagText.trim().length === 0 ||
      tagWithTextExists(tagText)
    );
  };

  const onSave = async () => {
    const newTag: VehicleTag = {
      value: tagText,
      hexColor,
      tagType: 'DEALER',
    };

    const savedTag = await createAndAssignTagMutation.mutateAsync(newTag);
    if (savedTag) {
      onCreateTag(savedTag);
    }
    onClose();
  };

  const onChangeText = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setTagText(value.length <= 50 ? value : value.substr(0, 50));
  };

  const disabledStyle = createAndAssignTagMutation.isLoading
    ? 'CreateTagModal-btn-submitting'
    : isCreateButtonDisabled()
    ? 'CreateTagModal-btn-disabled'
    : '';

  return (
    <Modal
      show
      centered
      onHide={onClose}
      className="CreateTagModal"
      backdrop="static"
    >
      <Modal.Header className="modal-header-container">
        <Modal.Title>{strings.CREATE_TAG_MODAL_TITLE}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="CreateTagModal-body flex-grow">
        <InputLabel shrink className="tags-dropdown-text-label">
          {strings.CREATE_TAG_MODAL_TEXT_LABEL}
        </InputLabel>
        <input
          type="text"
          value={tagText}
          className="CreateTagModal-text-input"
          onChange={onChangeText}
          disabled={createAndAssignTagMutation.isLoading}
        />
        <InputLabel
          shrink
          className="tags-dropdown-color-label CreateTagModal-dropdown-label"
        >
          {strings.CREATE_TAG_MODAL_COLOR_LABEL}
        </InputLabel>
        <Select
          options={TAG_COLOR_OPTIONS}
          defaultValue={TAG_COLOR_OPTIONS[0]}
          isSearchable
          onChange={(item: any) => {
            setHexColor(item.value);
          }}
          customComponents={{
            Option: CustomOption,
          }}
          disabled={createAndAssignTagMutation.isLoading}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="CreateTagModal-footer">
          <button
            type="button"
            onClick={onClose}
            className="CreateTagModal-cancel-button"
          >
            {strings.CANCEL}
          </button>
          <button
            type="button"
            className={`blue-button CreateTagModal-create-button ${disabledStyle}`}
            onClick={onSave}
            disabled={isCreateButtonDisabled()}
          >
            {createAndAssignTagMutation.isLoading ? (
              <LoadingIndicator size={30} />
            ) : (
              <div>{strings.CREATE_TAG_MODAL_CREATE_BUTTON}</div>
            )}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateTagModal;
