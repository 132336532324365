import { useState } from 'react';

import { useUpdateNote } from 'api';
import { permissions } from 'common';
import Alert from 'components/shared/Alert';
import Button from 'components/shared/Button/Button';
import PermissionsGate from 'components/shared/PermissionsGate';
import { NoteV2 } from 'models';

import MentionableInput from '../MentionableInput';

import './EditNote.scss';

interface VehicleCardNotesProps {
  vehicleId: string;
  note: NoteV2;
  onClose: () => void;
}
const EditNote = ({ vehicleId, note, onClose }: VehicleCardNotesProps) => {
  const { isLoading, mutateAsync } = useUpdateNote(vehicleId);

  const [shouldFocus, setShouldFocus] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [message, setMessage] = useState(note.note);

  const handleNoteSubmit = () => {
    mutateAsync({ noteId: note.id!, note: message })
      .catch((error) => setErrorMessage(error))
      .finally(() => onClose());
  };

  return (
    <PermissionsGate permissions={[permissions.INVENTORY_VDP_NOTES_UPDATE]}>
      <div className="EditNote-container">
        <div className="EditNote-input">
          <MentionableInput
            disabled={isLoading}
            shouldFocus={shouldFocus}
            onFocus={() => setShouldFocus(true)}
            onBlur={() => setShouldFocus(false)}
            existingContent={message}
            onChange={setMessage}
            submitAction={handleNoteSubmit}
            shouldClearContent={false}
          />
        </div>
        <div className="EditNote-actions">
          <Button
            className="EditNote-cancel-button"
            type="button"
            variant="danger"
            onClick={onClose}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            className="EditNote-cancel-button"
            type="button"
            variant="success"
            onClick={handleNoteSubmit}
            disabled={!message || isLoading}
          >
            Save
          </Button>
        </div>
        <Alert
          open={!!errorMessage}
          contentProps={{
            variant: 'error',
            onClose: () => setErrorMessage(''),
            message: errorMessage,
          }}
        />
      </div>
    </PermissionsGate>
  );
};

export default EditNote;
