import { FC } from 'react';

import UsersDropdown from 'components/shared/UsersDropdown';
import { StepItem } from 'models';

interface VehicleCardUserDropDownProps {
  currentStep: StepItem | undefined;
  inventoryId: string | undefined;
  tenantId?: string;
}

export const VehicleCardUserDropDown: FC<VehicleCardUserDropDownProps> = ({
  currentStep,
  inventoryId,
  tenantId,
}) => {
  if (!currentStep || !inventoryId) {
    return null;
  }

  return (
    <UsersDropdown
      inventoryId={inventoryId}
      currentStep={currentStep}
      tenantId={tenantId}
      loaderType="srpGradient"
    />
  );
};
