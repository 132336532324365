import {
  Box,
  createStyles,
  Divider,
  IconButton,
  makeStyles,
  Modal,
  Slide,
  Theme,
  Typography,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

type Props = {
  title: string;
  children: React.ReactElement;
  open: boolean;
  onClose: () => void;
};

const MobileFlatTaskDialog = ({ open, children, onClose, title }: Props) => {
  const classes = useStyles();

  return (
    <Modal open={open} style={{ zIndex: 9999 }}>
      <Slide direction="up" timeout={200} in={open} mountOnEnter unmountOnExit>
        <Box className={classes.container}>
          <Box className={classes.header}>
            <IconButton
              size="small"
              color="secondary"
              className={classes.backButton}
              onClick={onClose}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography className={classes.title} variant="h5">
              {title}
            </Typography>
          </Box>
          <Divider />
          <Box className={classes.body}>{children}</Box>
        </Box>
      </Slide>
    </Modal>
  );
};

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: '#fff',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      position: 'fixed',
    },
    header: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      height: 40,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    title: {
      fontWeight: 700,
      marginLeft: theme.spacing(1),
    },
    backButton: {},
    body: {
      height: '100%',
    },
  })
);

export default MobileFlatTaskDialog;
