import React, { useEffect, useState } from 'react';

import { permissions } from 'common';
import strings from 'common/strings';
import Checkbox from 'components/shared/Inputs/Checkbox';
import { usePermissions, useWindowSize } from 'hooks';

import './TaskTableHeader.scss';

interface TaskTableHeaderProps {
  pinned?: boolean;
  checked: boolean;
  onClick: () => void;
}

const TaskTableHeader: React.FC<TaskTableHeaderProps> = ({
  pinned = false,
  checked = false,
  onClick,
}) => {
  const { hasPermission } = usePermissions();
  const windowSize = useWindowSize();
  const [paddingRightClassName, setPaddingRightClassName] = useState('');

  const userHasTaskUpdatePermissions = hasPermission(
    permissions.INVENTORY_VDP_TASKS_UPDATE
  );

  useEffect(() => {
    const { scrollHeight, clientHeight } =
      document.getElementById('FlatTasks-tasks') ?? {};
    if (scrollHeight && clientHeight) {
      const hasVerticalScrollbar = scrollHeight > clientHeight;
      if (hasVerticalScrollbar) {
        setPaddingRightClassName('TaskTableHeader-padding-right');
      } else {
        setPaddingRightClassName('');
      }
    }
  }, [windowSize.height, windowSize.width]);

  return (
    <div
      className={`TaskTableHeader ${
        pinned ? 'TaskTableHeader-pinned' : ''
      } ${paddingRightClassName}`}
    >
      <div className="checkbox-col">
        <Checkbox
          onClick={onClick}
          label=""
          id="all-checkbox"
          name="all-checkbox"
          className={''}
          selected={checked}
          disabled={!userHasTaskUpdatePermissions}
        />
      </div>
      <div className="status-col">{strings.STATUS}</div>
      <div className="task-col">{strings.TASK}</div>
      <div className="description-col">{strings.DESCRIPTION}</div>
      <div className="assignee-col">{strings.ASSIGNEE}</div>
      <div className="photos-col">{strings.PHOTOS}</div>
      <div className="rate-col">{strings.RATE}</div>
      <div className="hours-col">{strings.HOURS}</div>
      <div className="labor-col">{strings.LABOR}</div>
      <div className="parts-col">{strings.PARTS}</div>
      <div className="total-col">{strings.TOTAL}</div>
    </div>
  );
};
export default TaskTableHeader;
