import { Paper } from '@material-ui/core';
import moment from 'moment';

import { Invoice as InvoiceType, Vehicle } from 'models/invoicing';
import { currencyFormatter, phoneNumberFormatter } from 'utils/formatter';

import './MobileInvoice.scss';

type Props = {
  invoice: InvoiceType;
  invoiceNumber: React.ReactElement;
  statusComponent: React.ReactElement | null;
  total: number;
};

const MobileInvoice = ({
  invoice,
  invoiceNumber,
  statusComponent,
  total,
}: Props) => {
  const vehicles = invoice?.vehicles;

  const formattedPhone = phoneNumberFormatter(
    invoice?.vendor?.serviceDepartment?.phone ?? ''
  );

  const header = (
    <div className="header-mobile">
      <div className="header-top">
        <div className="header-left">
          <div className="vendor-logo-container">
            {invoice?.vendor?.logoUrl && (
              <img
                className="vendor-logo"
                alt="logo"
                src={invoice?.vendor?.logoUrl}
              />
            )}
          </div>
        </div>
        <div className="header-right">
          <div className="header-details">
            <div className="header-mobile-item">
              <div>
                <div className="invoice-tenant-name">
                  {invoice?.vendor?.name}
                </div>
                <div className="invoice-tenant-detail">
                  {invoice?.vendor?.mailingAddress?.street1}
                </div>
                <div className="invoice-tenant-detail">
                  {invoice?.vendor?.mailingAddress?.city},{' '}
                  {invoice?.vendor?.mailingAddress?.state?.name}{' '}
                  {invoice?.vendor?.mailingAddress?.postalCode}
                </div>
              </div>
            </div>
            {formattedPhone.length > 0 && (
              <div className="header-mobile-item">
                <div className="invoice-tenant-detail">
                  <a href={`tel:${formattedPhone}`}>{formattedPhone}</a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="header-mobile-item">{invoiceNumber}</div>
    </div>
  );

  const dealerDetails = (
    <div className="invoice-dealer-details">
      <div className="invoice-dealer-details-total">
        <span className="invoice-dealer-details-label">Total Amount Due</span>
        <span className="invoice-dealer-details-amount">
          {currencyFormatter(total)}
        </span>
      </div>
      <div className="invoice-dealer-details-row invoice-dealer-hr">
        <div className="invoice-dealer-details-item">
          <div className="invoice-dealer-details-label">Date</div>
          <div className="invoice-dealer-details-value">
            {moment(invoice?.createdAt).format('l')}
          </div>
        </div>
        <div className="invoice-dealer-details-item invoice-dealer-center">
          <div>
            <div className="invoice-dealer-details-label">Payment Due By</div>
            <div className="invoice-dealer-details-value">
              {moment(invoice?.dueByDate).format('l')}
            </div>
          </div>
        </div>
      </div>
      <div className="invoice-dealer-details-row">
        <div className="invoice-dealer-details-item">
          <div className="invoice-dealer-details-label">Invoice for</div>

          <div className="tenant-details-container">
            <span className="tenant-detail-bold">{invoice?.tenant?.name}</span>
            <span className="tenant-detail">
              {phoneNumberFormatter(
                invoice?.tenant?.serviceDepartment?.phone ?? ''
              )}
            </span>
            <span className="tenant-detail">
              {invoice?.tenant?.serviceDepartment?.email}
            </span>
          </div>
        </div>
      </div>
    </div>
  );

  const getTasks = (vehicle: Vehicle) => (
    <div className="task">
      <div className="task-item">Tasks</div>
      {vehicle?.tasks?.map((task) => {
        return (
          <div className="task-value-background">
            <div key={task?.label} className="task-row ">
              <div className="task-value task-full">{task?.label}</div>
              <div className="task-value task-value-left task-total">
                {currencyFormatter(task?.price?.amount ?? 0)}
              </div>
            </div>
            <div className="task-value task-notes">{task?.notes}</div>
          </div>
        );
      })}
    </div>
  );

  const getVehicleTotal = (vehicle: Vehicle) => (
    <div className="task">
      <div className="task-row">
        <div className="task-item task-vehicle-total">Vehicle Total</div>
        <div className="task-value task-total">
          {currencyFormatter(vehicle.totalTaskCost)}
        </div>
      </div>
    </div>
  );

  const getVehicleItem = (vehicle: Vehicle) => {
    return (
      <div className="item">
        <div className="vehicle">
          <div className="year-make-model">
            {vehicle?.year} {vehicle?.make} {vehicle?.model}
          </div>
          <div className="vin">VIN: {vehicle?.vin}</div>
          <div className="stock">Stock #:{vehicle?.stockNumber}</div>
        </div>
        {getTasks(vehicle)}
        {getVehicleTotal(vehicle)}
      </div>
    );
  };

  return (
    <div className="mobile-invoice">
      <Paper elevation={1} className="invoice-page">
        <header className="invoice-header">
          {statusComponent}
          {header}
          {dealerDetails}
        </header>
        <main className="invoice-main">
          {vehicles.map((vehicle) => getVehicleItem(vehicle))}
        </main>
        <div className="horizontal-line horizontal-line-margin-top horizontal-line-margin-small-bottom" />
        <footer className="invoice-footer">
          <div className="total-price">Total: {currencyFormatter(total)}</div>
        </footer>
      </Paper>
    </div>
  );
};

export default MobileInvoice;
