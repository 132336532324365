import { convertToRaw, EditorState as DraftEditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Editor } from 'react-draft-wysiwyg';
import Select from 'react-select';

import {
  useCreateEngagement,
  useEngageProspects,
  useSubmitForm,
  useToken,
} from 'api';
import permissions from 'common/permissions';
import strings from 'common/strings';
import { testIds } from 'common/testIds';
import { Button as ButtonShared } from 'components/shared/Button/Button';
import Radio from 'components/shared/Inputs/Radio';
import { useWindowSize } from 'hooks';
import { EngagementShareUrl, VehicleSummary } from 'models';
import { getFullName } from 'utils/user';

import Alert from '../Alert';
import CheckBox from '../Checkbox/Checkbox';
import { TextInput } from '../Inputs';
import LoadingSpinner from '../LoadingSpinner';
import PermissionsGate from '../PermissionsGate';
import SendVehicleCard from '../SendVehicleCard';
import ShopperModal from '../ShopperModal';
import { MockModule, mockModuleList } from './MockSendVehicleData';

import './SendVehicleModal.scss';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export interface FormState {
  from: string | undefined;
  checkboxEmail: boolean;
  checkboxText: boolean;
  to: SelectUser[];
  htmlBody: string;
  shareLink: string;
  modules: MockModule[] | undefined;
  checkboxPrice: boolean;
}

interface SendVehicleModalProps {
  open: boolean;
  selectedVehicleList: VehicleSummary[];
  onClose: () => void;
}

interface SelectUser {
  id: string | undefined;
  label: string;
  value?: string;
  isSelected: boolean;
}
interface ProspectItem {
  id: string;
  label: string;
  value: string;
  isSelected: boolean;
}

const SendVehicleModal: React.FC<
  React.PropsWithChildren<SendVehicleModalProps>
> = ({ onClose, selectedVehicleList, open }) => {
  const windowSize = useWindowSize();
  const [createdEngagement, setCreatedEngagement] = useState<
    EngagementShareUrl[]
  >([]);
  const [alertMessage, setAlertMessage] = useState('');
  const createEngagementAsync = useCreateEngagement();
  const submitFormAsync = useSubmitForm();
  const [editorState, setEditorState] = useState<DraftEditorState>(
    DraftEditorState.createEmpty()
  );
  const {
    data: prospectSavedList,
    isLoading: isLoadingProspect,
    isError: isErrorProspectSavedList,
  } = useEngageProspects();
  const [prospects, setProspects] = useState<ProspectItem[]>([]);
  const [formValid, setFormValid] = useState(false);
  const [showShopperModal, setShowShopperModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formState, setFormState] = useState<FormState>({
    from: undefined,
    checkboxEmail: true,
    checkboxText: false,
    to: [],
    htmlBody: '',
    shareLink: '',
    modules: undefined,
    checkboxPrice: true,
  });

  useEffect(() => {
    if (
      prospectSavedList?.data?.length !== prospects.length &&
      !isLoadingProspect &&
      !isErrorProspectSavedList
    ) {
      const prospectList =
        prospectSavedList?.data.map((item) => {
          return {
            id: item.id,
            label: item.person.fullName,
            isSelected: false,
            value: item.id,
          };
        }) ?? [];
      setProspects(prospectList);
    }
  }, [
    prospectSavedList,
    prospects,
    isLoadingProspect,
    isErrorProspectSavedList,
  ]);
  const { data: sessionData } = useToken();

  const getEngagementIds = useCallback(
    async (prospectIdList: string[], showPrice: boolean) => {
      try {
        setIsLoading(true);
        const response = await createEngagementAsync.mutateAsync({
          vehicleId: selectedVehicleList[0]?.vehicleCard?.id ?? '',
          prospectIdList,
          senderId: sessionData?.user?.id ?? '',
          showPrice,
        });

        const engagements: EngagementShareUrl[] = response.data.flatMap(
          (item: any) => item
        );

        setCreatedEngagement(engagements);
        setFormValid(true);
      } catch (error: any) {
        setAlertMessage(error.message);
      } finally {
        setIsLoading(false);
      }

      return;
    },
    [sessionData?.user?.id, createEngagementAsync, selectedVehicleList]
  );

  const copyToClipboard = (content: string) => {
    if (content && navigator?.clipboard) {
      navigator.clipboard.writeText(content).catch(() => {
        setAlertMessage(strings.UNABLE_TO_COPY);
      });
      setAlertMessage(strings.COPIED_SHARE_LINK);
    } else {
      setAlertMessage(strings.UNABLE_TO_COPY);
    }
  };

  const onSubmit = async () => {
    try {
      await submitFormAsync.mutateAsync({
        vehicleId: selectedVehicleList[0]?.vehicleCard?.id ?? '',
        message: draftToHtml(convertToRaw(editorState?.getCurrentContent())),
        engagementType: formState.checkboxEmail ? 'EMAIL' : 'TEXT',
        engagementIds: createdEngagement.map((item) => item.engagementId),
      });

      proxyClose();
    } catch (error: any) {
      setAlertMessage('There was an error sending the vehicle.');
    }
  };

  const onCancel = () => {
    clearFormData();
    onClose();
  };

  const proxyClose = () => {
    clearFormData();
    onClose();
  };

  const clearFormData = () => {
    setFormState({
      from: sessionData?.user
        ? 'From: ' + getFullName(sessionData?.user) + ' (LOGGED IN USER)'
        : strings.SELECT_FROM_PLACEHOLDER,
      checkboxEmail: true,
      checkboxText: false,
      to: [],
      htmlBody: '',
      shareLink: '',
      modules: undefined,
      checkboxPrice: true,
    });
    setCreatedEngagement([]);
    setFormValid(false);
  };

  const handleSelectToChange = (selectedShoppers: any) => {
    const to = selectedShoppers as SelectUser[];
    setFormState((prevState) => {
      updateEngagementIds(to, prevState.checkboxPrice);
      return { ...prevState, to };
    });
  };

  const handleSelectModuleChange = (selectedModules: any) => {
    if (selectedModules.length) {
      const modules = selectedModules as MockModule[];
      setFormState((prevState) => ({ ...prevState, modules }));
    }
  };

  const updateEngagementIds = (to: SelectUser[], showPrice: boolean) => {
    if (sessionData?.user?.id && to?.length > 0) {
      const prospectList: string[] = [];
      to.forEach((item) => {
        if (item?.id) prospectList.push(item?.id.toString());
      });
      getEngagementIds(prospectList, showPrice);
    }
  };

  const togglePriceChecked = () => {
    setFormState((prevState) => {
      updateEngagementIds(prevState.to, !prevState.checkboxPrice);
      return {
        ...prevState,
        checkboxPrice: !prevState.checkboxPrice,
      };
    });
  };

  const toggleRadioValues = () => {
    setFormState((prevState) => ({
      ...prevState,
      checkboxEmail: !prevState.checkboxEmail,
      checkboxText: !prevState.checkboxText,
    }));
  };

  const addNewContact = () => {
    setShowShopperModal(true);
  };

  const onEditorStateChange = (editorState: DraftEditorState) => {
    setEditorState(editorState);
  };

  function getProspectNameById(prospectId: string): string {
    if (!prospectId && prospects) return '';
    const prospect = prospects.find((prospect) => prospectId === prospect.id);
    return prospect ? prospect.label : prospectId;
  }

  const renderVehicleCards = () =>
    selectedVehicleList.map((vehicle) => (
      <div className="SendVehicleModal-form-row">
        <SendVehicleCard vehicle={vehicle} height={110} />
      </div>
    ));

  const renderSendForm = () => (
    <div className="SendVehicleModal-form-column">
      {selectedVehicleList.length > 0 && renderVehicleCards()}
      <div
        className="SendVehicleModal-form-row pt-3"
        data-vas-testing={testIds.FROM_USER_DROPDOWN}
      >
        <Select
          className="SendVehicleModal-form-row-select"
          id={testIds.FROM_USER_DROPDOWN_LOGGED_IN_USER}
          isSearchable
          inputId={testIds.FROM_USER_DROPDOWN_DISABLED}
          isMulti
          noOptionsMessage={() => strings.NO_OPTIONS_FROM}
          placeholder={
            sessionData?.user
              ? 'From: ' + getFullName(sessionData?.user) + ' (LOGGED IN USER)'
              : strings.SELECT_FROM_PLACEHOLDER
          }
          on
          isDisabled
        />
        <Radio
          className="SendVehicleModal-form-radio-text disabled"
          label={
            windowSize.isWideViewport()
              ? strings.SEND_VIA_EMAIL
              : strings.SEND_EMAIL
          }
          name="radio-email"
          onClick={() => !formState.checkboxEmail && toggleRadioValues()}
          selected={formState.checkboxEmail}
          id="radio-email"
          disabled
        />
        <Radio
          className="SendVehicleModal-form-radio-text hidden disabled"
          label={
            windowSize.isWideViewport()
              ? strings.SEND_VIA_TEXT
              : strings.SEND_TEXT
          }
          name="radio-text"
          onClick={() => !formState.checkboxText && toggleRadioValues()}
          selected={formState.checkboxText}
          id="radio-text"
          disabled
        />
      </div>
      <div className="SendVehicleModal-form-row pt-3 SendVehicleModal-shopper-row">
        <Select
          className="SendVehicleModal-form-row-select"
          options={prospects}
          isSearchable
          isMulti
          classNamePrefix="prospects"
          noOptionsMessage={() => strings.NO_OPTIONS_TO}
          onChange={handleSelectToChange}
          placeholder={strings.SELECT_TO_PLACEHOLDER}
          inputId={testIds.SEND_TO_SHOPPER_DROPDOWN}
        />
        <ButtonShared
          id="SendVehicleModal-add-contact-button"
          data-vas-testing={testIds.ADD_SHOPPER_BUTTON}
          variant="secondary"
          onClick={() => addNewContact()}
          type="button"
          className="SendVehicleModal-add-contact-button"
        >
          {strings.ADD_SHOPPER}
        </ButtonShared>
      </div>

      <div className="SendVehicleModal-form-row pt-3">
        <Editor
          editorState={editorState}
          wrapperClassName="SendVehicleModal-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={onEditorStateChange}
        />
      </div>

      <div className="SendVehicleModal-form-row pt-3 pb-3">
        <Select
          className="SendVehicleModal-form-row-select"
          options={mockModuleList}
          isSearchable
          isMulti
          isDisabled
          noOptionsMessage={() => strings.NO_OPTIONS_MODULE}
          onChange={handleSelectModuleChange}
          placeholder={strings.SELECT_MODULE_PLACEHOLDER}
        />
        <div className="SendVehicleModal-form-checkbox-text">
          {strings.SHOW_PRICE}
        </div>
        <CheckBox
          checked={formState.checkboxPrice}
          onClick={() => togglePriceChecked()}
          disabled={false}
        />
      </div>
      {createdEngagement.map((item: EngagementShareUrl) => (
        <div className="SendVehicleModal-form-row pt-3">
          <TextInput
            className="SendVehicleModal-form-input"
            containerClassName="border-primary"
            placeholderClassName="text-primary"
            value={item.shareURL || ''}
            readOnly
            placeholder={`${strings.SHARE_LINK} for ${getProspectNameById(
              item.prospectId
            )}`}
          />
          <ButtonShared
            id="SendVehicleModal-add-contact-button"
            variant="secondary"
            onClick={() => copyToClipboard(item.shareURL)}
            type="button"
            className="SendVehicleModal-add-contact-button"
          >
            {strings.COPY}
          </ButtonShared>
        </div>
      ))}
      {isLoading && (
        <div className="SendVehicleModal-form-row pt-3 pb-3">
          <LoadingSpinner />
        </div>
      )}
    </div>
  );

  return (
    <PermissionsGate permissions={[permissions.PLUGIN_VELOCITYENGAGE_SEND]}>
      {open && !showShopperModal ? (
        <Modal
          show
          centered
          onHide={onClose}
          className="SendVehicleModal"
          backdrop="static"
          dialogClassName="SendVehicleDialog"
        >
          {alertMessage && (
            <Alert
              open={alertMessage.length > 0}
              duration={3000}
              handleClose={() => setAlertMessage('')}
              contentProps={{
                message: alertMessage,
                variant:
                  alertMessage === strings.COPIED_SHARE_LINK
                    ? 'success'
                    : 'error',
                onClose: () => setAlertMessage(''),
              }}
            />
          )}
          <Modal.Header className="modal-header-container">
            <Modal.Title data-vas-testing={testIds.SEND_VEHICLE_MODAL_TITLE}>
              {strings.VEHICLES_TO_SEND}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="SendVehicleModal-body flex-grow">
            {renderSendForm()}
          </Modal.Body>
          <Modal.Footer>
            <div className="SendVehicleModal-footer">
              <button
                type="button"
                onClick={onCancel}
                data-vas-testing={testIds.SEND_VEHICLE_MODAL_CANCEL_BUTTON}
                className="SendVehicleModal-cancel-button"
              >
                {strings.CANCEL}
              </button>
              <button
                data-vas-testing={testIds.SEND_VEHICLE_MODAL_SUBMIT_BUTTON}
                type="button"
                className="blue-button SendVehicleModal-submit-button"
                onClick={onSubmit}
                disabled={!formValid}
              >
                <div>{strings.SUBMIT}</div>
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      ) : (
        showShopperModal && (
          <ShopperModal
            onClose={() => {
              clearFormData();
              setShowShopperModal(false);
            }}
          />
        )
      )}
    </PermissionsGate>
  );
};

export default SendVehicleModal;
