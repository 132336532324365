import { ChevronLeft, Close } from '@material-ui/icons';
import React, { useMemo, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';

import { useUtilityWorkflow } from 'api';
import permissions from 'common/permissions';
import strings from 'common/strings';
import { testIds } from 'common/testIds';
import { usePermissions, useWindowSize } from 'hooks';
import { StepItem } from 'models';
import { useToggleWithAutoCondition } from 'utils/hooks';

import FilterColumn from '../FilterColumn';
import Overview from '../Overview';

import './Sidebar.scss';

export interface WorkflowMenuItem {
  id: string;
  name: string;
  level: number;
}

interface SidebarProps {
  isShowroomMode: boolean;
  isOverviewFiltersModalOpen: boolean;
  setIsOverviewFiltersModalOpen: (isOpen: boolean) => void;
}

interface ToggleButtonProps {
  classes: string;
  onClick: () => void;
}

const ToggleButton: React.FC<ToggleButtonProps> = ({ classes, onClick }) => (
  <div className={classes} onClick={onClick} role="none">
    <ChevronLeft />
  </div>
);

function getFlattenedStepItems(
  steps: StepItem[],
  level: number = 1
): WorkflowMenuItem[] {
  return steps.flatMap((step) => [
    {
      id: step.id,
      name: step.name,
      level,
    },
    ...getFlattenedStepItems(step.childSteps ?? [], level + 1),
  ]);
}

const Sidebar: React.FC<SidebarProps> = ({
  isShowroomMode,
  isOverviewFiltersModalOpen,
  setIsOverviewFiltersModalOpen,
}) => {
  const { hasPermission } = usePermissions();
  const [tabKey, setTabKey] = useState<string>('overview');
  const [isSidebarHidden, onClickToggleSidebar] = useToggleWithAutoCondition();
  const windowSize = useWindowSize();
  const isOverviewVisible = !!(windowSize.width >= 1024);
  const { data: { data: workFlowStepTree } = { data: [] } } =
    useUtilityWorkflow();

  const workflowMenuItems = useMemo(
    () => getFlattenedStepItems(workFlowStepTree),
    [workFlowStepTree]
  );

  function handleTabKey(val: string | null) {
    setTabKey(typeof val === 'string' ? val : 'overview');
  }

  const renderTabs = () => (
    <div className={`Sidebar ${isSidebarHidden ? 'hiddenSidebar' : ''}`}>
      {isOverviewVisible && (
        <ToggleButton
          classes={`toggle-sidebar ${isSidebarHidden ? 'hiddenSidebar' : ''}`}
          onClick={onClickToggleSidebar}
        />
      )}
      <Tabs
        id="SRP-sidebar"
        className="Sidebar-tabs"
        activeKey={
          isShowroomMode || !hasPermission(permissions.RECON_VIEW)
            ? 'filters'
            : tabKey
        }
        onSelect={handleTabKey}
      >
        {!isShowroomMode && hasPermission(permissions.RECON_VIEW) && (
          <Tab
            eventKey="overview"
            title={strings.RECON}
            tabClassName="Sidebar-tabs-overview-tab"
          >
            <Overview workflowMenuItems={workflowMenuItems} />
          </Tab>
        )}
        <Tab
          eventKey="filters"
          title={strings.ALL_VEHICLES}
          tabClassName={testIds.FILTERS_TAB}
        >
          <FilterColumn
            workflowMenuItems={workflowMenuItems}
            isShowroomMode={isShowroomMode}
          />
        </Tab>
      </Tabs>
    </div>
  );

  const renderFilterModal = () => {
    const visibilityClassName = isOverviewFiltersModalOpen
      ? 'SidebarMobile-show'
      : 'SidebarMobile-hide';
    return (
      <div className={`SidebarMobile ${visibilityClassName}`}>
        <div className="SidebarMobile-title-row">
          <Close
            className="SidebarMobile-close"
            onClick={() => setIsOverviewFiltersModalOpen(false)}
          />
        </div>
        {renderTabs()}
      </div>
    );
  };

  return isOverviewVisible ? renderTabs() : renderFilterModal();
};

export default Sidebar;
