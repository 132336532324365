import { chain } from 'lodash';
import moment from 'moment';
import { FC, useCallback, useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { useVehicleHistory } from 'api';
import permissions from 'common/permissions';
import strings from 'common/strings';
import { testIds } from 'common/testIds';
import FlatVDPPaneContent from 'components/shared/FlatVDPPaneContent';
import FlatVDPPaneHeader from 'components/shared/FlatVDPPaneHeader';
import PermissionsGate from 'components/shared/PermissionsGate';
import { HistoryItem } from 'models';

import VehicleLog, { HistoryLog } from './VehicleLog';

import './FlatHistory.scss';

interface FlatHistoryProps {
  vehicleId: string;
}

const FlatHistory: FC<FlatHistoryProps> = ({ vehicleId }) => {
  const {
    isInitialLoading,
    data,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useVehicleHistory(vehicleId);

  const totalRecords = data?.pages[0]?.meta?.totalRecords ?? 0;

  const history = useMemo(() => {
    return (
      data?.pages?.reduce<HistoryItem[]>(
        (acc, page) => [...acc, ...page.data.items],
        []
      ) ?? []
    );
  }, [data]);

  const loadMoreItems = useCallback(
    async (startIndex: number, stopIndex: number) => {
      if (isFetchingNextPage) {
        return Promise.resolve();
      }

      await fetchNextPage();
    },
    [isFetchingNextPage, fetchNextPage]
  );

  const historyLog: HistoryLog = chain(history)
    .groupBy((item) => moment(new Date(item.timestamp)).format('M/D/YYYY'))
    .map((value, title) => [
      { kind: 'section', title } as const,
      ...value.map((item) => ({ kind: 'item', data: item } as const)),
    ])
    .flatten()
    .value();

  return (
    <Container
      fluid
      className="FlatHistory full-height"
      data-vas-testing={testIds.VDP_HISTORY_CONTAINER}
    >
      <FlatVDPPaneHeader vehicleId={vehicleId}>
        {strings.HISTORY}
      </FlatVDPPaneHeader>
      <FlatVDPPaneContent className="FlatHistory-content">
        <PermissionsGate permissions={[permissions.INVENTORY_VDP_HISTORY_VIEW]}>
          <Row noGutters className="full-height">
            <Col>
              <VehicleLog
                historyLog={historyLog}
                loadMoreItems={loadMoreItems}
                hasNextPage={!!hasNextPage}
                totalRecords={totalRecords}
                isLoading={isInitialLoading}
              />
            </Col>
          </Row>
        </PermissionsGate>
      </FlatVDPPaneContent>
    </Container>
  );
};

export default FlatHistory;
