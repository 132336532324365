import { useMemo } from 'react';

import { useCurrentLocation } from 'api';

export function useGetProductServiceSettings() {
  const { settings } = useCurrentLocation() ?? {};
  return useMemo(() => {
    function getProductServiceSetting(settingName: string) {
      return settings?.[settingName] ?? '';
    }
    return { getProductServiceSetting };
  }, [settings]);
}
