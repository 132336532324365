import { useContext } from 'react';

import MediaUploadsContext from './MediaUploadContext';

const useMediaUploadContext = () => {
  const context = useContext(MediaUploadsContext);

  if (!context) {
    throw new Error(
      'FlatMediaSection components must be rendered as children of MediaUploadsProvider'
    );
  }

  return context;
};

export default useMediaUploadContext;
