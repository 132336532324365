import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { Link as RLink } from '@reach/router';

import { fetchAuthCognito } from 'api';
import { FORGOT_PASSWORD } from 'navigation/routes';

import './ForgotPasswordLink.scss';

const ForgotPasswordLink: React.FC<{}> = () => {
  const sso = new URLSearchParams(window.location.search).get('sso');
  return (
    <div className="ForgotPasswordLink">
      <Typography variant="body2" className="grey-text">
        {!!sso && (
          <Link
            color="inherit"
            onClick={() => {
              fetchAuthCognito().then((res) => {
                window.open(res?.redirectUrl, '_self');
              });
            }}
          >
            Single Sign-On
          </Link>
        )}
      </Typography>
      <Typography variant="body2" className="grey-text">
        <Link color="inherit" component={RLink} to={`/${FORGOT_PASSWORD}`}>
          Forgot password?
        </Link>
      </Typography>
    </div>
  );
};

export default ForgotPasswordLink;
