import { CSSProperties, MouseEventHandler, useMemo, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';

import useGetDealerInventoryQuery from 'api/invoicing/useGetDealerInventoryQuery';
import useGetVendorInventoryQuery from 'api/invoicing/useGetVendorInventoryQuery';
import { testIds } from 'common';
import { strings } from 'common/strings';
import VehicleCard from 'components/pages/VehicleCollectionBrowser/VehicleCard';
import { useVehicleCardInfo } from 'components/pages/VehicleCollectionBrowser/VehicleCard/VehicleCard';
import ApiError from 'components/shared/ApiError';
import LoadingSpinner from 'components/shared/LoadingSpinner';
import { useVendor } from 'hooks';
import { VehicleSummary } from 'models';

import Tour from '../Tour';

import './VehicleList.scss';

const VehicleList = () => {
  const { isVendor, isLoading: isVendorLoading } = useVendor();
  const [showTour, setShowTour] = useState(false);

  const getDealerInventoryQuery = useGetDealerInventoryQuery(
    !isVendorLoading && !isVendor
  );

  const getVendorInventoryQuery = useGetVendorInventoryQuery(
    !isVendorLoading && isVendor
  );

  const vehicles = useMemo(() => {
    if (getDealerInventoryQuery.data?.data.length) {
      return getDealerInventoryQuery.data.data;
    }
    if (getVendorInventoryQuery.data?.data.length) {
      return getVendorInventoryQuery.data.data;
    }
    return [];
  }, [getDealerInventoryQuery.data?.data, getVendorInventoryQuery.data?.data]);

  const rowCount = vehicles?.length ?? 0;
  const itemSize = useVehicleCardInfo(false, true).cardHeight + 41;

  const isInventoryLoading =
    getDealerInventoryQuery.isInitialLoading ||
    getVendorInventoryQuery.isInitialLoading;

  const error = getDealerInventoryQuery.error || getVendorInventoryQuery.error;

  const handleOnClick = () => {};

  if (isInventoryLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div
      data-vas-testing={testIds.INVOICING_VEHICLE_LIST}
      className="VehicleList2 full-height full-width flex-rows"
    >
      <div className="VehicleList-list-container flex-grow full-width">
        <AutoSizer>
          {({ height }) => {
            return (
              <FixedSizeList
                height={height}
                width={'100%'}
                style={{ overflowX: 'hidden' }}
                itemCount={rowCount}
                itemSize={itemSize}
                itemData={{
                  onClick: handleOnClick,
                  vehicles,
                  itemHeight: itemSize,
                }}
                onItemsRendered={() => {
                  setShowTour(true);
                }}
              >
                {renderRow}
              </FixedSizeList>
            );
          }}
        </AutoSizer>
      </div>
      {vehicles?.length === 0 && (
        <span className="vendor-no-vehicles full-height full-width flex-rows">
          {strings.NO_INVENTORY_FOUND}
        </span>
      )}
      <ApiError error={error} />
      <Tour name={`${strings.INVOICING} Tour`} showTour={showTour} />
    </div>
  );
};

type RenderRowProps = {
  index: number;
  data: {
    onClick: MouseEventHandler<HTMLDivElement>;
    vehicles: VehicleSummary[];
    itemHeight: number;
  };
  style: CSSProperties;
};
const renderRow = ({ index, data, style }: RenderRowProps) => {
  const { itemHeight, vehicles } = data;
  const vehicle = vehicles[index];

  return (
    <div style={style}>
      <VehicleCard
        key={vehicle?.vehicleCard?.id}
        contextData={{
          vehicle,
          tasks: vehicle?.tasks,
          forceShowEngageTab: true,
          inExtensionCardMode: false,
          isOnboarding: false,
          isShowroomMode: false,
          position: index,
          showingInvoicing: true,
        }}
        height={itemHeight}
        config={{
          onVehicleCardEngageSendClick: () => {},
          onViewCVDPClick: async () => {},
        }}
        onPreviewVehicle={() => {}}
        isPreviewed={false}
      />
    </div>
  );
};

export default VehicleList;
