import { range } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

import strings from 'common/strings';
import { Select } from 'components/shared/Inputs';

import 'react-date-range/dist/styles.css';
import './MonthYearPickerModal.scss';
import 'react-date-range/dist/theme/default.css';

interface MonthYearPickerModalProps {
  onClose: any;
  onAccept: (s: DateRangeProps) => void;
  showModal: boolean;
}

export interface DateRangeProps {
  startDate: Date;
  endDate: Date;
}

const MonthYearPickerModal: React.FC<
  React.PropsWithChildren<MonthYearPickerModalProps>
> = ({ onClose, showModal, onAccept }) => {
  const [year, setYear] = useState<number>();
  const [month, setMonth] = useState<number>();
  const [selectedRange, setSelectedRange] = useState<DateRangeProps>();
  const currentYear = new Date().getFullYear();
  const monthOptions = moment.monthsShort().map((m, index) => {
    return { label: m, value: index.toString() };
  });

  useEffect(() => {
    if (typeof month === 'undefined' || typeof year === 'undefined') {
      setSelectedRange(undefined);
      return;
    }
    setSelectedRange({
      startDate: new Date(year, month, 1),
      endDate: new Date(year, month + 1, 0),
    });
  }, [month, year]);

  return (
    <Modal
      show={showModal}
      size="lg"
      centered
      onHide={onClose}
      dialogClassName="MonthYearPickerModal"
    >
      <div className="MonthYearPickerModal-body">
        <Select
          className="border-light add-month"
          placeholder={strings.MONTH}
          options={monthOptions}
          onChange={(selectedOption) =>
            setMonth(parseInt(selectedOption.value, 10))
          }
          value={month ? monthOptions[month] : undefined}
        />
        <Select
          className="border-light add-year"
          placeholder={strings.YEAR}
          options={range(currentYear - 5, currentYear + 1)
            .map((y) => {
              return { label: y.toString(), value: y.toString() };
            })
            .reverse()}
          onChange={(selectedOption) =>
            setYear(parseInt(selectedOption.value, 10))
          }
          value={
            year
              ? { label: year.toString(), value: year.toString() }
              : undefined
          }
        />
      </div>
      <div className="MonthYearPickerModal-footer">
        <Button
          onClick={() => onClose()}
          className="white-button MonthYearPickerModal-cancel-button"
        >
          {strings.CANCEL}
        </Button>
        <Button
          className="blue-button MonthYearPickerModal-ok-button"
          disabled={!selectedRange}
          onClick={() => selectedRange && onAccept(selectedRange)}
        >
          {strings.OK}
        </Button>
      </div>
    </Modal>
  );
};

export default MonthYearPickerModal;
