import { useQuery } from '@tanstack/react-query';

import { APIResponse } from 'api/useAPI';
import { Invoice } from 'models/invoicing';

/**
 * @package api/vendor-invoice/{invoiceId}
 * @description - Get summary for a vehicle with vendor tasks
 */
export function useGetInvoiceQuery(invoiceId: string | undefined) {
  const path = `/vendor-invoice/${invoiceId}`;
  return useQuery<APIResponse<Invoice>>([path], { enabled: !!invoiceId });
}

export default useGetInvoiceQuery;
