import { useQuery } from '@tanstack/react-query';

import { APIResponse } from 'api';

const PATH = `/plugins/velocitylocate/generateSSOUrl`;

export function useGenerateVelocityLocateSSOUrl() {
  return useQuery<APIResponse<string>>([PATH]);
}

export function useGenerateVelocityLocateSSOUrlForVehicle(
  vin: string,
  enabled: boolean
) {
  const params = vin ? `?vin=${encodeURIComponent(vin)}` : '';
  const path = `${PATH}${params}`;

  return useQuery<APIResponse<string>>([path], {
    enabled,
  });
}
