import { InvoiceStatus } from 'models/invoicing';
import { getLastQueryParamWithName } from 'navigation/util/ParamHelpers';

export { default as useCreateInvoiceMutation } from './useCreateInvoiceMutation';

export function getInventoryQueryParams() {
  return {
    s: getLastQueryParamWithName('s') || '',
    invoiceStatus:
      (getLastQueryParamWithName('invoiceStatus') as InvoiceStatus) || '',
  };
}

export function getInventoryQueryString() {
  const searchParams = new URLSearchParams();
  const { s, invoiceStatus } = getInventoryQueryParams();

  s && searchParams.set('s', s);
  invoiceStatus && searchParams.set('invoiceStatus', invoiceStatus);

  return searchParams.toString();
}
