import React, { useCallback } from 'react';

import strings from 'common/strings';
import { DealerStepReportSummaryItem } from 'models/reports/DealerStepReportPayload';
import { getFormattedDurationFromSeconds } from 'utils/time';

// TODO: move to helper functions along with ReportRow.tsx
const DEFAULT_MAX_TERMS = 2;
const INCLUDE_SECONDS = false;

const formatDurationFromSeconds = (timeInSeconds: number): string =>
  getFormattedDurationFromSeconds(
    timeInSeconds,
    DEFAULT_MAX_TERMS,
    INCLUDE_SECONDS,
    {
      trim: true,
      trunc: true,
    }
  );

const displayOptionalGoalTime = (
  timeInSeconds?: number | null,
  goalTimeInSeconds?: number | null
): string =>
  timeInSeconds && goalTimeInSeconds
    ? ' / ' + formatDurationFromSeconds(goalTimeInSeconds) + ' GOAL'
    : '';

interface StepCellProps {
  step: DealerStepReportSummaryItem;
  value: number;
  goal?: number;
  formatValue?: boolean;
  addClassName?: string;
  onClick: () => void;
}

const StepCell: React.FC<StepCellProps> = (props) => {
  const {
    step,
    value,
    goal,
    formatValue = false,
    addClassName,
    onClick,
  } = props;

  const clickable = !!step.totalVehicles;
  const selectableClassName = clickable ? 'selectable' : '';
  let strValue = `${value}`;
  if (formatValue) {
    strValue =
      value === 0
        ? strings.EMPTY_VALUE
        : getFormattedDurationFromSeconds(value);
  }

  const onStepCellClick = useCallback(
    (e) => {
      if (clickable) {
        onClick();
        e.stopPropagation();
      }
    },
    [clickable, onClick]
  );

  const goalTime = displayOptionalGoalTime(value, goal);

  return (
    <div
      role="button"
      tabIndex={-1}
      onClick={onStepCellClick}
      className={`evenly-spaced-item`}
    >
      <span
        className={`${selectableClassName} ${addClassName} ${
          goal ? 'withGoal' : ''
        }`}
      >
        {strValue}
      </span>
      <span className="goal">{goalTime}</span>
    </div>
  );
};

export default StepCell;
