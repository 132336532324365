import IconButton from '@material-ui/core/IconButton';
import {
  Close,
  FlashOn,
  PriorityHigh,
  RemoveCircle,
  Room,
  Visibility,
} from '@material-ui/icons';
import React, { ReactNode } from 'react';

import { TagIcon, VehicleTag } from 'models/inventory/vehicleTag';

import './TagSrp.scss';

export interface TagProps {
  tag: VehicleTag;
  removable?: boolean;
  removeTag?: (tag: VehicleTag) => void;
}

const systemIcons: Record<TagIcon, ReactNode> = {
  CUSTOMER_INTEREST: <FlashOn className="TagComponent-tag-icon" />,
  HIGH_PRIORITY: <PriorityHigh className="TagComponent-tag-icon" />,
  NEEDS_ATTENTION: <Visibility className="TagComponent-tag-icon" />,
  NOT_IN_FEED: <RemoveCircle className="TagComponent-tag-icon" />,
  TRACKING: <Room className="TagComponent-tag-icon" />,
  MATCHED: null,
  NONE: null,
};

const Tag: React.FC<TagProps> = ({ removeTag, tag, removable = false }) => {
  const tagIcon = tag?.tagIcon || 'NONE';
  return (
    <div
      className="SrpTagComponent-tag"
      style={{ backgroundColor: tag.hexColor }}
      key={tag.tagId}
    >
      <div className="TagComponent-tag-padding">
        {tag.tagType === 'SYSTEM' && systemIcons[tagIcon]}
        <div className="TagComponent-tag-name">{tag.value}</div>
        {removable && tag.tagType !== 'SYSTEM' && (
          <IconButton
            size="small"
            className="TagComponent-tag-remove-button"
            onClick={async () =>
              tag.tagId && typeof removeTag !== 'undefined' && removeTag(tag)
            }
          >
            <Close className="TagComponent-tag-remove-icon" />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default Tag;
