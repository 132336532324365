import { ArrowRight } from '@material-ui/icons';
import { FC, useContext } from 'react';
import { AccordionContext } from 'react-bootstrap';

import { DealerStepReportSummaryItem } from 'models';

import StepCell from '../StepCell';

interface StepRowProps {
  step: DealerStepReportSummaryItem;
  showArrow?: boolean;
  openStepModal: (step: DealerStepReportSummaryItem) => void;
  customStyle?: string;
}

const StepRow: FC<StepRowProps> = ({
  step,
  showArrow = false,
  customStyle = '',
  openStepModal,
}) => {
  const currentEventKey = useContext(AccordionContext);

  const {
    stepId,
    stepName,
    idleTimeAsSeconds,
    workTimeAsSeconds,
    stepTimeAsSeconds,
    stepGoalAsSeconds,
    averageRetailReadyTimeAsSeconds,
    averageReconTimeAsSeconds,
    totalVehicles,
  } = step;

  const isAccordionOpen = currentEventKey === stepId;

  const excludedFromRecon =
    idleTimeAsSeconds === 0 && stepTimeAsSeconds !== workTimeAsSeconds;

  let stepClassName = '';

  if (stepGoalAsSeconds !== 0) {
    stepClassName = stepGoalAsSeconds > stepTimeAsSeconds ? 'early' : 'overdue';
  }

  const grayedClassName = excludedFromRecon ? 'grey-text' : '';

  const onClick = () => {
    openStepModal(step);
  };

  return (
    <div
      key={stepId}
      className={`DealerStepReport-StepSummary-summary-row ${
        showArrow ? 'HasArrow' : ''
      } ${isAccordionOpen ? 'AccordionOpen' : ''} ${customStyle}`}
    >
      <div className="tour-stepreport-step-accordion DealerStepReport-StepSummary-stepWrapper w-25">
        {showArrow && <ArrowRight className="ArrowIcon" />}
        <span
          className={`DealerStepReport-StepSummary-step ${grayedClassName}`}
        >
          {stepName}
        </span>
      </div>
      <div className="tour-stepreport-step-details DealerStepReport-StepSummary-columnsWrapper w-75 evenly-spaced-container">
        <StepCell
          onClick={onClick}
          step={step}
          value={totalVehicles}
          addClassName={`DealerStepReport-StepSummary-vehicles ${grayedClassName}`}
        />
        <StepCell
          onClick={onClick}
          step={step}
          value={averageRetailReadyTimeAsSeconds}
          goal={stepGoalAsSeconds}
          formatValue
          addClassName={`DealerStepReport-StepSummary-AvgRetailReadyTime ${
            !excludedFromRecon &&
            averageRetailReadyTimeAsSeconds &&
            stepClassName
          } ${grayedClassName}`}
        />
        <StepCell
          onClick={onClick}
          step={step}
          value={averageReconTimeAsSeconds}
          formatValue
          addClassName={`DealerStepReport-StepSummary-AvgReconTime ${
            !excludedFromRecon && averageReconTimeAsSeconds && stepClassName
          } ${grayedClassName}`}
        />
      </div>
    </div>
  );
};

export default StepRow;
