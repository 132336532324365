import { FC, useMemo, useState } from 'react';

import ActiveUploadsContext, {
  AttachmentUploadContextData,
} from './AttachmentUploadContext';
import { StagedAttachments, SubmittedAttachments } from './types';

const AttachmentUploadProvider: FC = ({ children }) => {
  const [stagedAttachments, setStagedAttachments] = useState<StagedAttachments>(
    []
  );
  const [submittedAttachments, setSubmittedAttachments] =
    useState<SubmittedAttachments>([]);

  const context: AttachmentUploadContextData = useMemo(
    () => ({
      stagedAttachments,
      submittedAttachments,
      setStagedAttachments,
      setSubmittedAttachments,
    }),
    [stagedAttachments, submittedAttachments]
  );

  return (
    <ActiveUploadsContext.Provider value={context}>
      {children}
    </ActiveUploadsContext.Provider>
  );
};

export default AttachmentUploadProvider;
