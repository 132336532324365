import { useQuery } from '@tanstack/react-query';

import { APIResponse } from 'api';
import EngagementsPayload from 'models/engage/engagementsPayload';
import { EngagementSearchFacets } from 'models/engage/engagmenetSearchFacets';

export interface UseVehicleEngagementsParameters {
  pageSize?: number;
  startIndex?: number;
  filterFacets?: Record<string, string | null>;
  groupBy?: string | null;
}

function velocityEngageInventoryUrl(inventoryId?: string) {
  return `/inventory/${inventoryId}/plugins/vdp/velocityengage`;
}

export default function useVehicleEngagements(
  inventoryId?: string,
  parameters?: UseVehicleEngagementsParameters
) {
  const filterFacetsEntries = Object.entries(parameters?.filterFacets ?? {})
    .filter((entry) => entry[1] !== null)
    .map((entry) => entry[0].toLocaleUpperCase() + '::' + entry[1]);
  const filterFacetsString = encodeURIComponent(filterFacetsEntries.join(','));
  const searchParams = new URLSearchParams({
    pageSize: parameters?.pageSize?.toString() ?? '0',
    startIndex: parameters?.startIndex?.toString() ?? '0',
    filterFacets: filterFacetsString,
    groupFacets: parameters?.groupBy ?? '',
  });
  const path = `${velocityEngageInventoryUrl(
    inventoryId
  )}/engagements?${searchParams.toString()}`;
  return useQuery<APIResponse<EngagementsPayload>>([path], {
    enabled: !!inventoryId,
  });
}

export function useVehicleEngagementsSearchFacets(inventoryId?: string) {
  const path = `${velocityEngageInventoryUrl(
    inventoryId
  )}/engagementSearchFacets`;
  return useQuery<APIResponse<EngagementSearchFacets>>([path], {
    enabled: !!inventoryId,
  });
}
