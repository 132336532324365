import { Popover, PopoverProps, Typography } from '@material-ui/core';

import theme from 'common/theme';

export const TourPopover = ({
  open,
  onClose,
  anchorEl,
  message,
  ...props
}: PopoverProps & { message: string }) => {
  return (
    <Popover
      style={{
        zIndex: open ? 3000 : 1300,
      }}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    >
      <div
        style={{
          maxWidth: '300px',
          backgroundColor: theme.palette.primary.main,
          padding: '16px',
        }}
      >
        <Typography
          variant="subtitle2"
          style={{ color: theme.palette.secondary.light, fontWeight: 600 }}
        >
          {message}
        </Typography>
      </div>
    </Popover>
  );
};
