import { ArrowDropDown } from '@material-ui/icons';
import { Accordion, Card } from 'react-bootstrap';

import strings from 'common/strings';
import DownloadMenu from 'components/shared/DownloadMenu';
import { ReportType } from 'components/shared/DownloadMenu/DownloadMenu';
import StickyContainer from 'components/shared/StickyContainer';
import {
  DealerStepReportPayload,
  DealerStepReportSummaryItem,
  DealerStepReportVehicle,
} from 'models';

import StepCell from '../StepCell';

import './DealerStepReport.scss';

export const StepSummary = ({
  dealerStepReport,
  isDownloading,
  downloadFileUrl,
  setSelectedReportType,
  openStepModal,
}: {
  dealerStepReport?: DealerStepReportPayload;
  isDownloading: boolean;
  downloadFileUrl: string;
  setSelectedReportType: (type: ReportType | null) => void;
  openStepModal: (step: DealerStepReportSummaryItem | null) => void;
}) => {
  if (!dealerStepReport) {
    return <></>;
  }

  const { vehicles, summaryItems } = dealerStepReport;

  const totalNumberOfVehicles =
    summaryItems?.reduce(
      (acc: number, item: DealerStepReportSummaryItem) =>
        acc + item.totalVehicles,
      0
    ) || 0;

  return (
    <div className="DealerStepReport-StepSummary">
      <div className="DealerStepReport-title">
        {strings.STEP_SUMMARY}
        {totalNumberOfVehicles > 0 && (
          <DownloadMenu
            downloadFileUrl={downloadFileUrl}
            onSelectedMenuType={setSelectedReportType}
            isLoading={isDownloading}
            formats={['EXCEL', 'PDF']}
          />
        )}
      </div>
      <StickyContainer>
        <div className="DealerStepReport-StepSummary-summary-row-header border-bottom">
          <div className="w-25" />
          <div className=" w-75 evenly-spaced-container">
            <div className="evenly-spaced-item">
              <div className="blue-text font-bold">{strings.VEHICLES}</div>
            </div>
            <div className="evenly-spaced-item">
              <div className="blue-text font-bold label retail">
                {strings.AVERAGE_RETAIL_READY_TIME}
              </div>
            </div>
            <div className="evenly-spaced-item">
              <div className="blue-text font-bold label recon">
                {strings.AVERAGE_RECON_TIME}
              </div>
            </div>
          </div>
        </div>
      </StickyContainer>
      {summaryItems?.map((step: DealerStepReportSummaryItem, index: number) =>
        step.childSteps?.length ? (
          <Accordion key={step.stepId}>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey={step.stepId}>
                <StepRow
                  step={step}
                  customStyle={'can-hover'}
                  showArrow={true}
                  vehicleData={index === 0 ? vehicles : undefined}
                  openStepModal={openStepModal}
                />
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={step.stepId}>
                <Card.Body>
                  {step.childSteps.map(
                    (childStep: DealerStepReportSummaryItem) => (
                      <StepRow
                        key={`step-row-${childStep.stepId}`}
                        step={childStep}
                        openStepModal={openStepModal}
                      />
                    )
                  )}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        ) : (
          <StepRow
            step={step}
            customStyle={'border-bottom'}
            showArrow={false}
            vehicleData={index === 0 ? vehicles : undefined}
            openStepModal={openStepModal}
          />
        )
      )}
    </div>
  );
};

export const StepRow = ({
  step,
  vehicleData,
  showArrow = false,
  customStyle,
  openStepModal,
}: {
  step: DealerStepReportSummaryItem;
  vehicleData?: DealerStepReportVehicle[];
  showArrow?: boolean;
  openStepModal: (step: DealerStepReportSummaryItem) => void;
  customStyle?: string;
}) => {
  const {
    stepId,
    stepName,
    idleTimeAsSeconds,
    workTimeAsSeconds,
    stepTimeAsSeconds,
    stepGoalAsSeconds,
    averageRetailReadyTimeAsSeconds,
    averageReconTimeAsSeconds,
    totalVehicles,
  } = step;
  const excludedFromRecon =
    idleTimeAsSeconds === 0 && stepTimeAsSeconds !== workTimeAsSeconds;
  let stepClassName = '';
  if (stepGoalAsSeconds !== 0) {
    stepClassName = stepGoalAsSeconds > stepTimeAsSeconds ? 'early' : 'overdue';
  }
  const grayedClassName = excludedFromRecon ? 'grey-text' : '';
  const onClick = () => openStepModal(step);

  return (
    <div
      key={stepId}
      className={`DealerStepReport-StepSummary-summary-row ${customStyle}`}
    >
      <div className="w-25">
        {showArrow && <ArrowDropDown fontSize="small" />}
        <span
          className={`DealerStepReport-StepSummary-step ${grayedClassName}`}
        >
          {stepName}
        </span>
      </div>
      <div className=" w-75 evenly-spaced-container">
        <StepCell
          {...{
            onClick,
            step,
            value: totalVehicles,
            addClassName: grayedClassName,
            vehicleData,
          }}
        />
        <StepCell
          {...{
            onClick,
            step,
            value: averageRetailReadyTimeAsSeconds,
            goal: stepGoalAsSeconds,
            formatValue: true,
            addClassName: `${
              !excludedFromRecon && stepClassName
            } ${grayedClassName}`,
            vehicleData,
          }}
        />
        <StepCell
          {...{
            onClick,
            step,
            value: averageReconTimeAsSeconds,
            formatValue: true,
            addClassName: `${
              !excludedFromRecon && stepClassName
            } ${grayedClassName}`,
            vehicleData,
          }}
        />
      </div>
    </div>
  );
};
