import { FC, useState } from 'react';

import { useDealerStepReport, useDealerStepReportDownloadLink } from 'api';
import { DateRange } from 'common/DateRange';
import ReportContainer from 'components/pages/Reports/ReportContainer';
import { ReportType } from 'components/shared/DownloadMenu/DownloadMenu';
import { TabProps } from 'components/shared/Tabs/Tabs';

import VehicleStepReportModalV3 from '../VehicleStepReportModalV3';
import DealerStepReportTour from './DealerStepReportTour';
import GridOverview from './GridOverview';
import StepSummary from './StepSummary';
import { useVehicleStepReportModalState } from './useVehicleStepReportModalState';
import { VehicleStateFilterProps } from './VehicleStateFilter';

import './DealerStepReportV3.scss';

interface DealerStepReportV3Props extends TabProps, VehicleStateFilterProps {
  dateRange: DateRange;
  corporateTenantId?: string;
  displayOverView?: boolean;
}

const DealerStepReportV3: FC<DealerStepReportV3Props> = ({
  dateRange,
  corporateTenantId,
  displayOverView = true,
  selectedVehicleState,
  setSelectedVehicleState,
}) => {
  const { data, isInitialLoading, error } = useDealerStepReport(
    dateRange,
    corporateTenantId,
    selectedVehicleState
  );
  const dealerStepReport = data?.data;

  const [selectedReportType, setSelectedReportType] =
    useState<ReportType | null>(null);

  const { vehicleStepReportModalState, onOpenStepModal, onCloseStepModal } =
    useVehicleStepReportModalState();

  const { isLoading: isDownloading, downloadFileUrl = '' } =
    useDealerStepReportDownloadLink(
      selectedReportType,
      dateRange,
      corporateTenantId,
      selectedVehicleState
    );

  return (
    <ReportContainer
      className="tour-stepreport-main DealerStepReportV3"
      loading={isInitialLoading}
      error={error}
    >
      {dealerStepReport && displayOverView && (
        <GridOverview
          dealerStepReport={dealerStepReport}
          isLoading={isDownloading}
          selectedVehicleState={selectedVehicleState}
          setSelectedVehicleState={setSelectedVehicleState}
        />
      )}
      {dealerStepReport && (
        <StepSummary
          dealerStepReport={dealerStepReport}
          isDownloading={isDownloading}
          downloadFileUrl={downloadFileUrl}
          setSelectedReportType={setSelectedReportType}
          openStepModal={onOpenStepModal}
        />
      )}

      {vehicleStepReportModalState.step && (
        <VehicleStepReportModalV3
          onClose={onCloseStepModal}
          showModal={vehicleStepReportModalState.open}
          step={vehicleStepReportModalState.step}
          dateRange={dateRange}
          tenantId={corporateTenantId}
          selectedVehicleState={selectedVehicleState}
        />
      )}
      <DealerStepReportTour name={'Step Report Tour'} showTour={true} />
    </ReportContainer>
  );
};

export default DealerStepReportV3;
