import { FC } from 'react';

import { useInventoryNotes } from 'api';
import strings from 'common/strings';
import FlatVDPPaneHeader from 'components/shared/FlatVDPPaneHeader';
import { Pin } from 'components/shared/icons';
import VehicleCardNotes from 'components/shared/VehicleCardNotes/VehicleCardNotes';
import { useWindowSize } from 'hooks';

import { PinnableComponentType } from '../VehicleDetails/PinnedComponent';

import './Notes.scss';

interface NotesProps {
  path?: string;
  vehicleId: string;
  pinned?: boolean;
  openPinnedComponent?: (componentName: PinnableComponentType) => void;
}

const Notes: FC<NotesProps> = ({ vehicleId, pinned, openPinnedComponent }) => {
  const notesQuery = useInventoryNotes(vehicleId);
  const notes = notesQuery.data?.data;

  const { isMobileViewport } = useWindowSize();
  const mobileCSS = isMobileViewport() ? 'mobile' : '';

  if (!notes) {
    return null;
  }

  return (
    <div className={`notes-container ${mobileCSS}`}>
      {!pinned && (
        <FlatVDPPaneHeader
          className="notes-container-header"
          vehicleId={vehicleId}
        >
          {strings.NOTES}
          <button
            type="button"
            className="notes-container-header-pin-button"
            onClick={() => openPinnedComponent?.('notes')}
          >
            <Pin size={35} />
          </button>
        </FlatVDPPaneHeader>
      )}
      <div className="notes-container-content">
        <VehicleCardNotes
          vehicleId={vehicleId}
          notes={notes}
          shouldStageAttachments
        />
      </div>
    </div>
  );
};

export default Notes;
