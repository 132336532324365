import { useMatch } from '@reach/router';
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { cloneDeep } from 'lodash';

import {
  APIResponse,
  defaultMetaQueryFn,
  defaultMutationFn,
  defaultTextPlainMutationFn,
  getVehicleListParams,
} from 'api';
import { permissions } from 'common';
import { usePermissions } from 'hooks';
import { PagedVehicleList, VehicleSummary } from 'models';
import { StepItem, StepItemState, Workflow } from 'models/inventory/stepItem';

import { getLastQueryParamWithName } from '../../navigation/util/ParamHelpers';

/**
 * @package api/inventory
 * @description - Get steps for a vehicle
 * @param id vehicle UUID
 */
export function useVehicleWorkflow(vehicleId?: string, tenantId?: string) {
  const path = `/recon/${vehicleId}/workflow/steps`;
  const { hasPermission } = usePermissions();
  const hasReconView = hasPermission(permissions.RECON_VIEW);
  return useQuery<APIResponse<Workflow>>({
    enabled: hasReconView && !!vehicleId,
    queryKey: [path, tenantId],
    queryFn: () =>
      defaultMetaQueryFn(path, { headers: { 'auth-tenant-id': tenantId } }),
  });
}

export function useUtilityWorkflow(tenantId?: string) {
  const path = `/utility/workflow/steps`;
  const { hasPermission } = usePermissions();
  const enabled = hasPermission(permissions.RECON_VIEW);
  return useQuery<APIResponse<Workflow>>({
    enabled,
    queryKey: [path, tenantId],
    queryFn: () =>
      defaultMetaQueryFn(path, { headers: { 'auth-tenant-id': tenantId } }),
  });
}

export function useTenantUtilityWorkflow(tenantId?: string) {
  const path = `/utility/workflow/${tenantId}/steps`;
  const { hasPermission } = usePermissions();
  const enabled = hasPermission(permissions.RECON_VIEW) && !!tenantId;
  return useQuery<APIResponse<Workflow>>([path], { enabled });
}

/**
 * Determines whether a vehicle is still part of the current SRP vehicle list
 * after a step change.
 * @param newStepId
 */
const isMatchSrpFilter = (newStepId?: string): boolean => {
  const currentStepIdFilter = getLastQueryParamWithName('stepId') || '';
  if (
    currentStepIdFilter !== 'ALL' &&
    currentStepIdFilter !== '' &&
    currentStepIdFilter !== newStepId
  ) {
    return false;
  }
  return true;
};

function updateVehicleListQueryCache(
  vehicleId: string | undefined,
  queryClient: QueryClient,
  updatedValues: {
    inRecon?: boolean;
    reconStatus?: string;
    stepItem?: StepItem | null;
  }
) {
  if (
    !vehicleId ||
    (!updatedValues.hasOwnProperty('inRecon') &&
      !updatedValues?.reconStatus &&
      !updatedValues.hasOwnProperty('stepItem'))
  ) {
    return;
  }
  let isInListFilter = true;
  if (
    updatedValues.hasOwnProperty('stepItem') &&
    !isMatchSrpFilter(updatedValues.stepItem?.id)
  ) {
    isInListFilter = false;
  }
  const { queryKey } = getVehicleListParams();
  const inventoryList: PagedVehicleList | undefined =
    queryClient.getQueryData(queryKey);
  if (inventoryList?.pages) {
    let updatedVehicleList = cloneDeep(inventoryList);
    if (!isInListFilter) {
      updatedVehicleList.pages.forEach((page) => {
        const pageLengthBeforeFilter = page.data.length;
        page.data = page.data.filter((vehicleSummary) => {
          return vehicleSummary.vehicleCard.id !== vehicleId;
        });
        if (pageLengthBeforeFilter !== page.data.length && page.meta) {
          page.meta.totalRecords = page.meta?.totalRecords
            ? page.meta?.totalRecords - 1
            : 0;
        }
      });
    } else {
      updatedVehicleList.pages.forEach((page) => {
        page.data.forEach((vehicleSummary) => {
          if (vehicleSummary.vehicleCard.id === vehicleId) {
            if (updatedValues.hasOwnProperty('inRecon')) {
              vehicleSummary.vehicleCard.inRecon = updatedValues.inRecon;
            }
            if (updatedValues?.reconStatus) {
              vehicleSummary.vehicleCard.reconStatus =
                updatedValues.reconStatus;
            }
            if (updatedValues.hasOwnProperty('stepItem')) {
              vehicleSummary.stepItem = updatedValues.stepItem;
            }
          }
        });
      });
    }
    queryClient.setQueryData(queryKey, updatedVehicleList);
  }
}

function updateVehicleQueryCache(
  vehicleId: string | undefined,
  queryClient: QueryClient,
  updatedValues: {
    inRecon?: boolean;
    reconStatus?: string;
    stepItem?: StepItem | null;
  }
) {
  if (
    !vehicleId ||
    (!updatedValues.hasOwnProperty('inRecon') &&
      !updatedValues?.reconStatus &&
      !updatedValues.hasOwnProperty('stepItem'))
  ) {
    return;
  }
  const queryKey = [`/inventory/${vehicleId}`];
  const vehicleData: APIResponse<VehicleSummary> | undefined =
    queryClient.getQueryData(queryKey);
  if (vehicleData?.data) {
    const updatedVehicleData = {
      ...vehicleData,
      data: {
        ...vehicleData.data,
        stepItem: updatedValues.hasOwnProperty('stepItem')
          ? updatedValues.stepItem
          : vehicleData?.data?.stepItem,
        vehicleCard: {
          ...vehicleData?.data?.vehicleCard,
          inRecon: updatedValues.hasOwnProperty('inRecon')
            ? updatedValues.inRecon
            : vehicleData.data.vehicleCard.inRecon,
          reconStatus: updatedValues?.reconStatus
            ? updatedValues.reconStatus
            : vehicleData?.data?.vehicleCard?.reconStatus,
        },
      },
    };
    queryClient.setQueryData(queryKey, updatedVehicleData);
  }
}

export function useChangeStep(inventoryId?: string, tenantId?: string) {
  const path = `/recon/${inventoryId}/workflow/step`;
  const queryClient = useQueryClient();
  const isRouteSrp = useMatch('/:rooftopId/inventory');
  const options = {
    headers: { 'auth-tenant-id': tenantId },
  };
  return useMutation(
    (stepId: string) =>
      defaultTextPlainMutationFn(path, 'PUT', stepId, options),
    {
      onSuccess: async (data) => {
        if (isRouteSrp) {
          updateVehicleListQueryCache(inventoryId, queryClient, {
            stepItem: data.data,
          });
        } else {
          queryClient.invalidateQueries(['/inventory']);
        }
        // TODO: this is not working due to new key of ['/inventory/${inventoryId}', ${tenantId}]
        updateVehicleQueryCache(inventoryId, queryClient, {
          stepItem: data.data,
        });
        await queryClient.invalidateQueries(['/dashboard/goals']);
        if (inventoryId) {
          queryClient.invalidateQueries([`/inventory/${inventoryId}`]);
        }
      },
    }
  );
}

export function useChangeSharedStep(tenantId?: string, inventoryId?: string) {
  const path = `/recon/${tenantId}/${inventoryId}/workflow/step`;
  const queryClient = useQueryClient();

  return useMutation(
    (stepId: string) => defaultTextPlainMutationFn(path, 'PUT', stepId),
    {
      onSuccess: async (data) => {
        queryClient.invalidateQueries(['/inventory']);
        queryClient.invalidateQueries(['/dashboard/goals']);
        if (inventoryId) {
          queryClient.invalidateQueries([`/inventory/${inventoryId}`]);
        }
      },
    }
  );
}

export function useAssignUserToStep(inventoryId?: string, tenantId?: string) {
  const path = `/recon/${inventoryId}/workflow/step/user`;
  const queryClient = useQueryClient();
  const isRouteSrp = useMatch('/:rooftopId/inventory');
  const options = {
    headers: { 'auth-tenant-id': tenantId },
  };
  return useMutation(
    (userId?: string) =>
      userId
        ? defaultTextPlainMutationFn(path, 'PUT', userId, options)
        : defaultMutationFn(path, 'DELETE', {}, options),
    {
      onSuccess: async (data) => {
        if (isRouteSrp) {
          updateVehicleListQueryCache(inventoryId, queryClient, {
            stepItem: data.data,
          });
        }
        updateVehicleQueryCache(inventoryId, queryClient, {
          stepItem: data.data,
        });
        if (inventoryId) {
          queryClient.invalidateQueries([`/inventory/${inventoryId}`]);
        }
      },
    }
  );
}

export function useChangeStepState(inventoryId?: string) {
  const path = `/recon/${inventoryId}/workflow/step/state`;
  const queryClient = useQueryClient();
  const isRouteSrp = useMatch('/:rooftopId/inventory');

  return useMutation(
    (newState: StepItemState) => defaultMutationFn(path, 'PUT', newState),
    {
      onSuccess: async (data) => {
        if (isRouteSrp) {
          updateVehicleListQueryCache(inventoryId, queryClient, {
            stepItem: data.data,
          });
        } else {
          queryClient.invalidateQueries(['/inventory']);
        }
        updateVehicleQueryCache(inventoryId, queryClient, {
          stepItem: data.data,
        });
        await queryClient.invalidateQueries(['/dashboard/goals']);
        if (inventoryId) {
          queryClient.invalidateQueries([`/inventory/${inventoryId}`]);
        }
      },
    }
  );
}

export function useCompleteWorkflow(inventoryId?: string) {
  const path = `/recon/${inventoryId}/workflow/complete`;
  const queryClient = useQueryClient();
  const isRouteSrp = useMatch('/:rooftopId/inventory');

  return useMutation(() => defaultMutationFn(path, 'PUT'), {
    onSuccess: async (data) => {
      const updatedValues = {
        reconStatus: 'COMPLETED',
        stepItem: data?.data ?? null,
      };
      if (isRouteSrp) {
        updateVehicleListQueryCache(inventoryId, queryClient, updatedValues);
      } else {
        queryClient.invalidateQueries(['/inventory']);
      }
      updateVehicleQueryCache(inventoryId, queryClient, updatedValues);
      await queryClient.invalidateQueries(['/dashboard/goals']);
      if (inventoryId) {
        queryClient.invalidateQueries([`/inventory/${inventoryId}`]);
      }
    },
  });
}

export function useDeleteWorkflow(inventoryId?: string) {
  const path = `/recon/${inventoryId}`;
  const queryClient = useQueryClient();
  const isRouteSrp = useMatch('/:rooftopId/inventory');

  return useMutation(() => defaultMutationFn(path, 'DELETE'), {
    onSuccess: async (data) => {
      const updatedValues = {
        inRecon: false,
        reconStatus: 'NOT_STARTED',
        stepItem: null,
      };
      if (isRouteSrp) {
        updateVehicleListQueryCache(inventoryId, queryClient, updatedValues);
      } else {
        queryClient.invalidateQueries(['/inventory']);
      }
      updateVehicleQueryCache(inventoryId, queryClient, updatedValues);
      await queryClient.invalidateQueries(['/dashboard/goals']);
      if (inventoryId) {
        queryClient.invalidateQueries([`/inventory/${inventoryId}`]);
      }
    },
  });
}

export function useDeleteVehicleWorkflow(inventoryId?: string) {
  const path = `/recon/${inventoryId}/delete`;
  const queryClient = useQueryClient();
  const isRouteSrp = useMatch('/:rooftopId/inventory');

  return useMutation(() => defaultMutationFn(path, 'DELETE'), {
    onSuccess: async (data) => {
      const updatedValues = {
        inRecon: false,
        reconStatus: 'NOT_STARTED',
        stepItem: null,
      };
      if (isRouteSrp) {
        updateVehicleListQueryCache(inventoryId, queryClient, updatedValues);
      } else {
        queryClient.invalidateQueries(['/inventory']);
      }
      updateVehicleQueryCache(inventoryId, queryClient, updatedValues);
      await queryClient.invalidateQueries(['/dashboard/goals']);
      if (inventoryId) {
        queryClient.invalidateQueries([`/inventory/${inventoryId}`]);
      }
    },
  });
}
