import { Box, Button, CircularProgress, makeStyles } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

import { hasImageContentType } from 'common';
import theme from 'common/theme';
import { hasVideoContentType } from 'common/video';
import VehicleImageView from 'components/shared/VehicleImageView';
import { Attachment, Task } from 'models';

type Props = {
  attachments: Attachment[] | undefined;
  showMediaLabel?: boolean;
  readOnly?: boolean;
  onAdd?: () => void;
  onClick?: (index: number) => void;
  task?: Task;
  vehicleId?: string;
  isLoading?: boolean;
  disabled?: boolean;
};

const MobileMediaList = ({
  attachments,
  isLoading = false,
  showMediaLabel = true,
  readOnly = false,
  task,
  vehicleId = '',
  onAdd = () => {},
  onClick = () => {},
  disabled = false,
}: Props) => {
  const classes = useStyles();
  let buttonContent: React.ReactNode = showMediaLabel ? 'Add Media' : '';

  if (isLoading) {
    buttonContent = <CircularProgress />;
  }
  const addMedia = (
    <Button
      variant="outlined"
      color="secondary"
      disabled={isLoading || disabled}
      className={
        attachments?.length === 0 ? classes.addMediaFullWidth : classes.addMedia
      }
      startIcon={
        !isLoading && (
          <AddAPhotoIcon
            style={{
              color: theme.palette.grey[500],
            }}
          />
        )
      }
      classes={{
        label: classes.addMediaIconLabel,
        startIcon: classes.addMediaStartIcon,
      }}
      onClick={() => {
        onAdd();
      }}
    >
      {buttonContent}
    </Button>
  );

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.mediaContainer}>
          <Box className={classes.mediaList}>
            {attachments?.map((attachment, index) => {
              if (hasImageContentType(attachment?.contentType)) {
                return (
                  <VehicleImageView
                    onClick={(e) => {
                      e.stopPropagation();
                      onClick(index);
                    }}
                    size="normal"
                    className={classes.media}
                    imageUrl={attachment.uri}
                    contentType={attachment.contentType}
                  />
                );
              }
              if (hasVideoContentType(attachment?.contentType)) {
                return (
                  <Box
                    onClick={(e) => {
                      e.stopPropagation();
                      onClick(index);
                    }}
                    style={{
                      position: 'relative',
                    }}
                  >
                    <video className={classes.media}>
                      <source
                        src={`${attachment.uri}#t=0.001`}
                        type="video/mp4"
                      />
                    </video>

                    <PlayCircleFilledIcon
                      style={{
                        fontSize: '40px',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        color: theme.palette.grey[700],
                        zIndex: 9999,
                      }}
                    />
                  </Box>
                );
              }

              return (
                <div
                  className={`${classes.media} ${classes.object}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClick(index);
                  }}
                >
                  <GetApp
                    style={{ fontSize: '40px', color: theme.palette.grey[700] }}
                  />
                </div>
              );
            })}
          </Box>
        </Box>
        {!readOnly && addMedia}
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: theme.spacing(0.5),
    marginTop: theme.spacing(1),
  },
  mediaContainer: {
    overflowX: 'auto',
  },
  mediaList: {
    display: 'flex',
    minWidth: 'min-content',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  media: {
    height: '80px',
    width: '80px',
    border: `1px solid ${theme.palette.grey[500]}`,
    marginRight: '4px',
    borderRadius: '4px',
    backgroundSize: 'cover',
    display: 'inline',
    margin: 'initial',
    objectFit: 'cover',
  },
  addMedia: {
    paddingTop: theme.spacing(1),
    height: '80px',
    minWidth: '80px',
  },
  addMediaFullWidth: {
    paddingTop: theme.spacing(1),
    width: '100%',
    minHeight: '114px',
  },
  addMediaIconLabel: {
    flexDirection: 'column',
    display: 'flex',
  },
  addMediaStartIcon: {
    marginLeft: 0,
    marginRight: 0,
  },
  object: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default MobileMediaList;
