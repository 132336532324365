import {
  Box,
  createStyles,
  Divider,
  Drawer,
  ListItemIcon,
  makeStyles,
  MenuItem,
  Theme,
  Typography,
} from '@material-ui/core';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import DeleteIcon from '@material-ui/icons/Delete';

import { useToken } from 'api';
import { strings } from 'common';
import theme from 'common/theme';
import { useTaskPermissions } from 'hooks';
import { Task, TaskStatus } from 'models';
import {
  canApproveTask,
  canCompleteTask,
  canDenyTask,
  canRequestTask,
} from 'utils/tasks';

type Props = {
  task: Task;
  show: boolean;
  onClose: () => void;
  onClick: (status: TaskStatus) => void;
  onDelete: () => void;
};

const MobileFlatTaskMenu = ({
  task,
  onClose,
  onClick,
  onDelete,
  show,
}: Props) => {
  const classes = useStyles();
  const { data: auth } = useToken();
  const { user } = auth ?? {};
  const {
    hasTaskCreatePermission,
    hasTaskApprovePermission,
    hasTaskUpdatePermission,
    hasTaskDeletePermission,
  } = useTaskPermissions();

  const requestTask =
    hasTaskCreatePermission && canRequestTask(task, hasTaskApprovePermission);
  const approveTask = hasTaskApprovePermission && canApproveTask(task);
  const denyTask = hasTaskApprovePermission && canDenyTask(task);
  const completeTask = hasTaskUpdatePermission && canCompleteTask(task);
  const canDeleteTask =
    hasTaskDeletePermission || task.createdByUserId === user?.id;

  return (
    <Drawer
      anchor={'bottom'}
      open={show}
      style={{ zIndex: 9999 }}
      onClose={onClose}
    >
      <Box className={classes.container}>
        <Typography variant="body1" className={classes.title}>
          Update Status
        </Typography>
        {requestTask && (
          <MenuItem onClick={() => onClick('PENDING_APPROVAL')}>
            <Box className={classes.request}></Box>
            {strings.REQUEST_APPROVAL.toUpperCase()}
          </MenuItem>
        )}
        {approveTask && (
          <MenuItem onClick={() => onClick('APPROVED_PENDING_COMPLETE')}>
            <Box className={classes.approve}></Box>
            {strings.APPROVE.toUpperCase()}
          </MenuItem>
        )}
        {denyTask && (
          <MenuItem onClick={() => onClick('DENIED')}>
            <Box className={classes.deny}></Box>
            {strings.DENY.toUpperCase()}
          </MenuItem>
        )}
        {completeTask && (
          <MenuItem onClick={() => onClick('COMPLETE')}>
            <Box className={classes.completed}></Box>
            {strings.COMPLETE.toUpperCase()}
          </MenuItem>
        )}
        {canDeleteTask && (
          <>
            <Divider className={classes.divider} />
            <MenuItem onClick={onDelete}>
              <ListItemIcon className={classes.deleteIcon}>
                <DeleteIcon />
              </ListItemIcon>
              {strings.DELETE_TASK.toUpperCase()}
            </MenuItem>
          </>
        )}
      </Box>
    </Drawer>
  );
};

const squareStyle: CreateCSSProperties<{}> = {
  width: '20px',
  height: '20px',
  borderRadius: '4px',
  marginRight: theme.spacing(2),
};

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      fontWeight: 700,
    },
    divider: {
      marginBottom: theme.spacing(1),
    },
    approve: {
      ...squareStyle,
      backgroundColor: theme.palette.success.main,
    },
    request: {
      ...squareStyle,
      backgroundColor: theme.palette.warning.main,
    },
    deny: {
      ...squareStyle,
      backgroundColor: theme.palette.danger.main,
    },
    completed: {
      ...squareStyle,
      backgroundColor: theme.palette.success.main,
    },
    deleteIcon: {
      minWidth: '36px',
      position: 'relative',
      left: '-2px',
    },
  })
);

export default MobileFlatTaskMenu;
