import Delete from '@material-ui/icons/Delete';
import { isNil } from 'lodash';
import { FC, useState } from 'react';

import {
  useAddMediaImageToVehicle,
  usePresignedMediaUploadUrl,
  useUpdateVehicleImage,
  useUploadFileToS3,
  useVehicleImageShots,
} from 'api';
import { hasImageContentType, isImage } from 'common/images';
import permissions from 'common/permissions';
import strings from 'common/strings';
import { useMediaUploadContext } from 'components/pages/FlatMedia/UploadProvider';
import Alert from 'components/shared/Alert';
import { Upload } from 'components/shared/icons';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import PermissionsGate from 'components/shared/PermissionsGate';
import S3UploadPlaceholder from 'components/shared/S3UploadPlaceholder';
import { TabProps } from 'components/shared/Tabs/Tabs';
import { usePermissions } from 'hooks';
import {
  VehicleImage,
  VehicleImageShot,
  VehicleImageShots,
  VehicleSummary,
} from 'models';
import { replaceShots } from 'utils/photos';

import SyndicationModal from '../SyndicationModal';

import './Syndication.scss';

interface ISyndication extends TabProps {
  vehicle: VehicleSummary;
  images: VehicleImage[];
  reload: () => void;
}

export interface IShot extends VehicleImageShot {
  url?: string;
  image?: VehicleImage;
}

const Syndication: FC<ISyndication> = ({ vehicle, images, reload }) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedAngle, setSelectedAngle] = useState<IShot | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState(strings.API_MESSAGE);
  const { data } = useVehicleImageShots();
  const { mutateAsync: updateVehicleImage } = useUpdateVehicleImage();
  const { hasPermission } = usePermissions();

  const { uploadFileToS3 } = useUploadFileToS3();
  const { getNewPresignedMediaUploadUrl } = usePresignedMediaUploadUrl();
  const { addMediaImageToVehicle } = useAddMediaImageToVehicle();
  const { submittedMedia, setSubmittedMedia } = useMediaUploadContext();

  const vehicleImages = images.filter(
    (mediaItem: VehicleImage) =>
      isImage(mediaItem.uri) || hasImageContentType(mediaItem.contentType)
  );

  const activeUploads = submittedMedia.filter(
    (media) =>
      media.vehicleId === vehicle.vehicleCard.id && media.shot !== undefined
  );

  const unassignedImages: VehicleImage[] = vehicleImages.filter(
    (mediaItem: VehicleImage) =>
      mediaItem.shot === strings.ANGLE_UNKNOWN &&
      mediaItem.imageType === strings.DEALER_IMAGE_TYPE
  );

  const closeAlert = () => {
    setShowErrorAlert(false);
    setErrorMessage(strings.API_MESSAGE);
  };

  const closeModal = () => {
    setOpenModal(false);
    reload();
  };

  const uploadAndAssignShotImage = async (file: File, shot: IShot) => {
    const vehicleId = vehicle.vehicleCard.id;

    if (!vehicleId) {
      return;
    }

    setIsSubmitting(true);
    closeModal();

    setSubmittedMedia([
      {
        vehicleId,
        file,
        status: 'pending',
        shot: shot.value,
      },
    ]);

    try {
      const {
        data: { s3AttachmentId, uploadUrl },
      } = await getNewPresignedMediaUploadUrl({
        vehicleId,
        contentType: file.type,
      });

      setSubmittedMedia((media) => [
        {
          ...media[0],
          status: 'uploading',
          progress: 0,
        },
      ]);

      await uploadFileToS3({
        file,
        presignedUrl: uploadUrl,
        onUploadProgress: ({ loaded, total }) => {
          if (total !== undefined) {
            const progress = (loaded / total) * 100;

            setSubmittedMedia((media) => [
              {
                ...media[0],
                progress,
              },
            ]);
          }
        },
      });

      await addMediaImageToVehicle({
        vehicleId,
        s3AttachmentId,
        attachmentName: file.name,
        shot: shot.value,
      });
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage(strings.API_MESSAGE);
      }

      setShowErrorAlert(true);
    } finally {
      setSubmittedMedia([]);
      setSelectedAngle(null);
      setIsSubmitting(false);
    }
    setShowErrorAlert(false);
    setErrorMessage('');

    reload();
  };

  const assignExistingShotImage = async (image: VehicleImage, shot: IShot) => {
    setIsSubmitting(true);
    if (!shot.image) {
      await updateVehicleImage({
        vehicleId: vehicle?.vehicleCard?.id!,
        imageId: image.id!,
        shot: shot.value,
      });
    } else {
      await replaceShots(
        shot.image,
        image,
        vehicle?.vehicleCard?.id ?? '',
        updateVehicleImage
      );
    }
    reload();
    setSelectedAngle(null);
    setIsSubmitting(false);
  };

  const prepareDataForAngles = () => {
    const vehicleMediaShotsDict = vehicleImages
      .slice()
      .reduce((acc: Record<string, VehicleImage>, image: VehicleImage) => {
        acc[image.shot ?? ''] = image;
        return acc;
      }, {});

    const shots = data?.map((item: VehicleImageShot) => {
      if (vehicleMediaShotsDict[item.value]) {
        return {
          ...item,
          url: vehicleMediaShotsDict[item.value].uri,
          image: vehicleMediaShotsDict[item.value],
        };
      }
      return item;
    });

    return shots ?? [];
  };

  const onPhotoAngleClicked = (angle: IShot) => {
    if (hasPermission(permissions.INVENTORY_VDP_PHOTOS_CREATE)) {
      setSelectedAngle(angle);
      setOpenModal(true);
    }
  };

  const renderAngleRow = (shot: IShot, index: number, isLast?: boolean) => {
    const { value, url, name, image } = shot;

    if (value !== VehicleImageShots.UNKNOWN) {
      const isUnassigned = !value;
      const isUnset = isUnassigned || !url;
      const isUploadingShot = isSubmitting && value === selectedAngle?.value;
      const activeUpload = activeUploads.find(
        (upload) => upload.shot === value
      );

      const unAssignImage = () => {
        if (isNil(image)) {
          throw new Error('Must provide image');
        }
        updateVehicleImage({
          vehicleId: vehicle?.vehicleCard?.id!,
          imageId: image.id!,
          shot: VehicleImageShots.UNKNOWN,
        });
      };

      return (
        <div
          key={`angle-row-${image?.name}-${index}`}
          className="Syndication-row flex-rows full-width"
        >
          <div className="flex-columns full-width justify-content-between align-items-center padding">
            <div
              role="none"
              tabIndex={-1}
              className="flex-columns align-items-center Syndication-clickable"
              onClick={() => onPhotoAngleClicked(shot)}
            >
              {isUploadingShot ? (
                <>
                  {activeUpload ? (
                    <div className="icon-uploading">
                      <S3UploadPlaceholder
                        attachment={activeUpload}
                        showOverlay
                      />
                    </div>
                  ) : (
                    <div className="icon-loading">
                      <LoadingIndicator />
                    </div>
                  )}
                </>
              ) : isUnset ? (
                <div className="icon-upload">
                  <Upload size={28} />
                </div>
              ) : (
                <img className="angle-img" src={url} alt={name} />
              )}
              <div>{name}</div>
            </div>
            {!isUnassigned && url && image && image.imageType !== 'STOCK' && (
              <PermissionsGate
                permissions={[permissions.INVENTORY_VDP_PHOTOS_DELETE]}
              >
                <Delete
                  className="Syndication-delete-icon"
                  onClick={unAssignImage}
                />
              </PermissionsGate>
            )}
          </div>
          {!isLast && <div className="divider" />}
        </div>
      );
    }

    return null;
  };

  const shots = prepareDataForAngles();

  return (
    <div className="Syndication full-height">
      <div className="Syndication-message padding">
        {strings.SYNDICATION_HEADER_DESCRIPTION_BEFORE_ICON}
        <Upload size={16} />
        {strings.SYNDICATION_HEADER_DESCRIPTION_AFTER_ICON}
      </div>
      {shots.map((shot: IShot, index: number) => {
        const isLast = index === shots.length - 1;
        return renderAngleRow(shot, index, isLast);
      })}
      {openModal && selectedAngle && (
        <SyndicationModal
          images={unassignedImages}
          onClose={closeModal}
          selectedVehicleMediaShot={selectedAngle}
          onUploadAndAssignImage={uploadAndAssignShotImage}
          assignExistingImage={assignExistingShotImage}
        />
      )}
      <Alert
        open={showErrorAlert}
        contentProps={{
          variant: 'error',
          onClose: closeAlert,
          message: errorMessage,
        }}
      />
    </div>
  );
};

export default Syndication;
