import { FC, useMemo, useState } from 'react';

import { SubmittedAttachments } from '../types';
import ActiveUploadsContext, {
  DocumentUploadContextData,
} from './DocumentUploadContext';

const DocumentUploadProvider: FC = ({ children }) => {
  const [submittedDocuments, setSubmittedDocuments] =
    useState<SubmittedAttachments>([]);

  const context: DocumentUploadContextData = useMemo(
    () => ({
      submittedDocuments,
      setSubmittedDocuments,
    }),
    [submittedDocuments]
  );

  return (
    <ActiveUploadsContext.Provider value={context}>
      {children}
    </ActiveUploadsContext.Provider>
  );
};

export default DocumentUploadProvider;
