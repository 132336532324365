import ClockIcon from '@material-ui/icons/AccessTime';
import CarIcon from '@material-ui/icons/DriveEta';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { FC } from 'react';

import { permissions, strings } from 'common';
import SummaryCard from 'components/shared/SummaryCard';
import { usePermissions } from 'hooks';
import { DealerStepReportPayload } from 'models';
import { currencyFormatter } from 'utils/formatter';

import { compareGoalValue } from '../ReportsState';
import VehicleStateFilter, {
  VehicleStateFilterProps,
} from './VehicleStateFilter';

interface GridOverviewProps extends VehicleStateFilterProps {
  dealerStepReport: DealerStepReportPayload;
  isLoading: Boolean;
}

const formatNumber = (number: number) => {
  return currencyFormatter(number).replace(/\$/g, '');
};

const GridOverview: FC<GridOverviewProps> = ({
  dealerStepReport,
  isLoading,
  selectedVehicleState,
  setSelectedVehicleState,
}) => {
  const { hasPermission } = usePermissions();
  const userHasReconInfoPermission = hasPermission(
    permissions.INVENTORY_VDP_RECON_INFO_VIEW
  );
  const averageOverageAmount = dealerStepReport?.averageOverage?.amount;
  const totalOverageAmount = dealerStepReport?.totalOverage?.amount;

  const averageOverageString = [
    averageOverageAmount && averageOverageAmount > 0 ? '+' : '',
    averageOverageAmount ? formatNumber(averageOverageAmount) : '',
  ].join('');
  const totalOverageString = [
    totalOverageAmount && totalOverageAmount > 0 ? '+' : '',
    totalOverageAmount ? formatNumber(totalOverageAmount) : '',
  ].join('');

  const vehiclesCompleted = {
    itemType: 'VehiclesCompleted',
    statValue: dealerStepReport?.completedVehicles || 0,
    statLabel: String(
      selectedVehicleState === 'COMPLETED'
        ? strings.COMPLETED_VEHICLES
        : strings.VEHICLES_IN_RECON
    ),
    unit: 'Items',
  };
  const retailReadyTime = {
    itemType: 'RetailReadyTime',
    statValue: dealerStepReport?.averageRetailReadyAsSeconds || 0,
    statLabel: String(strings.AVG_RETAIL_READY_TIME),
    unit: 'Seconds',
    goalValue: dealerStepReport?.averageRetailReadyGoalAsSeconds || 0,
    goalLabel: strings.GOAL,
  };
  const reconTime = {
    itemType: 'ReconTime',
    statValue: dealerStepReport?.averageReconTimeAsSeconds || 0,
    statLabel: String(strings.AVG_RECON_TIME),
    unit: 'Seconds',
    goalValue: dealerStepReport?.averageReconTimeGoalAsSeconds || 0,
    goalLabel: strings.GOAL,
  };

  return (
    <div className="DealerStepReport-Overview">
      <div className="DealerStepReport-title">
        {strings.OVERVIEW}
        <VehicleStateFilter
          selectedVehicleState={selectedVehicleState}
          setSelectedVehicleState={setSelectedVehicleState}
        />
      </div>
      <div className="DealerStepReport-Overview-Items">
        <div
          className="tour-stepreport-overview-completedvehicles DealerStepReport-Overview-Item"
          id="completed-vehicles-container"
        >
          <div className="DealerStepReport-Overview-CompletedVehicles">
            <SummaryCard icon={<CarIcon />} summaryItem={vehiclesCompleted} />
          </div>
        </div>
        <div
          className="tour-stepreport-overview-avgretailreadytime DealerStepReport-Overview-Item"
          id="average-retail-ready-time-container"
        >
          <div
            className={`DealerStepReport-Overview-Item-${compareGoalValue(
              retailReadyTime
            )}`}
          >
            <SummaryCard icon={<ClockIcon />} summaryItem={retailReadyTime} />
          </div>
        </div>
        <div
          className="tour-stepreport-overview-avgrecontime DealerStepReport-Overview-Item"
          id="average-recon-time-container"
        >
          <div
            className={`DealerStepReport-Overview-Item-${compareGoalValue(
              reconTime
            )}`}
          >
            <SummaryCard icon={<ClockIcon />} summaryItem={reconTime} />
          </div>
        </div>
        <div className="DealerStepReport-Overview-QuadrantCard">
          <div
            className="tour-stepreport-overview-avgestimated DealerStepReport-Overview-Item-Quadrant"
            id="average-estimated-container"
          >
            <MonetizationOnIcon fontSize="small" />
            <h4>
              {!dealerStepReport?.averageEstimatedRecon?.amount ||
              !userHasReconInfoPermission
                ? strings.EMPTY_VALUE
                : formatNumber(dealerStepReport.averageEstimatedRecon.amount)}
            </h4>
            <h6>{strings.AVG_ESTIMATED}</h6>
          </div>
          <div
            className="tour-stepreport-overview-avgactual DealerStepReport-Overview-Item-Quadrant"
            id="average-actual-container"
          >
            <MonetizationOnIcon fontSize="small" />
            <h4>
              {!dealerStepReport?.averageActualRecon?.amount ||
              !userHasReconInfoPermission
                ? strings.EMPTY_VALUE
                : formatNumber(dealerStepReport.averageActualRecon.amount)}
            </h4>
            <h6>{strings.AVG_ACTUAL}</h6>
          </div>
          <div
            className="tour-stepreport-overview-avgoverage DealerStepReport-Overview-Item-Quadrant"
            id="average-overage-container"
          >
            <MonetizationOnIcon fontSize="small" />
            <h4>
              {!dealerStepReport?.averageOverage?.amount ||
              !userHasReconInfoPermission
                ? strings.EMPTY_VALUE
                : averageOverageString}
            </h4>
            <h6>{strings.AVG_OVERAGE}</h6>
          </div>
          <div
            className="tour-stepreport-overview-totaloverage DealerStepReport-Overview-Item-Quadrant"
            id="total-overage-container"
          >
            <MonetizationOnIcon fontSize="small" />
            <h4>
              {!dealerStepReport?.totalOverage?.amount ||
              !userHasReconInfoPermission
                ? strings.EMPTY_VALUE
                : totalOverageString}
            </h4>
            <h6>{strings.TOTAL_OVERAGE}</h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GridOverview;
