import { TablePagination } from '@material-ui/core';
import { debounce } from 'lodash';
import { useMemo, useState } from 'react';

import { TASKS_PAGE_SIZE, useInventoryTasks } from 'api';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { TabProps } from 'components/shared/Tabs/Tabs';
import TasksTable from 'components/shared/TasksTable';
import { useDebounce } from 'hooks';
import { TaskStatus } from 'models';
import {
  getAllQueryParamsWithName,
  updateUrlSearchParam,
} from 'navigation/util/ParamHelpers';

import TasksTableFilters from './TasksTableFilters/TasksTableFilters';

import './TasksTab.scss';

const DEBOUNCE_DELAY = 750;
let cachedTotalRecords = 0;

const updateStatusParamDebounce = debounce((value: string | string[]) => {
  updateUrlSearchParam({ status: value });
}, DEBOUNCE_DELAY);

const updateAssigneeParamDebounce = debounce((value: string | string[]) => {
  updateUrlSearchParam({ assignee: value });
}, DEBOUNCE_DELAY);

const Tasks: React.FC<TabProps> = () => {
  const [selectedStatuses, setSelectedStatuses] = useState<TaskStatus[]>(() => {
    return (getAllQueryParamsWithName('status') as TaskStatus[]) ?? [];
  });
  const [selectedAssignees, setSelectedAssignees] = useState<string[]>(() => {
    return (getAllQueryParamsWithName('assignee') as string[]) ?? [];
  });

  const [pageNumber, setPageNumber] = useState<number>(0);
  const debouncedPageNumber = useDebounce(pageNumber, DEBOUNCE_DELAY);

  const statusParams = getAllQueryParamsWithName('status') as TaskStatus[];
  const assigneeParams = getAllQueryParamsWithName('assignee') as string[];
  const inventorySummaryQuery = useInventoryTasks(
    statusParams,
    assigneeParams,
    debouncedPageNumber,
    TASKS_PAGE_SIZE
  );

  const inventory = inventorySummaryQuery.data?.data ?? [];
  const totalRecords = useMemo(() => {
    const total = Number(inventorySummaryQuery.data?.meta.totalRecords);
    if (total >= 0) {
      cachedTotalRecords = total;
    }
    return cachedTotalRecords;
  }, [inventorySummaryQuery.data?.meta.totalRecords]);

  const handleStatusSelect = (status: TaskStatus) => {
    let newSelectedStatuses;
    if (selectedStatuses.includes(status)) {
      newSelectedStatuses = selectedStatuses.filter(
        (selectedStatus) => selectedStatus !== status
      );
    } else {
      newSelectedStatuses = [...selectedStatuses, status];
    }
    setPageNumber(0);
    setSelectedStatuses(newSelectedStatuses);
    updateStatusParamDebounce(newSelectedStatuses);
  };

  const handleAssigneeSelect = (assignee: string) => {
    let newSelectedAssignees;
    if (selectedAssignees.includes(assignee)) {
      newSelectedAssignees = selectedAssignees.filter(
        (selectedAssignee) => selectedAssignee !== assignee
      );
    } else {
      newSelectedAssignees = [...selectedAssignees, assignee];
    }
    setPageNumber(0);
    setSelectedAssignees(newSelectedAssignees);
    updateAssigneeParamDebounce(newSelectedAssignees);
  };

  const handleOnChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => {
    event?.preventDefault();
    event?.stopPropagation();
    setPageNumber(page);
  };

  let content = null;

  if (inventorySummaryQuery.isInitialLoading) {
    content = (
      <div className="Tasks-loading">
        <LoadingIndicator />
      </div>
    );
  } else if (Number(inventory.length) === 0) {
    content = <div className="Tasks-no-data">No pending approvals</div>;
  } else {
    content = <TasksTable inventory={inventory} />;
  }

  return (
    <div className="Tasks-container">
      <TasksTableFilters
        selectedStatuses={selectedStatuses}
        setSelectedStatus={handleStatusSelect}
        selectedAssignees={selectedAssignees}
        setSelectedAssignee={handleAssigneeSelect}
      />
      {content}
      <TablePagination
        count={totalRecords}
        onChangePage={handleOnChangePage}
        page={pageNumber}
        rowsPerPage={TASKS_PAGE_SIZE}
        rowsPerPageOptions={[]}
      />
    </div>
  );
};

export default Tasks;
