import { useMemo } from 'react';

import { useToken } from 'api';

interface UseModulesResults {
  hasModule: (module: string) => boolean;
  hasModules: (modules: string[]) => boolean;
}

export const useModules = (): UseModulesResults => {
  const { data } = useToken();

  return useMemo<UseModulesResults>(() => {
    const enabledModules = data?.enabledModules ?? [];

    const hasModule = (module: string) => enabledModules.includes(module);

    return {
      hasModule,
      hasModules: (modules) => modules.every(hasModule),
    };
  }, [data?.enabledModules]);
};
