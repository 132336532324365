import { navigate } from '@reach/router';
import { FC, useContext, useRef } from 'react';

import strings from 'common/strings';
import LabelValue from 'components/shared/LabelValue';
import StepButtons from 'components/shared/StepButtons';
import { useFeatures } from 'hooks';
import { overviewBuilder } from 'navigation/routes';
import { getFormattedDurationFromSeconds } from 'utils/time';

import { VehicleCardContext } from '../../../VehicleCard';
import VehicleCardTabFooter from '../VehicleCardTabFooter/VehicleCardTabFooter';
import { VehicleCardSharedStepDropdown } from './VehicleCardSharedStepDropdown';
import { VehicleCardStepDropdown } from './VehicleCardStepDropdown';
import { VehicleCardUserDropDown } from './VehicleCardUserDropDown';

const VehicleCardReconDetails: FC = () => {
  const { vehicle, isOnboarding, position, inExtensionCardMode } =
    useContext(VehicleCardContext);
  const ref = useRef<HTMLDivElement>(document.createElement('div'));
  const inventoryId = vehicle?.vehicleCard.id;
  const currentStep = vehicle?.stepItem;
  const hasSharedStep = !!vehicle?.stepItem?.sharedStep;
  const { hasFeature } = useFeatures();
  const hasShowSrpTenantFilter = hasFeature('SHOW_SRP_TENANT_FILTER');
  const tenantId = hasShowSrpTenantFilter
    ? vehicle?.vehicleCard?.tenantId
    : undefined;
  const reconTenantId =
    vehicle?.vehicleCard?.reconTenantId ?? vehicle?.vehicleCard?.tenantId;
  const handleClickNavToRecon = (clickSource: string): Promise<void> | null => {
    if (inventoryId) {
      return navigate(`${overviewBuilder(inventoryId, true)}`);
    }
    return null;
  };

  return (
    <div ref={ref} className="VehicleCard-tab-container">
      <LabelValue label={strings.STEP} labelCaps>
        <VehicleCardStepDropdown
          stepItem={currentStep ?? undefined}
          inventoryId={inventoryId}
          reconTenantId={reconTenantId}
        />
      </LabelValue>

      <LabelValue label={strings.ASSIGNEE} labelCaps>
        <VehicleCardUserDropDown
          currentStep={currentStep ?? undefined}
          inventoryId={inventoryId}
          tenantId={tenantId}
        />
      </LabelValue>

      <LabelValue label={strings.RECON_TIME} labelCaps>
        <div id={`vehicle-card-recon-time-${position}`}>
          {vehicle?.reconTimeAsSeconds
            ? getFormattedDurationFromSeconds(vehicle?.reconTimeAsSeconds || 0)
            : strings.EMPTY_VALUE}
        </div>
      </LabelValue>

      <LabelValue label={strings.RETAIL_READY} labelCaps>
        <div id={`vehicle-card-retail-time-${position}`}>
          {vehicle?.retailReadyTimeAsSeconds
            ? getFormattedDurationFromSeconds(
                vehicle?.retailReadyTimeAsSeconds || 0
              )
            : strings.EMPTY_VALUE}
        </div>
      </LabelValue>

      {hasSharedStep && (
        <>
          <LabelValue label={strings.SHARED_TENANT} labelCaps>
            <div>{vehicle?.stepItem?.sharedTenant}</div>
          </LabelValue>
          <LabelValue label={strings.SHARED_STEP} labelCaps>
            <VehicleCardSharedStepDropdown
              stepItem={currentStep ?? undefined}
              inventoryId={inventoryId}
            />
          </LabelValue>
        </>
      )}

      {currentStep && (
        <StepButtons
          vehicleId={inventoryId}
          currentStep={currentStep}
          isOnboarding={isOnboarding}
          onComplete={() => true}
          small
        />
      )}

      <VehicleCardTabFooter
        vehicle={vehicle}
        navClick={handleClickNavToRecon}
        showRecon={true}
        hideNavigations={inExtensionCardMode}
      />
    </div>
  );
};

export default VehicleCardReconDetails;
