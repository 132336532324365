import { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { useGetInventoryTenantId, useVehicleSummaryByTenantId } from 'api';
import FlatVDPPaneContent from 'components/shared/FlatVDPPaneContent';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { useWindowSize } from 'hooks';

import ReconInfoSection from './ReconInfoSection';
import ReconProgressSection from './ReconProgressSection';
import StepProgressSection from './StepProgressSection';

import './ReconVelocityOverview.scss';

interface ReconVelocityOverviewProps {
  mobileVDPInfoHeight?: number;
  vehicleId: string;
  tenantId?: string;
}

const ReconVelocityOverview: FC<ReconVelocityOverviewProps> = ({
  mobileVDPInfoHeight = 0,
  vehicleId,
  tenantId,
}) => {
  const windowSize = useWindowSize();
  const paddingTop = windowSize.isMobileViewport()
    ? { paddingTop: 68 - mobileVDPInfoHeight }
    : {};
  const inventoryTenantIdQuery = useGetInventoryTenantId(vehicleId, !!tenantId);
  const inventoryTenantId = inventoryTenantIdQuery.data?.data ?? '';
  const {
    data: { data: vehicleSummary } = {},
    isLoading: vehicleSummaryIsLoading,
  } = useVehicleSummaryByTenantId(vehicleId, tenantId ?? inventoryTenantId);
  return (
    <div style={paddingTop}>
      <Container fluid className="ReconVelocityOverview">
        <FlatVDPPaneContent>
          {vehicleSummary && !vehicleSummaryIsLoading ? (
            <>
              <Row noGutters>
                <Col md={12} lg={6}>
                  <ReconProgressSection
                    vehicleSummary={vehicleSummary}
                    inventoryId={vehicleId}
                  />
                </Col>
                <Col md={12} lg={6} className="ReconVelocityOverview-separator">
                  <ReconInfoSection vehicleSummary={vehicleSummary} />
                </Col>
              </Row>
              <Row noGutters>
                <Col>
                  <StepProgressSection vehicleSummary={vehicleSummary} />
                </Col>
              </Row>
            </>
          ) : (
            <LoadingIndicator />
          )}
        </FlatVDPPaneContent>
      </Container>
    </div>
  );
};

export default ReconVelocityOverview;
