import { createContext, Dispatch, SetStateAction } from 'react';

import {
  AttachmentStatus,
  StagedAttachment,
  SubmittedAttachment,
} from './types';

export type AttachmentUploadContextData = {
  stagedAttachments: StagedAttachment[];
  submittedAttachments: SubmittedAttachment[];
  setStagedAttachments: Dispatch<SetStateAction<StagedAttachment[]>>;
  setSubmittedAttachments: Dispatch<SetStateAction<SubmittedAttachment[]>>;
  setAttachmentState: (
    index: number,
    status: AttachmentStatus,
    progress: number
  ) => void;
  stageFiles: (files: File[]) => void;
};

export default createContext<AttachmentUploadContextData | null>(null);
