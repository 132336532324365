import { FC, useCallback } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import { INVENTORY_HISTORY_PAGE_SIZE } from 'api';
import strings from 'common/strings';

import { HistoryLog } from './types';
import VehicleLogRow from './VehicleLogRow';

import './VehicleLog.scss';

const ITEM_ROW_HEIGHT = 86;
const SECTION_ROW_HEIGHT = 44;

interface VehicleLogProps {
  historyLog: HistoryLog;
  isLoading: boolean;
  hasNextPage: boolean;
  totalRecords: number;
  loadMoreItems: (startIndex: number, stopIndex: number) => Promise<void>;
}

const VehicleLog: FC<VehicleLogProps> = ({
  isLoading,
  historyLog,
  hasNextPage,
  totalRecords,
  loadMoreItems,
}) => {
  const isItemLoaded = useCallback(
    (index: number) => !hasNextPage || index < historyLog.length,
    [hasNextPage, historyLog.length]
  );

  const getRowHeight = useCallback(
    (index: number) =>
      historyLog[index].kind === 'section'
        ? SECTION_ROW_HEIGHT
        : ITEM_ROW_HEIGHT,
    [historyLog]
  );

  if (isLoading) return null;

  if (!historyLog.length)
    return (
      <div className="no-note-message">
        {strings.NO_CURRENT_HISTORY_MESSAGE}
      </div>
    );

  return (
    <AutoSizer>
      {({ height, width }) => (
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={totalRecords}
          threshold={Math.floor(INVENTORY_HISTORY_PAGE_SIZE / 2)}
          minimumBatchSize={INVENTORY_HISTORY_PAGE_SIZE}
          loadMoreItems={loadMoreItems}
        >
          {({ onItemsRendered, ref }) => (
            <VariableSizeList
              className="VehicleLog list"
              itemCount={historyLog.length}
              height={height}
              width={width}
              estimatedItemSize={ITEM_ROW_HEIGHT}
              itemSize={getRowHeight}
              onItemsRendered={onItemsRendered}
              itemData={{ historyLog }}
              ref={ref}
            >
              {VehicleLogRow}
            </VariableSizeList>
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
};

export default VehicleLog;
