import {
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  MobileStepper,
  Typography,
} from '@material-ui/core';
import {
  Close,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@material-ui/icons';
import { useEffect, useRef, useState } from 'react';
import Joyride, {
  CallBackProps,
  Props,
  Step,
  StoreHelpers,
  Styles,
  TooltipRenderProps,
} from 'react-joyride';

import { getItem, LocalStorageKey, setItem } from 'api/lib/typedLocalStorage';
import theme from 'common/theme';
import { MenuTriggerType, useTourMenu, useTourMenuActions } from 'hooks';

const textColor = '#212529';

export const styles: Styles = {
  options: {
    zIndex: 10000,
    primaryColor: theme.palette.primary.main,
    textColor,
  },
};

const ToolTip = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  skipProps,
  steps,
  tooltipProps,
}: TooltipRenderProps & { steps: Step[] }) => {
  return (
    <Card
      {...tooltipProps}
      style={{
        minWidth: 350,
        maxWidth: 400,
        minHeight: 100,
      }}
    >
      <CardContent style={{ paddingTop: 8 }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: step.title ? 'space-between' : 'flex-end',
            flex: '1',
          }}
        >
          {step.title && (
            <Typography
              gutterBottom
              variant="subtitle1"
              style={{ fontWeight: 600 }}
            >
              {(step.title as string)?.toUpperCase()}
            </Typography>
          )}
          <IconButton
            color="secondary"
            size="small"
            onClick={closeProps.onClick}
            style={{
              position: 'relative',
              right: -12,
              color: theme.palette.text.secondary,
            }}
          >
            <Close />
          </IconButton>
        </div>

        <Typography
          variant="body2"
          component="p"
          style={{
            paddingBottom: 16,
            textAlign: 'center',
            ...step.styles?.tooltipContainer,
          }}
        >
          {step.content}
        </Typography>
      </CardContent>
      <CardActions>
        <MobileStepper
          style={{ flex: 1, backgroundColor: 'transparent', padding: 0 }}
          variant="dots"
          steps={steps.length}
          position="static"
          activeStep={index}
          nextButton={
            continuous ? (
              <Button
                size="small"
                onClick={primaryProps.onClick}
                style={
                  primaryProps.title === 'Close' ||
                  primaryProps.title === 'Start'
                    ? {
                        backgroundColor: theme.palette.primary.main,
                        color: '#fff',
                      }
                    : undefined
                }
              >
                {primaryProps.title}
                {primaryProps.title !== 'Close' &&
                  primaryProps.title !== 'Start' && <KeyboardArrowRight />}
              </Button>
            ) : (
              <Button size="small" onClick={closeProps.onClick}>
                {closeProps.title}
              </Button>
            )
          }
          backButton={
            index === 0 ? (
              <Button size="small" onClick={skipProps.onClick}>
                {skipProps.title}
              </Button>
            ) : (
              <Button size="small" onClick={backProps.onClick}>
                <KeyboardArrowLeft />
                {backProps.title}
              </Button>
            )
          }
        />
      </CardActions>
    </Card>
  );
};

export const Tour = ({
  localStorageKey,
  name,
  menuTriggerType,
  showTour,
  callback,
  ...props
}: Props & {
  localStorageKey: LocalStorageKey;
  menuTriggerType: MenuTriggerType;
  name: string;
  showTour: boolean;
  data?: unknown;
  callback?: (args: CallBackProps) => void;
}) => {
  const { actionType: tourMenuActionType } = useTourMenu();
  const tourMenuActions = useTourMenuActions();
  const tourHelpers = useRef<StoreHelpers>();
  const hasShownTour = getItem(localStorageKey) === 'true';
  const [closeTour, setClose] = useState(false);
  let run =
    (!hasShownTour && !closeTour && showTour) || tourMenuActionType === 'show';

  useEffect(() => {
    if (showTour) {
      tourMenuActions.initTour(name, menuTriggerType);
    }
    return () => {
      tourMenuActions.reset();
    };
  }, [menuTriggerType, name, showTour, tourMenuActions]);

  const setHelpers = (storeHelpers: StoreHelpers) => {
    tourHelpers.current = storeHelpers;
  };

  const handleCallback = (args: CallBackProps) => {
    const { status, action } = args;
    callback?.(args);
    if (action === 'close') {
      setClose(true);
    }
    if (action === 'close' || status === 'skipped' || status === 'finished') {
      setItem(localStorageKey, 'true');
      tourMenuActions.hideTour();
      tourHelpers.current?.reset(true);
    }
  };

  return showTour ? (
    <Joyride
      run={run}
      getHelpers={setHelpers}
      callback={handleCallback}
      styles={styles}
      continuous
      disableScrollParentFix
      disableScrolling
      showSkipButton
      {...props}
      tooltipComponent={(toolTipProps) => (
        <ToolTip steps={props.steps} {...toolTipProps} />
      )}
    />
  ) : null;
};
