/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useRef, useState } from 'react';

import './FlatTaskInput.scss';

interface FlatTaskInputProps {
  testId: string;
  testData: string;
  disabled: boolean;
  value: any;
  onSave: (value: any) => void;
  hasViewPermission: boolean;
  formatter: (value: any) => any;
}

const FlatTaskInput: React.FC<FlatTaskInputProps> = (props) => {
  const {
    testId,
    testData,
    disabled,
    value,
    onSave,
    hasViewPermission,
    formatter,
  } = props;
  const [isEditingInput, setIsEditingInput] = useState(false);
  const ref = useRef<HTMLInputElement>(null);

  const displayValue = useMemo(() => {
    if (!hasViewPermission) {
      return '--';
    }
    if (!isEditingInput) {
      if (!value) {
        return '--';
      }
      return formatter(value);
    }
    return value || '';
  }, [formatter, hasViewPermission, isEditingInput, value]);

  useEffect(() => {
    if (isEditingInput) {
      ref?.current?.focus();
    }
  }, [isEditingInput, ref]);

  return isEditingInput ? (
    <input
      id={testData}
      ref={ref}
      type="text"
      disabled={disabled}
      defaultValue={displayValue}
      onBlur={(event) => {
        setIsEditingInput(false);
        if (displayValue?.toString() === event?.target?.value) {
          return;
        }
        onSave(event.target.value);
      }}
      onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
        if (
          event.key.toLowerCase() === 'tab' ||
          event.key.toLowerCase() === 'enter'
        ) {
          ref?.current?.blur();
        }
      }}
    />
  ) : (
    <div
      className="FlatTaskInput"
      onClick={() => !disabled && setIsEditingInput(true)}
    >
      {displayValue}
    </div>
  );
};

export default FlatTaskInput;
