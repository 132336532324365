import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import {
  useAddAttachmentToVehicle,
  useDeleteAttachment,
  usePresignedAttachmentUploadUrl,
  useUploadFileToS3,
  useVehicleAttachments,
} from 'api';
import images from 'common/images';
import permissions from 'common/permissions';
import strings from 'common/strings';
import { testIds } from 'common/testIds';
import Alert from 'components/shared/Alert';
import ConfirmationDialog from 'components/shared/ConfirmationDialog';
import FlatVDPPaneContent from 'components/shared/FlatVDPPaneContent';
import FlatVDPPaneHeader from 'components/shared/FlatVDPPaneHeader';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import PermissionsGate from 'components/shared/PermissionsGate';
import { useFileSelector, useWindowSize } from 'hooks';
import { emptyAttachment, VehicleAttachment, VehicleSummary } from 'models';

import DocumentRow from '../DocumentRow';
import {
  SubmittedAttachments,
  SubmittedAttachmentState,
  useDocumentUploadContext,
} from '../UploadProvider';

import './FlatDocumentsSection.scss';

const ALLOWED_ATTACHMENT_FILETYPES = ['application/pdf'];

interface FlatDocumentsSectionProps {
  vehicle: VehicleSummary;
  path?: string;
  title?: string;
}

const FlatDocumentsSection: React.FC<FlatDocumentsSectionProps> = ({
  vehicle,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [errorUploadingAttachment, setErrorUploadingAttachment] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState(strings.API_MESSAGE);
  const [attachmentToDelete, setAttachmentToDelete] = useState<
    VehicleAttachment | undefined
  >(undefined);
  const windowSize = useWindowSize();
  const { deleteAttachmentAsync } = useDeleteAttachment();
  const {
    data: { data: attachments = [] } = {},
    isLoading,
    refetch,
  } = useVehicleAttachments(vehicle?.vehicleCard?.id!);
  const { uploadFileToS3 } = useUploadFileToS3();
  const { getNewPresignedAttachmentUploadUrl } =
    usePresignedAttachmentUploadUrl();
  const { addAttachmentToVehicle } = useAddAttachmentToVehicle();
  const { submittedDocuments, setSubmittedDocuments } =
    useDocumentUploadContext();

  const [tempAttachments, setTempAttachments] = useState<VehicleAttachment[]>(
    []
  );

  const isUploading = submittedDocuments.length > 0;

  const setDocumentState = useCallback(
    (index: number, state: SubmittedAttachmentState) => {
      setSubmittedDocuments((curSubmittedDocument) =>
        curSubmittedDocument.map((mapState, mapIndex) => {
          if (mapIndex !== index) {
            return mapState;
          }

          return {
            ...mapState,
            ...state,
          };
        })
      );
    },
    [setSubmittedDocuments]
  );

  const onChange = useCallback(
    async (event: Event) => {
      const target = event.target as HTMLInputElement;
      const files = target.files ? Array.from(target.files) : [];
      const vehicleId = vehicle.vehicleCard.id;

      if (!files.length || !vehicleId) {
        return;
      }

      const documents: SubmittedAttachments = files.map((file) => ({
        vehicleId,
        file,
        status: 'pending',
      }));

      const draftAttachments = documents.map((document) => ({
        ...emptyAttachment,
        name: document.file.name,
        createdDate: new Date(document.file.lastModified).toString(),
      }));

      setSubmittedDocuments(documents);
      setTempAttachments(draftAttachments);

      const requests = documents.map(async ({ file }, index) => {
        const {
          data: { s3AttachmentId, uploadUrl },
        } = await getNewPresignedAttachmentUploadUrl({
          vehicleId,
          contentType: file.type,
        });

        setDocumentState(index, {
          status: 'uploading',
          progress: 0,
        });

        await uploadFileToS3({
          file,
          presignedUrl: uploadUrl,
          onUploadProgress: ({ loaded, total }) => {
            if (total !== undefined) {
              setDocumentState(index, {
                status: 'uploading',
                progress: (loaded / total) * 100,
              });
            }
          },
        });

        await addAttachmentToVehicle({
          vehicleId,
          s3AttachmentId,
          attachmentName: file.name,
        });

        setDocumentState(index, {
          status: 'completed',
        });
      });

      try {
        await Promise.all(requests);
      } catch (error) {
        if (error instanceof Error) {
          setErrorMessage(error.message);
        } else {
          setErrorMessage(strings.API_MESSAGE);
        }

        setErrorUploadingAttachment(true);
      } finally {
        setSubmittedDocuments([]);
        setTempAttachments([]);
      }

      setErrorUploadingAttachment(false);
      setErrorMessage('');

      refetch();
    },
    [
      addAttachmentToVehicle,
      getNewPresignedAttachmentUploadUrl,
      refetch,
      setDocumentState,
      setSubmittedDocuments,
      uploadFileToS3,
      vehicle.vehicleCard.id,
    ]
  );

  const onError = useCallback((event) => {
    setErrorMessage(event ?? strings.API_MESSAGE);
    setErrorUploadingAttachment(true);
  }, []);

  const [fileSelector] = useFileSelector(
    onChange,
    onError,
    ALLOWED_ATTACHMENT_FILETYPES.join(',')
  );

  useEffect(() => {
    if (submittedDocuments.length) {
      fileSelector.value = '';
    }
  }, [fileSelector, submittedDocuments.length]);

  const handleOpenConfirmationDialog = (attachment: VehicleAttachment) => {
    setAttachmentToDelete(attachment);
    setIsConfirmationDialogOpen(true);
  };

  const deleteAttachment = async () => {
    if (!attachmentToDelete) return;
    try {
      setIsSubmitting(true);
      await deleteAttachmentAsync({
        vehicleId: vehicle?.vehicleCard?.id!,
        attachmentId: attachmentToDelete.id!,
      });
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
    }
    setIsConfirmationDialogOpen(false);
  };

  const handleCloseConfirmationDialog = () => {
    setIsConfirmationDialogOpen(false);
  };

  const handleCloseError = () => {
    setErrorUploadingAttachment(false);
    setErrorMessage(strings.API_MESSAGE);
  };

  return (
    <>
      <FlatVDPPaneHeader
        className="FlatMedia-header"
        vehicleId={vehicle?.vehicleCard?.id}
        shouldShowBackButton={false}
      >
        {strings.DOCUMENTS}
        <PermissionsGate
          permissions={[permissions.INVENTORY_VDP_ATTACHMENTS_CREATE]}
        >
          <button
            type="button"
            className="FlatMedia-upload-button"
            onClick={() => fileSelector.click()}
          >
            <img
              data-vas-testing={testIds.DOCUMENTS_SECTION_UPLOAD_BUTTON_ICON}
              className="FlatMedia-upload-button-icon"
              src={images.UploadIcon}
              alt="Upload button"
            />
          </button>
        </PermissionsGate>
      </FlatVDPPaneHeader>
      <FlatVDPPaneContent className="FlatMedia-documents-content vertical-scroll">
        {windowSize.isMobileViewport() && (
          <PermissionsGate
            permissions={[permissions.INVENTORY_VDP_ATTACHMENTS_CREATE]}
          >
            <button
              type="button"
              className="FlatMedia-upload-button-mobile FlatMedia-upload-button"
              onClick={() => fileSelector.click()}
            >
              <img
                className="FlatMedia-upload-button-icon"
                src={images.UploadIcon}
                alt="Upload button"
              />
            </button>
          </PermissionsGate>
        )}
        <Container fluid className="p-0 pb-5">
          <Row noGutters>
            <Col>
              {isLoading ? (
                <LoadingIndicator />
              ) : attachments?.length > 0 ? (
                attachments?.map((attachment: VehicleAttachment) => (
                  <DocumentRow
                    attachment={attachment}
                    onDeleteClick={handleOpenConfirmationDialog}
                  />
                ))
              ) : (
                !isUploading && (
                  <div className="FlatDocumentsSection-content-empty">
                    {strings.ATTACHMENTS_EMPTY}
                  </div>
                )
              )}
              {tempAttachments.map((attachment, index) => (
                <DocumentRow
                  loading
                  attachment={attachment}
                  document={submittedDocuments[index]}
                />
              ))}
            </Col>
          </Row>
        </Container>
      </FlatVDPPaneContent>
      {isConfirmationDialogOpen && (
        <ConfirmationDialog
          onClose={handleCloseConfirmationDialog}
          title={strings.DELETE_DOCUMENT_TITLE}
          onAccept={deleteAttachment}
          mainButtonTitle={strings.DELETE}
          body={strings.DELETE_DOCUMENT_BODY}
          disabled={isSubmitting}
        />
      )}
      <Alert
        open={errorUploadingAttachment}
        contentProps={{
          variant: 'error',
          onClose: handleCloseError,
          message: errorMessage,
        }}
      />
    </>
  );
};

export default FlatDocumentsSection;
