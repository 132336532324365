import { Redirect, useLocation } from '@reach/router';

import { useCallbackCognito } from 'api';
import { getCredentialValue } from 'api/lib/credentialsPersistence';
import LoadingIndicator from 'components/shared/LoadingIndicator';

interface SingleSignOnProps {
  path?: string;
}

const SingleSignOn = ({ path }: SingleSignOnProps) => {
  const location = useLocation();
  const { isError, data } = useCallbackCognito(location?.search);

  const authBearerToken = getCredentialValue('authBearerToken');
  if (authBearerToken) {
    return <Redirect noThrow to="/" />;
  }
  if (isError || data?.status === 'failure') {
    return <Redirect noThrow to="/login" />;
  }
  return (
    <div style={{ position: 'absolute', top: '40%', left: '50%' }}>
      <LoadingIndicator />
    </div>
  );
};

export default SingleSignOn;
