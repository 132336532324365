/* eslint-disable consistent-return */
import { Close, Done, MoreHoriz } from '@material-ui/icons';
import { FC, useEffect, useState } from 'react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';

import {
  ALLOWED_IMAGE_EXTENSIONS,
  ALLOWED_VIDEO_EXTENSIONS,
} from 'store/uploads/types';

import { AttachmentIcon, ImageIcon, VideoIcon } from './Icons';

import './S3UploadPlaceholder.scss';

type AttachmentStatePending = {
  status: 'pending';
};

type AttachmentStateUploading = {
  status: 'uploading';
  progress: number;
};

type AttachmentStateCompleted = {
  status: 'completed';
};

type AttachmentWithoutState = {
  status?: undefined;
};

type AttachmentState =
  | AttachmentWithoutState
  | AttachmentStatePending
  | AttachmentStateUploading
  | AttachmentStateCompleted;

type Attachment = AttachmentState & {
  file: File;
};

interface S3UploadPlaceholderProps {
  large?: boolean;
  showOverlay?: boolean;
  attachment: Attachment;
  onRemove?: () => void;
}

const S3UploadPlaceholder: FC<S3UploadPlaceholderProps> = ({
  large = false,
  showOverlay,
  attachment,
  onRemove,
}) => {
  const [objectUrl, setObjectUrl] = useState('');

  useEffect(() => {
    if (
      !ALLOWED_IMAGE_EXTENSIONS.includes(getFileExtension(attachment.file.name))
    ) {
      setObjectUrl('');
    }

    const objUrl = URL.createObjectURL(attachment.file);
    setObjectUrl(objUrl);

    return () => {
      URL.revokeObjectURL(objUrl);
    };
  }, [attachment.file]);

  const getOverlayContent = () => {
    switch (attachment.status) {
      case 'pending':
        return <MoreHoriz className="UploadPlaceholder-progress-icon" />;
      case 'uploading':
        return (
          <CircularProgressbarWithChildren
            className={`UploadPlaceholder-overlay-progress ${progressbarClassName}`}
            value={attachment.status === 'uploading' ? attachment.progress : 0}
            strokeWidth={8}
          />
        );
      case 'completed':
        return <Done className="UploadPlaceholder-progress-icon" />;
    }
  };

  const getDefaultIcon = (fileExtension: string) => {
    if (ALLOWED_IMAGE_EXTENSIONS.includes(fileExtension)) {
      if (objectUrl) {
        return null;
      }
      return <ImageIcon />;
    }

    if (ALLOWED_VIDEO_EXTENSIONS.includes(fileExtension)) {
      return <VideoIcon />;
    }
    return <AttachmentIcon />;
  };

  const progressbarClassName = large
    ? 'UploadPlaceholder-overlay-progress-large'
    : '';
  const backgroundImageStyle = objectUrl
    ? { backgroundImage: `url(${objectUrl})` }
    : {};

  return (
    <div className="UploadPlaceholder">
      <div className="UploadPlaceholder-image" style={backgroundImageStyle}>
        {getDefaultIcon(getFileExtension(attachment.file.name))}
      </div>
      <>
        {showOverlay && (
          <div className="UploadPlaceholder-overlay">{getOverlayContent()}</div>
        )}
        {onRemove && (
          <button
            type="button"
            className="UploadPlaceholder-cancel-button"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              onRemove();
            }}
          >
            <Close className="UploadPlaceholder-cancel-button-icon" />
          </button>
        )}
      </>
    </div>
  );
};

const getFileExtension = (filename: string) => {
  const filenameParts = filename.split('.');
  if (filenameParts.length < 2) {
    return '';
  }
  return `.${filenameParts[filenameParts.length - 1]}`;
};

export default S3UploadPlaceholder;
