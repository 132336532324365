import { useCallback, useMemo } from 'react';

import S3UploadPlaceholder from 'components/shared/S3UploadPlaceholder/S3UploadPlaceholder';

import useAttachmentUploadContext from '../useAttachmentUploadContext';

import './StagedAttachments.scss';

const StagedAttachments = () => {
  const context = useAttachmentUploadContext();

  const handleRemoveAttachment = useCallback(
    (index: number) => {
      context.setStagedAttachments((attachments) =>
        attachments.filter((_, curIndex) => curIndex !== index)
      );
    },
    [context]
  );

  const images = useMemo(() => {
    return context.stagedAttachments.map((attachment, index) => (
      <div className="StagedAttachments-image">
        <S3UploadPlaceholder
          attachment={attachment}
          showOverlay={true}
          onRemove={() => handleRemoveAttachment(index)}
        />
      </div>
    ));
  }, [context.stagedAttachments, handleRemoveAttachment]);

  return <div className="StagedAttachments-container">{images}</div>;
};

export default StagedAttachments;
