import { useLocation, useMatch } from '@reach/router';
import { FC, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { DateRange } from 'common/DateRange';
import permissions from 'common/permissions';
import Tabs from 'components/shared/Tabs';
import { useFeatures, usePermissions } from 'hooks';

import CorporateReport from './CorporateReport';
import DealerStepReport from './DealerStepReport';
import DealerStepReportV3 from './DealerStepReportV3';
import { VehicleStateValue } from './DealerStepReportV3/VehicleStateFilter';
import ReportsDateFilter, { getDefaultDateRange } from './ReportsDateFilter';
import VelocityEngageCorporateReport from './VelocityEngageCorporateReport';
import VelocityEngageModuleReport from './VelocityEngageModuleReport';

import './Reports.scss';

interface ReportsProps {
  path?: string;
}

enum ReportPaths {
  dealer = 'dealer-step',
  corporate = 'corporate',
  velocityEngageCorporate = 'velocityEngageCorporate',
  velocityEngageModule = 'velocityEngageModule',
}

const Reports: FC<ReportsProps> = () => {
  const { hasPermission } = usePermissions();
  const { hasFeature } = useFeatures();
  const location = useLocation();

  const [dateRange, setDateRange] = useState<DateRange>(getDefaultDateRange());
  const [selectedVehicleState, setSelectedVehicleState] =
    useState<VehicleStateValue>('COMPLETED');

  const defaultTabPath = () => {
    if (hasPermission(permissions.REPORTS_STEP_REPORT_VIEW)) {
      return ReportPaths.dealer;
    }
    if (hasPermission(permissions.REPORTS_CORPORATE_REPORT_VIEW)) {
      return ReportPaths.corporate;
    }
    if (
      hasPermission(permissions.PLUGIN_VELOCITYENGAGE_CORPORATE_REPORT_VIEW)
    ) {
      return ReportPaths.velocityEngageCorporate;
    }

    return ReportPaths.dealer;
  };

  const matchesVelocityEngageModuleReport = useMatch(
    ReportPaths.velocityEngageModule
  );

  const isDealerStepReport = location.pathname
    .split('/')
    .includes(ReportPaths.dealer);

  const isCorporateReport = location.pathname
    .split('/')
    .includes(ReportPaths.corporate);

  return (
    <div className="Reports page vertical-scroll">
      <div className="Reports-panel-container">
        <Container fluid>
          {location.pathname.split('/').includes(ReportPaths.dealer)}
          <Row noGutters className="no-margin">
            <Col className="Reports-overview">
              {!matchesVelocityEngageModuleReport && (
                <ReportsDateFilter
                  allowCustomRange={isDealerStepReport}
                  allowMonthYear={isCorporateReport}
                  onChange={setDateRange}
                />
              )}
              <Tabs
                basePath="reports"
                defaultPath={defaultTabPath()}
                tabContentClassName={
                  (matchesVelocityEngageModuleReport ? '' : 'bg-white') +
                  ' tab-container'
                }
                className="full-width"
              >
                {hasPermission(permissions.REPORTS_STEP_REPORT_VIEW) &&
                  (hasFeature('STEP_REPORT_VERSION_3') ? (
                    <DealerStepReportV3
                      path={ReportPaths.dealer}
                      title="Recon Step"
                      dateRange={dateRange}
                      selectedVehicleState={selectedVehicleState}
                      setSelectedVehicleState={setSelectedVehicleState}
                    />
                  ) : (
                    <DealerStepReport
                      path={ReportPaths.dealer}
                      title="Recon Step"
                      dateRange={dateRange}
                    />
                  ))}
                {hasPermission(permissions.REPORTS_CORPORATE_REPORT_VIEW) && (
                  <CorporateReport
                    path={ReportPaths.corporate}
                    title="Recon Step Corporate"
                    dateRange={dateRange}
                  />
                )}
                {hasPermission(
                  permissions.PLUGIN_VELOCITYENGAGE_CORPORATE_REPORT_VIEW
                ) && (
                  <VelocityEngageCorporateReport
                    path={ReportPaths.velocityEngageCorporate}
                    title="Engage Corporate"
                    dateRange={dateRange}
                  />
                )}
                {hasPermission(permissions.PLUGIN_VELOCITYENGAGE_VIEW) && (
                  <VelocityEngageModuleReport
                    path={ReportPaths.velocityEngageModule}
                    title="Velocity Engage Module"
                  />
                )}
              </Tabs>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Reports;
