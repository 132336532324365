import { createContext, Dispatch, SetStateAction } from 'react';

import { StagedAttachments, SubmittedAttachments } from './types';

export type AttachmentUploadContextData = {
  stagedAttachments: StagedAttachments;
  submittedAttachments: SubmittedAttachments;
  setStagedAttachments: Dispatch<SetStateAction<StagedAttachments>>;
  setSubmittedAttachments: Dispatch<SetStateAction<SubmittedAttachments>>;
};

export default createContext<AttachmentUploadContextData | null>(null);
