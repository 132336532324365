import { Paper } from '@material-ui/core';
import React, { ReactElement } from 'react';

import { mobileTaskHeader } from 'components/pages/VehicleDetails/MobileVDPHeader/MobileVDPHeader';
import { useScrollDirection, useWindowSize } from 'hooks';

import MobileFlatTasksHeader from '../MobileFlatTasksHeader';

import './FlatTasksHeader.scss';

interface FlatTasksHeaderProps {
  title: string;
  taskButtons?: React.ReactNode[];
  actionButtons?: React.ReactNode[];
  onShowAppraisalInfo: () => void;
}

export const mobileHeaderHeight = 117; // Height of Mobile Header minus task header

const FlatTasksHeader: React.FC<FlatTasksHeaderProps> = ({
  actionButtons,
  taskButtons,
  title,
  onShowAppraisalInfo,
}) => {
  const windowSize = useWindowSize();
  const isMobile = windowSize.isMobileViewport();
  const showMobileTasks =
    new URLSearchParams(window.location.search).get('mobileTasks') !== null;

  const classHeaderString = isMobile
    ? 'FlatTasksHeader-mobile'
    : 'FlatTasksHeader-desktop';

  return showMobileTasks ? (
    <Container isMobile={isMobile}>
      <div className={classHeaderString}>
        {!isMobile && (
          <>
            <div className="FlatTasksHeader-title">{title}</div>
            <div className="FlatTasksHeader-task-buttons">{taskButtons}</div>
          </>
        )}
        {isMobile && actionButtons ? (
          <MobileFlatTasksHeader onShowAppraisalInfo={onShowAppraisalInfo} />
        ) : (
          <div className="FlatTasksHeader-action-buttons">{actionButtons}</div>
        )}
      </div>
    </Container>
  ) : (
    <div className="FlatTasksHeader">
      <div className={classHeaderString}>
        {!isMobile && (
          <>
            <div className="FlatTasksHeader-title">{title}</div>
            <div className="FlatTasksHeader-task-buttons">{taskButtons}</div>
          </>
        )}
        {actionButtons && (
          <div className="FlatTasksHeader-action-buttons">{actionButtons}</div>
        )}
      </div>
    </div>
  );
};

type ContainerProps = {
  isMobile: boolean;
  children: ReactElement;
};

const Container = ({ isMobile, children, ...props }: ContainerProps) => {
  const { scrollDirection, containerHeight, scrollHeight, scrollIncrement } =
    useScrollDirection('MobileVDPBody');
  const canHideHeader =
    scrollHeight >
    containerHeight + mobileHeaderHeight + mobileTaskHeader + scrollIncrement; // 48 is the height of the task header

  let topStyle: number | string =
    scrollDirection !== 'down' ? mobileHeaderHeight : 0;

  if (!canHideHeader) {
    topStyle = mobileHeaderHeight;
  }

  return isMobile ? (
    <Paper
      elevation={3}
      {...props}
      style={{
        position: 'fixed',
        top: topStyle,
        left: 0,
        right: 0,
        zIndex: 1500,
      }}
    >
      {children}
    </Paper>
  ) : (
    <div className="FlatTasksHeader">{children}</div>
  );
};

export default FlatTasksHeader;
