import { MoreHoriz } from '@material-ui/icons';
import { forwardRef } from 'react';
import { Dropdown } from 'react-bootstrap';

import { useDeleteNote, useToken } from 'api';
import { permissions } from 'common';
import { usePermissions } from 'hooks';
import { NoteV2 } from 'models';

interface VehicleCardNoteEditDropDownProps {
  note: NoteV2;
  userId: string;
  vehicleId: string;
  onEditNoteClick: (noteId: string) => void;
}
const VehicleCardNoteEditDropDown = ({
  note,
  userId,
  vehicleId,
  onEditNoteClick,
}: VehicleCardNoteEditDropDownProps) => {
  const { data: sessionData } = useToken();
  const { hasPermission } = usePermissions();
  const deleteNoteMutation = useDeleteNote();
  const canUserDelete = hasPermission(permissions.INVENTORY_VDP_NOTES_DELETE);
  const canUserUpdate = hasPermission(permissions.INVENTORY_VDP_NOTES_UPDATE);
  const isCurrentUserMessage = userId === sessionData?.user?.id;

  if (!isCurrentUserMessage) {
    return null;
  }

  const CustomToggle = forwardRef(({ onClick }: any, ref: any) => (
    <div role="button" onClick={(e: any) => onClick(e)} ref={ref} tabIndex={0}>
      <MoreHoriz />
    </div>
  ));

  const deleteNote = ({ id: noteId = '' }: NoteV2) =>
    deleteNoteMutation.mutate({ noteId, vehicleId });

  return (
    <Dropdown drop="right">
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" />
      <Dropdown.Menu className="border-0 drop-shadow">
        {canUserDelete && (
          <Dropdown.Item
            className="py-2"
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              deleteNote(note);
            }}
          >
            Delete Note
          </Dropdown.Item>
        )}
        {canUserUpdate && (
          <Dropdown.Item
            className="py-2"
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              onEditNoteClick(note.id as string);
            }}
          >
            Edit Note
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default VehicleCardNoteEditDropDown;
