import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

const reconMain = '#263238';
const reconMenuContrast = '#9E9E9E';
const reconMenuSelected = '#FFF';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: 'rgba(198, 0, 0, 1)',
      dark: '#000',
      light: '#fff',
      contrastText: '#37474F',
    },
    secondary: {
      light: reconMenuSelected,
      main: reconMain,
      contrastText: reconMenuContrast,
    },
    error: {
      main: 'rgba(198, 0, 0, 1)',
      light: 'rgba(198, 0, 0, 0.2)',
    },
    success: {
      main: '#1ab245',
    },
    warning: {
      main: '#ffab00',
    },
    danger: {
      main: '#d32f2f',
    },
  },
  typography: {
    fontFamily: '"Open Sans", sans-serif',
  },
});

// Allows danger to be added to theme
declare module '@material-ui/core/styles/createPalette' {
  export interface PaletteOptions {
    danger: {
      main: string;
    };
  }

  export interface Palette {
    danger: {
      main: string;
    };
  }
}

export default responsiveFontSizes(theme);
