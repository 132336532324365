import { FC, useEffect, useRef, useState } from 'react';

const StickyContainer: FC = ({ children }) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [isStuck, setIsStuck] = useState(false);

  useEffect(() => {
    if (!elementRef.current) {
      return;
    }

    const observer = new IntersectionObserver(([e]) => {
      setIsStuck(!e.isIntersecting);
    });
    observer.observe(elementRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <>
      <div
        ref={elementRef}
        aria-hidden
        style={{
          backgroundColor: 'transparent',
          height: '0',
        }}
      />
      <div
        className={`StickyContainer ${isStuck ? 'stuck' : ''}`}
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 20,
          backgroundColor: 'white',
        }}
      >
        {children}
      </div>
    </>
  );
};

export default StickyContainer;
