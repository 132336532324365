import { RefObject, useRef, useState } from 'react';

import { useAddNote } from 'api';
import { permissions } from 'common';
import Alert from 'components/shared/Alert';
import {
  AttachmentUploadProvider,
  useAttachmentUploadContext,
} from 'components/shared/AttachmentManager';
import StagedAttachments from 'components/shared/AttachmentManager/StagedAttachments/StagedAttachments';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import PermissionsGate from 'components/shared/PermissionsGate';
import { useAttachments } from 'hooks/useAttachments';

import MentionableInput from '../MentionableInput';
import NoteAttachmentInput from '../NoteAttachmentInput';
import SendIconButton from '../SendIconButton';

import './VehicleCardNoteInput.scss';

interface VehicleCardNotesProps {
  vehicleId: string;
  shouldStageAttachments?: boolean;
}
const VehicleCardNoteInput = ({
  vehicleId,
  shouldStageAttachments = false,
}: VehicleCardNotesProps) => {
  const { isLoading: isLoadingAddNote } = useAddNote();
  const attachmentUploadContext = useAttachmentUploadContext();

  const [shouldFocus, setShouldFocus] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [message, setMessage] = useState('');
  const [shouldClearContent, setShouldClearContent] = useState(false);
  const {
    createNoteAndUploadAttachments,
    isLoading: isLoadingCreateNoteAndUploadAttachments,
  } = useAttachments();
  const attachmentInputRef: RefObject<HTMLInputElement> = useRef<any>();

  const handleCloseError = () => setErrorMessage('');

  const handleNoteSubmit = () => {
    setShouldClearContent(true);
    setShouldFocus(false);
    createNoteAndUploadAttachments(
      vehicleId,
      attachmentUploadContext.stagedAttachments,
      message
    )
      .catch((error) => setErrorMessage(error))
      .finally(() => {
        attachmentUploadContext.setStagedAttachments([]);
        setShouldClearContent(false);
        setShouldFocus(true);
      });
  };

  const handleAttachmentsSubmit = (event: any) => {
    const target = event.target as HTMLInputElement;
    const files = target.files ? Array.from(target.files) : [];
    event.target.value = null;

    if (shouldStageAttachments) {
      attachmentUploadContext.stageFiles(files);
    } else {
      setShouldClearContent(true);
      setShouldFocus(false);
      createNoteAndUploadAttachments(vehicleId, files, message)
        .catch((error) => setErrorMessage(error))
        .finally(() => {
          setShouldClearContent(false);
          setShouldFocus(true);
        });
    }
  };

  const isLoading = isLoadingAddNote || isLoadingCreateNoteAndUploadAttachments;
  return (
    <div className="VehicleCardNotes-inputContainer">
      <div className="VehicleCardNotes-input">
        <PermissionsGate
          permissions={[permissions.INVENTORY_VDP_NOTES_ATTACHMENT_CREATE]}
        >
          {isLoading ? (
            <LoadingIndicator
              size={24}
              className={'VehicleCardNotes-loading'}
            />
          ) : (
            <NoteAttachmentInput
              onFileSelected={handleAttachmentsSubmit}
              disabled={isLoading}
              attachmentInputRef={attachmentInputRef}
              id={vehicleId}
            />
          )}
        </PermissionsGate>
        <PermissionsGate permissions={[permissions.INVENTORY_VDP_NOTES_CREATE]}>
          <MentionableInput
            disabled={isLoading}
            shouldFocus={shouldFocus}
            onFocus={() => setShouldFocus(true)}
            onBlur={() => setShouldFocus(false)}
            existingContent={message}
            onChange={setMessage}
            submitAction={handleNoteSubmit}
            shouldClearContent={shouldClearContent}
          />
          <SendIconButton
            disabled={!message || isLoading}
            onClick={handleNoteSubmit}
          />
        </PermissionsGate>
      </div>
      {shouldStageAttachments && <StagedAttachments />}
      <Alert
        open={!!errorMessage}
        contentProps={{
          variant: 'error',
          onClose: handleCloseError,
          message: errorMessage,
        }}
      />
    </div>
  );
};

const VehicleCardNoteInputWithAttachments = (props: VehicleCardNotesProps) => {
  return (
    <AttachmentUploadProvider>
      <VehicleCardNoteInput {...props} />
    </AttachmentUploadProvider>
  );
};

export default VehicleCardNoteInputWithAttachments;
