import { Badge } from '@material-ui/core';
import { AssignmentTurnedIn, Comment, ReportProblem } from '@material-ui/icons';
import { navigate } from '@reach/router';

import { permissions, strings } from 'common';
import { RVLogo, VelocityEngageIcon } from 'components/shared/icons';
import { usePermissions, useVendor } from 'hooks';
import { VehicleSummary } from 'models';
import { notesBuilder, recallsBuilder, tasksBuilder } from 'navigation/routes';

import './VehicleCardTabFooter.scss';

interface VehicleCardTabFooterProps {
  vehicle: VehicleSummary | undefined;
  navClick: (route: string) => void;
  children?: any;
  showEngage?: boolean;
  showRecon?: boolean;
  showTasks?: boolean;
  showRecalls?: boolean;
  hideNavigations?: boolean;
}

const VehicleCardTabFooter = ({
  vehicle,
  navClick,
  children,
  showEngage = false,
  showRecon = false,
  showTasks = true,
  showRecalls = true,
  hideNavigations = false,
}: VehicleCardTabFooterProps) => {
  const { isVendor } = useVendor();
  const vehicleCard = vehicle?.vehicleCard;
  const recallCount = vehicleCard?.recallCount || 0;
  const taskCount = vehicleCard?.tasksNeedingAttentionCount || 0;
  const { hasPermission } = usePermissions();

  return (
    <div className="VehicleCardTabFooter-container">
      {!hideNavigations && (
        <>
          <Badge
            className={`tour-invoice-notes VehicleCardTabFooter-badge recallBadge ${
              taskCount > 0 ? 'active' : 'inactive'
            }`}
            onClick={() => {
              navigate(
                notesBuilder(vehicleCard?.id!, false, undefined, isVendor)
              );
            }}
          >
            <Comment color="secondary" />
            <div>{strings.NOTES.toUpperCase()}</div>
          </Badge>
          {showTasks && (
            <Badge
              badgeContent={taskCount}
              invisible={!taskCount}
              className={`tour-invoice-tasks VehicleCardTabFooter-badge recallBadge ${
                taskCount > 0 ? 'active' : 'inactive'
              }`}
              onClick={() => {
                navigate(
                  `${tasksBuilder(
                    vehicleCard?.id!,
                    false,
                    undefined,
                    isVendor
                  )}`
                );
              }}
            >
              <AssignmentTurnedIn color="secondary" />
              <div>{strings.TASKS.toUpperCase()}</div>
            </Badge>
          )}
          {showRecalls && (
            <Badge
              badgeContent={recallCount}
              invisible={!recallCount}
              className={`VehicleCardTabFooter-badge recallBadge ${
                recallCount > 0 ? 'active' : 'inactive'
              }`}
              onClick={() => {
                navigate(
                  `${recallsBuilder(vehicleCard?.id!, false)}?fromVDP=inventory`
                );
              }}
            >
              <ReportProblem color="secondary" />
              <div>{strings.RECALLS.toUpperCase()}</div>
            </Badge>
          )}
          {showRecon && hasPermission(permissions.RECON_VIEW) && (
            <div className="VehicleCardTabFooter-Inventory">
              <div
                className="VehicleCardTabFooter-badge"
                onClick={() => navClick('VehicleCard-step-buttons-container')}
              >
                <RVLogo
                  className="VehicleCardTabFooter-badge-icon"
                  viewBox="-1 0 28 20"
                />
                <div>{strings.RECON.toUpperCase()}</div>
              </div>
            </div>
          )}
          {showEngage &&
            hasPermission(permissions.PLUGIN_VELOCITYENGAGE_VIEW) && (
              <div
                className="VehicleCardTabFooter-badge"
                onClick={() => navClick('VehicleCard-view-more-container')}
              >
                <VelocityEngageIcon
                  className="VehicleCardTabFooter-badge-icon"
                  viewBox="3 -3 24 28"
                />
                <div>{strings.ENGAGE.toUpperCase()}</div>
              </div>
            )}
        </>
      )}
      <div className="VehicleCardTabFooter-children">{children}</div>
    </div>
  );
};

export default VehicleCardTabFooter;
