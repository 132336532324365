import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { navigate } from '@reach/router';
import moment from 'moment';
import { useEffect } from 'react';

import { strings } from 'common';
import { useVendor } from 'hooks/useVendor';
import { Invoice } from 'models/invoicing';
import { tasksBuilder } from 'navigation/routes';
import { currencyFormatter } from 'utils/formatter';
import { getInvoiceStatus } from 'utils/invoice';

type Props = {
  invoices: Invoice[];
  onClick: (invoice: Invoice) => void;
  onItemsRendered: () => void;
  vehicleId: string;
};

const InvoiceTable = ({
  invoices,
  onClick,
  onItemsRendered,
  vehicleId,
}: Props) => {
  useEffect(() => {
    if (invoices.length > 0) {
      onItemsRendered();
    }
  }, [invoices, onItemsRendered]);
  const { isVendor } = useVendor();
  const navigateToTasks = () => {
    navigate(tasksBuilder(vehicleId, true, undefined, isVendor));
  };

  return (
    <Table size="small">
      <TableHead className="invoices-table-head">
        <TableRow>
          <TableCell>{strings.INVOICE_STATUS}</TableCell>
          <TableCell>{strings.INVOICE_NUMBER}</TableCell>
          <TableCell>{strings.INVOICE_DATE}</TableCell>
          <TableCell>{strings.INVOICE_PAYMENT_DUE_BY}</TableCell>
          <TableCell>{strings.INVOICE_VENDOR}</TableCell>
          <TableCell align="right">
            {strings.INVOICE_TOTAL_AMOUNT_DUE}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {invoices.length > 0 ? (
          invoices.map((invoice) => {
            const status = invoice?.vehicles?.[0]?.tasks?.[0]?.status;
            return (
              <TableRow key={invoice?.id} onClick={() => onClick(invoice)}>
                <TableCell
                  className={`invoices-status-${status?.toLocaleLowerCase()}`}
                >
                  <span className="tour-invoice-status">
                    {getInvoiceStatus(status)}
                  </span>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Link
                    color="textPrimary"
                    href="#"
                    underline="always"
                    onClick={(e) => e.preventDefault()}
                    style={{ fontWeight: 600 }}
                    className="tour-invoice-number"
                  >
                    {invoice?.invoiceNumber}
                  </Link>
                </TableCell>
                <TableCell>
                  <span className="tour-invoice-date">
                    {moment(invoice?.createdAt).format('l')}
                  </span>
                </TableCell>
                <TableCell>
                  <span className="tour-invoice-payment-due-by">
                    {moment(invoice?.dueByDate).format('l')}
                  </span>
                </TableCell>
                <TableCell>
                  <span className="tour-invoice-vendor">
                    {invoice.vendor?.name}
                  </span>
                </TableCell>
                <TableCell align="right">
                  <span className="tour-invoice-total">
                    {currencyFormatter(invoice?.totalAmountDue ?? 0)}
                  </span>
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell
              colSpan={6}
              align="center"
              style={{
                verticalAlign: 'middle',
                alignItems: 'center',
                fontSize: '16px',
                height: '600px',
                border: 'none',
              }}
            >
              {strings.NO_INVOICE_DATA}
              <Link
                color="textPrimary"
                href="#"
                underline="always"
                onClick={(e) => {
                  e.preventDefault();
                  navigateToTasks();
                }}
                style={{ fontWeight: 600 }}
                className="invoice-nav-to-task"
              >
                {strings.NO_INVOICE_DATA_CREATE_NEW}
              </Link>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default InvoiceTable;
