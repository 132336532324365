import { useMemo } from 'react';

import { permissions, strings } from 'common';
import Button from 'components/shared/Button';
import PermissionsGate from 'components/shared/PermissionsGate';
import { useTaskPermissions } from 'hooks';
import { Task, TaskStatus } from 'models';
import { canRequestTask } from 'utils/tasks';

interface RequestApprovalButtonProps {
  selectedIds: string[];
  tasks: Task[];
  loading: boolean;
  onClick: (status: TaskStatus) => void;
}

const RequestApprovalButton: React.FC<RequestApprovalButtonProps> = (props) => {
  const { selectedIds, tasks, loading, onClick } = props;
  const { hasTaskApprovePermission } = useTaskPermissions();

  const disabled = useMemo(() => {
    if (!selectedIds.length) {
      return true;
    }
    return !selectedIds.every((id) => {
      const task = tasks.find((task) => task.id === id);
      return canRequestTask(task!, hasTaskApprovePermission);
    });
  }, [selectedIds, tasks, hasTaskApprovePermission]);

  return (
    <PermissionsGate permissions={[permissions.INVENTORY_VDP_TASKS_CREATE]}>
      <Button
        className="FlatTasks-action-button FlatTasks-action-button-request"
        id="request-approval-task-button"
        type="button"
        variant="warning"
        onClick={() => onClick('PENDING_APPROVAL')}
        disabled={disabled}
        loading={loading}
      >
        {strings.REQUEST_APPROVAL}
      </Button>
    </PermissionsGate>
  );
};

export default RequestApprovalButton;
