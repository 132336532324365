import { HttpMethod } from 'api';

import { extensionStrings } from '../common/constants';

export const EXTENSION_SOURCE = 'extension';

type ExtensionFeature =
  | 'auto_refresh'
  | 'extension_fetch'
  | 'extension_fetch_plain_text';

export interface NewHoverTargetMessage {
  vehicleId: string;
  locationId: string;
}

export interface CacheVehiclesMessage {
  vehicleIds: string[];
  locationId: string;
}

export const ID_LENGTH = 24;
const LEGACY_ROOFTOP_PREFIX_ID_LENGTH = 'ROOFTOP-'.length;
const LOCATION_ID_LENGTH = LEGACY_ROOFTOP_PREFIX_ID_LENGTH + ID_LENGTH;
const WAT_ID_LENGTH = 36;

export const defaultSupportedExtensionIds = [
  'nmnbkeffccomlhaepaaiglmolinmjapm',
  'mcgepijpaooibppegecokeodkmanncij',
  'mkanlikcnccbgabpaphgjidigdjnekab',
] as const;

export function getExtensionModeInfo(): {
  enabled: boolean;
  features: Record<ExtensionFeature, boolean>;
} {
  const params = new URLSearchParams(window.location.search);
  const extensionVersion =
    params.get('extensionVersion') || params.get('sourceVersion');
  return {
    enabled: !!extensionVersion,
    features: {
      extension_fetch_plain_text: isMinimumVersionSupported(
        extensionVersion,
        '0.53.0'
      ),
      extension_fetch: isMinimumVersionSupported(extensionVersion, '0.43.0'),
      auto_refresh: isMinimumVersionSupported(extensionVersion, '0.5.0'),
    },
  };
}

const isMinimumVersionSupported = (
  currentVersion: string | null,
  expectedVersion: string
) => {
  if (currentVersion == null) {
    return false;
  }
  if (currentVersion === expectedVersion) {
    return true;
  }

  return (
    currentVersion.localeCompare(expectedVersion, undefined, {
      numeric: true,
    }) === 1
  );
};

type ExtensionMessage =
  | {
      action: 'ping';
    }
  | {
      action: 'fetch';
      url: string;
      headers?: Record<string, string>;
      method?: HttpMethod;
      body?: string;
    };
export async function sendMessageToExtensionAsync(
  message: ExtensionMessage
): Promise<any> {
  const chromeRuntime = window?.chrome?.runtime;
  const browserRuntime = (window as any)?.browser?.runtime;
  try {
    if (chromeRuntime) {
      return new Promise((resolve) => {
        return chromeRuntime.sendMessage(
          process.env.REACT_APP_EXTENSION_ID ?? '',
          message,
          {},
          (message) => {
            resolve(message);
          }
        );
      });
    } else if (browserRuntime) {
      return browserRuntime.sendMessage(
        process.env.REACT_APP_EXTENSION_ID,
        message,
        {}
      );
    }
  } catch (e: any) {
    window.newrelic?.noticeError(e);
  }
}

interface ExtensionContentScriptMessage {
  action: 'extension_card_loaded' | 'extension_card_clicked';
}
/**
 * This function sends a 'message' event to the window that contains this iframe. It is used to send messages to the extension content script.
 * @param message
 */
export function sendMessageToExtensionContentScript(
  message: ExtensionContentScriptMessage
) {
  window.top?.postMessage(message, '*');
}

export function sanitizeWindowPostMessage(
  event: MessageEvent
): NewHoverTargetMessage | CacheVehiclesMessage | undefined {
  switch (event.data.action) {
    case extensionStrings.EXTENSION_EVENT_NEW_HOVER_TARGET: {
      // Verify vehicleId and locationId are strings and valid length
      const { vehicleId, locationId } = event.data;
      const result: NewHoverTargetMessage = { vehicleId: '', locationId: '' };

      const locationIdLengthValid =
        locationId.length === ID_LENGTH || // Required for users of extension v0.5
        locationId.length === LOCATION_ID_LENGTH || // Required for legacy users
        locationId.length === WAT_ID_LENGTH; // Required for wat users
      const vehicleIdValid =
        typeof vehicleId === 'string' &&
        (vehicleId.length === ID_LENGTH || vehicleId.length === WAT_ID_LENGTH);
      const locationIdValid =
        typeof locationId === 'string' && locationIdLengthValid;
      if (vehicleIdValid && locationIdValid) {
        result.vehicleId = vehicleId;
        result.locationId = locationId;

        return result;
      }

      return undefined;
    }
    case extensionStrings.EXTENSION_EVENT_CACHE_VEHICLES: {
      const { vehicleIds, locationId } = event.data;
      const result: CacheVehiclesMessage = { vehicleIds: [], locationId: '' };

      // Verify all vehicleIds are strings and valid length
      const vehicleIdsValid = vehicleIds.reduce(
        (idsValid: boolean, vehicleId: any) => {
          const vehicleIdValid =
            typeof vehicleId === 'string' &&
            (vehicleId.length === ID_LENGTH ||
              vehicleId.length === WAT_ID_LENGTH);
          if (!vehicleIdValid) {
            idsValid = false;
            return idsValid;
          }

          result.vehicleIds.push(vehicleId);
          return idsValid;
        },
        true
      );

      const locationIdLengthValid =
        locationId.length === ID_LENGTH || // Required for users of extension v0.5
        locationId.length === LOCATION_ID_LENGTH || // Required for legacy users
        locationId.length === WAT_ID_LENGTH; // Required for wat users
      const locationIdValid =
        typeof locationId === 'string' && locationIdLengthValid;
      if (!vehicleIdsValid && !locationIdValid) {
        return undefined;
      }

      result.locationId = locationId;
      return result;
    }
    default:
      return undefined;
  }
}
