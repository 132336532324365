import { Router, useLocation } from '@reach/router';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';

import { removeUUIDs } from 'api/authenticate/urlManagement';
import CarfaxAuthRedirect from 'components/pages/Carfax/CarfaxAuthRedirect';
import ChangePassword from 'components/pages/ChangePassword';
import ForgotPassword from 'components/pages/ForgotPassword';
import SingleSignOn from 'components/pages/ForgotPassword/SingleSignOn';
import Login from 'components/pages/Login';
import VehicleCardExtension from 'components/pages/VehicleCollectionBrowser/VehicleCard/VehicleCardExtension';
import { VelocityInsightVehicleDetails } from 'components/pages/VehicleDetails/VelocityInsightVehicleDetails';
import VelocityAutomotiveVinScanner from 'components/pages/VelocityAutomotiveVinScanner';

import ProtectedRoute from './ProtectedRoute';
import {
  CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  HANDLE_AUTH_REDIRECT,
  LOGIN,
  SSO,
  VELOCITY_AUTOMOTIVE_VIN_SCANNER,
  VELOCITY_INSIGHT,
} from './routes';
import TenantUserSessionRouter from './TenantUserSessionRouter';

function useSendPageviews() {
  const { pathname } = useLocation();
  useEffect(() => {
    // Send pageview without ids to analytics
    const page = removeUUIDs(pathname, true);
    if (window.recon.gtmInit && !!page) {
      ReactGA.send({ hitType: 'pageview', page });
    }
  }, [pathname]);
}

const AppRouter: React.FC = () => {
  useSendPageviews();
  return (
    <Router>
      <ForgotPassword path={FORGOT_PASSWORD} />
      <ChangePassword path={CHANGE_PASSWORD} />
      <Login path={LOGIN} />
      <VelocityAutomotiveVinScanner path={VELOCITY_AUTOMOTIVE_VIN_SCANNER} />
      <VehicleCardExtension
        path={`${VELOCITY_INSIGHT}/tenant/:tenantId/inventory/:inventoryId/card`}
      />
      <VelocityInsightVehicleDetails
        path={`${VELOCITY_INSIGHT}/tenant/:tenantId/inventory/:inventoryId/details/*`}
      />
      <CarfaxAuthRedirect path={HANDLE_AUTH_REDIRECT} />
      <SingleSignOn path={SSO} />
      <ProtectedRoute default component={TenantUserSessionRouter} />
    </Router>
  );
};

export default AppRouter;
