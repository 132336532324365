import { FC } from 'react';
import { Accordion } from 'react-bootstrap';

import { strings } from 'common';
import DownloadMenu, {
  ReportType,
} from 'components/shared/DownloadMenu/DownloadMenu';
import StickyContainer from 'components/shared/StickyContainer';
import { DealerStepReportPayload, DealerStepReportSummaryItem } from 'models';

import StepRow from './StepRow';

interface StepSummaryProps {
  dealerStepReport: DealerStepReportPayload;
  isDownloading: boolean;
  downloadFileUrl: string;
  setSelectedReportType: (type: ReportType | null) => void;
  openStepModal: (step: DealerStepReportSummaryItem | null) => void;
}

const StepSummary: FC<StepSummaryProps> = ({
  dealerStepReport,
  isDownloading,
  downloadFileUrl,
  setSelectedReportType,
  openStepModal,
}) => {
  const { summaryItems } = dealerStepReport;

  const totalNumberOfVehicles =
    summaryItems?.reduce((acc, item) => acc + item.totalVehicles, 0) ?? 0;

  return (
    <div className="DealerStepReport-StepSummary">
      <div className="DealerStepReport-title">
        {strings.STEP_SUMMARY}
        {totalNumberOfVehicles > 0 && (
          <DownloadMenu
            downloadFileUrl={downloadFileUrl}
            onSelectedMenuType={setSelectedReportType}
            isLoading={isDownloading}
            formats={['EXCEL', 'PDF']}
          />
        )}
      </div>
      <div className="DealerStepReport-StepSummary-table">
        <StickyContainer>
          <div className="DealerStepReport-StepSummary-summary-row-header border-bottom">
            <div className="evenly-spaced-container">
              <div className="evenly-spaced-item step">{strings.STEP}</div>
              <div className="evenly-spaced-item">{strings.VEHICLES}</div>
              <div className="evenly-spaced-item">
                {strings.AVG_RETAIL_READY_TIME}
              </div>
              <div className="evenly-spaced-item">{strings.AVG_RECON_TIME}</div>
            </div>
          </div>
        </StickyContainer>
        <div className="DealerStepReport-StepSummary-summary-body">
          {summaryItems?.map(
            (step: DealerStepReportSummaryItem, index: number) =>
              step.childSteps?.length ? (
                <Accordion className="StepAccordion" key={step.stepId}>
                  <Accordion.Toggle eventKey={step.stepId}>
                    <StepRow
                      step={step}
                      customStyle={'can-hover'}
                      showArrow={true}
                      openStepModal={openStepModal}
                    />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={step.stepId}>
                    <div className="DealerStepReport-StepSummary-summary-body-ChildSteps">
                      {step.childSteps.map(
                        (childStep: DealerStepReportSummaryItem) => (
                          <StepRow
                            key={`step-row-${childStep.stepId}`}
                            step={childStep}
                            openStepModal={openStepModal}
                          />
                        )
                      )}
                    </div>
                  </Accordion.Collapse>
                </Accordion>
              ) : (
                <StepRow
                  key={step.stepId}
                  step={step}
                  customStyle={'border-bottom'}
                  showArrow={false}
                  openStepModal={openStepModal}
                />
              )
          )}
        </div>
      </div>
    </div>
  );
};

export default StepSummary;
