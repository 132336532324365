import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { navigate } from '@reach/router';
import { useCallback, useMemo } from 'react';

import { useUpdateVehicleTasksStatus } from 'api';
import TextCopy from 'components/shared/Inputs/TextCopy/TextCopy';
import ActionMenu from 'components/shared/TaskActionMenu';
import TaskStatusComp from 'components/shared/TaskStatus/TaskStatus';
import { Task, TaskStatus, VehicleSummary } from 'models';
import { tasksBuilder } from 'navigation/routes';
import { currencyFormatter } from 'utils/formatter';
import { getUsersFullName } from 'utils/user';

import './TasksTable.scss';

let taskCount: number;

const VehicleColProps = {
  className: 'TasksTable-container-vehicle',
};
const TaskColProps = {
  className: 'TasksTable-container-task',
};
const DescriptionColProps = {
  className: 'TasksTable-container-description',
};
const StatusColProps = {
  className: 'TasksTable-container-status',
};
const StatusByColProps = {
  className: 'TasksTable-container-status-by',
};
const AssignedToColProps = {
  className: 'TasksTable-container-assigned-to',
};
const LaborRateColProps = {
  align: 'right' as 'right',
  className: 'TasksTable-container-labor-rate',
};
const LaborHoursColProps = {
  align: 'right' as 'right',
  className: 'TasksTable-container-labor-hours',
};
const LaborColProps = {
  align: 'right' as 'right',
  className: 'TasksTable-container-labor',
};
const PartsColProps = {
  align: 'right' as 'right',
  className: 'TasksTable-container-parts',
};
const TotalColProps = {
  align: 'right' as 'right',
  className: 'TasksTable-container-total',
};
const ActionColProps = {
  className: 'TasksTable-container-action',
};

const handleOnClick = (vehicleId: string) => {
  navigate(`${tasksBuilder(vehicleId)}`);
};

const VehicleInfo = ({ vehicle }: { vehicle: VehicleSummary }) => {
  return (
    <div className="TasksTable-VehicleInfo-container">
      <div className="TasksTable-VehicleInfo-year-make-model">
        {vehicle.vehicleCard.year} {vehicle.vehicleCard.make}{' '}
        {vehicle.vehicleCard.model}
      </div>
      <div className="TasksTable-VehicleInfo-vin">
        <TextCopy value={vehicle.vehicleCard.vin} />
      </div>
    </div>
  );
};

const VehicleSummaryComponent = ({
  vehicle,
  tasks,
}: {
  vehicle: VehicleSummary;
  tasks: Task[];
}) => {
  const { mutate: updateVehicleTasksStatus } = useUpdateVehicleTasksStatus(
    vehicle?.vehicleCard?.id
  );
  const handleTaskClick = useCallback(
    (task: Task, status: TaskStatus): void => {
      if (task.id) {
        updateVehicleTasksStatus({
          taskIds: [task.id],
          status,
        });
      }
    },
    [updateVehicleTasksStatus]
  );

  const handleAllTasksClick = useCallback(
    (status: TaskStatus, targetStatus: TaskStatus) => {
      updateVehicleTasksStatus({
        taskIds: tasks
          .filter((task) => task.status === status)
          .map((task) => task.id ?? ''),
        status: targetStatus,
      });
    },
    [tasks, updateVehicleTasksStatus]
  );

  const taskComponents = useMemo(() => {
    return vehicle.tasks?.map((task, index) => {
      const isVehicleInfoRow = index === 0;
      const rowClass =
        taskCount % 2 === 0 ? 'TasksTable-container-even-row' : '';
      taskCount++;

      return (
        <TableRow key={task.id} className={rowClass}>
          <TableCell
            {...VehicleColProps}
            onClick={() => handleOnClick(vehicle.vehicleCard.id!)}
          >
            {isVehicleInfoRow && <VehicleInfo vehicle={vehicle} />}
          </TableCell>
          <TableCell {...TaskColProps}>{task.label}</TableCell>
          <TableCell {...DescriptionColProps}>{task.notes}</TableCell>
          <TableCell {...StatusColProps}>
            <TaskStatusComp taskStatus={task.status} />
          </TableCell>
          <TableCell {...StatusByColProps}>
            {getUsersFullName('', task.statusBy)}
          </TableCell>
          <TableCell {...AssignedToColProps}>
            {getUsersFullName('', task.assignedTo)}
          </TableCell>
          <TableCell {...LaborRateColProps}>
            {currencyFormatter(task.laborRate?.amount ?? 0)}
          </TableCell>
          <TableCell {...LaborHoursColProps}>{task.laborHours}</TableCell>
          <TableCell {...LaborColProps}>
            {currencyFormatter(task.laborPrice?.amount ?? 0)}
          </TableCell>
          <TableCell {...PartsColProps}>
            {currencyFormatter(task.partsPrice?.amount ?? 0)}
          </TableCell>
          <TableCell {...TotalColProps}>
            {currencyFormatter(task.price?.amount ?? 0)}
          </TableCell>
          <TableCell {...ActionColProps}>
            <ActionMenu
              task={task}
              vehicleId={vehicle?.vehicleCard?.id}
              onRequestClick={(task: Task) => {
                handleTaskClick(task, 'PENDING_APPROVAL');
              }}
              onApproveClick={(task: Task) =>
                handleTaskClick(task, 'APPROVED_PENDING_COMPLETE')
              }
              onApproveAllClick={(status: TaskStatus) =>
                handleAllTasksClick(status, 'APPROVED_PENDING_COMPLETE')
              }
              onDenyClick={(task: Task) => handleTaskClick(task, 'DENIED')}
              onDenyAllClick={(status: TaskStatus) =>
                handleAllTasksClick(status, 'DENIED')
              }
              onCompleteClick={(task: Task) =>
                handleTaskClick(task, 'COMPLETE')
              }
              allText="For Vehicle"
            />
          </TableCell>
        </TableRow>
      );
    });
  }, [handleAllTasksClick, handleTaskClick, vehicle]);

  return <TableBody>{taskComponents}</TableBody>;
};

const TasksTableContainer = ({
  inventory,
}: {
  inventory: VehicleSummary[];
}) => {
  const VehicleSummaries = useMemo(() => {
    taskCount = 0;
    return inventory.map((vehicle) => (
      <VehicleSummaryComponent
        key={vehicle.vehicleCard.id}
        vehicle={vehicle}
        tasks={vehicle.tasks || []}
      />
    ));
  }, [inventory]);

  return (
    <TableContainer>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <TableCell {...VehicleColProps}>Vehicle</TableCell>
            <TableCell {...TaskColProps}>Task</TableCell>
            <TableCell {...DescriptionColProps}>Description</TableCell>
            <TableCell {...StatusColProps}>Status</TableCell>
            <TableCell {...StatusByColProps}>Status By</TableCell>
            <TableCell {...AssignedToColProps}>Assigned To</TableCell>
            <TableCell {...LaborRateColProps}>Rate</TableCell>
            <TableCell {...LaborHoursColProps}>Hours</TableCell>
            <TableCell {...LaborColProps}>Labor</TableCell>
            <TableCell {...PartsColProps}>Parts</TableCell>
            <TableCell {...TotalColProps}>Total</TableCell>
            <TableCell {...ActionColProps}></TableCell>
          </TableRow>
        </TableHead>
        {VehicleSummaries}
      </Table>
    </TableContainer>
  );
};

type TasksTableProps = {
  inventory: VehicleSummary[];
};
const TasksTable: React.FC<TasksTableProps> = ({ inventory }) => {
  return (
    <div className="TasksTable-container">
      <TasksTableContainer inventory={inventory} />
    </div>
  );
};

export default TasksTable;
