/* eslint-disable jsx-a11y/media-has-caption */
import { FC, useContext, useState } from 'react';

import { useDeleteGalleryMedia, useUpdateVehicleImage } from 'api';
import { hasImageContentType, isImage } from 'common/images';
import strings from 'common/strings';
import { testIds } from 'common/testIds';
import { FlatMediaContext } from 'components/pages/FlatMedia/FlatMedia';
import { useMediaUploadContext } from 'components/pages/FlatMedia/UploadProvider';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import ModalCarousel from 'components/shared/ModalCarousel';
import S3UploadPlaceholder from 'components/shared/S3UploadPlaceholder';
import { TabProps } from 'components/shared/Tabs/Tabs';
import VehicleImageView from 'components/shared/VehicleImageView';
import { VehicleImage, VehicleSummary } from 'models';
import { replaceShots } from 'utils/photos';

import './Gallery.scss';

interface IGallery extends TabProps {
  vehicle: VehicleSummary;
}

const Gallery: FC<IGallery> = ({ vehicle }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [galleryIndex, setGalleryIndex] = useState(0);
  const { deleteImageAsync } = useDeleteGalleryMedia();
  const { mutate: updateVehicleImage } = useUpdateVehicleImage();
  const { images, isLoading, onImageError } = useContext(FlatMediaContext);
  const { submittedMedia } = useMediaUploadContext();

  const activeUploads = submittedMedia.filter(
    (media) => media.vehicleId === vehicle.vehicleCard.id
  );

  let imagesLoadedState = images.reduce((acc: any, image) => {
    acc[image.id ?? ''] = false;
    return acc;
  }, {});

  // TODO Pending to support after removing mobx on FlatMedia component
  const allImagesLoaded = true; // isLoading ? false : Object.keys(imagesLoadedState).every((imageId: string) => imagesLoadedState[imageId])

  const openModalCarousel = (index: number) => {
    setGalleryIndex(index || 0);
    setModalIsOpen(true);
  };

  const closeModalCarousel = () => {
    setModalIsOpen(false);
  };

  const renderGalleryPhoto = (image: VehicleImage, index: number) => {
    const containerClassName = [
      'gallery-vehicle-image-container',
      'align-items-center',
      'justify-center',
      image?.imageType === 'STOCK' ? 'white-background' : '',
      !allImagesLoaded ? 'Gallery-hidden-image' : 'flex-rows',
    ].join(' ');
    if (isImage(image?.uri) || hasImageContentType(image.contentType)) {
      return (
        <div
          key={`gallery-photo-row-${image.id}`}
          className={containerClassName}
          onClick={() => openModalCarousel(index)}
          role="link"
          tabIndex={-1}
        >
          <VehicleImageView
            className="Gallery-image-view"
            imageUrl={image.uri}
            size="square"
            onError={() => onImageError(index)}
            onLoad={() =>
              (imagesLoadedState = { ...imagesLoadedState, [image.id!]: true })
            }
          />
        </div>
      );
    }
    return (
      <div
        key={`gallery-photo-row-${image.id}`}
        className={containerClassName}
        onClick={() => openModalCarousel(index)}
        role="link"
        tabIndex={-1}
      >
        <div className="Gallery-image-view">
          <div className="Gallery-image-view-video">
            <video src={image.uri} />
          </div>
        </div>
      </div>
    );
  };

  const onDeleteImage = async (id: string) => {
    const image = images.find(
      (vehicleImage: VehicleImage) => vehicleImage.id === id
    );
    if (image) {
      await deleteImageAsync({
        vehicleId: vehicle?.vehicleCard?.id!,
        mediaId: id,
        mediaUrl: image.uri,
        mediaContentType: image.contentType,
      });
      if (images.length > 0) {
        setGalleryIndex(0);
      } else {
        setModalIsOpen(false);
      }
    }
  };

  const onAssignImage = async (id: string, shot: string) => {
    const image = images.find(
      (vehicleImage: VehicleImage) => vehicleImage.id === id
    );

    if (!image) return;

    const existingShot = images.find(
      (vehicleImage: VehicleImage) => vehicleImage.shot === shot
    );
    if (existingShot) {
      await replaceShots(
        existingShot as VehicleImage,
        image,
        vehicle?.vehicleCard?.id ?? '',
        updateVehicleImage
      );
    } else {
      updateVehicleImage({
        vehicleId: vehicle?.vehicleCard?.id!,
        imageId: image.id!,
        shot,
      });
    }
  };

  return (
    <div
      data-vas-testing={testIds.MEDIA_GALLERY_VEHICLE_IMAGE_CONTAINER}
      className="Gallery pane-content full-height full-width"
    >
      <div className="flex-columns flex-wrap pb-5">
        {activeUploads.map((media) => (
          <div className="gallery-vehicle-image-container flex-rows align-items-center justify-center">
            <S3UploadPlaceholder attachment={media} large showOverlay />
          </div>
        ))}
        {isLoading && <LoadingIndicator />}
        {!isLoading && images.length === 0 ? (
          <div className="Gallery-empty">{strings.IMAGES_EMPTY}</div>
        ) : (
          images.map((image: VehicleImage, index) =>
            renderGalleryPhoto(image, index)
          )
        )}
      </div>
      <ModalCarousel
        modalIsOpen={modalIsOpen}
        currentIndex={galleryIndex}
        images={images}
        onClose={closeModalCarousel}
        onDeleteImage={onDeleteImage}
        onAssignImage={onAssignImage}
      />
    </div>
  );
};

export default Gallery;
