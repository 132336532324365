import { Step } from 'react-joyride';

import { styles } from 'components/shared/Tour';

import imageDesktopStep1 from './images/desktop/step1.png';
import imageDesktopStep2 from './images/desktop/step2.png';
import imageDesktopStep3 from './images/desktop/step3.png';
import imageDesktopStep4 from './images/desktop/step4.png';
import imageDesktopStep5 from './images/desktop/step5.png';
import imageMobileStep1 from './images/mobile/step1.png';
import imageMobileStep2 from './images/mobile/step2.png';
import imageMobileStep3 from './images/mobile/step3.png';
import imageMobileStep4 from './images/mobile/step4.png';
import imageMobileStep5 from './images/mobile/step5.png';

export const name: string = 'Create Invoice Tour';

export const steps: Step[] = [
  {
    target: '.tour-main',
    title: 'Create Invoice Tour',
    content:
      "Welcome to the vehicle tasks page.  Let's take a quick tour to familiarize you with tasks and creating invoices.",
    disableBeacon: true,
    placement: 'center',
    styles: {
      ...styles,
      tooltipContainer: {
        textAlign: 'left',
      },
    },
    locale: {
      next: 'Start',
    },
  },
  {
    content: "The tasks list shows all vendor's tasks assigned to the vehicle",
    target: '.tour-carousel',
    spotlightPadding: 0,
  },
  {
    content: 'Click ADD button to add a task to the vehicle',
    target: '.tour-carousel',
    spotlightPadding: 0,
  },
  {
    content: 'When the task is complete and selected an invoice can be created',
    target: '.tour-carousel',
    spotlightPadding: 0,
  },
  {
    content: 'Enter invoice number and then send invoice to customer',
    target: '.tour-carousel',
    spotlightPadding: 0,
  },
  {
    content: 'Invoice has been successfully sent',
    target: '.tour-carousel',
    spotlightPadding: 0,
    locale: {
      last: 'Close ',
    },
  },
];

export const images = [
  {
    alt: 'step 1',
    mobileSrc: imageMobileStep1,
    desktopSrc: imageDesktopStep1,
  },
  {
    alt: 'step 2',
    mobileSrc: imageMobileStep2,
    desktopSrc: imageDesktopStep2,
  },
  {
    alt: 'step 3',
    mobileSrc: imageMobileStep3,
    desktopSrc: imageDesktopStep3,
  },
  {
    alt: 'step 4',
    mobileSrc: imageMobileStep4,
    desktopSrc: imageDesktopStep4,
  },
  {
    alt: 'step 5',
    mobileSrc: imageMobileStep5,
    desktopSrc: imageDesktopStep5,
  },
];
