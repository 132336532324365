import { useMutation } from '@tanstack/react-query';
import { merge } from 'lodash';

import { defaultMutationFn, getAPIHeaders } from 'api';

type ResetPasswordErrorCode =
  | 'EXPIRED_TOKEN'
  | 'INVALID_TOKEN'
  | 'USED_TOKEN'
  | 'INVALID_NEW_PASSWORD';

type ResetPasswordErrorPayload = {
  error: ResetPasswordErrorCode;
  message: string;
};

export class ResetPasswordError extends Error {
  error: ResetPasswordErrorCode;
  message: string;

  constructor({ error, message }: ResetPasswordErrorPayload) {
    super(message);
    this.name = 'ResetPasswordError';
    this.error = error;
    this.message = message;
  }
}

export function useResetPassword({
  passwordToken,
  newPassword,
}: {
  passwordToken: string;
  newPassword: string;
}) {
  return useMutation(async () => {
    const headers = await getAPIHeaders('POST');
    const requestOptions: RequestInit = merge<RequestInit, RequestInit>(
      headers?.options,
      {
        body: JSON.stringify({
          passwordToken,
          newPassword,
        }),
      }
    );

    const response = await fetch(
      `${headers.host}/authenticate/resetPassword`,
      requestOptions
    );
    if (!response.ok) {
      const errorPayload = await response.json();
      throw new ResetPasswordError(errorPayload);
    }
  });
}

export function useValidateResetPasswordToken(passwordToken: string | null) {
  return useMutation(async () => {
    const headers = await getAPIHeaders('POST');
    const requestOptions: RequestInit = merge<RequestInit, RequestInit>(
      headers?.options,
      {
        body: JSON.stringify({
          passwordToken,
        }),
      }
    );

    const response = await fetch(
      `${headers.host}/authenticate/validateResetPasswordToken`,
      requestOptions
    );
    if (!response.ok) {
      const errorPayload = await response.json();
      throw new ResetPasswordError(errorPayload);
    }
  });
}

export function useChangePassword({
  oldPassword,
  newPassword,
}: {
  oldPassword: string;
  newPassword: string;
}) {
  return useMutation({
    mutationFn: () => {
      return defaultMutationFn('/authenticate/resetPassword', 'POST', {
        oldPassword,
        newPassword,
      });
    },
  });
}
