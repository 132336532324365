import { navigate } from '@reach/router';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { configuredFetch } from 'api/base';
import { clearCredentials } from 'api/lib/credentialsPersistence';

import { getTokenWithExistingServiceWorker } from '../../firebase';

export const logoutActions = async () => {
  await logout();
  clearCredentials();
  navigate('/');
};

export function useLogoutMutation() {
  const queryClient = useQueryClient();
  return useMutation(async () => {
    await logoutActions();
    queryClient.clear();
  });
}

/**
 * Invalidate Bearer token by calling AppService's logout method
 */
const logout = async () => {
  const options = { method: 'POST', body: {} };
  try {
    const deviceId = await getTokenWithExistingServiceWorker();
    if (deviceId) {
      options.body = JSON.stringify({ deviceId, deviceType: 'WEB' });
    }
    await configuredFetch('/authenticate/logout', options);
  } catch (error) {
    console.error('Error logging out', error);
  }
};
