import { FC, useMemo, useState } from 'react';

import ActiveUploadsContext, {
  AttachmentUploadContextData,
} from './AttachmentUploadContext';
import {
  AttachmentStatus,
  StagedAttachment,
  SubmittedAttachment,
} from './types';

const AttachmentUploadProvider: FC = ({ children }) => {
  const [stagedAttachments, setStagedAttachments] = useState<
    StagedAttachment[]
  >([]);
  const [submittedAttachments, setSubmittedAttachments] = useState<
    SubmittedAttachment[]
  >([]);

  const stageFiles = (files: File[]) => {
    const toStageAttachments = files.map((file) => {
      return { file, status: 'pending' } as StagedAttachment;
    });
    setStagedAttachments((attachments) => {
      return [...attachments, ...toStageAttachments];
    });
  };

  const setAttachmentState = (
    index: number,
    status: AttachmentStatus,
    progress: number
  ) => {
    setStagedAttachments((attachments) => {
      return attachments.map((mapState, mapIndex) => {
        if (mapIndex !== index) {
          return mapState;
        }
        return {
          ...mapState,
          status,
          progress,
        } as StagedAttachment;
      });
    });
  };

  const context: AttachmentUploadContextData = useMemo(() => {
    return {
      stagedAttachments,
      submittedAttachments,
      setStagedAttachments,
      setSubmittedAttachments,
      setAttachmentState,
      stageFiles,
    };
  }, [stagedAttachments, submittedAttachments]);

  return (
    <ActiveUploadsContext.Provider value={context}>
      {children}
    </ActiveUploadsContext.Provider>
  );
};

export default AttachmentUploadProvider;
