import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, {
  defaultSuggestionsFilter,
  MentionData,
} from '@draft-js-plugins/mention';
import {
  ContentState,
  convertFromRaw,
  EditorState,
  getDefaultKeyBinding,
} from 'draft-js';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useTeams, useUsers } from 'api';
import strings from 'common/strings';
import { TeamInfo, TeamType, User } from 'models';
import { getFullName } from 'utils/user';

import { convertStringToRawState } from '../utils';
import { getRawContent, MentionConfig } from './utils';

type SyntheticKeyboardEvent = React.KeyboardEvent<{}>;

interface MentionableInputProps {
  onChange: (textBlob: string) => void;
  onFocus?: () => void;
  onBlur?: (e: any) => void;
  submitAction: () => void;
  shouldClearContent: boolean;
  existingContent?: string;
  shouldFocus?: boolean;
  disabled?: boolean;
}

const MentionableInput: React.FC<
  React.PropsWithChildren<MentionableInputProps>
> = ({
  onChange,
  onFocus,
  onBlur,
  shouldClearContent,
  submitAction,
  existingContent,
  disabled,
  shouldFocus = false,
}) => {
  const refEditor = useRef<Editor>(null);
  const [suggestions, setSuggestions] = useState<MentionData[]>([]);
  const { data: userData } = useUsers();
  const teamData = useTeams();

  const [editorState, setEditorState] = useState<EditorState>(() => {
    if (!existingContent) return EditorState.createEmpty();

    const rawContent = convertStringToRawState(existingContent);
    const fromRaw = convertFromRaw(rawContent);
    return EditorState.createWithContent(fromRaw);
  });
  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      ...MentionConfig,
      mentionComponent: ({ className, children, mention }) => {
        const mentionData = mention as MentionData & TeamInfo;
        return mentionData.type === 'user' ? (
          <span className={className}>{children}</span>
        ) : (
          <>
            <span className={className}>@{mentionData.name}</span>{' '}
            <span className={className}>{mentionData.teamNames}</span>
          </>
        );
      },
    });
    const { MentionSuggestions } = mentionPlugin;
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  const [open, setOpen] = useState(false);

  const onOpenChange = useCallback((open: boolean) => {
    setOpen(open);
  }, []);

  const suggestionsData = useMemo(
    () => [
      ...(userData?.data
        ?.filter(
          (user: User) =>
            user.id &&
            !user.company &&
            (user.companyName || user.firstName || user.lastName)
        )
        .map((user) => ({
          id: user.id,
          name: getFullName(user),
          type: 'user' as TeamType,
        })) ?? []),
      ...(teamData?.data?.data?.map((team) => ({
        id: team.id,
        name: team.teamName,
        teamNames: `(${team.users
          .reduce(
            (names, currentUser) => names + `@${getFullName(currentUser)} `,
            ''
          )
          .trim()})`,
        // Replace spaces with unicode character NO_BREAK SPACE (\u00A0) to support highlighting team name with multiple words
        teamNamesWithId: `${team.teamName.replace(
          /\s/gi,
          '\u00A0'
        )} (${team.users
          .reduce(
            (names, currentUser) =>
              names + `@[${currentUser.id}:${getFullName(currentUser)}] `,
            ''
          )
          .trim()})`,
        users: team.users,
        type: 'team' as TeamType,
      })) ?? []),
    ],
    [teamData?.data?.data, userData?.data]
  );

  const handleChange = (editorState: EditorState) => {
    setEditorState(editorState);
    onChange(getRawContent(editorState.getCurrentContent()));
  };

  const handleFocus = () => (onFocus ? onFocus() : null);
  const handleBlur = (e: any) => (onBlur ? onBlur(e) : null);

  useEffect(() => {
    if (shouldClearContent) {
      setEditorState((editor) => {
        const newEditorState = EditorState.push(
          editor,
          ContentState.createFromText(''),
          'remove-range'
        );
        return EditorState.moveFocusToEnd(newEditorState);
      });
    }
  }, [shouldClearContent]);

  useEffect(() => {
    if (shouldFocus) {
      setTimeout(() => {
        refEditor?.current?.focus();
        onChange(getRawContent(editorState.getCurrentContent()));
      }, 50);
    } else {
      refEditor?.current?.blur();
    }
  }, [editorState, onChange, shouldFocus]);

  const onSearchChange = async ({ value }: any) => {
    setSuggestions(defaultSuggestionsFilter(value, suggestionsData));
  };

  function handleKeyBinding(event: SyntheticKeyboardEvent): string | null {
    const { keyCode, shiftKey } = event;
    const emptyInput =
      getRawContent(editorState.getCurrentContent()).trim().length === 0;

    if (keyCode === 13 && emptyInput) {
      return null;
    }

    if (keyCode === 13 && !shiftKey) {
      submitAction();
      return null;
    }

    return getDefaultKeyBinding(event);
  }

  return (
    <div>
      <MentionSuggestions
        open={open}
        onOpenChange={onOpenChange}
        onSearchChange={onSearchChange}
        suggestions={suggestions}
      />
      <div className="mentionable-input-editor">
        <Editor
          readOnly={disabled}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={strings.NOTES_PLACEHOLDER}
          editorState={editorState}
          onChange={handleChange}
          plugins={plugins}
          ref={refEditor}
          keyBindingFn={handleKeyBinding}
        />
      </div>
    </div>
  );
};

export default MentionableInput;
