import React, { useCallback, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';

import { useUpdateReconInfo } from 'api';
import strings from 'common/strings';
import { TextInput } from 'components/shared/Inputs';
import { VehicleSummary } from 'models';
import Money, { Currency } from 'models/money';
import { isCorrectNumberLength, isPriceFormat, removeMoneySign } from 'utils';
import { currencyFormatter, deleteCommas, parseToFloat } from 'utils/formatter';

import './EditReconInfoModal.scss';

interface EditReconInfoModalProps {
  onClose: () => void;
  onSave?: () => void;
  vehicleSummary: VehicleSummary;
}

const EditReconInfoModal: React.FC<EditReconInfoModalProps> = ({
  onClose,
  vehicleSummary,
}) => {
  const updateReconInfoMutation = useUpdateReconInfo(
    vehicleSummary.vehicleCard?.id
  );
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState({
    appraisedValue: '0',
    estimatedRecon: '0',
    initialCost: '0',
  });

  const getReconInfo = useCallback(async () => {
    const { reconInfo } = vehicleSummary;
    if (reconInfo) {
      const { appraisedValue, estimatedRecon, initialCost } = reconInfo;
      setValues({
        appraisedValue: appraisedValue?.amount
          ? removeMoneySign(currencyFormatter(appraisedValue.amount))
          : '0.00',
        estimatedRecon: estimatedRecon?.amount
          ? removeMoneySign(currencyFormatter(estimatedRecon.amount))
          : '0.00',
        initialCost: initialCost?.amount
          ? removeMoneySign(currencyFormatter(initialCost.amount))
          : '0.00',
      });
    } else {
      setValues({
        appraisedValue: '0.00',
        estimatedRecon: '0.00',
        initialCost: '0.00',
      });
    }
  }, [vehicleSummary]);

  const getValuesFromReconInfo = () => {
    let { appraisedValue, estimatedRecon, initialCost } =
      vehicleSummary.reconInfo;
    if (!appraisedValue) {
      appraisedValue = { amount: 0, currency: Currency.USD };
    }

    if (!estimatedRecon) {
      estimatedRecon = { amount: 0, currency: Currency.USD };
    }

    if (!initialCost) {
      initialCost = { amount: 0, currency: Currency.USD };
    }

    return { appraisedValue, estimatedRecon, initialCost };
  };

  const saveReconInfo = async () => {
    try {
      setIsLoading(true);
      const { reconInfo } = vehicleSummary;
      if (reconInfo) {
        const { appraisedValue, estimatedRecon, initialCost } =
          getValuesFromReconInfo();

        appraisedValue.amount = parseToFloat(values.appraisedValue || '0');
        estimatedRecon.amount = parseToFloat(values.estimatedRecon || '0');
        initialCost.amount = parseToFloat(values.initialCost || '0');

        const patchData = { appraisedValue, estimatedRecon, initialCost };
        await updateReconInfoMutation.mutateAsync(patchData);
        onClose();
      } else {
        const appraisedValue: Money = {
          amount: parseToFloat(values.appraisedValue || '0'),
          currency: Currency.USD,
        };
        const estimatedRecon: Money = {
          amount: parseToFloat(values.estimatedRecon || '0'),
          currency: Currency.USD,
        };
        const initialCost: Money = {
          amount: parseToFloat(values.initialCost || '0'),
          currency: Currency.USD,
        };
        const patchData = { appraisedValue, estimatedRecon, initialCost };
        await updateReconInfoMutation.mutateAsync(patchData);
        onClose();
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const handleOnChange = (value: string, fieldName: string) => {
    if (!isCorrectNumberLength(deleteCommas(value))) return;

    if (isPriceFormat(deleteCommas(value))) {
      setValues({
        ...values,
        [fieldName]: value,
      });
    }
  };

  const setDefaultValue = (
    value: 'appraisedValue' | 'estimatedRecon' | 'initialCost'
  ) => {
    setValues({
      ...values,
      [value]: removeMoneySign(
        currencyFormatter(values[value] ? parseToFloat(values[value]) : 0)
      ),
    });
  };

  React.useEffect(() => {
    getReconInfo();
  }, [getReconInfo, vehicleSummary.vehicleCard.id]);

  return (
    <Modal show centered onHide={onClose} className="EditReconInfoModal">
      <Modal.Header
        closeButton
        className="modal-header-container"
        id="edit-recon-information-title"
      >
        <Modal.Title>{strings.EDIT_RECON_INFO_MODAL_TITLE}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="flex-grow">
        <div className="EditReconInfoModal-message-container">
          {strings.EDIT_RECON_INFO_MODAL_SUBTITLE}
          <div className="modal-sub-title">
            {strings.REQUIRED_FIELDS} <b>required.</b>
          </div>
        </div>
        <div className="full-width flex-rows edit-recon-form">
          <TextInput
            id="appraised-value-text-input"
            placeholder={strings.APPRAISED_VALUE}
            value={values.appraisedValue}
            onChange={(e) => handleOnChange(e.target.value, 'appraisedValue')}
            onBlur={() => setDefaultValue('appraisedValue')}
            disabled={isLoading}
            containerClassName="edit-recon-field"
          />
          <TextInput
            id="initial-cost-text-input"
            placeholder={strings.INITIAL_COST}
            value={values.initialCost}
            onChange={(e) => handleOnChange(e.target.value, 'initialCost')}
            onBlur={() => setDefaultValue('initialCost')}
            disabled={isLoading}
            containerClassName="edit-recon-field"
          />
          <TextInput
            id="estimated-recon-text-input"
            placeholder={strings.ESTIMATED_RECON}
            value={values.estimatedRecon}
            onChange={(e) => handleOnChange(e.target.value, 'estimatedRecon')}
            onBlur={() => setDefaultValue('estimatedRecon')}
            disabled={isLoading}
            containerClassName="edit-recon-field"
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer-container">
        <button
          type="button"
          onClick={onClose}
          className="white-button modal-footer-button-base"
        >
          {strings.CANCEL}
        </button>
        <button
          type="button"
          className="blue-button modal-footer-button-base"
          onClick={saveReconInfo}
        >
          {isLoading ? (
            <Spinner
              size="sm"
              role="status"
              aria-hidden="true"
              as="span"
              animation="border"
              variant="danger"
            />
          ) : (
            strings.SAVE
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditReconInfoModal;
