import { IconButton } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { FC, forwardRef } from 'react';
import { Dropdown } from 'react-bootstrap';

import { useDeleteVehicleTask, useToken } from 'api';
import { useTaskPermissions } from 'hooks';
import { Task, TaskStatus } from 'models';
import {
  canApproveAllRequestedTasks,
  canApproveTask,
  canCompleteTask,
  canDenyTask,
  canRequestTask,
  STATUS,
} from 'utils/tasks';

import './TaskActionMenu.scss';

const CustomToggle = forwardRef((props: any, ref) => {
  return (
    //@ts-ignore need a ref here but typescript acting up...
    <IconButton
      onClick={(e) => {
        e.preventDefault();
        props.onClick(e);
      }}
      ref={ref}
      size="small"
    >
      <MoreVert />
    </IconButton>
  );
});

interface ActionMenuProps {
  task: Task;
  vehicleId?: string;
  status?: TaskStatus;
  onRequestClick?: (task: Task) => void;
  onApproveClick?: (task: Task) => void;
  onApproveAllClick?: (status: TaskStatus) => void;
  onDenyClick?: (task: Task) => void;
  onDenyAllClick?: (status: TaskStatus) => void;
  onCompleteClick?: (task: Task) => void;
  allText?: string;
}

const ActionMenu: FC<ActionMenuProps> = ({
  task,
  vehicleId,
  status,
  onRequestClick,
  onApproveClick,
  onApproveAllClick,
  onDenyClick,
  onDenyAllClick,
  onCompleteClick,
  allText,
}) => {
  const {
    hasTaskCreatePermission,
    hasTaskUpdatePermission,
    hasTaskDeletePermission,
    hasTaskApprovePermission,
  } = useTaskPermissions();

  const { data: auth } = useToken();
  const { user } = auth ?? {};

  const deleteVehicleTaskQuery = useDeleteVehicleTask(vehicleId, task.id);

  const canViewRequest =
    hasTaskCreatePermission && canRequestTask(task, hasTaskApprovePermission);

  const canViewApprove = hasTaskApprovePermission && canApproveTask(task);

  const canViewApproveAll =
    hasTaskApprovePermission && canApproveAllRequestedTasks(task);

  const canViewDeny = hasTaskApprovePermission && canDenyTask(task);

  const canViewComplete = hasTaskUpdatePermission && canCompleteTask(task);

  const canDelete =
    hasTaskDeletePermission || task.createdByUserId === user?.id;

  return (
    <Dropdown className={'Task-ActionMenu-dropdown'}>
      <Dropdown.Toggle
        as={CustomToggle}
        id={`Task-dropdown-id-${task.id}`}
      ></Dropdown.Toggle>
      <Dropdown.Menu>
        {canViewRequest && (
          <Dropdown.Item onClick={() => onRequestClick?.(task)}>
            Request
          </Dropdown.Item>
        )}
        {canViewApprove && (
          <Dropdown.Item onClick={() => onApproveClick?.(task)}>
            Approve
          </Dropdown.Item>
        )}
        {canViewApproveAll && (
          <Dropdown.Item onClick={() => onApproveAllClick?.(task.status)}>
            Approve All {allText || (status && STATUS[status])}
          </Dropdown.Item>
        )}
        {canViewDeny && (
          <Dropdown.Item onClick={() => onDenyClick?.(task)}>
            Deny
          </Dropdown.Item>
        )}
        {canViewApproveAll && (
          <Dropdown.Item onClick={() => onDenyAllClick?.(task.status)}>
            Deny All {allText || (status && STATUS[status])}
          </Dropdown.Item>
        )}
        {canViewComplete && (
          <Dropdown.Item onClick={() => onCompleteClick?.(task)}>
            Complete
          </Dropdown.Item>
        )}
        {canDelete && (
          <Dropdown.Item onClick={() => deleteVehicleTaskQuery.mutate()}>
            Delete
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ActionMenu;
