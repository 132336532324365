import React from 'react';

import useAccordionContext from '../useAccordionContext';

export interface CollapseProps {
  element: any;
  eventKey: string | number;
  className?: string;
}

const Collapse: React.FC<CollapseProps> = ({
  element: Component,
  eventKey,
  children,
  className = '',
  ...otherProps
}) => {
  const { activeEventKey } = useAccordionContext();

  return activeEventKey === eventKey ? (
    <Component className={className} {...otherProps}>
      {children}
    </Component>
  ) : null;
};

export default Collapse;
