import { useMemo } from 'react';

import { useUsers } from 'api';
import { strings } from 'common';
import MultipleSearchSelection from 'components/shared/MultipleSearchSelection/MultipleSearchSelection';
import { TaskStatus } from 'models';
import { statusOptions } from 'utils/tasks';
import { getFullName } from 'utils/user';

import './TasksTableFilters.scss';

type TasksTableFiltersProps = {
  selectedStatuses: TaskStatus[];
  setSelectedStatus: (status: TaskStatus) => void;
  selectedAssignees: string[];
  setSelectedAssignee: (assignee: string) => void;
};

const TasksTableFilters: React.FC<TasksTableFiltersProps> = ({
  selectedStatuses,
  setSelectedStatus,
  selectedAssignees,
  setSelectedAssignee,
}) => {
  const usersQuery = useUsers();

  const assigneeOptions = useMemo(() => {
    return (
      usersQuery.data?.data.map((user) => ({
        id: user.id,
        value: getFullName(user),
        checked: selectedAssignees.includes(user.id),
      })) ?? []
    );
  }, [selectedAssignees, usersQuery.data?.data]);

  const statusSelectOptions = statusOptions.map((option) => ({
    ...option,
    checked: selectedStatuses.includes(option.id as TaskStatus),
  }));

  return (
    <div className="TasksTableFilters-container">
      <div className="TasksTableFilters-container-label">{strings.STATUS}:</div>
      <MultipleSearchSelection
        options={statusSelectOptions}
        filterId="STATUS"
        onSelect={(optionId) => setSelectedStatus(optionId as TaskStatus)}
      />
      <div className="TasksTableFilters-container-label">
        {strings.ASSIGNEE}:
      </div>
      <MultipleSearchSelection
        options={assigneeOptions}
        filterId="ASSIGNEE"
        onSelect={setSelectedAssignee}
      />
    </div>
  );
};

export default TasksTableFilters;
