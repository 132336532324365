import { useState } from 'react';
import { ACTIONS, CallBackProps, EVENTS, STATUS } from 'react-joyride';
import { Carousel } from 'react-responsive-carousel';

import { Tour as BaseTour } from 'components/shared/Tour';
import { useVendor, useWindowSize } from 'hooks';

import {
  name as createInvoiceName,
  images as invoiceImages,
  steps as invoiceSteps,
} from './createInvoiceTour';
import {
  name as createTasksName,
  images as tasksImages,
  steps as tasksSteps,
} from './taskTour';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

const Tour = () => {
  const { isVendor, isLoading } = useVendor();
  const { isMobileViewport } = useWindowSize();
  const isMobile = isMobileViewport();
  const [stepIndex, setStepIndex] = useState(0);

  if (isLoading) {
    return null;
  }
  const name = isVendor ? createInvoiceName : createTasksName;
  const images = isVendor ? invoiceImages : tasksImages;
  const steps = isVendor ? invoiceSteps : tasksSteps;

  const imageHeight = isMobile ? 645 : 602;
  const imageWidth = isMobile ? 300 : 1030;
  const ratio = imageHeight / imageWidth;

  const handleCallback = ({ action, index, status, type }: CallBackProps) => {
    if (
      ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)
    ) {
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    }
    if (
      ([STATUS.FINISHED, STATUS.SKIPPED, STATUS.PAUSED] as string[]).includes(
        status
      )
    ) {
      // Closes Carousel
      setStepIndex(0);
    }
  };

  return (
    <>
      <BaseTour
        name={name}
        callback={handleCallback}
        menuTriggerType={isMobile ? 'vdpMenu' : 'profileMenu'}
        showTour
        localStorageKey={'tour_hasShownTasksTour'}
        steps={steps}
        continuous
        stepIndex={stepIndex}
        styles={{
          options: {
            zIndex: 9999,
            backgroundColor: '#000000a6',
          },
        }}
      />
      {stepIndex > 0 && (
        <div
          className="tour-carousel"
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: `translate(-50%, ${isMobile ? '-40' : '-50'}%)`,
            height: Math.floor(imageWidth * ratio),
            width: imageWidth,
            border: '1px solid #000',
            zIndex: 1,
          }}
        >
          <Carousel
            selectedItem={stepIndex - 1}
            showArrows={false}
            showIndicators={false}
            showStatus={false}
            showThumbs={false}
          >
            {images.map((image) => {
              return (
                <img
                  alt={image.alt}
                  src={isMobile ? image.mobileSrc : image.desktopSrc}
                />
              );
            })}
          </Carousel>
        </div>
      )}
    </>
  );
};

export default Tour;
