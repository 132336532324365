import { RouteComponentProps, useLocation } from '@reach/router';
import React, { useEffect, useState } from 'react';

import { useChangeLocation, useLocations, useToken } from 'api';
import { useCurrentLocationId } from 'hooks';

import UserSessionRouter from './UserSessionRouter';

const TenantUserSessionRouter: React.FC<RouteComponentProps> = (props: any) => {
  const [readyToNavigate, setReadyToNavigate] = useState<boolean>(false);
  const [requiresChangeLocation, setRequiresChangeLocation] =
    useState<boolean>(false);

  const { data: userData } = useToken();
  const { data: rooftopList, isLoading: isLoadingRooftopList } = useLocations();
  const urlLocationId = useCurrentLocationId();
  const location = useLocation();
  const { mutateAsync: applyChangeLocation } = useChangeLocation();

  const userLocationId = userData?.location?.id;
  const currentHash = location?.hash ?? '';

  useEffect(() => {
    if (
      urlLocationId &&
      urlLocationId !== userLocationId &&
      currentHash === '#notification'
    ) {
      setRequiresChangeLocation(true);
    } else {
      setReadyToNavigate(true);
    }
  }, [currentHash, urlLocationId, userLocationId]);

  useEffect(() => {
    if (requiresChangeLocation && !isLoadingRooftopList) {
      const foundRooftop = rooftopList?.find(
        (rooftop) => rooftop.id === urlLocationId
      );
      if (urlLocationId && foundRooftop) {
        const href = window.location.href;
        applyChangeLocation({ locationId: urlLocationId }).finally(() => {
          const url = new URL(href);
          url.hash = '#notification-redirect';
          window.location.href = url.href;
        });
      } else {
        setReadyToNavigate(true);
      }
    }
  }, [
    applyChangeLocation,
    requiresChangeLocation,
    urlLocationId,
    rooftopList,
    isLoadingRooftopList,
  ]);

  if (readyToNavigate) {
    return <UserSessionRouter {...props} />;
  }

  return null;
};

export default TenantUserSessionRouter;
