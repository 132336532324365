import { useMemo } from 'react';

import { permissions, strings } from 'common';
import Button from 'components/shared/Button';
import PermissionsGate from 'components/shared/PermissionsGate';
import { Task, TaskStatus } from 'models';
import { canCompleteTask } from 'utils/tasks';

interface CompleteButtonProps {
  selectedIds: string[];
  tasks: Task[];
  loading: boolean;
  onClick: (status: TaskStatus) => void;
}

const CompleteButton: React.FC<CompleteButtonProps> = (props) => {
  const { selectedIds, tasks, loading, onClick } = props;

  const disabled = useMemo(() => {
    if (!selectedIds.length) {
      return true;
    }
    return !selectedIds.every((id) => {
      const task = tasks.find((task) => task.id === id);
      return canCompleteTask(task!);
    });
  }, [selectedIds, tasks]);

  return (
    <PermissionsGate permissions={[permissions.INVENTORY_VDP_TASKS_UPDATE]}>
      <Button
        className="FlatTasks-action-button FlatTasks-action-button-deny"
        id="complete-task-button"
        type="button"
        variant="success"
        onClick={() => onClick('COMPLETE')}
        disabled={disabled}
        loading={loading}
      >
        {strings.COMPLETE}
      </Button>
    </PermissionsGate>
  );
};

export default CompleteButton;
