import {
  getItem,
  LocalStorageKey,
  removeItem,
  setItem,
} from './typedLocalStorage';

const credentialKeys = [
  'authBearerToken',
  'authBearerTokenExpiration',
  'authRefreshToken',
] as const;
type CredentialKey = (typeof credentialKeys)[number];

function prefixed(key: CredentialKey): LocalStorageKey {
  switch (key) {
    case 'authBearerToken':
      return 'rvweb_authBearerToken';
    case 'authBearerTokenExpiration':
      return 'rvweb_authBearerTokenExpiration';
    case 'authRefreshToken':
      return 'rvweb_authRefreshToken';
  }
}

export function saveCredentials(
  credentials: Partial<Record<CredentialKey, string>>
) {
  credentialKeys.forEach((key) => {
    const value = credentials[key];
    if (value) {
      setItem(prefixed(key), value);
    }
  });
}

export function getCredentialValue(key: CredentialKey) {
  return getItem(prefixed(key));
}

export function clearCredentials() {
  removeCredentialValue('authBearerToken');
  removeCredentialValue('authBearerTokenExpiration');
  removeCredentialValue('authRefreshToken');
}

function removeCredentialValue(key: CredentialKey) {
  removeItem(prefixed(key));
}
