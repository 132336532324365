import {
  Box,
  ButtonBase,
  Card,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { strings } from 'common';
import theme from 'common/theme';
import { useTaskPermissions } from 'hooks';
import { Task, VehicleSummary } from 'models';
import { currencyFormatter } from 'utils/formatter';
import { STATUS } from 'utils/tasks';

import TaskPhotos from '../TaskPhotos';
import MobileMediaList from './MobileMediaList';
import MobileTaskItem from './MobileTaskItem';

type Props = {
  task: Task;
  position: number;
  vehicle: VehicleSummary;
  onTaskPress: (task: Task) => void;
  onTaskEdit: (task: Task) => void;
};

const MobileFlatTask = ({
  task,
  position,
  vehicle,
  onTaskEdit,
  onTaskPress,
}: Props) => {
  const classes = useStyles();
  const unassigned = 'UNASSIGNED';
  let assignee = `${task?.assignedTo?.firstName ?? ''} ${
    task?.assignedTo?.lastName ?? unassigned
  }`.trim();

  // Permissions
  const {
    hasTaskUpdatePermission,
    hasTaskLaborCostViewPermission,
    hasTaskPartsCostViewPermission,
  } = useTaskPermissions();

  let rate = currencyFormatter(task?.laborRate?.amount ?? 0);
  let hours = (task?.laborHours ?? 0).toString();
  let price = currencyFormatter(task?.laborPrice?.amount ?? 0);
  let parts = currencyFormatter(task?.partsPrice?.amount ?? 0);
  let total = currencyFormatter(task?.price?.amount ?? 0);

  if (!hasTaskLaborCostViewPermission) {
    rate = '--';
    hours = '--';
    price = '--';
  }

  if (!hasTaskPartsCostViewPermission) {
    parts = '--';
  }

  if (!hasTaskLaborCostViewPermission || !hasTaskPartsCostViewPermission) {
    total = '--';
  }

  return (
    <Card
      className={classes.container}
      id={`mobile-task-card-${position}`}
      onClick={() => onTaskEdit(task)}
    >
      <Box
        className={classes.col}
        style={{ flex: 1, rowGap: theme.spacing(1) }}
      >
        <MobileTaskItem
          label={strings.STATUS}
          position={position}
          value={
            <Box className={classes.statusContainer}>
              <ButtonBase
                disabled={!hasTaskUpdatePermission}
                onClick={(e) => {
                  e.stopPropagation();
                  onTaskPress(task);
                }}
              >
                <Box className={classes[STATUS[task.status].toLowerCase()]}>
                  {STATUS[task.status].toUpperCase()}
                </Box>
                <ArrowDropDownIcon className={classes.arrow} />
              </ButtonBase>
            </Box>
          }
        />
        <MobileTaskItem
          label={strings.TASK}
          position={position}
          value={task.label}
        />
        {task.notes && (
          <MobileTaskItem
            label={strings.DESCRIPTION}
            position={position}
            value={task.notes}
          />
        )}
        <MobileTaskItem
          label={strings.ASSIGNEE}
          position={position}
          value={
            <Box
              style={assignee === unassigned ? { color: '#7e8487' } : undefined}
            >
              {assignee}
            </Box>
          }
        />
        <TaskPhotos
          disabled
          task={task}
          vehicle={vehicle}
          mediaListRender={({ attachments, onClick }) => (
            <MobileMediaList
              attachments={attachments}
              onClick={onClick}
              readOnly
            />
          )}
        ></TaskPhotos>
      </Box>
      <Box
        className={classes.footer}
        id={`mobile-task-card-footer-${position}`}
      >
        <Typography variant="body2" className={classes.costs}>
          {strings.COSTS}
        </Typography>
        <Box className={classes.costContainer}>
          <Box
            className={classes.cell}
            id={`mobile-task-card-rate-${position}`}
          >
            <Typography variant="body2" className={classes.costValue}>
              {rate}
            </Typography>
          </Box>
          <Box
            className={classes.cell}
            id={`mobile-task-card-hours-${position}`}
          >
            <Typography variant="body2" className={classes.costValue}>
              {hours}
            </Typography>
          </Box>
          <Box
            className={classes.cell}
            id={`mobile-task-card-labor-${position}`}
          >
            <Typography variant="body2" className={classes.costValue}>
              {price}
            </Typography>
          </Box>
          <Box
            className={classes.cell}
            id={`mobile-task-card-parts-${position}`}
          >
            <Typography variant="body2" className={classes.costValue}>
              {parts}
            </Typography>
          </Box>
          <Box
            className={classes.cell}
            id={`mobile-task-card-total-${position}`}
          >
            <Typography variant="body2" className={classes.costValue}>
              {total}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

const fontStyle: CreateCSSProperties<{}> = {
  textTransform: 'uppercase',
  fontWeight: 600,
  fontSize: '12px',
};

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    base: {
      display: 'flex',
      justifyContent: 'stretch',
      flexDirection: 'column',
    },
    container: {
      display: 'flex',
      // flex: 1,
      justifyContent: 'stretch',
      flexDirection: 'column',
      backgroundColor: '#fff',
      padding: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    col: { display: 'flex', flexDirection: 'column' },
    row: { display: 'flex', flexDirection: 'row', columnGap: 8 },
    label: {
      fontWeight: 700,
      width: 100,
    },
    statusContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      height: '20px',
    },
    arrow: {
      position: 'relative',
      top: '-1px',
      color: theme.palette.grey[600],
    },
    autocompletePopper: {
      zIndex: 9999,
    },
    addPhoto: {
      marginTop: theme.spacing(1),
      height: 114,
    },
    addPhotoIconLabel: {
      flexDirection: 'column',
      display: 'flex',
    },
    addPhotoStartIcon: {
      marginLeft: 0,
      marginRight: 0,
    },
    downArrowIcon: {
      marginLeft: 0,
      marginRight: 0,
      position: 'relative',
      left: '5px',
    },
    footer: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: theme.palette.grey[200],
      columnGap: '10px',
      marginTop: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
    },
    costContainer: {
      flex: 1,
      padding: theme.spacing(0.8),
      columnGap: '10px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    costs: {
      ...fontStyle,
      width: 85,
      paddingLeft: theme.spacing(1),
    },
    costValue: {
      ...fontStyle,
    },
    cell: {
      flex: 1,
    },
    estimated: {
      color: theme.palette.info.main,
    },
    approved: {
      color: theme.palette.success.main,
    },
    completed: {
      color: theme.palette.success.main,
    },
    requested: {
      color: theme.palette.warning.main,
    },
    denied: {
      color: theme.palette.danger.main,
    },
    approveAll: {
      color: theme.palette.secondary.main,
    },
    invoiced: {
      color: '#44c7ff',
    },
  })
);

export default MobileFlatTask;
