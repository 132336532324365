import { RouteComponentProps, Router } from '@reach/router';
import { useEffect, useRef } from 'react';

import { useCurrentLocation } from 'api';
import VehicleDetailsRouter from 'navigation/VehicleDetailsRouter';

import { Inventory } from './Inventory';
import { ViewInvoice } from './ViewInvoice';

import './Invoicing.scss';

type Props = RouteComponentProps & { rooftopId?: string };

const Invoicing = ({ path, navigate, ...props }: Props) => {
  const currentLocation = useCurrentLocation();
  const originalLocationRef = useRef<string>();
  const hasRedirectRef = useRef(false);

  //Redirects to /invoicing route if user is on invoicing tab and switched rooftops
  useEffect(() => {
    originalLocationRef.current = props.rooftopId;
    if (
      originalLocationRef.current !== currentLocation?.id &&
      !hasRedirectRef.current
    ) {
      if (props?.uri) {
        hasRedirectRef.current = true;
        navigate?.(props.uri);
      }
    }
  }, [currentLocation?.id, navigate, props, props.rooftopId]);

  return (
    <div className="invoicing page vertical-scroll">
      <Router
        primary={false}
        style={{ flex: 1, display: 'flex', width: '100%' }}
      >
        <Inventory default />
        <VehicleDetailsRouter path="inventory/:vehicleId/*" />
        <ViewInvoice path=":invoiceId" />
      </Router>
    </div>
  );
};

export default Invoicing;
