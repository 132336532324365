import { useMemo, useState } from 'react';

import { useDeleteNoteAttachment, useToken } from 'api';
import { permissions } from 'common';
import ModalCarousel from 'components/shared/ModalCarousel/ModalCarousel';
import VehicleImageView from 'components/shared/VehicleImageView';
import { Attachment, convertTaskAttachmentsToVehicleImage } from 'models';

import VehicleCardAttachmentEditDropDown from '../VehicleCardAttachmentEditDropDown';

import './NoteAttachments.scss';

type NoteAttachmentsProps = {
  attachments: Attachment[] | undefined;
  noteId: string;
  userId: string;
  vehicleId: string;
};
const NoteAttachments = ({
  attachments,
  noteId,
  userId,
  vehicleId,
}: NoteAttachmentsProps) => {
  const { data: sessionData } = useToken();
  const { deleteNoteAttachmentAsync } = useDeleteNoteAttachment();

  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [galleryIndex, setGalleryIndex] = useState(0);

  const openGallery = (index: number = 0) => {
    setGalleryIndex(index);
    setIsGalleryOpen(true);
  };

  const imageViews = useMemo(() => {
    return attachments?.map((attachment, index) => {
      const { id: attachmentId } = attachment;

      return (
        <div
          key={attachmentId}
          className="NoteAttachments-attachment"
          onClick={() => openGallery(index)}
        >
          <VehicleImageView
            size="sm"
            imageUrl={attachment.uri}
            contentType={attachment.contentType}
          />
          <VehicleCardAttachmentEditDropDown
            attachmentId={attachmentId}
            noteId={noteId!}
            userId={userId}
            vehicleId={vehicleId}
          />
        </div>
      );
    });
  }, [attachments, noteId, userId, vehicleId]);

  const images = useMemo(
    () => convertTaskAttachmentsToVehicleImage(attachments?.slice() || []),
    [attachments]
  );

  const isCurrentUserMessage = userId === sessionData?.user?.id;

  return (
    <div className="NoteAttachments-container">
      {imageViews}
      <ModalCarousel
        modalIsOpen={isGalleryOpen}
        currentIndex={galleryIndex}
        images={images}
        onClose={() => setIsGalleryOpen(false)}
        deletePermission={
          isCurrentUserMessage
            ? permissions.INVENTORY_VDP_NOTES_ATTACHMENT_DELETE
            : 'NONE'
        }
        onDeleteImage={(attachmentId: string) => {
          setGalleryIndex(0);
          deleteNoteAttachmentAsync({
            attachmentId,
            noteId,
            vehicleId,
          });
          if (attachments?.length === 1) {
            setIsGalleryOpen(false);
          }
        }}
        assignPermission="NONE"
      />
    </div>
  );
};

export default NoteAttachments;
