import { useContext, useEffect, useRef } from 'react';

import permissions from 'common/permissions';
import strings from 'common/strings';
import { usePermissions } from 'hooks';
import { VehicleCardTabType } from 'models';

import VehicleCardNotes from '../../../../../shared/VehicleCardNotes';
import { VehicleCardContext } from '../../VehicleCard';
import InvoiceTasks from '../../VehicleCardInvoiceDesktop/InvoiceTasks';
import VehicleCardEngageDetails from './VehicleCardEngageDetails';
import VehicleCardInventoryDetails from './VehicleCardInventoryDetails';
import VehicleCardReconDetails from './VehicleCardReconDetails';

import './VehicleCardReconInventoryTabs.scss';

interface VehicleCardReconInventoryTabsProps {
  showingInvoicing: boolean;
  showNotesTab?: boolean;
}
var VehicleCardReconInventoryTabs = ({
  showingInvoicing,
  showNotesTab = false,
}: VehicleCardReconInventoryTabsProps) => {
  const {
    inExtensionCardMode,
    position,
    forceShowEngageTab,
    setCurrentTab,
    currentTab,
    vehicle,
  } = useContext(VehicleCardContext);
  const { hasPermission } = usePermissions();
  const hasSetInitialTaskTabRef = useRef(false);
  const onClick = (activeCollectionType: VehicleCardTabType) => {
    if (setCurrentTab !== undefined) {
      setCurrentTab(activeCollectionType);
    }
  };

  useEffect(() => {
    if (
      showingInvoicing &&
      hasPermission(permissions.INVOICING_VIEW) &&
      !hasSetInitialTaskTabRef.current
    ) {
      hasSetInitialTaskTabRef.current = true;
      setCurrentTab?.('tasks');
    }

    if (!showNotesTab && currentTab === 'notes') {
      const tab =
        (hasPermission(permissions.RECON_VIEW) && 'recon') ||
        (hasPermission(permissions.INVENTORY_VIEW) && 'inventory') ||
        (hasPermission(permissions.PLUGIN_VELOCITYENGAGE_VIEW) && 'engage');

      setCurrentTab?.(tab);
    }
  }, [
    currentTab,
    hasPermission,
    showingInvoicing,
    setCurrentTab,
    showNotesTab,
  ]);

  const tabClass = showNotesTab
    ? 'VehicleCard-recon-inventory-tabs-no-notes'
    : '';

  return (
    <div className={`VehicleCard-recon-inventory-tabs ${tabClass}`}>
      <div
        className="VehicleCard-recon-inventory-tabs-container"
        data-vas-testing={`VehicleCard-tabs-container-${position.toString()}`}
      >
        {!showingInvoicing && hasPermission(permissions.RECON_VIEW) && (
          <div
            role="none"
            className={`VehicleCard-recon-inventory-tab-label mr-3  ${
              currentTab === 'recon' ? 'active' : ''
            }`}
            onClick={() => onClick('recon')}
          >
            {strings.RECON}
          </div>
        )}
        {!showingInvoicing && hasPermission(permissions.INVENTORY_VIEW) && (
          <div
            role="none"
            className={`VehicleCard-recon-inventory-tab-label mr-3 ${
              currentTab === 'inventory' ? 'active' : ''
            }`}
            onClick={() => onClick('inventory')}
          >
            {strings.INVENTORY}
          </div>
        )}
        {!showingInvoicing &&
          hasPermission(permissions.PLUGIN_VELOCITYENGAGE_VIEW) && (
            <div
              role="none"
              className={`VehicleCard-recon-inventory-tab-label mr-3 ${
                currentTab === 'engage' ? 'active' : ''
              }`}
              onClick={() => onClick('engage')}
            >
              {strings.ENGAGE}
            </div>
          )}
        {showingInvoicing && (
          <div
            role="none"
            className={`VehicleCard-recon-inventory-tab-label mr-3 ${
              currentTab === 'tasks' ? 'active' : ''
            }`}
            onClick={() => onClick('tasks')}
          >
            {strings.TASKS}
          </div>
        )}
        {showNotesTab && (
          <div
            role="none"
            className={`VehicleCard-recon-inventory-tab-label mr-3 ${
              currentTab === 'notes' ? 'active' : ''
            }`}
            onClick={() => onClick('notes')}
          >
            {strings.NOTES}
          </div>
        )}
      </div>
      <div className="VehicleCard-recon-inventory-tab-content">
        {currentTab === 'recon' && <VehicleCardReconDetails />}
        {currentTab === 'inventory' && <VehicleCardInventoryDetails />}
        {currentTab === 'engage' &&
          (forceShowEngageTab || inExtensionCardMode) && (
            <VehicleCardEngageDetails />
          )}
        {currentTab === 'tasks' && <InvoiceTasks vehicle={vehicle!} />}
        {currentTab === 'notes' && (
          <VehicleCardNotes
            vehicleId={vehicle?.vehicleCard?.id!}
            notes={vehicle?.notesByDate!}
            isInTab={showNotesTab}
          />
        )}
      </div>
    </div>
  );
};

export default VehicleCardReconInventoryTabs;
