import { FC } from 'react';
import { Dropdown } from 'react-bootstrap';

import { strings } from 'common';

import './VehicleStateFilter.scss';

export type VehicleStateValue = 'ACTIVE' | 'COMPLETED';

interface VehicleStateOption {
  label: string;
  value: VehicleStateValue;
}

export interface VehicleStateFilterProps {
  setSelectedVehicleState: (selectedVehicleState: VehicleStateValue) => void;
  selectedVehicleState?: VehicleStateValue;
}

const vehicleStateFilterOptions: VehicleStateOption[] = [
  { label: strings.ACTIVE_RECON, value: 'ACTIVE' },
  { label: strings.COMPLETED, value: 'COMPLETED' },
];

const VehicleStateFilter: FC<VehicleStateFilterProps> = ({
  setSelectedVehicleState,
  selectedVehicleState,
}) => {
  return (
    <div className="tour-stepreport-filter-vehiclestate VehicleStateFilter">
      <Dropdown>
        <Dropdown.Toggle variant="light" id="Reports-VehicleStateFilter">
          {
            vehicleStateFilterOptions.find(
              (filterOption) => filterOption.value === selectedVehicleState
            )?.label
          }
        </Dropdown.Toggle>
        <Dropdown.Menu alignRight>
          {vehicleStateFilterOptions.map((filterOption) => (
            <Dropdown.Item
              eventKey={filterOption.value}
              key={filterOption.value}
              onSelect={(key) => {
                if (!key) return;
                setSelectedVehicleState(key as VehicleStateValue);
              }}
            >
              {filterOption.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default VehicleStateFilter;
