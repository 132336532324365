import { Step } from 'react-joyride';

import { Tour as BaseTour, styles } from 'components/shared/Tour';
import { useWindowSize } from 'hooks';

const steps: Step[] = [
  {
    target: '.tour-stepreport-main',
    title: 'Step Report Tour',
    content:
      "Welcome to the Step Report.  Let's take a quick tour to familiarize you with how to use it.",
    disableBeacon: true,
    placement: 'center',
    styles: {
      ...styles,
      tooltipContainer: {
        textAlign: 'left',
      },
    },
    locale: {
      next: 'Start',
    },
  },
  {
    content:
      'Choose between vehicles currently in active recon or vehicles that have already completed recon.',
    target: '.tour-stepreport-filter-vehiclestate',
  },
  {
    content:
      'Choose a date range for when vehicles completed recon. (This does not apply when filtering on active recon).',
    target: '.tour-stepreport-filter-date .dropdown',
  },
  {
    content:
      'The number of vehicles that completed recon during the selected date range.',
    target: '.tour-stepreport-overview-completedvehicles',
  },
  {
    content:
      'The average time elapsed between a vehicle starting and completing recon.',
    target: '.tour-stepreport-overview-avgretailreadytime',
  },
  {
    content:
      'The average time elapsed in all steps that are configured to count towards this metric.  Can also respect dealership hours when configured.',
    target: '.tour-stepreport-overview-avgrecontime',
  },
  {
    content:
      'The average amount of money allocated to reconditioning a vehicle.',
    target: '.tour-stepreport-overview-avgestimated',
  },
  {
    content: 'The average amount of money spent on reconditioning vehicles.',
    target: '.tour-stepreport-overview-avgactual',
  },
  {
    content:
      'Average actual recon dollars minus estimated recon dollars per vehicle.',
    target: '.tour-stepreport-overview-avgoverage',
  },
  {
    content: 'Total actual recon dollars minus total estimated recon dollars.',
    target: '.tour-stepreport-overview-totaloverage',
  },
  {
    content: 'Click here to toggle showing / hiding child steps',
    target: '.tour-stepreport-step-accordion',
  },
  {
    content:
      'Click here to drill into specific metrics for each vehicle that spent recon time in a particular step.',
    target: '.tour-stepreport-step-details',
    locale: {
      last: 'Close ',
    },
  },
];

type Props = {
  showTour: boolean;
  name: string;
};
const DealerStepReportTour = ({ name, showTour }: Props) => {
  const { isMobileViewport } = useWindowSize();
  const isMobile = isMobileViewport();

  return (
    <BaseTour
      name={name}
      menuTriggerType={isMobile ? 'vdpMenu' : 'profileMenu'}
      showTour={showTour}
      localStorageKey={'tour_hasShownStepReportTour'}
      steps={steps}
    />
  );
};

export default DealerStepReportTour;
