import { navigate } from '@reach/router';
import { FC } from 'react';
import { Button } from 'react-bootstrap';

import { strings } from 'common';
import { useCarfax } from 'hooks/useCarfax';

export interface CarfaxControlsProps {
  isMobile?: boolean;
  currentTab: 'report' | 'hbv';
  setCurrentTab: (tab: 'report' | 'hbv') => void;
}

const CarfaxControls: FC<CarfaxControlsProps> = ({
  isMobile = false,
  currentTab,
  setCurrentTab,
}) => {
  const { handleLogout, LOGOUT_REDIRECT } = useCarfax();
  const containerClassName = isMobile ? 'controls-mobile' : 'controls';

  const handleLogoutClick = async () => {
    await handleLogout();
    navigate(LOGOUT_REDIRECT);
  };

  return (
    <div className={containerClassName}>
      <div className="tabs">
        <Button
          type="button"
          variant={currentTab === 'report' ? 'info' : 'primary'}
          onClick={() => setCurrentTab('report')}
        >
          REPORT
        </Button>
        <Button
          type="button"
          variant={currentTab === 'hbv' ? 'info' : 'primary'}
          onClick={() => setCurrentTab('hbv')}
        >
          History Based Value
        </Button>
      </div>
      <div>
        <Button variant="primary" type="button" onClick={handleLogoutClick}>
          {strings.CARFAX_LOGOUT}
        </Button>
      </div>
    </div>
  );
};

export default CarfaxControls;
