import IconButton from '@material-ui/core/IconButton';
import {
  CheckBox,
  CheckBoxOutlineBlank,
  IndeterminateCheckBox,
} from '@material-ui/icons';

import './Checkbox.scss';

interface ICheckboxProps {
  label: string;
  onClick(event?: any): void;
  selected?: boolean;
  id: string;
  className?: string;
  disabled?: boolean;
  name: string;
  partiallySelected?: boolean;
}

interface ICheckBoxIconProps {
  selected?: boolean;
  partiallySelected?: boolean;
}

var CheckboxIcon = ({ selected, partiallySelected }: ICheckBoxIconProps) => {
  return partiallySelected ? (
    <IndeterminateCheckBox />
  ) : selected ? (
    <CheckBox />
  ) : (
    <CheckBoxOutlineBlank />
  );
};

var Checkbox = ({
  label,
  onClick,
  selected,
  id,
  disabled,
  name,
  className = '',
  partiallySelected,
}: ICheckboxProps) => {
  return (
    <span role="none" className="CheckboxInput">
      <label
        role="none"
        onClick={!disabled ? onClick : undefined}
        className={`checkbox-container ${
          disabled ? 'text-muted no-pointer' : ''
        }`}
      >
        <input
          disabled={disabled}
          key={`${id}_${label}_select_button`}
          type="checkbox"
          name={name}
          value={label}
          defaultChecked={selected || partiallySelected}
          onClick={(e) => e.stopPropagation()}
        />
        <IconButton
          id={`${id}`}
          className={`icon-button-primary p-2 ${className}`}
        >
          <CheckboxIcon
            selected={selected}
            partiallySelected={partiallySelected}
          />
        </IconButton>
        <span className="align-middle ml-3">{label}</span>
      </label>
    </span>
  );
};

export default Checkbox;
