import { permissions } from 'common';
import { usePermissions } from 'hooks';

export const useTaskPermissions = () => {
  const { hasPermission } = usePermissions();

  const hasTaskCreatePermission = hasPermission(
    permissions.INVENTORY_VDP_TASKS_CREATE
  );
  const hasTaskUpdatePermission = hasPermission(
    permissions.INVENTORY_VDP_TASKS_UPDATE
  );
  const hasTaskDeletePermission = hasPermission(
    permissions.INVENTORY_VDP_TASKS_DELETE
  );
  const hasTaskApprovePermission = hasPermission(
    permissions.INVENTORY_VDP_TASKS_APPROVE
  );
  const hasTaskAttachmentViewPermission = hasPermission(
    permissions.INVENTORY_VDP_TASKS_ATTACHMENT_VIEW
  );
  const hasTaskLaborCostViewPermission = hasPermission(
    permissions.INVENTORY_VDP_TASKS_LABOR_COST_VIEW
  );
  const hasTaskLaborCostUpdatePermission = hasPermission(
    permissions.INVENTORY_VDP_TASKS_LABOR_COST_UPDATE
  );
  const hasTaskPartsCostViewPermission = hasPermission(
    permissions.INVENTORY_VDP_TASKS_PARTS_COST_VIEW
  );
  const hasTaskPartsCostUpdatePermission = hasPermission(
    permissions.INVENTORY_VDP_TASKS_PARTS_COST_UPDATE
  );

  return {
    hasTaskCreatePermission,
    hasTaskUpdatePermission,
    hasTaskDeletePermission,
    hasTaskApprovePermission,
    hasTaskAttachmentViewPermission,
    hasTaskLaborCostViewPermission,
    hasTaskLaborCostUpdatePermission,
    hasTaskPartsCostViewPermission,
    hasTaskPartsCostUpdatePermission,
  };
};
