import { createContext, Dispatch, SetStateAction } from 'react';

import { SubmittedAttachments } from '../types';

export type DocumentUploadContextData = {
  submittedDocuments: SubmittedAttachments;
  setSubmittedDocuments: Dispatch<SetStateAction<SubmittedAttachments>>;
};

export default createContext<DocumentUploadContextData | null>(null);
