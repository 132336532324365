import { FC, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import permissions from 'common/permissions';
import strings from 'common/strings';
import { generateTestId, testIds } from 'common/testIds';
import Button from 'components/shared/Button';
import PermissionsGate from 'components/shared/PermissionsGate';
import { usePermissions } from 'hooks';
import { VehicleSummary } from 'models';
import { currencyFormatter } from 'utils/formatter';

import EditReconInfoModal from './EditReconInfoModal';

import './ReconInfoSection.scss';

interface ReconInfoRowProps {
  title: string;
  value: number | null;
}

const ReconInfoRow: FC<ReconInfoRowProps> = ({ title, value }) => (
  <Row className="ReconInfoRow">
    <Col
      data-vas-testing={generateTestId(testIds.RECON_INFO_ROW_TITLE, {
        title: `${title}`,
      })}
      className="ReconInfoRow-title d-flex justify-content-start align-items-center"
    >
      {title}
    </Col>
    <Col
      className="ReconInfoRow-value d-flex justify-content-end align-items-center"
      id={title.replace(/\s+/g, '-').toLowerCase()}
    >
      {typeof value === 'number'
        ? currencyFormatter(value)
        : strings.EMPTY_VALUE}
    </Col>
  </Row>
);

interface ReconInfoSectionProps {
  vehicleSummary: VehicleSummary;
}

const ReconInfoSection: React.FC<ReconInfoSectionProps> = ({
  vehicleSummary,
}) => {
  const { hasPermission } = usePermissions();
  const [showEditReconInfoModal, setShowEditReconInfoModal] = useState(false);
  const userHasViewReconInfoPermissions = hasPermission(
    permissions.INVENTORY_VDP_RECON_INFO_VIEW
  );

  const getReconInfoData = (
    reconInfo: VehicleSummary['reconInfo']
  ): ReconInfoRowProps[] => {
    if (!reconInfo) return [];
    return [
      {
        title: strings.INITIAL_COST,
        value: userHasViewReconInfoPermissions
          ? reconInfo.initialCost?.amount
          : null,
      },
      {
        title: strings.APPRAISED_VALUE,
        value: userHasViewReconInfoPermissions
          ? reconInfo.appraisedValue?.amount
          : null,
      },
      {
        title: strings.ESTIMATED_RECON,
        value: userHasViewReconInfoPermissions
          ? reconInfo.estimatedRecon?.amount
          : null,
      },
      {
        title: strings.TARGET_COST,
        value: userHasViewReconInfoPermissions
          ? reconInfo.targetCost?.amount
          : null,
      },
      {
        title: strings.COST,
        value: userHasViewReconInfoPermissions ? reconInfo.cost?.amount : null,
      },
      {
        title: strings.PACK,
        value: userHasViewReconInfoPermissions ? reconInfo.pack?.amount : null,
      },
      {
        title: strings.ACTUAL_RECON,
        value: userHasViewReconInfoPermissions
          ? reconInfo.actualRecon?.amount
          : null,
      },
      {
        title: strings.OVERAGE,
        value: userHasViewReconInfoPermissions
          ? reconInfo.overage?.amount
          : null,
      },
      // TODO: hook this up after we get data from the API
      // {
      //   title: 'Book Value',
      //   value: 50,
      // },
    ];
  };
  return (
    <Container
      fluid
      className="ReconInfoSection"
      data-vas-testing={testIds.RECON_INFO_SECTION_CONTAINER}
    >
      <Row
        className="ReconInfoSection-title-row pb-3"
        data-vas-testing={testIds.RECON_INFO_SECTION_TITLE_CONTAINER}
      >
        <Col className="d-flex justify-content-start align-items-center p-0">
          <div className="ReconInfoSection-title">Recon Info</div>
        </Col>
        <Col className="d-flex justify-content-end align-items-center p-0">
          <PermissionsGate
            permissions={[
              permissions.RECON_VDP_UPDATE,
              permissions.INVENTORY_VDP_RECON_INFO_VIEW,
            ]}
          >
            <Button
              id="edit-recon-info-button"
              variant="secondary"
              onClick={() => setShowEditReconInfoModal(true)}
              type="button"
              className="ReconInfoSection-edit-btn"
            >
              Edit Recon Info
            </Button>
          </PermissionsGate>
        </Col>
      </Row>
      <div
        className="ReconInfoSection-data-container"
        data-vas-testing={testIds.RECON_INFO_SECTION_DATA_CONTAINER}
      >
        {vehicleSummary.reconInfo ? (
          getReconInfoData(vehicleSummary.reconInfo).map((item) => (
            <ReconInfoRow
              key={`recon-info-row-${item.title}`}
              title={item.title}
              value={item.value}
            />
          ))
        ) : (
          <div>{strings.NO_RECON_INFO}</div>
        )}
      </div>
      {showEditReconInfoModal && (
        <EditReconInfoModal
          vehicleSummary={vehicleSummary}
          onClose={() => setShowEditReconInfoModal(false)}
        />
      )}
    </Container>
  );
};

export default ReconInfoSection;
