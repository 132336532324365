import axios from 'axios';

export type S3UploadProgressEventData = {
  progress: number;
};

type OnUploadProgressEvent = {
  loaded: number;
  total?: number;
};

type UploadFileToS3Input = {
  presignedUrl: string;
  file: File;
  onUploadProgress?: (progressEvent: OnUploadProgressEvent) => void;
};

export const useUploadFileToS3 = () => {
  const uploadFileToS3 = ({
    presignedUrl,
    file,
    onUploadProgress,
  }: UploadFileToS3Input) => {
    return axios.put(presignedUrl, file, {
      headers: {
        'Content-Type': file.type,
      },
      onUploadProgress,
    });
  };

  return {
    uploadFileToS3,
  };
};
