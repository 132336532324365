import { FC } from 'react';

import permissions from 'common/permissions';
import strings from 'common/strings';
import { ArrowUpBold } from 'components/shared/icons';
import { usePermissions } from 'hooks';
import { DealerStepReportVehicleV2 } from 'models';
import { vehicleDetailsBuilder } from 'navigation/routes';
import { currencyFormatter } from 'utils/formatter';
import { getFormattedDurationFromSeconds } from 'utils/time';

import { compareItemOverage, ratings } from '../ReportsState';

interface StepReportTableRowProps {
  item: DealerStepReportVehicleV2;
}

export const StepReportTableRow: FC<StepReportTableRowProps> = ({ item }) => {
  const { hasPermission } = usePermissions();
  const userHasReconInfoPermission = hasPermission(
    permissions.INVENTORY_VDP_RECON_INFO_VIEW
  );

  const openVDPScreenTabForVehicleItem = (item: DealerStepReportVehicleV2) => {
    const vehicleId = item?.vehicle?.id;
    if (!vehicleId) return;
    window.open(
      `${vehicleDetailsBuilder(vehicleId)}/overview`,
      '_blank',
      'noreferrer'
    );
  };

  const renderItemOverage = () => {
    if (!userHasReconInfoPermission || !item?.overage?.amount) {
      return (
        <>
          <div className="VehicleStepReportModal-ArrowContainer" />
          {strings.EMPTY_VALUE}
        </>
      );
    }

    const arrowType =
      compareItemOverage(item) === ratings.GOOD ? 'ArrowUp' : 'ArrowDown';

    return (
      <>
        <div className={`VehicleStepReportModal-ArrowContainer ${arrowType}`}>
          <ArrowUpBold size={16} />
        </div>
        {currencyFormatter(Math.abs(item?.overage?.amount) || 0)}
      </>
    );
  };

  const itemOverageClassName = userHasReconInfoPermission
    ? `VehicleStepReportModal-${compareItemOverage(item)}`
    : '';

  return (
    <div className="VehicleStepReportModal-tableDataRow">
      <div className="VehicleStepReportModal-tableDataRow-vehicleInfo">
        <div>
          <div
            className="VehicleStepReportModal-tableDataRow-stockNumber"
            onClick={() => openVDPScreenTabForVehicleItem(item)}
          >
            {item?.vehicle?.stockNumber || ''}
          </div>
          {`${item?.vehicle?.year || ''} ${item?.vehicle?.make || ''} ${
            item?.vehicle?.model || ''
          }`}
        </div>
      </div>
      <div className="VehicleStepReportModal-tableDataRow-reconditionEffort">
        <div>
          {getFormattedDurationFromSeconds(item.retailReadyTimeInStepAsSeconds)}
        </div>
        <div>
          {getFormattedDurationFromSeconds(item.reconTimeInStepAsSeconds)}
        </div>
        <div>
          {getFormattedDurationFromSeconds(item?.retailReadyTimeAsSeconds) ||
            ''}
        </div>
        <div>
          {getFormattedDurationFromSeconds(item?.reconTimeAsSeconds) || ''}
        </div>
      </div>
      <div className="VehicleStepReportModal-tableDataRow-reconditionCost">
        <div>
          {item?.estimatedRecon && userHasReconInfoPermission
            ? currencyFormatter(item?.estimatedRecon?.amount || 0)
            : strings.EMPTY_VALUE}
        </div>
        <div>
          {item?.actualRecon && userHasReconInfoPermission
            ? currencyFormatter(item?.actualRecon?.amount || 0)
            : strings.EMPTY_VALUE}
        </div>
        <div
          className={`VehicleStepReportModal-itemOverage ${itemOverageClassName}`}
        >
          {renderItemOverage()}
        </div>
      </div>
    </div>
  );
};
