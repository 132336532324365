import {
  Box,
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import { DriveEta, InfoOutlined } from '@material-ui/icons';
import { useState } from 'react';

import { strings } from 'common';
import theme from 'common/theme';
import { VehicleSummary } from 'models';

import VehicleDetailsModal from '../VehicleDetailsModal';

type Props = {
  vehicleSummary: VehicleSummary;
};

const VehicleDetails = ({ vehicleSummary }: Props) => {
  const classes = useStyles();
  const vehicleCard = vehicleSummary?.vehicleCard;
  const [showModal, setShowModal] = useState(false);

  if (!vehicleCard) {
    return null;
  }

  return (
    <>
      <Paper
        elevation={0}
        className={`${classes.container}`}
        onClick={() => setShowModal(true)}
      >
        <Paper elevation={3} className={classes.vehicleContainer}>
          {vehicleCard.vehicleImage ? (
            <img
              className={classes.vehicleImage}
              src={vehicleCard.vehicleImage}
              alt="vehicle"
            />
          ) : (
            <Box className={classes.vehicleIconContainer}>
              <DriveEta className={classes.vehicleIcon} />
            </Box>
          )}
          <Box className={classes.vehicleDetailsContainer}>
            <Box>
              <Box className={classes.vehicleDetailsContainerText}>
                <Typography variant="body2" style={{ fontWeight: 600 }} noWrap>
                  {vehicleCard.year} {vehicleCard.make}&nbsp;
                </Typography>
                <Typography variant="body2" noWrap>
                  {vehicleCard.model}
                </Typography>
              </Box>
              <Typography variant="caption" className={classes.label}>
                {strings.MVDP_VIN}&nbsp;
              </Typography>
              <Typography variant="caption" className={classes.labelValue}>
                {vehicleCard.vin}
              </Typography>
              <Typography
                variant="caption"
                className={classes.label}
                style={{ paddingLeft: theme.spacing(1) }}
              >
                {strings.MVDP_STK}&nbsp;
              </Typography>
              <Typography variant="caption" className={classes.labelValue}>
                {vehicleCard.stockNumber}
              </Typography>
            </Box>
          </Box>
          <Box>
            <InfoOutlined className={classes.icon} />
          </Box>
        </Paper>
      </Paper>
      <VehicleDetailsModal
        open={showModal}
        onClose={() => setShowModal(false)}
        vehicle={vehicleSummary}
      />
    </>
  );
};

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    container: {
      padding: `0 ${theme.spacing(2)}px`,
      paddingBottom: theme.spacing(0.5),
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
    },
    vehicleContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      border: `1px solid ${theme.palette.primary.dark}`,
      borderRadius: '4px',
      padding: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
      columnGap: theme.spacing(2),
    },
    vehicleImage: {
      width: '48px',
      objectFit: 'cover',
      height: '40px',
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: '3px',
    },
    vehicleDetailsContainer: { flex: 1 },
    vehicleDetailsContainerText: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    vehicleIconContainer: {
      padding: `0 2px`,
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: '3px',
    },
    vehicleIcon: {
      fontSize: '40px',
      color: theme.palette.grey[500],
    },
    icon: {
      color: theme.palette.grey[500],
    },
    label: {
      fontSize: '10px',
      fontWeight: 600,
      color: theme.palette.grey[600],
    },
    labelValue: {
      fontSize: '11px',
      fontWeight: 600,
    },
    columnHeader: {
      display: 'flex',
      flexDirection: 'row',
      columnGap: '10px',
      marginTop: theme.spacing(2),
      alignItems: 'center',
    },
    columnCellContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      columnGap: '10px',
      backgroundColor: theme.palette.grey[200],
      padding: theme.spacing(0.8),
      borderRadius: 3,
      justifyContent: 'space-between',
    },
    columnCell: {
      textTransform: 'uppercase',
      fontWeight: 600,
      fontSize: '12px',
    },
    link: {
      textTransform: 'uppercase',
      textDecoration: 'underline',
      fontWeight: 600,
      fontSize: '12px',
      color: theme.palette.primary.dark,
    },
  })
);

export default VehicleDetails;
