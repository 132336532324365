import { useContext } from 'react';

import AccordionContext from './AccordionContext';

const useAccordionContext = () => {
  const context = useContext(AccordionContext);

  if (!context) {
    throw new Error(
      'Accordion components must be rendered as children of <Accordion />'
    );
  }

  return context;
};

export default useAccordionContext;
