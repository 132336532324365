import moment from 'moment';
import { FC } from 'react';
import { ListChildComponentProps } from 'react-window';

import { strings } from 'common';

import HistoryMessage from '../HistoryMessage';
import { HistoryLog } from './types';

interface SectionProps {
  title: string;
}

const Section: FC<SectionProps> = ({ title }) => {
  const getSectionLabel = (date: string) => {
    const historySectionDate = moment(date);
    const today = moment();
    const yesterday = moment().subtract(1, 'days');

    const isToday = historySectionDate.isSame(today, 'day');
    const isYesterday = historySectionDate.isSame(yesterday, 'day');

    return isToday
      ? strings.TODAY
      : isYesterday
      ? strings.YESTERDAY
      : historySectionDate.format('MMMM D, YYYY');
  };

  const label = getSectionLabel(title);

  return <div className="VehicleLog-section-header">{label}</div>;
};

interface VehicleLogRowData {
  historyLog: HistoryLog;
}

const VehicleLogRow: FC<ListChildComponentProps<VehicleLogRowData>> = ({
  index,
  style,
  data,
}) => {
  const { historyLog } = data;
  const historyLogRow = historyLog[index];

  return (
    <div className="VehicleLogRow" style={style}>
      {historyLogRow.kind === 'section' ? (
        <Section
          key={`section-${historyLogRow.title}`}
          title={historyLogRow.title}
        />
      ) : (
        <HistoryMessage
          key={historyLogRow.data.id}
          historyItem={historyLogRow.data}
        />
      )}
    </div>
  );
};

export default VehicleLogRow;
