import { strings } from 'common';
import { Task, TaskStatus } from 'models';

type STATUS_Type = {
  [key in TaskStatus]: string;
};

export const STATUS: STATUS_Type = {
  DRAFT: strings.STATUS_ESTIMATED,
  PENDING_APPROVAL: strings.STATUS_REQUESTED,
  APPROVED_PENDING_COMPLETE: strings.STATUS_APPROVED,
  ACCEPTED: strings.STATUS_APPROVED,
  COMPLETE: strings.STATUS_COMPLETED,
  COMPLETED: strings.STATUS_COMPLETED,
  DENIED: strings.STATUS_DENIED,
  DELETED: strings.STATUS_DELETED,
  INVOICED: strings.INVOICED,
  PAID: strings.PAID,
};

type statusOptionsType = {
  id: TaskStatus;
  value: string;
};

export const statusOptions: statusOptionsType[] = [
  {
    id: 'DRAFT',
    value: strings.STATUS_ESTIMATED,
  },
  {
    id: 'PENDING_APPROVAL',
    value: strings.STATUS_REQUESTED,
  },
  {
    id: 'APPROVED_PENDING_COMPLETE',
    value: strings.STATUS_APPROVED,
  },
  {
    id: 'COMPLETE',
    value: strings.STATUS_COMPLETED,
  },
  {
    id: 'DENIED',
    value: strings.STATUS_DENIED,
  },
];

export const canRequestTask = (
  task: Task,
  hasTaskApprovePermission: boolean
) => {
  return (
    task?.status === 'DRAFT' ||
    task?.status === 'DENIED' ||
    (task?.status === 'COMPLETE' && hasTaskApprovePermission)
  );
};

export const canApproveTask = (task: Task) => {
  return (
    task?.status === 'DRAFT' ||
    task?.status === 'PENDING_APPROVAL' ||
    task?.status === 'COMPLETE' ||
    task?.status === 'DENIED'
  );
};

export const canApproveAllRequestedTasks = (task: Task) => {
  return task?.status === 'DRAFT' || task?.status === 'PENDING_APPROVAL';
};

export const canDenyTask = (task: Task) => {
  return (
    task?.status === 'DRAFT' ||
    task?.status === 'PENDING_APPROVAL' ||
    task?.status === 'APPROVED_PENDING_COMPLETE'
  );
};

export const canCompleteTask = (task: Task) => {
  return task?.status === 'APPROVED_PENDING_COMPLETE';
};
