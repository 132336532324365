import Typography from '@material-ui/core/Typography';
import { useMatch } from '@reach/router';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  useAddMediaImageToVehicle,
  useAddMediaVideoToVehicle,
  usePresignedMediaUploadUrl,
  useUploadFileToS3,
} from 'api';
import images from 'common/images';
import permissions from 'common/permissions';
import strings from 'common/strings';
import { testIds } from 'common/testIds';
import Gallery from 'components/pages/Photos/Gallery';
import Syndication from 'components/pages/Photos/Syndication';
import Alert from 'components/shared/Alert';
import FlatVDPPaneContent from 'components/shared/FlatVDPPaneContent';
import FlatVDPPaneHeader from 'components/shared/FlatVDPPaneHeader';
import PermissionsGate from 'components/shared/PermissionsGate';
import Tabs from 'components/shared/Tabs';
import { useFileSelector, usePermissions, useWindowSize } from 'hooks';
import { VehicleSummary } from 'models';

import FlatDocumentsSection from '../FlatDocumentsSection';
import { FlatMediaContext } from '../FlatMedia';
import {
  SubmittedAttachments,
  SubmittedAttachmentState,
  useMediaUploadContext,
} from '../UploadProvider';

import './FlatMediaSection.scss';

const ALLOWED_IMAGE_FILETYPES = [
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/svg',
  'image/webp',
];
const ALLOWED_VIDEO_FILETYPES = ['video/mp4', 'video/webm'];

export const ALLOWED_MEDIA_FILETYPES = [
  ...ALLOWED_IMAGE_FILETYPES,
  ...ALLOWED_VIDEO_FILETYPES,
];

interface FlatMediaSectionProps {
  vehicle: VehicleSummary;
}

const FlatMediaSection: FC<FlatMediaSectionProps> = ({ vehicle }) => {
  const { hasPermission } = usePermissions();
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState(strings.API_MESSAGE);
  const isGalleryRoute = useMatch('gallery');
  const windowSize = useWindowSize();
  const { images: data, refetch } = useContext(FlatMediaContext);

  const { uploadFileToS3 } = useUploadFileToS3();
  const { getNewPresignedMediaUploadUrl } = usePresignedMediaUploadUrl();
  const { addMediaImageToVehicle } = useAddMediaImageToVehicle();
  const { addMediaVideoToVehicle } = useAddMediaVideoToVehicle();
  const { submittedMedia, setSubmittedMedia } = useMediaUploadContext();

  const syndicationCount = useMemo(
    () =>
      data.reduce((acc, image) => {
        if (image.shot !== undefined && image.shot !== strings.UNKNOWN_OPTION) {
          return acc + 1;
        }
        return acc;
      }, 0),
    [data]
  );

  const galleryTitle = windowSize.isMobileViewport()
    ? strings.GALLERY
    : strings.GALLERY_WITH_COUNT(data.length);
  const syndicationTitle = windowSize.isMobileViewport()
    ? strings.SYNDICATION
    : strings.SYNDICATION_WITH_COUNT(syndicationCount);

  const handleErrorClose = useCallback(() => {
    setShowErrorAlert(false);
    setErrorMessage(strings.API_MESSAGE);
  }, []);

  const setMediaState = useCallback(
    (index: number, state: SubmittedAttachmentState) => {
      setSubmittedMedia((curSubmittedMedia) =>
        curSubmittedMedia.map((mapState, mapIndex) => {
          if (mapIndex !== index) {
            return mapState;
          }

          return {
            ...mapState,
            ...state,
          };
        })
      );
    },
    [setSubmittedMedia]
  );

  const onChange = useCallback(
    async (event: Event) => {
      const target = event.target as HTMLInputElement;
      const files = target.files ? Array.from(target.files) : [];
      const vehicleId = vehicle.vehicleCard.id;

      if (!files.length || !vehicleId) {
        return;
      }

      const media: SubmittedAttachments = files.map((file) => ({
        vehicleId,
        file,
        status: 'pending',
      }));

      setSubmittedMedia(media);

      const requests = media.map(async ({ file }, index) => {
        const {
          data: { s3AttachmentId, uploadUrl },
        } = await getNewPresignedMediaUploadUrl({
          vehicleId,
          contentType: file.type,
        });

        setMediaState(index, { status: 'uploading', progress: 0 });

        await uploadFileToS3({
          file,
          presignedUrl: uploadUrl,
          onUploadProgress: ({ loaded, total }) => {
            if (total !== undefined) {
              setMediaState(index, {
                status: 'uploading',
                progress: (loaded / total) * 100,
              });
            }
          },
        });

        if (ALLOWED_IMAGE_FILETYPES.includes(file.type)) {
          await addMediaImageToVehicle({
            vehicleId,
            s3AttachmentId,
            attachmentName: file.name,
          });
        } else {
          await addMediaVideoToVehicle({
            vehicleId,
            s3AttachmentId,
            attachmentName: file.name,
          });
        }

        setMediaState(index, { status: 'completed' });
      });

      try {
        await Promise.all(requests);
      } catch (error) {
        debugger;
        if (error instanceof Error) {
          setErrorMessage(error.message || strings.API_MESSAGE);
        } else {
          setErrorMessage(strings.API_MESSAGE);
        }

        setShowErrorAlert(true);
      } finally {
        setSubmittedMedia([]);
      }

      setErrorMessage('');
      setShowErrorAlert(false);

      refetch();
    },
    [
      addMediaImageToVehicle,
      addMediaVideoToVehicle,
      getNewPresignedMediaUploadUrl,
      refetch,
      setMediaState,
      setSubmittedMedia,
      uploadFileToS3,
      vehicle.vehicleCard.id,
    ]
  );

  const onError = useCallback((event) => {
    setErrorMessage(event || strings.API_MESSAGE);
    setShowErrorAlert(true);
  }, []);

  const [fileSelector] = useFileSelector(
    onChange,
    onError,
    ALLOWED_MEDIA_FILETYPES.join(',')
  );

  useEffect(() => {
    if (submittedMedia.length) {
      fileSelector.value = '';
    }
  }, [fileSelector, submittedMedia.length]);

  return (
    <div className="FlatMediaSection full-height">
      <FlatVDPPaneHeader
        vehicleId={vehicle?.vehicleCard?.id}
        className="FlatMedia-header"
      >
        <div
          data-vas-testing={testIds.MEDIA_AND_DOCS_CONTAINER_TITLE}
          className="Section-title"
        >
          {strings.MEDIA}
        </div>
        <PermissionsGate
          permissions={[permissions.INVENTORY_VDP_PHOTOS_CREATE]}
        >
          <button
            type="button"
            className="FlatMedia-upload-button"
            onClick={() => fileSelector.click()}
          >
            <img
              data-vas-testing={testIds.MEDIA_UPLOAD_BUTTON_ICON}
              className="FlatMedia-upload-button-icon"
              src={images.UploadIcon}
              alt="Upload button"
            />
          </button>
        </PermissionsGate>
      </FlatVDPPaneHeader>
      <FlatVDPPaneContent className="FlatMedia-media-content">
        {windowSize.isMobileViewport() && isGalleryRoute && (
          <PermissionsGate
            permissions={[permissions.INVENTORY_VDP_PHOTOS_CREATE]}
          >
            <button
              type="button"
              className="FlatMedia-upload-button FlatMedia-upload-button-mobile"
              onClick={() => fileSelector.click()}
            >
              <img
                className="FlatMedia-upload-button-icon"
                src={images.UploadIcon}
                alt="Upload button"
              />
            </button>
          </PermissionsGate>
        )}
        {hasPermission(permissions.INVENTORY_VDP_PHOTOS_VIEW) ? (
          <Tabs
            defaultPath="gallery"
            basePath="media"
            tabContainerClassName="FlatMedia-tab-container"
            tabContentClassName="FlatMedia-tab-content vertical-scroll"
            className="FlatMedia-tabs"
          >
            <Gallery title={galleryTitle} path="gallery" vehicle={vehicle} />
            <Syndication
              reload={refetch}
              title={syndicationTitle}
              path="syndication"
              vehicle={vehicle}
              images={data}
            />
            {windowSize.isMobileViewport() && (
              <FlatDocumentsSection
                vehicle={vehicle}
                title={strings.DOCUMENTS}
                path="documents"
              />
            )}
          </Tabs>
        ) : (
          <div className="d-flex align-items-center flex-column flex-grow justify-content-center px-4 pt-5">
            <Typography variant="subtitle1" className="no-note-title">
              {strings.PHOTOS_PERMISSION_DENIED}
            </Typography>
            <Typography variant="body2" className="no-note-message">
              {strings.PHOTOS_PERMISSION_DENIED_SUB}
            </Typography>
          </div>
        )}
      </FlatVDPPaneContent>
      <Alert
        open={showErrorAlert}
        contentProps={{
          message: errorMessage,
          onClose: handleErrorClose,
          variant: 'error',
        }}
      />
    </div>
  );
};

export default FlatMediaSection;
