import { useContext } from 'react';

import ActiveUploadsContext from './AttachmentUploadContext';

const useAttachmentUploadContext = () => {
  const context = useContext(ActiveUploadsContext);

  if (!context) {
    throw new Error(
      'Tasks components must be rendered as children of ActiveUploadsProvider'
    );
  }

  return context;
};

export default useAttachmentUploadContext;
