import {
  QueryClient,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import {
  APIResponse,
  defaultMetaQueryFn,
  defaultMutationFn,
  defaultTextPlainMutationFn,
  getVehicleListParams,
} from 'api';
import { getDealerInventoryQueryKey } from 'api/invoicing/useGetDealerInventoryQuery';
import { getVendorInventoryQueryKey } from 'api/invoicing/useGetVendorInventoryQuery';
import {
  Attachment,
  Note,
  NotesByDate,
  NotesByUser,
  NoteV2,
  VehicleSummary,
} from 'models';
import { getCalendarDateStringFromDate } from 'utils/time';

export async function fetchNote(inventoryId: string, noteId: string) {
  const path = `/inventory/${inventoryId}/notes/${noteId}`;
  return defaultMetaQueryFn(path);
}

export function useDeleteNote() {
  const queryClient = useQueryClient();

  type DeleteNoteData = { vehicleId: string; noteId: string };
  return useMutation(
    ({ vehicleId, noteId }: DeleteNoteData) => {
      const path = `/inventory/${vehicleId}/notes/${noteId}`;
      return defaultMutationFn(path, 'DELETE');
    },
    {
      onSuccess: (data, { vehicleId, noteId }: DeleteNoteData) => {
        deleteNoteFromQueryCache(vehicleId, queryClient, noteId);
      },
    }
  );
}

export function useDeleteNoteAttachment() {
  const queryClient = useQueryClient();

  type NoteAttachmentData = {
    vehicleId: string;
    noteId: string;
    attachmentId: string;
  };
  const mutation = useMutation(
    ({ vehicleId, noteId, attachmentId }: NoteAttachmentData) => {
      const path = `/inventory/${vehicleId}/notes/${noteId}/attachments/${attachmentId}`;
      return defaultMutationFn(path, 'DELETE');
    }
  );

  async function deleteNoteAttachmentAsync({
    vehicleId,
    noteId,
    attachmentId,
  }: NoteAttachmentData) {
    return await mutation.mutateAsync(
      {
        vehicleId,
        noteId,
        attachmentId,
      },
      {
        onSuccess: () =>
          deleteNoteFromQueryCache(
            vehicleId,
            queryClient,
            noteId,
            attachmentId
          ),
      }
    );
  }

  return {
    ...mutation,
    deleteNoteAttachmentAsync,
  };
}

export function useUpdateNote(vehicleId: string) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ noteId, note }: { noteId: string; note: string }) => {
      const path = `/inventory/${vehicleId}/notes/${noteId}`;
      return defaultTextPlainMutationFn(path, 'PUT', note);
    },
    {
      onSuccess: (data) => {
        updateNotesQueryCache(vehicleId, queryClient, data.data);
      },
    }
  );
}

type AddNoteData = {
  vehicleId: string;
  note: string;
  skipAddToCache?: boolean;
};
export function useAddNote() {
  const queryClient = useQueryClient();
  return useMutation(
    ({ vehicleId, note }: AddNoteData) => {
      const path = `/inventory/${vehicleId}/notes`;
      return defaultTextPlainMutationFn(path, 'POST', note);
    },
    {
      onSuccess(data, variables, context) {
        const { vehicleId, skipAddToCache } = variables;
        if (skipAddToCache) {
          return;
        }
        addNoteToQueryCache(vehicleId, queryClient, data.data);
      },
    }
  );
}

type PresignedAttachmentUploadUrlInput = {
  vehicleId: string;
  noteId: string;
  contentType: string;
};

export function usePresignedNoteAttachmentUploadUrl() {
  const getNewPresignedAttachmentUploadUrl = async ({
    vehicleId,
    noteId,
    contentType,
  }: PresignedAttachmentUploadUrlInput) => {
    const path = `/inventory/${vehicleId}/notes/${noteId}/attachments/upload`;

    const params = new URLSearchParams();
    params.set('contentType', contentType);

    const partialUrl = `${path}?${params}`;

    return defaultMetaQueryFn(partialUrl);
  };

  return {
    getNewPresignedAttachmentUploadUrl,
  };
}

type AddAttachmentToNoteInput = {
  vehicleId: string;
  noteId: string;
  attachmentName: string;
  s3AttachmentId: string;
};

export function useAddAttachmentToNote() {
  return useMutation<
    APIResponse<Attachment>,
    unknown,
    AddAttachmentToNoteInput
  >(({ vehicleId, noteId, attachmentName, s3AttachmentId }) => {
    const path = `/inventory/${vehicleId}/notes/${noteId}/attachments`;

    const params = new URLSearchParams();
    params.set('attachmentName', attachmentName);
    params.set('s3AttachmentId', s3AttachmentId);

    const partialUrl = `${path}?${params}`;

    return defaultMutationFn(partialUrl, 'POST');
  });
}

function addToInventoryNotes(inventoryNotes: NotesByDate[], note: Note) {
  const { sender, note: NoteV2, timestamp, attachments, id } = note ?? {};
  const date = getCalendarDateStringFromDate(new Date(timestamp));
  const fullName = `${sender?.firstName} ${sender?.lastName}`.trim();
  const newNoteV2 = { note: NoteV2, time: timestamp, attachments, id };
  const newNoteByUser = { fullName, notes: [newNoteV2], userId: sender?.id };
  const newNoteByDate = { date, timestamp, notesByUser: [newNoteByUser] };

  if (!inventoryNotes?.length) {
    inventoryNotes = [newNoteByDate];
  } else {
    let foundDate = false;
    inventoryNotes = inventoryNotes.map((notesByDate) => {
      const { notesByUser = [] } = notesByDate ?? {};

      if (notesByDate.date === date) {
        foundDate = true;
        const lastNote = notesByUser[notesByUser.length - 1];

        if (lastNote.fullName === fullName) {
          lastNote.notes = lastNote.notes?.concat(newNoteV2);
        } else {
          notesByDate.notesByUser = notesByUser.concat(newNoteByUser);
        }
      }
      return notesByDate;
    });

    if (!foundDate) {
      inventoryNotes = inventoryNotes.concat(newNoteByDate);
    }
  }
  return inventoryNotes;
}

export function addNoteToQueryCache(
  vehicleId: string | null,
  queryClient: QueryClient,
  note: Note
) {
  if (note?.attachments || note?.note) {
    const inventoryNotesQueryKey = [`/inventory/${vehicleId}/inventoryNotes`];
    if (queryClient.getQueryData(inventoryNotesQueryKey)) {
      queryClient.setQueryData(inventoryNotesQueryKey, (data: any) => {
        if (data?.data) {
          data.data = addToInventoryNotes(data.data, note);
        }
        return data;
      });
    }

    const vehicleQueryKey = [`/inventory/${vehicleId}`];
    if (queryClient.getQueryData(vehicleQueryKey)) {
      queryClient.setQueryData(vehicleQueryKey, (data: any) => {
        if (data?.data) {
          data.data.notes = addToInventoryNotes(data.data?.notes, note);
        }
        return data;
      });
    }

    let invalidateInventoryQuery = false;
    const { queryKey: vehicleListQueryKey } = getVehicleListParams();
    queryClient.setQueryData(vehicleListQueryKey, (data: any) => {
      if (!data) {
        // Inventory infiniteQuery is inactive.  Data will not be available
        invalidateInventoryQuery = true;
      }
      data?.pages?.map((page: APIResponse<VehicleSummary[]>) => {
        page.data?.map((summary) => {
          if (summary.vehicleCard?.id === vehicleId) {
            summary.notesByDate = addToInventoryNotes(
              summary?.notesByDate,
              note
            );
          }
          return summary;
        });
        return page;
      });
      return data;
    });

    // https://tanstack.com/query/v4/docs/react/reference/QueryClient#queryclientinvalidatequeries
    if (invalidateInventoryQuery) {
      queryClient.invalidateQueries(['/inventory'], {
        refetchType: 'all', // all queries that match the refetch predicate will be refetched in the background.
        refetchPage: (page: APIResponse<VehicleSummary[]>) => {
          const invalidatePage = page.data?.some(
            (summary) => summary.vehicleCard?.id === vehicleId // Vehicle is found that needs to be invalidated to show the new note.
          );
          return invalidatePage;
        },
      });
    }

    queryClient.setQueryData(getVendorInventoryQueryKey(), (data: any) => {
      data?.data?.map((summary: VehicleSummary) => {
        if (summary.vehicleCard?.id === vehicleId) {
          summary.notesByDate = addToInventoryNotes(summary?.notesByDate, note);
        }
        return summary;
      });
      return data;
    });

    queryClient.setQueryData(getDealerInventoryQueryKey(), (data: any) => {
      data?.data?.map((summary: VehicleSummary) => {
        if (summary.vehicleCard?.id === vehicleId) {
          summary.notesByDate = addToInventoryNotes(summary?.notesByDate, note);
        }
        return summary;
      });
      return data;
    });
  }
}

function deleteFromInventoryNotes(
  inventoryNotes: NotesByDate[],
  noteId: string,
  attachmentId?: string
) {
  inventoryNotes = inventoryNotes?.reduce<NotesByDate[]>(
    (prevNotes, currentNote) => {
      currentNote.notesByUser = currentNote.notesByUser.reduce<NotesByUser[]>(
        (prevInnerNotes, currentInnerNote) => {
          currentInnerNote.notes = currentInnerNote.notes.reduce<NoteV2[]>(
            (prevMessages, currentMessage) => {
              if (currentMessage.id === noteId) {
                if (attachmentId) {
                  currentMessage.attachments =
                    currentMessage.attachments.reduce<Attachment[]>(
                      (prevAttachments, currAttachment) => {
                        if (currAttachment.id === attachmentId) {
                          return prevAttachments;
                        }
                        return prevAttachments.concat(currAttachment);
                      },
                      []
                    );
                } else {
                  return prevMessages;
                }
              }
              return prevMessages.concat(currentMessage);
            },
            []
          );
          if (!currentInnerNote?.notes?.length) {
            return prevInnerNotes;
          }
          return prevInnerNotes.concat(currentInnerNote);
        },
        []
      );
      if (!currentNote?.notesByUser?.length) {
        return prevNotes;
      }
      return prevNotes.concat(currentNote);
    },
    []
  );
  return inventoryNotes;
}

function deleteNoteFromQueryCache(
  vehicleId: string | null,
  queryClient: QueryClient,
  noteId: string,
  attachmentId?: string
) {
  const inventoryNotesQueryKey = [`/inventory/${vehicleId}/inventoryNotes`];
  if (attachmentId) {
    queryClient.invalidateQueries(inventoryNotesQueryKey);
  } else {
    if (queryClient.getQueryData(inventoryNotesQueryKey)) {
      queryClient.setQueryData(inventoryNotesQueryKey, (data: any) => {
        if (data?.data) {
          data.data = deleteFromInventoryNotes(data.data, noteId, attachmentId);
        }
        return data;
      });
    }
  }

  const vehicleQueryKey = [`/inventory/${vehicleId}`];
  if (queryClient.getQueryData(vehicleQueryKey)) {
    queryClient.setQueryData(vehicleQueryKey, (data: any) => {
      if (data?.data) {
        data.data.notes = deleteFromInventoryNotes(
          data.data?.notes,
          noteId,
          attachmentId
        );
      }
      return data;
    });
  }

  let invalidateInventoryQuery = false;
  const { queryKey: vehicleListQueryKey } = getVehicleListParams();
  queryClient.setQueryData(vehicleListQueryKey, (data: any) => {
    if (!data) {
      // Inventory infiniteQuery is inactive.  Data will not be available
      invalidateInventoryQuery = true;
    }
    data?.pages?.map((page: APIResponse<VehicleSummary[]>) => {
      page.data?.map((summary) => {
        if (summary.vehicleCard?.id === vehicleId) {
          summary.notesByDate = deleteFromInventoryNotes(
            summary?.notesByDate,
            noteId,
            attachmentId
          );
        }
        return summary;
      });
      return page;
    });
    return data;
  });

  // https://tanstack.com/query/v4/docs/react/reference/QueryClient#queryclientinvalidatequeries
  if (invalidateInventoryQuery) {
    queryClient.invalidateQueries(['/inventory'], {
      refetchType: 'all', // all queries that match the refetch predicate will be refetched in the background.
      refetchPage: (page: APIResponse<VehicleSummary[]>) => {
        const invalidatePage = page.data?.some(
          (summary) => summary.vehicleCard?.id === vehicleId // Vehicle is found that needs to be invalidated to show the new note.
        );
        return invalidatePage;
      },
    });
  }

  queryClient.setQueryData(getVendorInventoryQueryKey(), (data: any) => {
    data?.data?.map((summary: VehicleSummary) => {
      if (summary.vehicleCard?.id === vehicleId) {
        summary.notesByDate = deleteFromInventoryNotes(
          summary?.notesByDate,
          noteId,
          attachmentId
        );
      }
      return summary;
    });
    return data;
  });

  queryClient.setQueryData(getDealerInventoryQueryKey(), (data: any) => {
    data?.data?.map((summary: VehicleSummary) => {
      if (summary.vehicleCard?.id === vehicleId) {
        summary.notesByDate = deleteFromInventoryNotes(
          summary?.notesByDate,
          noteId,
          attachmentId
        );
      }
      return summary;
    });
    return data;
  });
}

function updateNotesQueryCache(
  vehicleId: string | null,
  queryClient: QueryClient,
  note: Note
) {
  const queryKey = [`/inventory/${vehicleId}/inventoryNotes`];
  queryClient.invalidateQueries(queryKey);
  queryClient.invalidateQueries(getVendorInventoryQueryKey());
  queryClient.invalidateQueries(getDealerInventoryQueryKey());

  // https://tanstack.com/query/v4/docs/react/reference/QueryClient#queryclientinvalidatequeries
  queryClient.invalidateQueries(['/inventory'], {
    refetchType: 'all', // all queries that match the refetch predicate will be refetched in the background.
    refetchPage: (page: APIResponse<VehicleSummary[]>) => {
      const invalidatePage = page.data?.some(
        (summary) => summary.vehicleCard?.id === vehicleId // Vehicle is found that needs to be invalidated to show the new note.
      );
      return invalidatePage;
    },
  });
}
