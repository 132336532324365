import { TextareaAutosize } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Select from 'react-select';

import {
  useCreateOrUpdateVehicleTask,
  useCurrentLocation,
  useTaskTemplates,
  useUsers,
  useVehicleTask,
} from 'api';
import strings from 'common/strings';
import Checkbox from 'components/shared/Inputs/Checkbox';
import { useTaskPermissions, useWindowSize } from 'hooks';
import { Task, TaskStatus, VehicleSummary } from 'models';
import { addDollarSymbol } from 'utils/formatter';
import { STATUS } from 'utils/tasks';
import { getUsersFullName } from 'utils/user';

import ActionMenu from '../../../shared/TaskActionMenu';
import TaskPhotos from '../TaskPhotos';
import { getTaskRowTestIds, taskFromTaskTemplate } from '../taskUtils';
import FlatTaskInput from './FlatTaskInput';
import {
  customDropdownComponents,
  customDropdownStyles,
  getDropdownTheme,
} from './utils';

import './FlatTask.scss';

interface FlatTaskProps {
  position: number;
  taskId?: string;
  vehicle: VehicleSummary;
  checked?: boolean;
  pinned?: boolean;
  printPressed: boolean;
  onDeleteTask: (task: Task) => void;
  onTaskCheckboxClick: (task: Task) => void;
  onRequestClick: (task: Task) => void;
  onApproveClick: (task: Task) => void;
  onApproveAllClick: (status: TaskStatus) => void;
  onDenyClick: (task: Task) => void;
  onDenyAllClick: (status: TaskStatus) => void;
  onCompleteClick: (task: Task) => void;
}

const FlatTask: FC<FlatTaskProps> = ({
  position,
  taskId,
  checked = false,
  pinned = false,
  vehicle,
  printPressed,
  onTaskCheckboxClick,
  onRequestClick,
  onApproveClick,
  onApproveAllClick,
  onDenyClick,
  onDenyAllClick,
  onCompleteClick,
}) => {
  const tenant = useCurrentLocation();
  const tenantId = tenant?.id;

  // Hooks
  const { data: taskTemplateData } = useTaskTemplates();
  const { data: taskTemplates = [] } = taskTemplateData ?? {};

  const { data: userData } = useUsers();
  const { data: users = [] } = userData ?? {};

  const { data: taskData } = useVehicleTask(vehicle?.vehicleCard?.id, taskId);
  const { data: task = {} as Task } = taskData ?? {};

  const createOrUpdateVehicleTaskQuery = useCreateOrUpdateVehicleTask(
    vehicle?.vehicleCard?.id
  );

  const windowSize = useWindowSize();
  const descriptionRef = useRef<HTMLTextAreaElement>(null);

  const [isAddingCustomTaskType, setIsAddingCustomTaskType] = useState(false);
  const [customTypeText, setCustomTypeText] = useState('');

  // Classes
  const pinnedClassName = pinned ? 'FlatTask-pinned' : '';

  // Permissions
  const {
    hasTaskUpdatePermission,
    hasTaskAttachmentViewPermission,
    hasTaskLaborCostViewPermission,
    hasTaskLaborCostUpdatePermission,
    hasTaskPartsCostViewPermission,
    hasTaskPartsCostUpdatePermission,
  } = useTaskPermissions();

  const taskHasInvoice = !!task.invoiceId;

  const disabled =
    task.status === 'APPROVED_PENDING_COMPLETE' ||
    task.status === 'COMPLETE' ||
    task.status === 'DENIED' ||
    !hasTaskUpdatePermission ||
    taskHasInvoice;

  const photosDisabled =
    task.status === 'COMPLETE' ||
    task.status === 'DENIED' ||
    !hasTaskUpdatePermission ||
    taskHasInvoice;

  const assigneeDisabled =
    task.status === 'COMPLETE' ||
    task.status === 'DENIED' ||
    !hasTaskUpdatePermission ||
    taskHasInvoice;

  // helpers
  const focusDescription = useCallback(() => {
    if (descriptionRef && descriptionRef.current) {
      descriptionRef.current.focus();
    }
  }, []);

  const handleDescriptionColPrint = (task: Task) => {
    return task.notes ? task.notes : strings.ENTER_DESCRIPTION;
  };

  // Set up dropdowns
  const taskTypeDropdownOptions = useMemo(
    () =>
      taskTemplates.map((taskTemplate) => ({
        value: taskTemplate.id,
        label: taskTemplate.label,
      })),
    [taskTemplates]
  );
  const taskTypeSelectedDropdownOption = useMemo(() => {
    let data: { value: string; label: string; tenantName?: string } = {
      value: task.taskTemplateId || 'no-id',
      label: task.label || 'Select Task Type',
    };
    if (tenantId !== task.tenantId) {
      data['tenantName'] = task.tenantName;
    }
    return data;
  }, [task, tenantId]);

  const userDropdownOptions = useMemo(
    () =>
      users.map((user) => ({
        value: user.id || strings.EMPTY_VALUE,
        label: getUsersFullName(strings.EMPTY_VALUE, user),
      })),
    [users]
  );

  const userSelectedDropdownOption = useMemo(
    () => ({
      value: task?.assignedTo?.id || 'no-user-id',
      label: getUsersFullName('Select Assignee', task.assignedTo),
    }),
    [task.assignedTo]
  );

  const taskRowTestIds = useMemo(
    () => getTaskRowTestIds('', position),
    [position]
  );

  const updateRateOrHours = useCallback(
    (rate: string | number, hours: string | number) => {
      const rateFloat = parseFloat(Number(rate).toFixed(2));
      const hoursFloat = parseFloat(Number(hours).toFixed(2));
      task.laborRate = {
        amount: rateFloat,
        currency: task.laborRate?.currency,
      };
      task.laborHours = hoursFloat;
      task.laborPrice = {
        amount: parseFloat(Number(rateFloat * hoursFloat).toFixed(2)),
        currency: task.laborPrice?.currency,
      };
      createOrUpdateVehicleTaskQuery.mutate(task);
    },
    [createOrUpdateVehicleTaskQuery, task]
  );

  const createOrUpdateCustomTaskType = useCallback(async () => {
    if (isAddingCustomTaskType && customTypeText.trim().length > 0) {
      task.label = customTypeText;
      createOrUpdateVehicleTaskQuery.mutateAsync(task);
      focusDescription();
    }
  }, [
    createOrUpdateVehicleTaskQuery,
    customTypeText,
    focusDescription,
    isAddingCustomTaskType,
    task,
  ]);

  // TODO: remove ephemeral
  const ephemeral = !taskId;

  // TODO: break complex columns out into their own components
  return (
    <div
      id={`task-row-${position}`}
      role="none"
      className={`FlatTask ${pinnedClassName}`}
    >
      <div className="FlatTask-checkbox-col">
        {createOrUpdateVehicleTaskQuery.isLoading ? (
          <div className="Loading-centered">
            <Spinner size="sm" animation="border" />
          </div>
        ) : (
          <Checkbox
            onClick={() => onTaskCheckboxClick(task)}
            label=""
            id={`task-checkbox-${position}`}
            name={`${task.id}-checkbox`}
            selected={checked}
            disabled={!hasTaskUpdatePermission}
          />
        )}
      </div>

      <div className="FlatTask-sm-label FlatTask-status-label">
        {strings.STATUS}
      </div>
      <div
        className={`FlatTask-status-col FlatTask-status-${STATUS[task.status]}`}
        id={`task-status-${position}`}
      >
        {STATUS[task.status]}
      </div>

      <div className="FlatTask-sm-label FlatTask-task-label">
        {strings.TASK}
      </div>
      <div
        id={`task-select-task-input-${position}`}
        className="FlatTask-task-col"
      >
        {printPressed && taskTypeSelectedDropdownOption.label}
        {isAddingCustomTaskType && (
          <div className="FlatTask-custom-type-input">
            <input
              id={`task-custom-input-${position}`}
              placeholder={strings.CUSTOM_PLACEHOLDER}
              type="text"
              value={customTypeText}
              autoFocus
              onChange={(event) => setCustomTypeText(event.target.value)}
              onBlur={() => {
                task.label = customTypeText;
                if (ephemeral && task.status === 'APPROVED_PENDING_COMPLETE') {
                  return;
                }
                createOrUpdateCustomTaskType();
              }}
              disabled={disabled}
            />
            <div
              role="none"
              onMouseDown={(event) => {
                event.stopPropagation();
                setIsAddingCustomTaskType(false);
              }}
            >
              <Close className="FlatTask-close-icon" />
            </div>
          </div>
        )}
        {!printPressed && !isAddingCustomTaskType && (
          <div className="FlatTask-task-container">
            <Select
              classNamePrefix={taskRowTestIds.taskTypeClass}
              placeholder={strings.TASK_TYPE_PLACEHOLDER}
              value={taskTypeSelectedDropdownOption}
              options={taskTypeDropdownOptions}
              isDisabled={disabled}
              theme={getDropdownTheme}
              components={customDropdownComponents(() => {})}
              styles={customDropdownStyles(windowSize.isMobileViewport())}
              onChange={async ({ value: id }: any) => {
                const taskTemplate =
                  id === 'custom'
                    ? taskTemplates[0]
                    : taskTemplates.find((template) => template.id === id);

                const updatedTask = taskFromTaskTemplate(task, taskTemplate);

                if (id === 'custom') {
                  setIsAddingCustomTaskType(true);
                  return;
                }

                createOrUpdateVehicleTaskQuery.mutateAsync(updatedTask);
                focusDescription();
              }}
            />
            {taskTypeSelectedDropdownOption.tenantName && (
              <div className="FlatTask-tenant-label">
                {taskTypeSelectedDropdownOption.tenantName}
              </div>
            )}
          </div>
        )}
      </div>

      <div className="FlatTask-sm-label FlatTask-description-label">
        {strings.DESCRIPTION}
      </div>
      <div className="FlatTask-description-col">
        {printPressed ? (
          handleDescriptionColPrint(task)
        ) : (
          <TextareaAutosize
            id={`task-description-input-${position}`}
            ref={descriptionRef}
            className={`FlatTask-description-col`} //TODO
            defaultValue={task.notes}
            placeholder={strings.ENTER_DESCRIPTION}
            onBlur={(event) => {
              task.notes = event.target.value;
              createOrUpdateVehicleTaskQuery.mutate(task);
            }}
            disabled={disabled}
          />
        )}
      </div>

      <div className="FlatTask-sm-label FlatTask-assignee-label">
        {strings.ASSIGNEE}
      </div>
      <div className="FlatTask-assignee-col">
        {printPressed ? (
          userSelectedDropdownOption.label
        ) : (
          <Select
            id={`task-assignee-input-${position}`}
            classNamePrefix={taskRowTestIds.assigneeClass}
            placeholder={strings.TASK_TYPE_PLACEHOLDER}
            value={userSelectedDropdownOption}
            options={userDropdownOptions}
            isDisabled={assigneeDisabled}
            theme={getDropdownTheme}
            components={customDropdownComponents(() => {})}
            styles={customDropdownStyles(windowSize.isMobileViewport())}
            onChange={({ value: id }: any) => {
              const user = users.find((user) => user.id === id);
              task.assignedTo = user;
              task.assignedToId = user?.id;
              createOrUpdateVehicleTaskQuery.mutate(task);
            }}
          />
        )}
      </div>

      <div className="FlatTask-sm-label FlatTask-photos-label">
        {strings.PHOTOS}
      </div>
      <div className="FlatTask-photos-col">
        {hasTaskAttachmentViewPermission && (
          <TaskPhotos
            task={task}
            disabled={photosDisabled || ephemeral}
            vehicle={vehicle}
          />
        )}
      </div>

      <div className="FlatTask-sm-label FlatTask-costs-label">
        {strings.COSTS}
      </div>

      <div className="FlatTask-rate-col" id={`task-rate-price-${position}`}>
        <FlatTaskInput
          testId={''}
          testData={`task-rate-input-${position}`}
          disabled={disabled || !hasTaskLaborCostUpdatePermission}
          value={task.laborRate?.amount}
          onSave={(value) => updateRateOrHours(value, `${task.laborHours}`)}
          hasViewPermission={hasTaskLaborCostViewPermission}
          formatter={addDollarSymbol}
        />
      </div>
      <div className="FlatTask-hours-col" id={`task-hours-price-${position}`}>
        <FlatTaskInput
          testData={`task-hours-input-${position}`}
          testId={''} //TODO
          disabled={disabled || !hasTaskLaborCostUpdatePermission}
          value={task.laborHours}
          onSave={(value) =>
            updateRateOrHours(`${task.laborRate?.amount}`, value)
          }
          hasViewPermission={hasTaskLaborCostViewPermission}
          formatter={(value) => value}
        />
      </div>

      <div className="FlatTask-sm-label FlatTask-labor-label">
        {strings.LABOR}
      </div>
      <div
        className={`FlatTask-labor-price-col`}
        id={`task-labor-price-${position}`}
      >
        <FlatTaskInput
          testData={`task-labor-input-${position}`}
          testId={''} //TODO
          disabled={disabled || !hasTaskLaborCostUpdatePermission}
          value={task.laborPrice?.amount}
          onSave={function (value: any): void {
            task.laborRate = {
              amount: 0,
              currency: task.laborRate?.currency,
            };
            task.laborHours = 0;
            task.laborPrice = {
              amount: parseFloat(value),
              currency: task.laborPrice?.currency,
            };
            createOrUpdateVehicleTaskQuery.mutate(task);
          }}
          hasViewPermission={hasTaskLaborCostViewPermission}
          formatter={addDollarSymbol}
        />
      </div>

      <div className="FlatTask-sm-label FlatTask-parts-label">
        {strings.PARTS}
      </div>
      <div
        className={`FlatTask-parts-price-col`}
        id={`task-parts-price-${position}`}
      >
        <FlatTaskInput
          testData={`task-parts-input-${position}`}
          testId={taskRowTestIds.partsCostId}
          disabled={disabled || !hasTaskPartsCostUpdatePermission}
          value={task.partsPrice?.amount}
          onSave={(value) => {
            task.partsPrice = {
              amount: parseFloat(value),
              currency: task.partsPrice?.currency,
            };
            createOrUpdateVehicleTaskQuery.mutate(task);
          }}
          hasViewPermission={hasTaskPartsCostViewPermission}
          formatter={addDollarSymbol}
        />
      </div>

      <div className="FlatTask-sm-label FlatTask-total-label">
        {strings.TOTAL}
      </div>
      <div
        id={`tasks-total-price-${position}`}
        className={`FlatTask-total-price-col`}
      >
        {hasTaskLaborCostViewPermission && hasTaskPartsCostViewPermission
          ? addDollarSymbol(
              parseFloat(Number(task.price?.amount ?? 0).toFixed(2))
            )
          : strings.EMPTY_VALUE}
      </div>
      <div
        id={`task-action-menu-dropdown-${position}`}
        className={`FlatTask-delete-col`}
      >
        <ActionMenu
          task={task}
          vehicleId={vehicle?.vehicleCard?.id}
          status={task.status}
          onRequestClick={onRequestClick}
          onApproveClick={onApproveClick}
          onApproveAllClick={onApproveAllClick}
          onDenyClick={onDenyClick}
          onDenyAllClick={onDenyAllClick}
          onCompleteClick={onCompleteClick}
        />
      </div>
    </div>
  );
};

export default FlatTask;
