import { createContext, Dispatch, SetStateAction } from 'react';

import { SubmittedMedia } from './types';

export type MediaUploadContextData = {
  submittedMedia: SubmittedMedia;
  setSubmittedMedia: Dispatch<SetStateAction<SubmittedMedia>>;
};

export default createContext<MediaUploadContextData | null>(null);
