import { Box } from '@material-ui/core';

import { mobileHeaderHeight } from 'components/pages/FlatTasks/FlatTasksHeader/FlatTasksHeader';
import { useScrollDirection } from 'hooks';
import { Section, VehicleSummary } from 'models';

import Header from './Header';
import VehicleDetails from './VehicleDetails';

type Props = {
  onClose: () => void;
  sections: Section[];
  vehicleSummary: VehicleSummary;
  headerHeight: number;
};

export const mobileTaskHeader = 48; // Height of the task header

const MobileVDPHeader = ({
  headerHeight,
  onClose,
  sections,
  vehicleSummary,
}: Props) => {
  const showMobileTasks =
    new URLSearchParams(window.location.search).get('mobileTasks') !== null;
  const { scrollDirection, containerHeight, scrollHeight, scrollIncrement } =
    useScrollDirection('MobileVDPBody');
  const height = showMobileTasks ? 50 : mobileTaskHeader; // height of the task header
  const canHideHeader =
    scrollHeight >
    containerHeight + mobileHeaderHeight + height + scrollIncrement; // Height of Mobile Header minus task header

  let heightStyle: number | string =
    scrollDirection !== 'down' ? headerHeight + height + 'px' : 0;

  if (!canHideHeader) {
    heightStyle = headerHeight + height;
  }

  return (
    <Box
      style={{
        height: heightStyle,
      }}
    >
      <Header
        onClose={onClose}
        sections={sections}
        vehicleSummary={vehicleSummary}
      />
      <VehicleDetails vehicleSummary={vehicleSummary} />
    </Box>
  );
};

export default MobileVDPHeader;
