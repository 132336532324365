import { navigate } from '@reach/router';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';

import { useCurrentLocation, useToken, useVehicleSummary } from 'api';
import { extensionStrings } from 'common/constants';
import permissions from 'common/permissions';
import TagList from 'components/pages/VehicleCollectionBrowser/VehicleCard/components/VehicleCardTagListAndActions/TagListSrp';
import PermissionsGate from 'components/shared/PermissionsGate/PermissionsGate';
import VehicleImageView from 'components/shared/VehicleImageView';
import { Location, VehicleCardTabType } from 'models';
import { vehicleExtensionCardBuilder } from 'navigation/routes';
import {
  getExtensionModeInfo,
  ID_LENGTH,
  NewHoverTargetMessage,
  sanitizeWindowPostMessage,
  sendMessageToExtensionContentScript,
} from 'utils/extension';

import VehicleDetailsTable from '../components/VehicleCardDetailsTable';
import VehicleCardProgressBar from '../components/VehicleCardProgressBar';
import VehicleCardReconInventoryTabs from '../components/VehicleCardReconInventoryTabs';
import VehicleCardYearMakeModel from '../components/VehicleCardYearMakeModel';
import { VehicleCardContext } from '../VehicleCard';

import '../VehicleCard.scss';
import './VehicleCardExtension.scss';

// If locationId is different return rooftop.id from user.rooftops
const verifyTenantId = (
  tenantId: string,
  userCurrentTenant: Location | undefined,
  userTenantList: Location[] = []
): string | undefined => {
  const newTenant = userTenantList.find((tenant: Location) => {
    if (tenantId.length === ID_LENGTH) {
      return tenant.id === `ROOFTOP-${tenant}`;
    }

    return tenant.id === tenantId;
  });

  if (newTenant && userCurrentTenant) {
    return newTenant.id;
  }

  return undefined;
};

interface VehicleCardExtensionProps {
  path?: string;
  inventoryId?: string;
}

const VehicleCardExtension = ({ inventoryId }: VehicleCardExtensionProps) => {
  const { data: vehicleData } = useVehicleSummary(
    !inventoryId || inventoryId === 'background' ? undefined : inventoryId
  );
  const [activeCollectionType, setActiveCollectionType] =
    useState<VehicleCardTabType>(
      vehicleData?.data?.vehicleCard?.inRecon ? 'recon' : 'inventory'
    );
  const { data: tokenData } = useToken();
  const location = useCurrentLocation();
  const queryClient = useQueryClient();

  const onNewHoverTarget = useCallback(
    async (vehicleId: string, tenantId: string) => {
      let locationQueryParam = '';
      const rooftopLocationId = verifyTenantId(
        tenantId,
        location,
        tokenData?.locations
      );
      if (rooftopLocationId) {
        locationQueryParam = `&locationId=${rooftopLocationId}`;
      }
      if (vehicleData?.data?.vehicleCard?.id === vehicleId) {
        await queryClient.invalidateQueries([`/inventory/${vehicleId}`]);
      }
      const vdpCardURL = `${vehicleExtensionCardBuilder(
        vehicleId,
        rooftopLocationId
      )}${locationQueryParam}`;
      navigate(vdpCardURL);
    },
    [
      location,
      queryClient,
      tokenData?.locations,
      vehicleData?.data?.vehicleCard?.id,
    ]
  );

  const receiveMessageHandler = useCallback(
    (event: MessageEvent) => {
      if (event.source !== window.parent && !event.data?.action) {
        return;
      }

      switch (event.data.action) {
        case extensionStrings.EXTENSION_EVENT_NEW_HOVER_TARGET: {
          const newEvent = sanitizeWindowPostMessage(event) as
            | NewHoverTargetMessage
            | undefined;
          if (newEvent?.vehicleId && newEvent?.locationId) {
            onNewHoverTarget(newEvent.vehicleId, newEvent.locationId);
          }
          break;
        }
        default:
          break;
      }
    },
    [onNewHoverTarget]
  );

  useEffect(() => {
    window.addEventListener('message', receiveMessageHandler, false);
    return () => {
      window.removeEventListener('message', receiveMessageHandler);
    };
  }, [receiveMessageHandler]);

  useEffect(() => {
    setActiveCollectionType(
      vehicleData?.data?.vehicleCard?.inRecon ? 'recon' : 'inventory'
    );
  }, [vehicleData?.data?.vehicleCard?.inRecon]);

  useEffect(() => {
    sendMessageToExtensionContentScript({ action: 'extension_card_loaded' });
  }, []);

  if (!getExtensionModeInfo().enabled) {
    return null;
  }

  const onClick = () => {
    sendMessageToExtensionContentScript({
      action: 'extension_card_clicked',
    });
  };

  // TODO: clean up VehicleCard2 css
  return (
    <VehicleCardContext.Provider
      value={{
        vehicle: vehicleData?.data,
        inExtensionCardMode: true,
        isOnboarding: false,
        isShowroomMode: false,
        position: 0,
        forceShowEngageTab: false,
        currentTab: activeCollectionType,
        setCurrentTab: setActiveCollectionType,
      }}
    >
      <div className={'VehicleCardExtension VehicleCard2'}>
        <div
          className="VehicleCardExtension-clickArea"
          role="button"
          onClick={onClick}
        >
          <div className="VehicleCardExtension-vehicleInfo">
            <VehicleImageView
              imageUrl={vehicleData?.data?.vehicleCard?.vehicleImage}
              skeleton={!vehicleData?.data}
              className="VehicleImageView-showroom-size"
            />
            <VehicleCardYearMakeModel enableNavigateOnClick={false} />
          </div>
          <VehicleDetailsTable showVin={false} />
          <PermissionsGate permissions={[permissions.TAGS_VIEW]}>
            <TagList
              showEditButton
              vehicleIsSold={vehicleData?.data?.vehicleCard?.sold ?? true}
              initialTags={vehicleData?.data?.tags}
              vehicleId={vehicleData?.data?.vehicleCard?.id ?? ''}
            />
          </PermissionsGate>
        </div>

        <VehicleCardProgressBar />
        <VehicleCardReconInventoryTabs
          showingInvoicing={false}
          showNotesTab={true}
        />
      </div>
    </VehicleCardContext.Provider>
  );
};

export default VehicleCardExtension;
