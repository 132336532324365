import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { isLocalhost } from 'serviceWorkerRegistration';

import { EXTENSION_SOURCE } from 'utils/extension';

const isSafari = navigator.vendor.match(/apple/i);
const isMobile = navigator.userAgent.match(/(Android|Mobile)/i);
const urlParams = new URLSearchParams(window.location.search);
const source = urlParams.get('source') || '';
const isExtension = source === EXTENSION_SOURCE;
export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

// eslint-disable-next-line no-restricted-globals
const canInitialize =
  (window.location.protocol.match(/^https/) ||
    process.env.NODE_ENV === 'development') &&
  navigator.serviceWorker;

export const initializeFirebase = () => {
  if (
    canInitialize &&
    !isSafari &&
    !isMobile &&
    !isExtension &&
    process.env.REACT_APP_FIREBASE_PROJECTID
  ) {
    if (isLocalhost) {
      return;
    }
    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);
    navigator.serviceWorker
      .register(`${process.env.PUBLIC_URL}/service-worker.js`)
      .then((serviceWorkerRegistration) => {
        onMessage(messaging, (payload) => {
          return serviceWorkerRegistration.showNotification(
            payload.notification.title,
            {
              ...payload.notification,
              data: payload.data,
            }
          );
        });
      });
  }
};

export const getTokenWithExistingServiceWorker = () => {
  if (isLocalhost) {
    return null;
  }
  return navigator.serviceWorker
    .register(`${process.env.PUBLIC_URL}/service-worker.js`)
    .then((serviceWorkerRegistration) => {
      const messaging = getMessaging();
      return getToken(messaging, { serviceWorkerRegistration });
    });
};
