import React from 'react';

import useAccordionContext from '../useAccordionContext';

export interface ToggleProps {
  element: any;
  eventKey: string | number;
  onClick?: (event: MouseEvent) => void;
  className?: string;
}

const useAccordionClick = (
  eventKey: string | number,
  onClick?: (event: MouseEvent) => void
) => {
  const { onToggle, activeEventKey } = useAccordionContext();
  return (event: MouseEvent) => {
    onToggle(eventKey === activeEventKey ? null : eventKey);
    if (onClick) {
      onClick(event);
    }
  };
};

const Toggle: React.FC<ToggleProps> = ({
  element: Component = 'div',
  eventKey,
  onClick,
  children,
  className = '',
  ...otherProps
}) => {
  const accordionClick = useAccordionClick(eventKey, onClick);

  return (
    <Component className={className} onClick={accordionClick} {...otherProps}>
      {children}
    </Component>
  );
};

export default Toggle;
