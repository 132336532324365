import {
  createStyles,
  Divider,
  Fade,
  ListItemIcon,
  makeStyles,
  MenuList,
  MenuListProps,
  MenuItem as MuiMenuItem,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import ExploreIcon from '@material-ui/icons/Explore';
import { useMemo } from 'react';

import theme from 'common/theme';
import { useTourMenu, useTourMenuActions } from 'hooks';
import { Section } from 'models';

import { getSelectedMenuOption, MobileHeaderData } from './Header';

const RV_SERVICE_SECTIONS = [
  'velocityEngage',
  'overview',
  'vehicleInfo',
  'velocityLocate',
];

export type MenuItem = {
  id?: string;
  icon?: React.ReactElement | undefined;
  label?: string;
  navigateTo?: (vehicleId: string) => string;
  links?: {
    self: string;
  };
  isDivider?: boolean;
  isTour?: boolean;
};

export type Props = MenuListProps & {
  open: boolean;
  sections: Section[];
  mobileHeaderData: Record<string, MobileHeaderData>;
  onMenuItemClick: (menuItem: MenuItem) => void;
};

const Menu = ({
  sections,
  onMenuItemClick,
  mobileHeaderData,
  open,
  ...props
}: Props) => {
  const classes = useStyles();
  const tourMenuActions = useTourMenuActions();
  const { name: tourName } = useTourMenu();

  const menuItems = useMemo(() => {
    const menuItems: MenuItem[] = [];
    const rvServiceSections = sections?.filter((vehicleSection) =>
      RV_SERVICE_SECTIONS.includes(vehicleSection.id)
    );
    const nonRvServiceSections = sections?.filter((vehicleSection) => {
      // TODO: Once API stops sending the Docs section we can remove this
      if (vehicleSection.id === 'documents') return false;
      return !RV_SERVICE_SECTIONS.includes(vehicleSection.id);
    });
    rvServiceSections.forEach((section) => {
      const sectionDataKey = getSelectedMenuOption(section.id);
      const sectionData = mobileHeaderData[sectionDataKey];
      menuItems.push({
        id: section.id,
        label: sectionData.title,
        icon: sectionData.icon,
        links: section.links,
        navigateTo: sectionData.navigateTo,
      });
    });
    menuItems.push({ isDivider: true });
    nonRvServiceSections.forEach((section) => {
      const sectionDataKey = getSelectedMenuOption(section.id);
      const sectionData = mobileHeaderData[sectionDataKey];
      menuItems.push({
        id: section.id,
        label: sectionData.title,
        icon: sectionData.icon,
        links: section.links,
        navigateTo: sectionData.navigateTo,
      });
    });
    return menuItems;
  }, [mobileHeaderData, sections]);

  if (tourMenuActions && tourName) {
    menuItems.push({
      id: tourName,
      label: tourName,
      icon: <ExploreIcon />,
      isDivider: false,
      isTour: true,
    });
  }

  return (
    <Fade in={open}>
      <MenuList
        {...props}
        style={{
          padding: 0,
          zIndex: 2000,
          backgroundColor: theme.palette.background.paper,
          position: 'fixed',
          left: 0,
          right: 0,
        }}
      >
        <Paper elevation={10}>
          {menuItems.map((item, index) => {
            return item.isDivider ? (
              <Divider key={index} />
            ) : (
              <MuiMenuItem onClick={() => onMenuItemClick(item)} key={index}>
                {item.icon && (
                  <ListItemIcon className={classes.icon}>
                    {item.icon}
                  </ListItemIcon>
                )}
                <Typography variant="body2" className={classes.menuItem}>
                  {item.label}
                </Typography>
              </MuiMenuItem>
            );
          })}
        </Paper>
      </MenuList>
    </Fade>
  );
};

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    icon: {
      color: theme.palette.primary.dark,
    },
    menuItem: {
      fontWeight: 600,
    },
  })
);

export default Menu;
