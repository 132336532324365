import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';

import { NotesByDate } from 'models';

import EditNote from './EditNote';
import NoteAttachments from './NoteAttachments';
import { getParsedMessage } from './utils';
import VehicleCardNoteEditDropDown from './VehicleCardNoteEditDropDown';
import VehicleCardNoteInput from './VehicleCardNoteInput';

import './VehicleCardNotes.scss';

interface VehicleCardNotesProps {
  vehicleId: string;
  notes: NotesByDate[];
  isInTab?: boolean;
  shouldStageAttachments?: boolean;
}
const VehicleCardNotes = ({
  vehicleId,
  notes,
  isInTab = false,
  shouldStageAttachments = false,
}: VehicleCardNotesProps) => {
  const notesRef = useRef<HTMLDivElement>(null);
  const [editingNoteId, setEditingNoteId] = useState<string | null>(null);
  const handleEditNoteClick = (noteId: string) => setEditingNoteId(noteId);

  useEffect(() => {
    notesRef?.current?.scrollTo({
      top: notesRef?.current?.scrollHeight,
      behavior: 'auto',
    });
  }, [notes]);

  const Notes = useMemo(() => {
    if (!notes?.length) {
      return (
        <div className="VehicleCardNotes-emptyNotes">
          When a note is shared about this vehicle, it will appear here.
        </div>
      );
    }
    return notes?.map((notesByDate) => {
      const { notesByUser, timestamp, date } = notesByDate;

      const momentDate = moment(date).format('MMM D, YYYY');
      const yesterdaysDate = moment().subtract(1, 'days').format('MMM D, YYYY');
      const todaysDate = moment().format('MMM D, YYYY');
      let dateText = momentDate;
      if (todaysDate === momentDate) {
        dateText = 'Today';
      } else if (yesterdaysDate === momentDate) {
        dateText = 'Yesterday';
      }

      return (
        <div
          key={`notesByDate-${timestamp}`}
          className="VehicleCardNotes-notesByDate"
        >
          <div className="VehicleCardNotes-date">{dateText}</div>
          {notesByUser.map((noteByUser) => {
            const { fullName, userId, notes } = noteByUser;
            const time = moment(notes?.[0]?.time).format('h:mm A');
            return (
              <div
                key={`note-${fullName}-${time}`}
                className="VehicleCardNotes-note"
              >
                <div className="VehicleCardNotes-senderTime">
                  <div className="VehicleCardNotes-sender">{fullName}</div>
                  <div className="VehicleCardNotes-time">{time}</div>
                </div>
                {notes?.map((note, index) => {
                  const { attachments, id: noteId } = note;
                  const text = getParsedMessage(note.note);
                  return (
                    <div
                      key={`message-${note.time}-${index}`}
                      className="VehicleCardNotes-messageContainer"
                    >
                      <div className="VehicleCardNotes-messageDropdown">
                        <div className="VehicleCardNotes-message">{text}</div>
                        <VehicleCardNoteEditDropDown
                          note={note}
                          userId={userId}
                          vehicleId={vehicleId}
                          onEditNoteClick={handleEditNoteClick}
                        />
                      </div>
                      <NoteAttachments
                        attachments={attachments}
                        noteId={noteId!}
                        userId={userId}
                        vehicleId={vehicleId}
                      />
                      {editingNoteId === noteId && (
                        <EditNote
                          note={note}
                          vehicleId={vehicleId}
                          onClose={() => setEditingNoteId(null)}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      );
    });
  }, [editingNoteId, notes, vehicleId]);

  const tabClass = isInTab ? 'VehicleCardNotesTab' : '';

  return (
    <div className={`VehicleCardNotes ${tabClass}`}>
      <div ref={notesRef} className="VehicleCardNotes-notes">
        {Notes}
      </div>
      <VehicleCardNoteInput
        vehicleId={vehicleId}
        shouldStageAttachments={shouldStageAttachments}
      />
    </div>
  );
};

export default VehicleCardNotes;
