import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';

import { defaultMutationFn } from 'api/useAPI';
import { Invoice } from 'models/invoicing';

const usePayInvoiceMutation = (invoiceId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    () => {
      return defaultMutationFn(
        `/vendor-invoice/${invoiceId}/statusPaid`,
        'PUT'
      ) as Promise<UseMutationResult<Invoice>>;
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(['/inventory/invoicedInventories']);
        queryClient.invalidateQueries(['invoices']);
        queryClient.invalidateQueries([`/vendor-invoice/${invoiceId}`]);
      },
    }
  );
};

export default usePayInvoiceMutation;
