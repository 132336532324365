import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { APIResponse, defaultMutationFn } from 'api';
import { permissions } from 'common';
import { usePermissions } from 'hooks';
import { Notification } from 'models';

const NOTIFICATIONS_PATH = '/notifications';

const invalidateNotifications = async (queryClient: QueryClient) => {
  await queryClient.invalidateQueries([NOTIFICATIONS_PATH]);
  await queryClient.invalidateQueries([`${NOTIFICATIONS_PATH}/unreadCount`]);
};

export function useNotifications() {
  const { hasPermission } = usePermissions();
  const enabled = hasPermission(permissions.NOTIFICATIONS_VIEW);
  return useQuery<APIResponse<Notification[]>>([NOTIFICATIONS_PATH], {
    enabled,
  });
}

export function useUnreadNotificationsCount() {
  const { hasPermission } = usePermissions();
  const enabled = hasPermission(permissions.NOTIFICATIONS_VIEW);
  return useQuery<APIResponse<number>>([`${NOTIFICATIONS_PATH}/unreadCount`], {
    enabled,
    refetchInterval: 1000 * 60 * 10,
  });
}

export function useDeleteAllNotifications() {
  const queryClient = useQueryClient();

  return useMutation(() => defaultMutationFn(NOTIFICATIONS_PATH, 'DELETE'), {
    onSuccess: async () => {
      await invalidateNotifications(queryClient);
    },
  });
}

export function useDeleteNotification() {
  const queryClient = useQueryClient();
  return useMutation(
    (notificationId: string) => {
      const path = `${NOTIFICATIONS_PATH}/${notificationId}`;
      return defaultMutationFn(path, `DELETE`);
    },
    {
      onSuccess: async () => {
        await invalidateNotifications(queryClient);
      },
    }
  );
}

export function useUpdateNotificationReadStatus() {
  const queryClient = useQueryClient();

  return useMutation(
    (notificationId: string) => {
      const path = `${NOTIFICATIONS_PATH}/${notificationId}?read=true`;
      return defaultMutationFn(path, `PUT`);
    },
    {
      onSuccess: async () => {
        await invalidateNotifications(queryClient);
      },
    }
  );
}
