import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  makeStyles,
  Modal,
  Theme,
} from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import Close from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';

import { hasImageContentType, strings } from 'common';
import theme from 'common/theme';
import { hasVideoContentType } from 'common/video';
import { useWindowSize } from 'hooks';
import { VehicleImage } from 'models';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

type Props = {
  open: boolean;
  media: VehicleImage[];
  onClose: (event: React.SyntheticEvent<HTMLButtonElement, Event>) => void;
  onDelete?: (id: string) => void;
  selectedItemIndex?: number;
  canDelete?: boolean;
};

const MobileMediaViewer = ({
  open,
  media,
  onClose,
  onDelete,
  selectedItemIndex = 0,
  canDelete,
}: Props) => {
  const classes = useStyles();
  const { width } = useWindowSize();
  const thumbnailRef = useRef<HTMLElement[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const thumbnailWidth = width / 3;
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  useEffect(() => {
    setSelectedIndex(selectedItemIndex);
  }, [selectedItemIndex]);

  const scrollItemIntoView = (index: number) => {
    thumbnailRef?.current?.[index]?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const hideDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
  };

  const handleDeleteMedia = () => {
    onDelete?.(media[selectedIndex].id ?? '');
    hideDeleteConfirmation();
  };
  return (
    <>
      <Modal open={open} style={{ backgroundColor: '#000', zIndex: 9999 }}>
        <Box className={classes.container} onClick={(e) => e.stopPropagation()}>
          <Box className={classes.header}>
            <IconButton
              size="small"
              style={{
                color: theme.palette.grey[400],
                backgroundColor: '#0000009e',
              }}
              onClick={(e) => onClose(e)}
            >
              <Close className={classes.arrow} />
            </IconButton>
            {canDelete && (
              <IconButton
                size="small"
                style={{
                  color: theme.palette.grey[400],
                  backgroundColor: '#0000009e',
                }}
                onClick={() => {
                  setShowDeleteConfirmation(true);
                }}
              >
                <DeleteIcon className={classes.arrow} />
              </IconButton>
            )}
          </Box>
          <Carousel
            className={classes.carousel}
            showThumbs={false}
            onChange={(index) => {
              setSelectedIndex(index);
              scrollItemIntoView(index);
            }}
            selectedItem={selectedIndex}
          >
            {media.map((media, index) => {
              return (
                <MediaItem
                  key={index}
                  source={media.uri}
                  height={400}
                  width={width}
                  contentType={media.contentType!}
                  className={classes.image}
                  controls={true}
                />
              );
            })}
          </Carousel>
          <Box className={classes.thumbnailContainer}>
            {media.map((media, index) => {
              return (
                <MediaItem
                  isThumbnail
                  ref={(ref: HTMLElement) =>
                    (thumbnailRef.current[index] = ref)
                  }
                  key={index}
                  source={media.uri}
                  height={100}
                  width={thumbnailWidth}
                  contentType={media.contentType!}
                  className={`${classes.thumbnail} ${
                    index === selectedIndex && classes.selected
                  }`}
                  onClick={() => {
                    setSelectedIndex(index);
                    scrollItemIntoView(index);
                  }}
                />
              );
            })}
          </Box>
        </Box>
      </Modal>
      <Dialog
        style={{ zIndex: 99999 }}
        open={showDeleteConfirmation}
        onClose={hideDeleteConfirmation}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {strings.DELETE_IMAGE_MODAL_TITLE}
        </DialogTitle>
        <DialogActions>
          <Button autoFocus onClick={hideDeleteConfirmation}>
            {strings.CANCEL}
          </Button>
          <Button onClick={handleDeleteMedia} color="primary" autoFocus>
            {strings.DELETE}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

type MediaItemProps = {
  contentType: string;
  source: string;
  width: number;
  height: number;
  className: string;
  onClick?: (event: React.SyntheticEvent) => void;
  isThumbnail?: boolean;
  controls?: boolean;
};

const MediaItem = React.forwardRef(
  (
    {
      contentType,
      source,
      width,
      height,
      className,
      onClick = () => {},
      controls,
      isThumbnail,
      ...props
    }: MediaItemProps,
    ref
  ) => {
    if (hasImageContentType(contentType)) {
      return (
        <img
          ref={ref as RefObject<HTMLImageElement>}
          src={source}
          alt="media"
          className={className}
          width={width}
          height={height}
          onClick={onClick}
          {...props}
        />
      );
    }

    if (hasVideoContentType(contentType)) {
      return (
        <Box
          style={{
            position: 'relative',
            width,
            height,
          }}
        >
          <video
            ref={ref as RefObject<HTMLVideoElement>}
            className={className}
            width={width}
            height={height}
            onClick={onClick}
            controls={controls}
            {...props}
          >
            <source src={`${source}#t=0.001`} type="video/mp4" />
            {/* HACK - Hack required to show video preview in mobile safari. 
            https://muffinman.io/blog/hack-for-ios-safari-to-display-html-video-thumbnail/ */}
          </video>
          {isThumbnail && (
            <PlayCircleFilledIcon
              style={{
                fontSize: '40px',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 9999,
                color: theme.palette.grey[700],
              }}
            />
          )}
        </Box>
      );
    }

    // HACK - Content type needs to be set to application/octet-stream to force browser to download file
    // in mobile safari
    return (
      <object
        data={source}
        type={'application/octet-stream'}
        width={width}
        height={height}
      >
        <div
          className="ModalCarousel-view-attachment-icon"
          style={{ width: 'auto', height: '100%' }}
          onClick={(e) => {
            if (isThumbnail) {
              onClick(e);
            } else {
              window.open(source);
            }
          }}
        >
          <GetApp
            style={
              isThumbnail
                ? { fontSize: '50px', color: theme.palette.grey[700] }
                : undefined
            }
          />
          {!isThumbnail && (
            <Button
              variant="contained"
              color="secondary"
              href="#contained-buttons"
            >
              Download
            </Button>
          )}
        </div>
      </object>
    );
  }
);

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flex: '1',
      flexDirection: 'column',
      height: '100%',
    },
    header: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: '50px',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(1),
      zIndex: 9999,
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    image: {
      objectFit: 'contain',
    },
    thumbnail: {
      objectFit: 'cover',
      padding: '2px',
      transition: 'transform 400ms, border-color 500ms',
    },
    thumbnailContainer: {
      overflowY: 'scroll',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    selected: {
      border: `2px solid ${theme.palette.grey[400]}`,
      padding: 0,
    },

    '@global': {
      '.carousel .carousel-status': {
        top: 'auto',
        left: 0,
        right: 0,
        width: '60px',
        marginLeft: 'auto',
        marginRight: 'auto',
        bottom: theme.spacing(1),
        textAlign: 'center',
      },
    },
  })
);
export default MobileMediaViewer;
