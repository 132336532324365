/* eslint unused-imports/no-unused-imports: 1 */
/* eslint unused-imports/no-unused-vars: 1 */
import moment from 'moment';
import { FC, useMemo, useState } from 'react';

import FlatVDPPaneHeader from 'components/shared/FlatVDPPaneHeader';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { useWindowSize } from 'hooks';
import { useCarfax } from 'hooks/useCarfax';
import { CarfaxReport as ICarfaxReport } from 'models/carfax';

import CarfaxControls from './CarfaxControls';
import CarfaxPurchaseReport from './CarfaxPurchaseReport';
import { CarfaxReport } from './CarfaxReport';
import HistoryBasedValue from './HistoryBasedValue';

import './CarfaxLandingPage.scss';

interface CarfaxLandingPageProps {
  vin: string;
  zipCode: string;
}

const CarfaxLandingPage: FC<CarfaxLandingPageProps> = ({ vin, zipCode }) => {
  const { useCarfaxReport } = useCarfax();
  const isMobile = useWindowSize().isMobileViewport();

  const { isLoading, data } = useCarfaxReport(vin);
  const [currentTab, setCurrentTab] = useState<'report' | 'hbv'>('report');

  const isCarfaxReportAvailable = useMemo<boolean | string | undefined>(() => {
    return !isLoading && data?.carfaxLink && moment().isBefore(data?.expiresAt);
  }, [isLoading, data]);
  return (
    <>
      <FlatVDPPaneHeader className="carfax-header">
        <div>CARFAX</div>
        <CarfaxControls currentTab={currentTab} setCurrentTab={setCurrentTab} />
      </FlatVDPPaneHeader>
      {isMobile && (
        <CarfaxControls
          isMobile
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      )}

      {isLoading && <LoadingIndicator />}
      {currentTab === 'report' ? (
        <>
          {isCarfaxReportAvailable ? (
            <CarfaxReport data={data as ICarfaxReport} vin={vin} />
          ) : (
            !isLoading && <CarfaxPurchaseReport vin={vin} />
          )}
        </>
      ) : (
        <HistoryBasedValue vin={vin} zipCode={zipCode} />
      )}
    </>
  );
};
export default CarfaxLandingPage;
